<div class="map-container">
    <h3 class="desc-title">
        <circle-progress class="only-web" *ngIf="loaderService.flow_detail != constants.add_fav_lab"
            [options]="loaderService.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-slocation.svg" class="copy">
        </circle-progress>
        <div class="img-container" *ngIf="loaderService.flow_detail == constants.add_fav_lab"> <img class="fav-lab-img"
                src="assets/images/application-logos/svg/ic-slocation.svg" /></div>

        {{'lab-google.lab-desc'|translate}}
    </h3>
    <div class="MapWrapper">
        <div class="map-inner mt-40">
            <div class="container-search">
                <div [hidden]="!mapLoaded" id="search" class="search-container">
                    <div class="search-tab">
                        <input matInput type="text" id="autocomplete-input" name="address"
                            class="form-control search-input" placeholder="{{'lab-google.search' | translate}}"
                            #searchData (click)="samplingStaton=''" autocomplete="off" autocorrect="off"
                            autocapitalize="off" spellcheck="off" [(ngModel)]="samplingStaton"
                            [formControl]="myControl" />
                    </div>
                    <button class="only-web near-me-button" (click)="nearMe()" mat-button>{{'lab-google.nearme' |
                        translate}}</button>
                    <button class="only-mobile near-me-button" (click)="nearMe()" mat-button><span
                            class="icon-ic-near-me"></span></button>
                </div>
            </div>
            <div [hidden]="!mapLoaded" id="searchResults">
                <div class="action">
                    <button class="open-button" id='open'
                        [ngClass]="loaderService.openNowClicked ? 'open-filled' : 'open-empty'" (click)="openNow()"
                        mat-button>{{(loaderService.openNowClicked ? 'lab-google.currently-open' :
                        'lab-google.currently-open') | translate}}</button>
                    <button hidden style="width:33%;" mat-button>{{'lab-google.schedule' | translate}}</button>
                </div>
                <div class="panel">
                    <div id="error" style="display:none; background-color: #eee;" class="info">
                        <div>
                            <div style="display: inline-flex;margin: 5px;align-items: baseline;">
                                <b style="margin-left: 8px;">{{'lab-google.test-unavailable' | translate}}</b>
                                <span hidden class="icon-ic_fav-filled" style="font-size: 20px;width:10%;"></span>
                            </div>
                            <div style="display: inline-flex;margin: 5px;align-items: baseline;">
                                <b style="margin-left: 8px;">{{selectedStation.sampling_station_name}}
                                </b>
                                <span hidden class="icon-ic_fav-filled" style="font-size: 20px;width:10%;"></span>
                            </div>
                            <div style="display: inline-flex;margin: 5px;align-items: baseline;width:80%;">
                                <span class="icon-ic-location" style="font-size: 20px;width:10%;"></span>
                                <label
                                    style="word-break: break-word;margin-left: 8px;margin-right: 8px;width:80%;">{{selectedStation.address}}</label>
                            </div>
                            <div style="display: none;margin: 5px;align-items: baseline;width:80%;">
                                <span class="icon-ic-clock" style="font-size: 20px;width:10%;"></span>
                                <label
                                    style="word-break: break-word;margin-left: 8px;width:80%;">{{selectedStation.time}}</label>
                            </div>
                            <div style="display: inline-flex;margin: 5px;align-items: baseline;width:80%;">
                                <span class="icon-ic-mobile" style="font-size: 20px;width:10%;"></span>
                                <label
                                    style="word-break: break-word;margin-left: 8px;width:80%;">{{selectedStation.formatted_phone_number
                                    | phonenoFormate}}</label>
                            </div>
                        </div>
                    </div>
                    <div id="information" *ngFor="let station of samplingStationsTestType" class="cp">
                        <div class="info" *ngIf="station.address">
                            <div class="station-title">
                                <mat-radio-button *ngIf="loaderService.flow_detail != constants.add_fav_lab"
                                    [checked]="station.id === selectedStation.id"
                                    (change)="samplingStationselect(station)">
                                    <span class="ss_name" (click)="samplingStationselect(station)"><b
                                            class="cp">{{station.sampling_station_name}}</b></span>
                                </mat-radio-button>

                                <mat-checkbox *ngIf="loaderService.flow_detail == constants.add_fav_lab"
                                    [(ngModel)]="station.is_favourite">
                                    <span class="ss_name"><b>{{station.sampling_station_name}}</b></span>
                                </mat-checkbox>
                                <div class="cp"
                                    [ngClass]="[station.is_favourite ? 'icon-ic_fav-filled' : 'icon-ic_fav-empty',loaderService.flow_detail == constants.add_fav_lab ? 'no-cursor' : '']"
                                    (click)="onSelectedLab($event,station)"
                                    style="font-size: 23px !important;width:20%;"></div>
                            </div>

                            <div style="display: inline-flex;margin: 5px;align-items: baseline;width:80%;"
                                (click)="openDirections(station)">
                                <span class="icon-ic-location" style="font-size: 20px;width:10%;"></span>
                                <label class="cp"
                                    style="word-break: break-word;margin-left: 8px;margin-right: 8px;width:80%;">{{station.address}}</label>
                            </div>
                            <div style="display: none;margin: 5px;align-items: baseline;width:80%;">
                                <span class="icon-ic-clock" style="font-size: 20px;width:10%;"></span>
                                <label
                                    style="word-break: break-word;margin-left: 8px;width:80%;">{{station.time}}</label>
                            </div>
                            <div style="display: inline-flex;margin: 5px;align-items: baseline;width:80%;">
                                <span class="icon-ic-mobile" style="font-size: 20px;width:10%;"></span>
                                <label style="word-break: break-word;margin-left: 8px;width:80%;">{{station.phone_number
                                    | phonenoFormate}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div id="woosmap-map" class="map">
        </div>

        <div [hidden]="!mapLoaded" class="searchResultsMobile">
            <div class="panel"
                [ngClass]="{'mt-40':loaderService.flow_detail != constants.add_fav_lab, 'mt-60':loaderService.flow_detail == constants.add_fav_lab}">
                <mat-slide-toggle (click)="openNow()">{{'lab-google.currently-open' | translate}}</mat-slide-toggle>
                <div id="information" class="info-box-container" *ngFor="let station of samplingStationsTestType">
                    <div class="info" *ngIf="isMobile">
                        <div class="info-header station-title">
                            <mat-radio-button *ngIf="loaderService.flow_detail != constants.add_fav_lab"
                                [checked]="station.id === selectedStation.id" (change)="samplingStationselect(station)">
                                <span class="ss_name" (click)="samplingStationselect(station)">
                                    <strong>{{station.sampling_station_name}}</strong></span>
                            </mat-radio-button>
                            <mat-checkbox *ngIf="loaderService.flow_detail == constants.add_fav_lab"
                                [(ngModel)]="station.is_favourite">
                                <span class="ss_name ">
                                    <strong>{{station.sampling_station_name}}</strong></span>
                            </mat-checkbox>
                            <!-- <div class="ml-40" *ngIf="loaderService.flow_detail == constants.add_fav_lab"><span
                                    class="ss_name"><b class="cp">{{station.sampling_station_name}}</b></span>
                            </div> -->
                            <div [ngClass]="[station.is_favourite ? 'icon-ic_fav-filled' : 'icon-ic_fav-empty',loaderService.flow_detail == constants.add_fav_lab ? 'no-cursor' : '']"
                                (click)="onSelectedLab($event,station)" class="fav-icon"></div>
                        </div>

                        <div class="info-header">
                            <span class="icon-ic-location location-icon"></span>
                            <label class="color-blue" (click)="openDirections(station)">{{station.address}}</label>
                        </div>
                        <div class="info-header">
                            <span class="icon-ic-clock"></span>
                            <label *ngIf="getOpeningClosing(station)">{{getOpeningClosing(station)}}</label>
                            <label class="color-red" *ngIf="!getOpeningClosing(station)">{{'lab-google.closed' |
                                translate}}</label>
                        </div>
                        <div class="info-header">
                            <span class="icon-ic-mobile"></span>
                            <label><a href="tel:{{station.phone_number | removeZero }}">{{station.phone_number |
                                    phonenoFormate}}</a></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="action-btn">
        <button mat-raised-button *ngIf="loaderService.flow_detail != constants.add_fav_lab" class="next-btn"
            type="button" [ngClass]="{'full-width':hideBack}" (click)="goToNext()">
            {{'date-slot.next' | translate}}
        </button>

        <button mat-raised-button *ngIf="loaderService.flow_detail == constants.add_fav_lab" class="next-btn"
            type="button" [ngClass]="{'full-width':hideBack}" (click)="addRemoveToFavorites()">
            {{'lab-google.add' | translate}}
        </button>
        <button mat-raised-button class="back-btn" *ngIf="!hideBack" type="button" (click)="goToBack()">
            {{'check-pres.back' | translate}}
        </button>

    </div>
</div>