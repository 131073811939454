export const constants = {
    nurseRole: 1,
    extMinPwdLength: 24,
    intMinPwdLength: 12,
    nurseMinPwdLength: 15,
    pwdMaxLength: 128,
    supportMinPwdLength: 15,
    supportRole: 2,
    adminRole: 5,
    ehpAdminRole: 8,
    ehpSupervisorRole: 9,
    ehpUserRole: 10,
    supervisorRole: 3,
    patientRole: 4,
    covidVaccinationDoc: 46,
    ssnOrPassportDoc: 44,
    insuranceDoc: 47,
    birthDoc: 83,
    male: '15',
    female: '16',
    other: '17',
    nationality: 1,
    passwordValidation:
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[@*%&+\\/\\\\'`!#$^?:.(){}[\\]~\\-_])(?=.{12,128}).*$",
    receptionistpasswordValidation:
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[@*%&+\\/\\\\'`!#$^?:.(){}[\\]~\\-_])(?=.{15,128}).*$",
    adminpasswordValidation:
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*?[@*&%+\\/\\\\'`!#$^?:.(){}[\\]~\\-_])(?=.{24,128}).*$",
    documentFileNameLengthWeb: 70,
    documentFileNameLengthMobile: 21,
    ssLevelCode: '07h20',
    companyLevelCode: '5mmru',
    countryLevelCode: 'c2l0f',
    hasNoSpecialChars: '/^[A-Za-z0-9àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ\s@.]+$/',
    doc_info: 1,
    have_request_number: 2,
    enter_token: 3,
    check_file: 4,
    check_presc: 5,
    select_lab: 6,
    select_test: 7,
    select_slot: 8,
    upload_presc: 9,
    login: 10,
    confirm_page: 11,
    token_number: 12,
    doc_upload: 13,
    inside_lab_flow: 1,
    website_flow: 2,
    findlab_flow: 3,
    book_app_flow: 4,
    recep_flow: 5,
    complete_booking_flow: 6,
    favlab_flow: 7,
    massiveflowwithtest: 8,
    massiveflowwithlab: 9,
    massiveflowwithtestlab: 10,
    add_fav_lab: 11,
    ehp_user_type: 'ehp',
    eclinic_user_type: 'eclinic',
    ehp_page_type: 'ehp',
    eclinic_page_type: 'eclinic',
    nav_user_ehp: 'nav-user-ehp',
    nav_supervisor_ehp: 'nav-supervisor-ehp',
    nav_admin_ehp: 'nav-admin-ehp',
    nav_user: 'nav-user',
    nav_supervisor: 'nav-supervisor',
    nav_admin: 'nav-admin',
    nav_receptionist: 'nav-receptionist',
}

export const constantsarr = {
    inside_lab_withappointment: [constants.have_request_number, constants.enter_token, constants.token_number],
    inside_lab_withpresc: [constants.have_request_number, constants.doc_info, constants.check_presc, constants.upload_presc, constants.login, constants.confirm_page],
    inside_lab_withoutpresc: [constants.have_request_number, constants.doc_info, constants.check_presc, constants.select_test, constants.login, constants.confirm_page],
    website_withoutpresc: [constants.doc_info, constants.check_presc, constants.select_test, constants.select_slot, constants.login, constants.confirm_page, constants.token_number],
    website_withpresc: [constants.doc_info, constants.check_presc, constants.select_slot, constants.upload_presc, constants.login, constants.confirm_page],
    selectlab_withoutpresc: [constants.select_lab, constants.doc_info, constants.check_file, constants.check_presc, constants.select_test, constants.select_slot, constants.login, constants.confirm_page],
    selectlab_withpresc: [constants.select_lab, constants.doc_info, constants.check_file, constants.check_presc, constants.select_slot, constants.upload_presc, constants.login, constants.confirm_page],
    favlab_withoutpresc: [constants.doc_info, constants.check_file, constants.check_presc, constants.select_test, constants.select_slot, constants.login, constants.confirm_page],
    favlab_withpresc: [constants.doc_info, constants.check_file, constants.check_presc, constants.select_slot, constants.upload_presc, constants.login, constants.confirm_page],
    book_withoutpresc: [constants.doc_info, constants.check_file, constants.check_presc, constants.select_test, constants.select_lab, constants.select_slot, constants.login, constants.confirm_page],
    book_withpresc: [constants.doc_info, constants.check_file, constants.check_presc, constants.select_lab, constants.select_slot, constants.upload_presc, constants.login, constants.confirm_page],
    recep_flow_withpresc: [constants.check_presc, constants.select_slot, constants.login, constants.confirm_page],
    recep_flow_withoutpresc: [constants.check_presc, constants.select_test, constants.select_slot, constants.login, constants.confirm_page],
    massive_flow_withtest: [constants.doc_info, constants.select_lab, constants.select_slot, constants.login, constants.confirm_page],
    massive_flow_withlab: [constants.doc_info, constants.select_test, constants.select_slot, constants.login, constants.confirm_page],
    massive_flow_withtestlab: [constants.doc_info, constants.select_slot, constants.login, constants.confirm_page],

    active_complete_booking_flow_withdocpresc: [constants.login, constants.doc_upload, constants.upload_presc, constants.confirm_page],
    active_complete_booking_flow_withpresc: [constants.login, constants.upload_presc, constants.confirm_page],
    active_complete_booking_flow_withdoc: [constants.login, constants.doc_upload, constants.confirm_page],
    active_complete_booking_flow_withoutdocpresc: [constants.login, constants.confirm_page],

    inactive_complete_booking_flow_withoutpresc: [constants.login, constants.confirm_page],
    inactive_complete_booking_flow_withpresc: [constants.login, constants.upload_presc, constants.confirm_page],
    inside_lab_withprescwithtest: [constants.upload_presc, constants.login, constants.confirm_page],
    inside_lab_withoutprescwithtest: [constants.login, constants.confirm_page],
    add_lab: [constants.select_lab]


}