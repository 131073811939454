<div class="calenderContainer" [ngClass]="loader.loggedIn ? 'calenderContainer-loggedin' : 'calenderContainer-guest'">
    <div *ngIf="fromBookingFlow" class="section-header"
        [ngClass]="loader.loggedIn ? 'section-header-loggedin' : 'section-header-guest'">
        <circle-progress class="only-web" [options]="loader.optionsG" [percent]="percent" [renderOnClick]="false"
            imageSrc="assets/images/application-logos/svg/ic-sappintment.svg" class="copy">
        </circle-progress>
        <h3 class="calenderTitle"> {{'date-slot.calendar-desc'|translate}} </h3>
    </div>
    <div *ngIf="loader.loggedIn && loader.isLoginReceptionist" class="patient-info">
        {{'stepper-flow.bookingFor' | translate}} :
        <span class="patientname">{{loader.bookingPatientName | translate}}</span>
    </div>
    <div class="calenderWebWrapper"
        [ngClass]="{'calenderWebWrapper-nurse': loader.isLoginReceptionist, 'calenderWebWrapper-guest': !loader.loggedIn, 'calenderWebWrapper-patient': loader.loggedIn && !loader.isLoginReceptionist}">

        <div class="accordion-wrapper">
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel class="panel-container" *ngFor="let item of filteredDates; index as index"
                    [expanded]="index===0">
                    <mat-expansion-panel-header class="acc-panel-header" id="pHeader{{index}}">
                        <mat-panel-title class="panel-title" id="pTitle{{index}}">
                            {{item.date | date:'EEEE, d MMMM':undefined:localeService.localeLang}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="timeSlotsContainer">
                        <button mat-button *ngFor="let slot of item.filterSlots; index as btnIndex"
                            class="selectslot-btn" id="slotButton{{btnIndex}}"
                            (click)="selectSlot(slot, btnIndex, index)" [ngClass]="{
                                'selected': selectedSlot?.panelIndex === index && selectedSlot?.slotIndex === btnIndex
                            }">
                            {{ slot.split('|')[0] | date:'HH:mm' }}
                        </button>
                    </div>
                    <mat-action-row *ngIf="item?.slots?.length>item?.filterSlots?.length">
                        <span class="viewMoreBtn" id="viewMore{{btnIndex}}"
                            (click)="viewMore(item)">{{'date-slot.view-more'|translate}}</span>
                    </mat-action-row>
                </mat-expansion-panel>
                <div class="showMoreSlotsBtn" id="viewMoreDays" (click)="viewMoreDays()" *ngIf="calendar_id>0">
                    {{'date-slot.view-more-days'|translate}}</div>
            </mat-accordion>
        </div>
    </div>
    <div *ngIf="fromBookingFlow" class="action-btn">
        <button mat-raised-button class="next-btn" type="button" (click)="goToNext()">
            {{'date-slot.next' | translate}}
        </button>
        <button mat-raised-button class="back-btn" type="button" (click)="goToBack()">
            {{'check-pres.back' | translate}}
        </button>
    </div>
</div>