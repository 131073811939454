import { Component } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

export interface PatientRecord {
  isRead: boolean;
  patient: string;
  dob: string;
  ipp: string;
  hospitalId: string;
  dossier: string;
  prescriber: string;
  department: string;
  status: string;
  slotDate: string;
  modifyDate: string;
  gender: string;
  is_emergency: boolean;
}

@Component({
  selector: 'app-result-grid',
  templateUrl: './result-grid.component.html',
  styleUrls: ['./result-grid.component.scss']
})
export class ResultGridComponent {
  displayedColumns: string[] = [
    'isRead',
    'patient',
    'dob',
    'ipp',
    'hospitalId',
    'dossier',
    'prescriber',
    'department',
    'status',
    'slotDate',
    'modifyDate',
    'actions'
  ];

  dataSource: PatientRecord[] = [
    {
      isRead: false,
      patient: 'Dove Pullman',
      dob: '01/02/2025',
      ipp: '1234',
      hospitalId: '56789',
      dossier: 'A12345',
      prescriber: 'Dr. Smith',
      department: 'Cardiology',
      status: 'Ongoing Analysis',
      slotDate: '2025-12-26 10:00',
      modifyDate: '2024-11-26 14:00',
      gender: 'female',
      is_emergency: true
    },
    {
      isRead: true,
      patient: 'Dubblin Star',
      dob: '01/01/2012',
      ipp: '1235',
      hospitalId: '56789',
      dossier: 'B12345',
      prescriber: 'Dr. Park',
      department: 'Cardiology',
      status: 'Sample Received',
      slotDate: '2024-12-26 10:00',
      modifyDate: '2024-12-27 14:00',
      gender: 'male',
      is_emergency: false
    },
    {
      isRead: false,
      patient: 'Jane Rio',
      dob: '02/02/1990',
      ipp: '5678',
      hospitalId: '98765',
      dossier: 'C67890',
      prescriber: 'Dr. Park',
      department: 'Neurology',
      status: 'Results',
      slotDate: '2024-12-26 11:00',
      modifyDate: 'N/A',
      gender: 'female',
      is_emergency: true
    },
    {
      isRead: true,
      patient: 'Dove Pullman',
      dob: '01/01/2012',
      ipp: '2234',
      hospitalId: '56789',
      dossier: 'D12345',
      prescriber: 'Dr. Wood',
      department: 'Cardiology',
      status: 'Ongoing Analysis',
      slotDate: '2025-12-01 18:00',
      modifyDate: '2025-01-02 10:00',
      gender: 'female',
      is_emergency: false
    },
    {
      isRead: false,
      patient: 'Phillip Pullman',
      dob: '04/01/1999',
      ipp: '3234',
      hospitalId: '56789',
      dossier: 'D12345',
      prescriber: 'Dr. Wood',
      department: 'Therapy',
      status: 'Partial results',
      slotDate: '2025-12-01 18:00',
      modifyDate: '2025-01-04 10:00',
      gender: 'male',
      is_emergency: false
    }
  ];
  

  filtersApplied: { [key: string]: boolean } = {};

  searchText: string = '';
  filteredPatients: { id: string; name: string }[] = [];
  selectedPatients: string[] = [];

  prescriberSearchText: string = '';
  filteredPrescribers: { id: string; name: string }[] = [];
  selectedPrescribers: string[] = [];

  departmentSearchText: string = '';
  filteredDepartments: { id: string; name: string }[] = [];
  selectedDepartments: string[] = [];

  dossierSearchText: string = '';
  filteredDossier: { id: string; name: string }[] = [];
  selectedDossier: string[] = [];

  ippSearchText: string = '';
  filteredIpp: { id: string; name: string }[] = [];
  selectedIpp: string[] = [];

  hospitalSearchText: string = '';
  filteredHospital: { id: string; name: string }[] = [];
  selectedHospital: string[] = [];

  selectedStatuses: string[] = [];

  startDate: Date | null = null;
  endDate: Date | null = null;
  startTime: string = '00:00';
  endTime: string = '23:59';

  modifyStartDate: Date | null = null;
  modifyEndDate: Date | null = null;
  modifyStartTime: string = '00:00';
  modifyEndTime: string = '23:59';

  activeColumn: 'slotDate' | 'modifyDate' = 'slotDate';

  filters = {
    slotDate: {
      startDate: null,
      endDate: null,
      startTime: '00:00',
      endTime: '23:59'
    },
    modifyDate: {
      startDate: null,
      endDate: null,
      startTime: '00:00',
      endTime: '23:59'
    },
    dob: {
      startDate: null,
      endDate: null,
    }
  };
  
  filteredDataSource: PatientRecord[] = [...this.dataSource];

  isReadFilter: boolean | null = null;

  constructor() {
    this.filteredPatients = this.patients;
    this.filteredPrescribers = this.prescribers;
    this.filteredDepartments = this.departments;
    this.filteredDossier=this.dossiers;
    this.filteredHospital = this.hospitalIds;
    this.filteredIpp= this.ipps;
  }

  // get patients(): { id: string; name: string }[] {
  //   return this.filteredDataSource.map(record => ({
  //     id: record.patient,
  //     name: record.patient
  //   }));
  // }
  get patients(): { id: string; name: string }[] {
    const uniquePatients = new Map<string, string>();
    this.dataSource.forEach(record => {
      if (!uniquePatients.has(record.prescriber)) {
        uniquePatients.set(record.patient, record.patient);
      }
    });
    return Array.from(uniquePatients.entries()).map(([id, name]) => ({ id, name }));
  }
  get prescribers(): { id: string; name: string }[] {
    const uniquePrescribers = new Map<string, string>();
    this.dataSource.forEach(record => {
      if (!uniquePrescribers.has(record.prescriber)) {
        uniquePrescribers.set(record.prescriber, record.prescriber);
      }
    });
    return Array.from(uniquePrescribers.entries()).map(([id, name]) => ({ id, name }));
  }
  get departments(): { id: string; name: string }[] {
    const uniqueDepartments = new Map<string, string>();
    this.dataSource.forEach(record => {
      if (!uniqueDepartments.has(record.department)) {
        uniqueDepartments.set(record.department, record.department);
      }
    });
    return Array.from(uniqueDepartments.entries()).map(([id, name]) => ({ id, name }));
  }
  get dossiers(): { id: string; name: string }[] {
    const uniqueDossiers = new Map<string, string>();
    this.dataSource.forEach(record => {
      if (!uniqueDossiers.has(record.dossier)) {
        uniqueDossiers.set(record.dossier, record.dossier);
      }
    });
    return Array.from(uniqueDossiers.entries()).map(([id, name]) => ({ id, name }));
  }
  get ipps(): { id: string; name: string }[] {
    const uniqueIpp = new Map<string, string>();
    this.dataSource.forEach(record => {
      if (!uniqueIpp.has(record.ipp)) {
        uniqueIpp.set(record.ipp, record.ipp);
      }
    });
    return Array.from(uniqueIpp.entries()).map(([id, name]) => ({ id, name }));
  }
  get hospitalIds(): { id: string; name: string }[] {
    const uniqueHospital = new Map<string, string>();
    this.dataSource.forEach(record => {
      if (!uniqueHospital.has(record.hospitalId)) {
        uniqueHospital.set(record.hospitalId, record.hospitalId);
      }
    });
    return Array.from(uniqueHospital.entries()).map(([id, name]) => ({ id, name }));
  }
  get statuses(): { id: string; name: string }[] {
    return [
      { id: 'Ongoing Analysis', name: 'Ongoing Analysis' },
      { id: 'Sample Received', name: 'Sample Received' },
      { id: 'Partial Results', name: 'Partial Results' },
      { id: 'Results', name: 'Results' }
    ];
  }

  setActiveColumn(column: 'slotDate' | 'modifyDate'): void {
    this.activeColumn = column;
  }
  openDateMenu(column: 'slotDate' | 'modifyDate', menuTrigger: MatMenuTrigger): void {
    this.activeColumn = column;
    menuTrigger.openMenu();
  }
  onStartDateSelected(date: Date): void {
    this.startDate = date;
    this.filters.dob.startDate = date;
  }
  onEndDateSelected(date: Date): void {
    this.endDate = date;
    this.filters.dob.endDate = date;
  }

  filterRecords(): void {
    if (this.searchText) {
      const searchTextLower = this.searchText.toLowerCase();
      this.filteredPatients = this.patients.filter(patient =>
        patient.name.toLowerCase().includes(searchTextLower)
      );
    } else {
      this.filteredPatients = [...this.patients]; // Reset if no search text
    }

    if (this.prescriberSearchText) {
      const prescriberSearchTextLower = this.prescriberSearchText.toLowerCase();
      this.filteredPrescribers = this.prescribers.filter(prescriber =>
        prescriber.name.toLowerCase().includes(prescriberSearchTextLower)
      );
    } else {
      this.filteredPrescribers = [...this.prescribers]; // Reset if no prescriber search text
    }

    if (this.departmentSearchText) {
      const searchTextLower = this.departmentSearchText.toLowerCase();
      this.filteredDepartments = this.departments.filter(department =>
        department.name.toLowerCase().includes(searchTextLower)
      );
    } else {
      this.filteredDepartments = [...this.departments]; // Reset if no search text
    }

    if (this.dossierSearchText) {
      const searchTextLower = this.dossierSearchText.toLowerCase();
      this.filteredDossier = this.dossiers.filter(dossier =>
        dossier.name.toLowerCase().includes(searchTextLower)
      );
    } else {
      this.filteredDossier = [...this.dossiers]; // Reset if no search text
    }

    if (this.hospitalSearchText) {
      const searchTextLower = this.hospitalSearchText.toLowerCase();
      this.filteredHospital= this.hospitalIds.filter(hospitalId =>
        hospitalId.name.toLowerCase().includes(searchTextLower)
      );
    } else {
      this.filteredHospital = [...this.hospitalIds]; // Reset if no search text
    }

    if (this.ippSearchText) {
      const searchTextLower = this.ippSearchText.toLowerCase();
      this.filteredIpp = this.ipps.filter(ipp =>
        ipp.name.toLowerCase().includes(searchTextLower)
      );
    } else {
      this.filteredIpp = [...this.ipps]; // Reset if no search text
    }
  }

  togglePatientSelection(patientId: string, isSelected: boolean): void {
    if (isSelected) {
      if (!this.selectedPatients.includes(patientId)) {
        this.selectedPatients.push(patientId);
      }
    } else {
      this.selectedPatients = this.selectedPatients.filter(id => id !== patientId);
    }
  }
  toggleIppSelection(ippId: string, isSelected: boolean): void {
    if (isSelected) {
      if (!this.selectedIpp.includes(ippId)) {
        this.selectedIpp.push(ippId);
      }
    } else {
      this.selectedIpp = this.selectedIpp.filter(id => id !== ippId);
    }
  }
  toggleHospitalSelection(hospitalId: string, isSelected: boolean): void {
    if (isSelected) {
      if (!this.selectedHospital.includes(hospitalId)) {
        this.selectedHospital.push(hospitalId);
      }
    } else {
      this.selectedHospital = this.selectedHospital.filter(id => id !== hospitalId);
    }
  }
  toggleDossierSelection(dossierId: string, isSelected: boolean): void {
    if (isSelected) {
      if (!this.selectedDossier.includes(dossierId)) {
        this.selectedDossier.push(dossierId);
      }
    } else {
      this.selectedDossier = this.selectedDossier.filter(id => id !== dossierId);
    }
  }
  togglePrescriberSelection(prescriberId: string, isSelected: boolean): void {
    if (isSelected) {
      if (!this.selectedPrescribers.includes(prescriberId)) {
        this.selectedPrescribers.push(prescriberId);
      }
    } else {
      this.selectedPrescribers = this.selectedPrescribers.filter(id => id !== prescriberId);
    }
  }
  toggleDepartmentSelection(departmentId: string, isSelected: boolean): void {
    if (isSelected) {
      if (!this.selectedDepartments.includes(departmentId)) {
        this.selectedDepartments.push(departmentId);
      }
    } else {
      this.selectedDepartments = this.selectedDepartments.filter(id => id !== departmentId);
    }
  }
  toggleStatusSelection(statusId: string, isChecked: boolean): void {
    if (isChecked) {
        if (!this.selectedStatuses.includes(statusId)) {
            this.selectedStatuses.push(statusId);
        }
    } else {
        this.selectedStatuses = this.selectedStatuses.filter(id => id !== statusId);
    }
}


  
  applyDobFilter(): void {
    console.log("apply dob was clicked")
    const { startDate, endDate } = this.filters.dob;
    if (startDate && endDate) {
      const startDateTime = new Date(startDate);
      const endDateTime = new Date(endDate);

      console.log('hi',startDateTime);
      
      this.filteredDataSource = this.dataSource.filter(record => {
        const dobDate = new Date(record.dob);
        return dobDate >= startDateTime && dobDate <= endDateTime;
      });
      this.filtersApplied['dob'] = true;
    } else {
      this.filteredDataSource = [...this.dataSource];
      this.filtersApplied['dob'] = true;
    }
  }
  applyDateFilter(): void {
    const { startDate, endDate, startTime, endTime } = this.filters[this.activeColumn];
    if (startDate && endDate) {
      const startDateTime = new Date(startDate);
      const [startHours, startMinutes] = startTime.split(':').map(Number);
      startDateTime.setHours(startHours, startMinutes);

      const endDateTime = new Date(endDate);
      const [endHours, endMinutes] = endTime.split(':').map(Number);
      endDateTime.setHours(endHours, endMinutes);

      this.filteredDataSource = this.dataSource.filter(record => {
        const recordDate = new Date(record[this.activeColumn]);
        return recordDate >= startDateTime && recordDate <= endDateTime;
      });
      this.filtersApplied[this.activeColumn] = true;
    } else {
      this.filteredDataSource = [...this.dataSource];
      this.filtersApplied[this.activeColumn] = false;
    }
  }
  applyFilter(): void {
    let filteredData = [...this.dataSource];

    // Apply isRead filter
    if (this.isReadFilter !== null) {
      filteredData = filteredData.filter(record => record.isRead === this.isReadFilter);
      this.filtersApplied['isRead'] = true;
    }
    // Apply patient selection filter
    if (this.selectedPatients.length > 0) {
      filteredData = filteredData.filter(record =>
        this.selectedPatients.includes(record.patient)
      );
      this.filtersApplied['patient'] = true;
    }
    // Apply ipp selection filter
    if (this.selectedIpp.length > 0) {
      filteredData = filteredData.filter(record =>
        this.selectedIpp.includes(record.ipp)
      );
      this.filtersApplied['ipp'] = true;
    }
    // Apply hospital selection filter
    if (this.selectedHospital.length > 0) {
      filteredData = filteredData.filter(record =>
        this.selectedHospital.includes(record.hospitalId)
      );
      this.filtersApplied['hospital'] = true;
    }
    // Apply dossier selection filter
    if (this.selectedDossier.length > 0) {
      filteredData = filteredData.filter(record =>
        this.selectedDossier.includes(record.dossier)
      );
      this.filtersApplied['dossier'] = true;
    }
    // Apply prescriber selection filter
    if (this.selectedPrescribers.length > 0) {
      filteredData = filteredData.filter(record =>
        this.selectedPrescribers.includes(record.prescriber)
      );
      this.filtersApplied['prescriber'] = true;
    }
    // Apply department selection filter
    if (this.selectedDepartments.length > 0) {
      filteredData = filteredData.filter(record =>
        this.selectedDepartments.includes(record.department)
      );
      this.filtersApplied['dept'] = true;
    }
    // Apply status selection filter
    if (this.selectedStatuses.length > 0) {
      filteredData = filteredData.filter(record =>
        this.selectedStatuses.includes(record.status)
      );
      this.filtersApplied['status'] = true;
    }

    this.filteredDataSource = filteredData;
    console.log('Filtered Data Source:', this.filteredDataSource);
  }

  clearIsReadFilter(): void {
    this.isReadFilter = null;
    this.applyFilter();
    this.filtersApplied['isRead'] = false;
  }
  clearPatientFilter(): void {
    this.selectedPatients = [];
    this.searchText = '';
    this.filteredPatients = this.patients;
    this.applyFilter(); 
    this.filtersApplied['patient'] = false;
  }
  clearDateFilter(): void {
    this.filters[this.activeColumn] = {
      startDate: null,
      endDate: null,
      startTime: '00:00',
      endTime: '23:59'
    };
    this.applyFilter();
    this.filtersApplied[this.activeColumn] = false;
  }
  clearDobFilter(): void {
    this.filters['dob'] = {
      startDate: null,
      endDate: null
    };
    this.applyFilter();
    this.filtersApplied['dob'] = false;
  }
  clearDepartmentFilter(): void {
    this.selectedDepartments = [];
    this.departmentSearchText = '';
    this.filteredDepartments = this.departments;
    this.applyFilter();
    this.filtersApplied['dept'] = false;
  }
  clearDossierFilter(): void {
    this.selectedDossier = [];
    this.dossierSearchText = '';
    this.filteredDossier = this.dossiers;
    this.applyFilter(); 
    this.filtersApplied['dossier'] = false;
  }
  clearHospitalFilter(): void {
    this.selectedHospital = [];
    this.hospitalSearchText = '';
    this.filteredHospital = this.hospitalIds;
    this.applyFilter(); 
    this.filtersApplied['hospital'] = false;
  }
  clearIppFilter(): void {
    this.selectedIpp = [];
    this.ippSearchText = '';
    this.filteredIpp= this.ipps;
    this.applyFilter(); 
    this.filtersApplied['ipp'] = false;
  }
  clearPrescriberFilter(): void {
    this.selectedPrescribers = [];
    this.prescriberSearchText = '';
    this.filteredPrescribers = this.prescribers; 
    this.applyFilter(); 
    this.filtersApplied['prescriber'] = false;
  }
  clearStatusFilter(): void {
    this.selectedStatuses = [];
    this.applyFilter(); 
    this.filtersApplied['status'] = false;
  }
  ClearAllFilters():void {
    this.clearIsReadFilter();
    this.clearPatientFilter();
    this.clearDobFilter();
    this.clearIppFilter();
    this.clearHospitalFilter();
    this.clearDossierFilter();
    this.clearPrescriberFilter();
    this.clearDepartmentFilter();
    this.clearStatusFilter();
    this.clearDateFilter();
    console.log("All filters cleared successfully!");
  }
}