<!-- <div class="header">
    <div class="header-content">
        <span class="icon-ic-left"></span>
        <span>Patient Name</span>
        <span class="ipp">Ipp No</span>
    </div>

    <div class="header-content">
        <span class="color-blue">Expand All</span>
    </div>
</div> -->
<div class="tables-wrapper">
    <!-- Table 2: Dossier Columns -->
    <div class="table-container2">
        <mat-table [dataSource]="flattenedData" class="mat-elevation-z8 tbl" class="tble2" (scroll)="onTableScroll($event)">

            <!-- Examen Column -->
            <ng-container matColumnDef="examen" sticky>
                <mat-header-cell *matHeaderCellDef class="table-column-160">
                    <div class="resultHeader">
                        <div class="header-spacing-gray">
                        </div>
                        <span class="pd-24">Examen </span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column-160" [ngClass]="{'row-out-of-range': isOutOfRangeForElement(row), 
                                'border-parent': row.isParent,  'ror-firstcell': isOutOfRangeForElement(row),
                                'border-last': row.isLastChild}">
                    <ng-container>
                        <span *ngIf="row.isParent" class="examen-font pd-24 br-word" [matTooltip]="row.examen"
                            [matTooltipDisabled]="!isMultiLine(textSpan)" #textSpan>
                            {{ row.examen  | uppercase }}
                        </span>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Element Column -->
            <ng-container matColumnDef="element" sticky>
                <mat-header-cell *matHeaderCellDef class="table-column-200">
                    <div class="resultHeader">
                        <div class="header-spacing-gray">
                        </div>
                        <span>Element </span>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column-200" [ngClass]="{'row-out-of-range': isOutOfRangeForElement(row), 
                'border-parent': row.isParent,
                'border-last': row.isLastChild}">
                    <ng-container>
                        <div class="ele">
                            <span class="br-word"  [matTooltip]="row.name"
                            [matTooltipDisabled]="!isMultiLine(textSpan2)" #textSpan2> {{ row.name }} &nbsp;</span>
                            <span class="icon-ic-warning warning" *ngIf="isOutOfRangeForElement(row)" matTooltip="Out of Range"
                            matTooltipPosition="below"></span>
                        </div>
                    </ng-container>
                </mat-cell>
            </ng-container>

            <!-- Arrow Column -->
            <ng-container matColumnDef="arrow-left-before-third" sticky>
                <mat-header-cell *matHeaderCellDef class="table-column-50"
                    [ngClass]="{'shadow-right': isRightButtonClicked}">
                    <div class="resultHeader">
                        <div class="header-spacing-gray" ></div>
                        <button class="left-btn" *ngIf="isRightButtonClicked" (click)="scrollLeft()">
                            <span class="icon-ic-left color-blue"></span>
                        </button>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column-50" [ngClass]="{'row-out-of-range': isOutOfRangeForElement(row), 
                            'border-parent': row.isParent, 'shadow-right': isRightButtonClicked,'border-last': row.isLastChild}">
                    
                    <span class="icon-ic-graph color-blue"></span>
                </mat-cell>
                </ng-container>

            <!-- Dossier Columns -->
            <ng-container *ngFor="let dossier of dossierHeaders" [matColumnDef]="dossier.columnId">
                <mat-header-cell *matHeaderCellDef class="table-column-166">
                    <div class="resultHeader">
                        <div class="resultdate">
                            <span class="dossier">{{ dossier.resultDateTime }}</span>
                        </div>
                        <div class="dossierHeader">
                            <span class="dossier">{{ dossier.dossierNum }}</span>
                            <span>{{ dossier.prescriberName }}</span>
                        </div>
                    </div>
                </mat-header-cell>
                <mat-cell *matCellDef="let row" class="table-column-166 cell-pd" [ngClass]="{'row-out-of-range': isOutOfRangeForElement(row), 
                 'border-parent': row.isParent,
                 'border-last': row.isLastChild}">
                    <div class="adjust">
                        <div class="cell-content">
                            <span class="value-span"
                                [ngClass]="{
                             'out-of-range': isOutOfRange(row.values[dossier.columnId], row.min[dossier.columnId], row.max[dossier.columnId]),
                             'in-range': !isOutOfRange(row.values[dossier.columnId], row.min[dossier.columnId], row.max[dossier.columnId])}">
                                {{ row.values[dossier.columnId] || 'N/A' }}
                            </span>
                            <span class="minmax">[{{row.min[dossier.columnId]|| '-' }};{{row.max[dossier.columnId]|| '-' }}]</span>
                        </div>
                        <ng-container *ngIf="row.comment[dossier.columnId]">
                            <span class="icon-ic-remarks cp color-blue" [matMenuTriggerFor]="commentMenu"></span>
                        </ng-container>
                    </div>
                    <mat-menu #commentMenu="matMenu" class="comment-popover" xPosition="after" yPosition="above">
                        <!-- <div class="arrow-top"></div> -->
                        <div>
                            <span>{{ row.comment[dossier.columnId] }}</span>
                        </div>
                    </mat-menu>
                </mat-cell>

            </ng-container>

            <!-- Header Row -->
            <mat-header-row *matHeaderRowDef="this.fullDisplayedColumns"></mat-header-row>
            <!-- Data Rows -->
            <mat-row *matRowDef="let row; columns: this.fullDisplayedColumns"></mat-row>
        </mat-table>
    </div>
    <button mat-icon-button class="right-btn" (click)="scrollRight()" *ngIf="showLeftButton">
        <span class="icon-ic-right color-blue"></span>
    </button>
</div>
<!-- JSON Input Area -->
<div class="testjson">
    <span>This test area is only for initial testing purpose</span>
    <textarea [(ngModel)]="jsonInput" placeholder="Paste your JSON here..." rows="10" cols="40"></textarea>
    <button (click)="loadJsonData()">Load JSON Data</button>
</div>