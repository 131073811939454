import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettingsService } from '../../core';
import { PaymentType, TimeZone } from '../admin/sampling/sampling';
import {
    Company,
    SamplingStation,
    SamplingStationObj,
    SamplingStationSpecialDatesObj,
    SlotCountObj,
    Role,
    SamplingStationSlot,
    Hospitals
} from '../models/common.model';
import { ResultStatus } from '../patient/results/results.model';
import { JsonResponse } from '../admin/landing-page-config/landing-page-config.model';

enum Week {
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thrusday = 4,
    friday = 5,
    saturday = 6,
    sunday = 7,
}
@Injectable({
    providedIn: 'root'
})
export class CommonApiService {
    error: any;

    HttpHeaders: any;

    constructor(
        private httpClient: HttpClient,
        private readonly appSettings: AppSettingsService,
        private datepipe: DatePipe
    ) { }
    GetSamplingStation(companyId: any): Observable<any> {
        return this.httpClient.get<SamplingStationObj>(
            this.appSettings.adminBaseUrl + 'company/stations?org_id=' + companyId
        );
    }
    SendActivationLink(emailData: any): Observable<any> {
        return this.httpClient.get(
            this.appSettings.patientBaseUrl + 'mail/verify?email=' + emailData.Email
        );
    }
    GetCompany(): Observable<Company[]> {
        return this.httpClient.get<Company[]>(
            this.appSettings.adminBaseUrl + 'companies?active=yes'
        );
    }
    GetAllCompany(): Observable<Company[]> {
        return this.httpClient.get<Company[]>(
            this.appSettings.adminBaseUrl + 'companies'
        );
    }
    GetAllSamplingStations(isAllUsers: boolean): Observable<SamplingStation[]> {
        // const id = JSON.parse(sessionStorage.getItem("access_token"))["org_id"];
        if (isAllUsers) {
            return this.httpClient.get<SamplingStation[]>(
                this.appSettings.adminBaseUrl + 'company/stations?all_users=1'
            );
        } else {
            return this.httpClient.get<SamplingStation[]>(
                this.appSettings.adminBaseUrl + 'company/stations'
            );
        }
    }

    GetTestType(): Observable<any[]> {
        return this.httpClient.get<any[]>(
            this.appSettings.adminBaseUrl + 'testtype'
        );
    }
    GetAllTestType(): Observable<any[]> {
        return this.httpClient.get<any[]>(
            this.appSettings.adminBaseUrl + 'alltesttype'
        );
    }
    GetAlActivelTestType(): Observable<any[]> {
        return this.httpClient.get<any[]>(
            this.appSettings.adminBaseUrl + 'alltesttype?is_active=true'
        );
    }
    GetCalendar(ssId: any): Observable<any> {
        return this.httpClient.get<any>(
            this.appSettings.adminBaseUrl +
            'company/stations/slot?sampling_station_id=' +
            ssId
        );
    }
    GetCalendarbyTest(ssId: any, test_id: any): Observable<any> {
        return this.httpClient.get<any>(
            this.appSettings.adminBaseUrl +
            'company/stations/slotbytest?sampling_station_id=' +
            ssId +
            '&test_id=' +
            test_id
        );
    }

    GetSlots(ssId: any, test_id: any, limit: any, s_date: any): Observable<any> {
        return this.httpClient.get<any>(
            this.appSettings.adminBaseUrl + 'slotdate?sampling_station_id=' + ssId + '&test_id=' + test_id + '&limit_no=' + limit + '&start_date=' + s_date);
    }
    getAllRoles(): Observable<Role[]> {
        return this.httpClient.get<Role[]>(this.appSettings.adminBaseUrl + 'roles');
    }

    GetCalenderSpecialDates(ssId: any): Observable<any> {
        return this.httpClient.get<SamplingStationSpecialDatesObj>(
            this.appSettings.adminBaseUrl + 'calender/specialdates?ss_id=' + ssId
        );
    }

    getCalenderDisabledDates(ssId: any): Observable<any> {
        return this.httpClient.get<SamplingStationSpecialDatesObj>(
            this.appSettings.adminBaseUrl + 'company/station?ss_id=' + ssId
        );
    }

    getBirthCities(): Observable<any> {
        if (this.birthCitiesList.value == null) {
            return this.httpClient.get<any[]>(
                this.appSettings.adminBaseUrl + 'common/cities?country_code=FR'
            );

        } else {
            return this.birthCitiesList.asObservable()
        }

    }
    birthCitiesList = new BehaviorSubject<any>(null);

    GetCalenderBookedSlots(
        calender_id: any,
        start_date: any,
        end_date: any
    ): Observable<any> {
        return this.httpClient.get<SlotCountObj>(
            this.appSettings.patientBaseUrl +
            'appointments/count?calendar_id=' +
            calender_id +
            '&start_time=' +
            start_date +
            '&end_time=' +
            end_date
        );
    }

    getPaymentType(): Observable<PaymentType[]> {
        return this.httpClient.get<PaymentType[]>(
            this.appSettings.adminBaseUrl + 'paymenttype'
        );
    }
    getDateOfISOWeek(w: any, y: number) {
        const simple = new Date(y, 0, 1 + (w - 1) * 7);
        // var dow = simple.getDay();
        const ISOweekStart = simple;
        ISOweekStart.setDate(simple.getDate() - simple.getDay());
        // if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
        // else ISOweekStart.setDate(simple.getDate() + 7 - simple.getDay());

        return this.datepipe.transform(
            ISOweekStart,
            'MM-dd-yyyy',
            undefined,
            'en-US'
        );
    }
    calculateSlots(calender: SamplingStationSlot, slots: any) {
        slots.sundaySlots = null;

        if (calender.dows.find((d) => d.dow === Week.sunday)) {
            slots.sundaySlots = this.generateDayWiseSlots(Week.sunday, calender);
        }

        slots.mondaySlots = null;
        if (calender.dows.find((d) => d.dow === Week.monday)) {
            slots.mondaySlots = this.generateDayWiseSlots(Week.monday, calender);
        }

        slots.tuesdaySlots = null;
        if (calender.dows.find((d) => d.dow === Week.tuesday)) {
            slots.tuesdaySlots = this.generateDayWiseSlots(Week.tuesday, calender);
        }

        slots.wednesdaySlots = null;
        if (calender.dows.find((d) => d.dow === Week.wednesday)) {
            slots.wednesdaySlots = this.generateDayWiseSlots(
                Week.wednesday,
                calender
            );
        }

        slots.thursdaySlots = null;
        if (calender.dows.find((d) => d.dow === Week.thrusday)) {
            slots.thursdaySlots = this.generateDayWiseSlots(Week.thrusday, calender);
        }

        slots.fridaySlots = null;
        if (calender.dows.find((d) => d.dow === Week.friday)) {
            slots.fridaySlots = this.generateDayWiseSlots(Week.friday, calender);
        }

        slots.saturdaySlots = null;
        if (calender.dows.find((d) => d.dow === Week.saturday)) {
            slots.saturdaySlots = this.generateDayWiseSlots(Week.saturday, calender);
        }
        const finalobj = {
            calendar: calender,
            slots
        };
        return finalobj;
    }
    private generateDayWiseSlots(day: number, calendar: SamplingStationSlot) {
        return this.getSlotsByStartEndTime(
            calendar.dows.find((d) => d.dow === day).start_time.toString(),
            calendar.dows.find((d) => d.dow === day).end_time.toString(),
            calendar.duration,
            calendar.dows.find((d) => d.dow === day).break_begins.toString(),
            calendar.dows.find((d) => d.dow === day).break_ends.toString()
        );
    }
    private getSlotsByStartEndTime(
        startTime: string,
        endTime: string,
        duration: number,
        breakFrom: string,
        breakTo: string
    ) {
        if (
            startTime != null &&
            startTime != '' &&
            endTime != null &&
            endTime != '' &&
            duration != null &&
            duration != 0
        ) {
            const timeslots = [startTime];
            if (
                breakFrom > breakTo ||
                !(startTime < breakFrom && endTime > breakFrom) ||
                !(startTime < breakTo && endTime > breakTo)
            ) {
                breakFrom = endTime;
                breakTo = endTime;
            }

            while (startTime < breakFrom) {
                startTime = this.addMinutes(startTime, duration);

                if (this.addMinutes(startTime, duration) < breakFrom) {
                    timeslots.push(startTime);
                }
            }
            if (breakTo < endTime) {
                timeslots.push(breakTo);
            }

            while (breakTo < endTime) {
                breakTo = this.addMinutes(breakTo, duration);

                if (this.addMinutes(breakTo, duration) < endTime) {
                    timeslots.push(breakTo);
                }
            }

            return timeslots;
        } else {
            return null;
        }
    }
    private addMinutes(time: string, minutes: number) {
        const date = new Date(
            new Date('01/01/2015 ' + time).getTime() + minutes * 60000
        );
        const tempTime =
            (date.getHours().toString().length == 1
                ? '0' + date.getHours()
                : date.getHours()) +
            ':' +
            (date.getMinutes().toString().length == 1
                ? '0' + date.getMinutes()
                : date.getMinutes());
        return tempTime;
    }

    /**
    * This method will return list of result status available.
    * @returns List of result status
    */
    getResultStatusList(): Observable<ResultStatus[]> {
        return this.httpClient.get<PaymentType[]>(
            this.appSettings.adminBaseUrl + 'master?key=samplestatus'
        );
    }

    getDocumentTypeList(): Observable<ResultStatus[]> {
        return this.httpClient.get<PaymentType[]>(
            this.appSettings.adminBaseUrl + 'master?key=documenttype'
        );
    }

    getKitResults(): Observable<ResultStatus[]> {
        return this.httpClient.get<PaymentType[]>(
            this.appSettings.adminBaseUrl + 'master?key=kitresult'
        );
    }

    getLandingPageConfiguration(): Observable<JsonResponse[]> {
        return this.httpClient.get<JsonResponse[]>(
            this.appSettings.adminBaseUrl + 'page?page=landing'
        );
    }

    getSlotConfiguration(): Observable<JsonResponse[]> {
        return this.httpClient.get<JsonResponse[]>(
            this.appSettings.adminBaseUrl + 'master?key=slot'
        );
    }

    updateLandingPageConfiguration(data: any) {
        return this.httpClient.put(
            this.appSettings.adminBaseUrl + 'page', data
        );
    }

    addHoliday(postData: any, sub_url: string): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.adminBaseUrl + sub_url, body);
    }
    updateHoliday(postData: any, sub_url: string): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.put<any>(this.appSettings.adminBaseUrl + sub_url, body);
    }

    getHolidays(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.adminBaseUrl + 'calender/holidays', body);
    }

    deleteHoliday(params: any): Observable<any> {
        return this.httpClient.delete<any>(
            this.appSettings.adminBaseUrl + "common/specialdate/delete", { params }
        );
    }

    addUnavailiablity(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.adminBaseUrl + 'calender/add', body);
    }

    updateUnavailiablity(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.put<any>(this.appSettings.adminBaseUrl + 'calender/unavilability', body);
    }

    getSlotGroups(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.adminBaseUrl + 'calender/slotgroups', body);
    }

    validateTestInGroup(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.adminBaseUrl + 'calender/slotgroup/validate', body);
    }

    getAvailableTest(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.adminBaseUrl + 'calender/slotgroup/tests', body);
    }

    addSlotGroup(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.adminBaseUrl + 'calender/slotgroup', body);
    }

    updateSlotGroup(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.put<any>(this.appSettings.adminBaseUrl + 'calender/slotgroup', body);
    }

    getSlotGroupData(id: any): Observable<any> {
        return this.httpClient.get<any>(this.appSettings.adminBaseUrl + 'calender/slotgroup?id=' + id);
    }

    deleteUnavailability(id: any): Observable<any> {
        return this.httpClient.delete<any>(
            this.appSettings.adminBaseUrl + "calender/unavilability?id=" + id
        );
    }

    deleteSlotGroup(id: any, s_date: any, is_sequence: boolean): Observable<any> {
        return this.httpClient.delete<any>(
            this.appSettings.adminBaseUrl + "calender/slotgroup?id=" + id + "&start_date=" + s_date + "&is_sequence=" + is_sequence
        );
    }

    getTimezone(): Observable<TimeZone[]> {
        return this.httpClient.get<TimeZone[]>(
            this.appSettings.adminBaseUrl + 'master?key=timezone'
        );
    }

    getActiveDates(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.adminBaseUrl + 'calender/startdates', body);
    }

    deleteUser(id: any): Observable<any> {
        return this.httpClient.delete<any>(
            this.appSettings.adminBaseUrl + "user?id=" + id
        );
    }

    GetHospitals(): Observable<Hospitals[]> {
        return this.httpClient.get<Hospitals[]>(
            this.appSettings.adminBaseUrl + 'hospitals?is_active=yes'
        );
    }
    GetAllHospitals(): Observable<Hospitals[]> {
        return this.httpClient.get<Hospitals[]>(
            this.appSettings.adminBaseUrl + 'hospitals'
        );
    }
}
