<div class="rm-container">
    <header>
        <h3>{{'relative-members.title'|translate}}</h3>
        <div class="btn-adjustment">
            <button mat-button color="accent" class="ghost" (click)="addMember()"
                type="button">{{'relative-members.add'|translate}}</button>
        </div>
        <!-- <button mat-button color="accent" class="ghost" (click)="manageAccess()" type="button">Manage Access</button> -->
    </header>
    <div class="no-record" *ngIf="RM_DATA ==null || RM_DATA?.length ==0">
        <div class="img-container">
            <img src="/assets/images/application-logos/svg/ic-family.svg">
        </div>
        <div class="desc">
            <p class="text1"> <strong>{{'relative-members.no-record-text1'|translate}}</strong></p>
            <p class="text2"> {{'relative-members.no-record-text2'|translate}}</p>
        </div>
    </div>
    <div class="card-container" *ngIf="RM_DATA?.length>0">
        <div *ngFor="let record of RM_DATA">
            <div class="guardian-wrapper">
                <div class="icon-container">
                    <div class="info-container">
                        <span [class]="getBackgroundClass(record.is_primary)">{{
                            record.rm_fname.charAt(0).toUpperCase()
                            +
                            record.rm_lname.charAt(0).toUpperCase() }}</span>

                        <div class="information">
                            <div class="row-disp">
                                <div class="form-label label-text">
                                    {{record.rm_fname + ' ' +record.rm_lname}}</div>
                                <div *ngIf="!record.is_primary && patientId==record.parent_id"
                                    class="icon-ic-edit icon icon-text edit-RM-Icon "
                                    matTooltip="{{'list-RM.edit' | translate}}" matTooltipClass="tooltip-style"
                                    (click)="getMemberInfo(record)">
                                </div>
                            </div>


                            <div class="form-value">{{record.dob
                                | date: 'dd/MM/yyyy'}}
                                {{getAgeText(record.age_years, record.age_months, record.dob)}}</div>

                            <div *ngIf="record.is_primary" class="orange-txt"> 
                                {{ 'list-RM.individual-acc' | translate }}
                            </div>

                            <div *ngIf="!record.is_owner_account && !record.is_primary" class="orange-txt">
                                {{ 'list-RM.shared-by' | translate}}
                                {{' ' + record.parent_fname + ' ' +
                                    record.parent_lname}}
                            </div>

                            <div class="display-flex mt" *ngIf="!record.is_primary">
                                <div class="form-label">{{ 'list-RM.documents' | translate}}</div>
                                <div class="form-value margin-left5">
                                    {{getDocText(record.insurance,record.optional_insurance,record.id_card,
                                    record.age_years, record, record.minor_passport, record.birthcertificate
                                    )}}
                                    {{'list-RM.uploaded' | translate}}</div>

                            </div>

                            
                            <div *ngIf="!record.is_primary"
                                [ngClass]="{'action-container' : !record.areAllDocsUploaded , 'action-container-hide' : record.areAllDocsUploaded}">
                                <div
                                    (click)="!record.areAllDocsUploaded && uploadDocument(record.rm_id,record.login_id,$event)">
                                    {{'list-RM.upload' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="display-none-icons menu-display">

                        <div><mat-icon mat-button [matMenuTriggerFor]="menu"
                                class="icon three-dots">more_vert</mat-icon>
                        </div>
                    </div>
                    <!-- Menu for options -->
                    <mat-menu #menu="matMenu" class="rm-popover">
                        <div class="arrow-top"></div>
                        <div class="rm-main">
                            <button mat-menu-item (click)="redirectToMyAppointments()" class="menu-btn">
                                <span class="web-icon icon-icon-my-appointment icon small-icon-app icon-text"
                                    matTooltip="{{'list-RM.access-apt14258' | translate}}"
                                    matTooltipClass="tooltip-style">
                                </span>
                                <span class="pd-2-8">{{'list-RM.access-apt' | translate}}</span>
                            </button>

                            <button *ngIf="!record.is_primary" mat-menu-item (click)="redirectToDocuments()"
                                class="menu-btn">
                                <div class="web-icon icon-icon-documents icon small-icon-doc icon-text"
                                    matTooltip="{{'list-RM.access-docs' | translate}}" matTooltipClass="tooltip-style">
                                </div>
                                <span class="pd-2-8">{{'list-RM.access-docs' | translate}}</span>
                            </button>

                            <button mat-menu-item (click)="redirectQR(record)" class="menu-btn">
                                <div class="web-icon icon-icon-qr-code icon small-icon-doc icon-text"
                                    matTooltip="{{'list-RM.access-qr' | translate}}" matTooltipClass="tooltip-style">
                                </div>
                                <span class="pd-2-8">{{'list-RM.access-qr' | translate}}</span>
                            </button>
                        </div>
                    </mat-menu>

                    <div class="display-flex display-none height-align">


                        <div class="icon-icon-my-appointment icon small-icon-app icon-text"
                            matTooltip="{{'list-RM.access-apt' | translate}}" matTooltipClass="tooltip-style"
                            (click)="redirectToMyAppointments()">
                        </div>
                        <div *ngIf="!record.is_primary" class="icon-icon-documents icon small-icon-doc icon-text"
                            matTooltip="{{'list-RM.access-docs' | translate}}" matTooltipClass="tooltip-style"
                            (click)="redirectToDocuments()">
                        </div>
                        <div class="icon-icon-qr-code icon small-icon-doc icon-text"
                            matTooltip="{{'list-RM.access-qr' | translate}}" matTooltipClass="tooltip-style"
                            (click)="redirectQR(record)">
                        </div>
                    </div>
                </div>

                <div class="icon-container pre-login-book-button bottom border-top">
                    <div *ngIf="record.is_owner_account" class="icon-text" (click)="manageAccess(record)">
                        <span class="web-icon icon-ic-manage icon edit-icon "></span>
                        <span>{{'list-RM.manage' | translate}}</span>
                    </div>
                    <div *ngIf="!record.is_owner_account" class="icon-text" (click)="removeAccess(record)">
                        <span class="web-icon icon icon-ic-delete icon remove-icon "></span>
                        <span>{{'list-RM.remove' | translate}}</span>
                    </div>
                    <div class="icon-text" (click)="redirectToBookAppointment(record.rm_id)">
                        <span class="web-icon icon-ic-regisgternew icon edit-icon"></span>
                        <span>{{'list-RM.book-appointment' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>