import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-partial-result',
  templateUrl: './partial-result.component.html',
  styleUrl: './partial-result.component.scss'
})
export class PartialResultComponent implements OnInit {
  jsonInput: string = '';
  dataSource: any[] = [];
  flattenedData: any[] = [];
  data: any[] = [];
  displayedColumns: string[] = ['examen', 'elementname'];
  dossierNumbers: string[] = [];
  dossierColumns: string[] = [];
  dossierHeaders: any[] = [];
  fullDisplayedColumns: string[];
  isRightButtonClicked = false;
  showLeftButton = false;


  constructor(private http: HttpClient, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData(): void {
    this.http.get<any>('assets/ehptest.json').subscribe(
      (response) => {
        this.data = response.results;
        this.processData(this.data);
      },
      (error) => {
        console.error('Error fetching data:', error);
      }
    );
  }

  //test text area 
  loadJsonData(): void {
    try {
      const parsedData = JSON.parse(this.jsonInput);
      this.data = parsedData.results;
      this.dossierHeaders = [];
      this.dossierHeaders = [];
      this.processData(this.data);
    } catch (error) {
      console.error('Invalid JSON format', error);
    }
  }

  processData(data: any[]): void {
    const resultMap: { [key: string]: any } = {};
    const dossierColumns = new Set<string>();
  
    data.forEach((result) => {
      const dossier = result.dossierNum;
      const prescriberName = result.prescriberName;
      const resultDateTime = result.resultDateTime;
      dossierColumns.add(dossier);
  
      this.dossierHeaders.push({
        columnId: dossier,
        prescriberName: prescriberName,
        dossierNum: dossier,
        resultDateTime: resultDateTime,
      });
  
      result.examen.forEach((examen: { name: string | number; elements: any[] }) => {
        if (!resultMap[examen.name]) {
          resultMap[examen.name] = {
            examen: examen.name,
            childData: [],
          };
        }
  
        examen.elements.forEach((element: { name: string; value: any; min: any; max: any; comment: any }) => {
          const existingElement = resultMap[examen.name].childData.find(
            (child: { elementname: string }) => child.elementname === element.name
          );
  
          if (existingElement) {
            existingElement.values[result.dossierNum] = element.value;
            existingElement.min[result.dossierNum] = element.min;
            existingElement.max[result.dossierNum] = element.max;
            existingElement.comment[result.dossierNum] = element.comment;
          } else {
            resultMap[examen.name].childData.push({
              elementname: element.name,
              isParent: false,
              values: {
                [result.dossierNum]: element.value,
              },
              min: {
                [result.dossierNum]: element.min,
              },
              max: {
                [result.dossierNum]: element.max,
              },
              comment: {
                [result.dossierNum]: element.comment,
              },
            });
          }
        });
      });
    });
  
    this.dataSource = Object.values(resultMap);
    this.flattenedData = this.flattenData(this.dataSource);
    console.log("Flattened Data", this.flattenedData);
    console.log("headers Data", this.dossierHeaders);

    const dossierSet = new Set<string>();
  
    this.dataSource.forEach((exam) => {
      exam.childData.forEach((child: any) => {
        Object.keys(child.values).forEach((dossier) => {
          dossierSet.add(dossier);
        });
      });
    });
  
    this.dossierNumbers = Array.from(dossierSet);
    this.displayedColumns = ['examen', 'element', 'arrow-left-before-third'];
    this.dossierColumns = [...this.dossierHeaders.map((d) => d.columnId)];
    this.fullDisplayedColumns = ['examen', 'element', 'arrow-left-before-third', ...this.dossierColumns];
  
    // Ensure DOM updates are complete before accessing clientWidth and scrollWidth
    this.cdr.detectChanges();
    setTimeout(() => {
      const tableElement = document.querySelector('.tble2') as HTMLElement;
      if (tableElement) {
        this.showLeftButton = tableElement.scrollWidth > tableElement.clientWidth;
      }
    }, 0);
  }

  flattenData(dataSource: any[]): any[] {
    const flattened: any[] = [];
    for (const item of dataSource) {
      const examen = item.examen;
      let isFirstChild = true;
      const totalChildren = item.childData.length;
      for (let i = 0; i < totalChildren; i++) {
        const child = item.childData[i];
        const isLastChild = i === totalChildren - 1;
        flattened.push({
          examen: examen,
          name: child.elementname,
          isParent: isFirstChild ? true : false,
          isLastChild: isLastChild,
          values: child.values,
          min: child.min,
          max: child.max,
          comment: child.comment,
        });
        isFirstChild = false;
      }
    }
    
    return flattened;
  }



  isOutOfRange(value: number, min: number, max: number): boolean {
    return value <= min || value >= max;
  }

  isOutOfRangeForElement(row: any): boolean {
    if (!row || !row.values || !row.min || !row.max) {
      return false;
    }
    for (const dossier of this.dossierHeaders) {
      const value = parseFloat(row.values?.[dossier.columnId]);
      const min = parseFloat(row.min?.[dossier.columnId]);
      const max = parseFloat(row.max?.[dossier.columnId]);

      if (value !== undefined && !isNaN(value) && (value <= min || value >= max)) {
        return true;
      }
    }
    return false;
  }

  scrollLeft() {
    document.querySelector('.tble2').scrollLeft -= 100;
  }

  scrollRight() {
    this.isRightButtonClicked = true;
    document.querySelector('.tble2').scrollLeft += 100;
  }

  onTableScroll(event: Event): void {
    const container = event.target as HTMLElement;
    const scrollLeft = container.scrollLeft;
    const scrollWidth = container.scrollWidth;
    const clientWidth = container.clientWidth;

    this.isRightButtonClicked = scrollLeft > 0;
    this.showLeftButton = scrollLeft < scrollWidth - clientWidth;
  }

  isMultiLine(spanElement: HTMLElement): boolean {
    if (!spanElement) {
        return false;
    }
    // Check if the height of the span element is greater than 14px (single line height)
    return spanElement.offsetHeight > 14;
}

}
