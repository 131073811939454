<button class="close" mat-dialog-close mat-button id="btn_close_dialog">X</button>
<div id="createAccount_Slider" class="slider-data-container ">
    <div class="header">
        <div style="text-align: center;">
            <app-section-title title="{{title | translate}}"></app-section-title>
        </div>
    </div>

    <form class="pt-30" [formGroup]="PatientForm" [appAutoScroll]="PatientForm"
        class="form-layout pt-30 input-min-spacing">
        <div id="createAccount_form" class="form-layout">
            <ng-container *ngIf="data.edit">
                <mat-form-field>
                    <mat-label class="label-float">{{'add-user.id-user' | translate}}</mat-label>
                    <input matInput name="Id" id="Id" [value]="data?.patient?.id" disabled />
                </mat-form-field>
                <button mat-button color="accent" (click)="confirmSendPasswordWithYesNo()"
                    [disabled]="data.patient.email == ''" class="ghost button_width btn-fit-content" type="button"
                    id="remove_btn">
                    {{'patient-information.reset-password' | translate}}
                </button>
                <mat-form-field def="Status">
                    <mat-label class="label-float">{{'admin.status' | translate}}</mat-label>
                    <mat-select placeholder="{{'admin.status' | translate}}" [formControl]="status" required="true"
                        [disableOptionCentering]="true">
                        <mat-option [value]="true">{{'admin.active' | translate}}</mat-option>
                        <mat-option [value]="false">{{'admin.suspended' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <button mat-button color="accent" (click)="showQRCode()" class="ghost button_width btn-fit-content"
                    type="button" id="remove_btn">
                    {{'qr-code-comp.qr-code' | translate}}
                </button>
            </ng-container>
            <div>
                <label class="d-block">{{'createAccount.gender' | translate}}<span class="color-red">
                        *</span></label>

                <mat-radio-group [formControl]="gender" (change)="onSSNChange()" required>
                    <mat-radio-button *ngFor="let gender of genders" value="{{gender.Id}}">{{gender.KeyName |
                        translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div></div>
            <mat-form-field>
                <mat-label>{{'createAccount.first-name' | translate}}</mat-label>
                <input matInput name="FirstName" id="FirstName" [formControl]="firstName" />
                <!-- <mat-error *ngIf="firstName.invalid">{{'createAccount.valid-first-name' | translate}}</mat-error> -->
                <mat-error
                    *ngIf="!PatientForm.get('firstName')?.hasError('invalidChars') && firstName.invalid">{{'createAccount.valid-first-name'
                    | translate}}</mat-error>
                <mat-error *ngIf="PatientForm.get('firstName')?.hasError('invalidChars') && firstName.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>{{'createAccount.last-name' | translate}}</mat-label>
                <input matInput name="LastName" id="LastName" [formControl]="lastName" (input)="copyLastName()" />
                <!-- <mat-error *ngIf="lastName.invalid">{{'createAccount.valid-last-name' | translate}}</mat-error> -->
                <mat-error
                    *ngIf="!PatientForm.get('lastName')?.hasError('invalidChars') && lastName.invalid">{{'createAccount.valid-first-name'
                    | translate}}</mat-error>
                <mat-error *ngIf="PatientForm.get('lastName')?.hasError('invalidChars') && lastName.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>
            <div>
                <label class="d-block">{{'createAccount.sameasLastName' | translate}}</label>
                <mat-radio-group [formControl]="sameLastName" (change)="onSameLastNameChange()" required>
                    <mat-radio-button value="0">{{'createAccount.yes'|translate}}
                    </mat-radio-button>
                    <mat-radio-button value="1">{{'createAccount.no'|translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div></div>
            <mat-form-field>
                <mat-label>{{'createAccount.birth-name' | translate}}</mat-label>
                <input matInput name="BirthName" id="BirthName" [formControl]="birthName"
                    [readonly]='sameLastName.value=="0"' />
                <!-- <mat-error *ngIf="birthName.invalid">{{'createAccount.valid-birth-name' | translate}}</mat-error> -->
                <mat-error
                    *ngIf="!PatientForm.get('birthName')?.hasError('invalidChars') && birthName.invalid">{{'createAccount.valid-first-name'
                    | translate}}</mat-error>
                <mat-error *ngIf="PatientForm.get('birthName')?.hasError('invalidChars') && birthName.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>

            <app-datepicker id="dateofBirth" labelText="{{'createAccount.dob' | translate}}" [dateInput]="dateOfBirth"
                (selectedDate)="onSSNChange()" isRequired="true" isRequiredError="true" [maxDate]="maxDate">
            </app-datepicker>
            <div>
                <label style="display: block">{{'createAccount.haveSSN' | translate}}</label>
                <mat-radio-group [formControl]="haveSSN" (change)="onHaveSSNChange()" required>
                    <mat-radio-button value="0">{{'createAccount.yes'|translate}}
                    </mat-radio-button>
                    <mat-radio-button value="1">{{'createAccount.no'|translate}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div *ngIf="!isSSNRequired"></div>
            <mat-form-field [hidden]="!isSSNRequired">
                <mat-label>{{'createAccount.ssn' | translate}}</mat-label>
                <input matInput name="ssn" id="ssn" [formControl]="ssn" (blur)="onSSNChange()"
                    [required]="isSSNRequired" (input)="restrictSpace($event)" (keydown.space)="$event.preventDefault()"
                    (paste)="restrictSpace($event)" />
                <mat-error *ngIf="ssn.invalid  && !ssn.hasError('ssnExist')">{{'createAccount.valid-ssn' | translate}}
                </mat-error>
                <mat-error *ngIf="ssn.invalid  && ssn.hasError('ssnExist')">{{'createAccount.invalid-ssn' | translate}}
                </mat-error>
            </mat-form-field>

            <mat-form-field id="nationalityFlag">
                <mat-label>{{'createAccount.nationality' | translate}}</mat-label>
                <mat-select placeholder="{{'createAccount.nationality' | translate}}"
                    [ngStyle]="{'background': 'url(/assets/svg-country-flags/svg/'+selectedFlag+'.svg) left / contain no-repeat',' white-space': 'nowrap','display':'inline'}"
                    name="Nationality" id="Nationality" [formControl]="nationality"
                    (selectionChange)="onSelectNationality($event.value,true)">
                    <mat-option class="country" *ngFor="let nationality of nationalities" [value]="nationality.id">
                        <img src="/assets/svg-country-flags/svg/{{nationality.country_code.toLowerCase()}}.svg" />
                        <span class="countryName">{{nationality.country_name}}</span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="nationality.invalid">{{'createAccount.valid-nationality' | translate}}</mat-error>
            </mat-form-field>
            <div></div>
            <mat-form-field id="birthCountryFlag">
                <mat-label>{{'createAccount.birth-country' | translate}}</mat-label>
                <mat-select placeholder="{{'createAccount.birth-country' | translate}}"
                    [ngStyle]="{'background': 'url(/assets/svg-country-flags/svg/'+selectedBirthCountryFlag+'.svg) left / contain no-repeat',' white-space': 'nowrap','display':'inline'}"
                    name="BirthCountry" id="BirthCountry" [formControl]="birthCountry"
                    (selectionChange)="onSelectBirthCountry($event.value)">
                    <mat-option class="country" *ngFor="let bc of nationalities" [value]="bc.id">
                        <img src="/assets/svg-country-flags/svg/{{bc.country_code.toLowerCase()}}.svg" />
                        <span class="countryName">{{bc.country_name}}</span>
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="birthCountry.invalid">{{'createAccount.valid-birth-country' | translate}}</mat-error>
            </mat-form-field>

            <mat-form-field class="dropdown-filter" [hidden]="!isBirthCityRequired">
                <mat-label>{{'createAccount.birth-city' | translate}}</mat-label>
                <input type="text" #searchBirthCityData matInput (click)="birthCity.setValue('')" name="birthCity"
                    [formControl]="birthCity" (blur)="validateSelection()" [matAutocomplete]="auto"
                    [required]="isBirthCityRequired" placeholder="{{'createAccount.SelectCity'|translate}}">
                <mat-icon matSuffix>arrow_drop_down</mat-icon>

                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)"
                    (optionSelected)="birthCity.setValue($event.option.value)">
                    <mat-option *ngFor="let ss of filteredBirthCities | async" [value]="ss">
                        {{ss.city_name}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="birthCity.invalid">{{'createAccount.invalid-birth-city' |
                    translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{'createAccount.email' | translate}}</mat-label>
                <input type="email" maxlength="60" matInput name="Email" id="Email" [formControl]="email" />
                <mat-error *ngIf="email.invalid  && !email.hasError('emailExist')">{{'createAccount.valid-email' |
                    translate}}</mat-error>
                <mat-error *ngIf="email.invalid  && email.hasError('emailExist')">{{'createAccount.email-exist' |
                    translate}}</mat-error>
            </mat-form-field>
            <mat-form-field floatLabel="always" class="phone-ctrl">
                <mat-label>{{'createAccount.phone' | translate}}</mat-label>
                <ngx-mat-intl-tel-input [preferredCountries]="['fr']" [enablePlaceholder]="false" name="phone" #phone
                    [formControl]="phoneNumber" (countryChanged)="selectedCountry($event)" required>
                </ngx-mat-intl-tel-input>
                <mat-error *ngIf="phone?.errors?.required">{{'createAccount.valid-phone' | translate}}</mat-error>
                <mat-error *ngIf="phone?.errors?.validatePhoneNumber">{{'createAccount.valid-phone' | translate}}
                </mat-error>

            </mat-form-field>
            <ng-container id="createAccount_form" class="form-layout" *ngIf=editMode>
                <mat-form-field>
                    <mat-label>{{'createAccount.address' | translate}}</mat-label>
                    <input matInput type="text" id="autocomplete-input" name="address" autocomplete="off"
                        [formControl]="address" />
                    <mat-error *ngIf="address.invalid">{{'createAccount.valid-address' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'createAccount.country' | translate}}</mat-label>
                    <mat-select name="country" id="country" [formControl]="country">
                        <mat-option *ngFor="let nationality of nationalities" [value]="nationality.id">
                            {{nationality.country_name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="country.invalid">{{'createAccount.valid-country' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'createAccount.city' | translate}}</mat-label>
                    <input matInput name="City" id="City" [formControl]="city" />
                    <mat-error *ngIf="city.invalid">{{'createAccount.valid-city' | translate}}</mat-error>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'createAccount.zipcode' | translate}}</mat-label>
                    <input matInput name="Zipcode" id="Zipcode" [formControl]="zip" />
                    <mat-error *ngIf="zip.invalid">{{'createAccount.valid-zipcode' | translate}}</mat-error>
                </mat-form-field>
            </ng-container>

        </div>

        <div class="actions">
            <button mat-button color="accent" mat-dialog-close="true" class="ghost button_width" type="submit"
                id="btn_close">
                {{'admin.close' | translate}}
            </button>
            <button mat-button color="accent" (click)="clear()" class="ghost button_width" type="button"
                id="btn_remove">
                {{'admin.clear' | translate}}
            </button>
            <button mat-button color="accent" class="ghost button_width" type="submit" (click)="onSave()" id="btn_save">
                {{'admin.save' | translate}}
            </button>
            <button mat-button color="accent" (click)="deleteUser()" class="ghost button_width" type="button"
                id="btn_clear" *ngIf="data.edit">
                {{'admin.delete' | translate}}
            </button>
        </div>
    </form>
</div>