import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from '../../../app.locale.service';
import { LoaderService } from '../../../main';
import { MessageService } from '../../../main/services/message.service';
import { PatientService } from '../../services/patient.service';
import { ScanQrComponent } from './scan-qr/scan-qr.component';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { Location } from '@angular/common';

export interface PeriodicElement {
    test: Object;
    lab: Object;
    labContact: string;
    dateTime: string;
}

@Component({
    selector: 'app-find-patient',
    templateUrl: './find-patient.component.html',
    styleUrls: ['./find-patient.component.scss']
})
export class FindPatientComponent implements OnInit, AfterViewChecked {
    // form = FormGroup;
    seacrchInput = new FormControl("");
    public lastname = new FormControl("", [this.loader.validateAllowedCharacters(),]);
    public firstname = new FormControl("", [this.loader.validateAllowedCharacters(),]);
    public birthname = new FormControl("", [this.loader.validateAllowedCharacters(),]);
    public dateOfBirth = new FormControl("");
    public ssn = new FormControl("", [
        Validators.pattern("^.{1,15}$"),
    ]);
    public maxDate: Date;
    public phoneNumber = new FormControl("");
    public email = new FormControl("");
    displayedColumns: string[] = [
        'firstName',
        'lastName',
        'birthName',
        'dateOfBirth',
        'ssn',
        'gender',
        "actions"
    ];
    dataSource: any = [];
    refresh = true;
    isDOB = false;
    patient: any;
    ss_external_id: any;
    hasNoSpecialChars = /^[A-Za-z0-9àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ\s@./]+$/;
    newDOB = '';
    public pageSize = 10;
    public currentPage = 1;
    public totalRecords = 0;
    public filterText: string = "";
    public filterApplied: boolean = false;
    protected navigationDetails: any;
    public openSearchFilter: boolean = true;
    constructor(
        private formBuilder: FormBuilder,
        public router: Router,
        public patientService: PatientService,
        private translate: TranslateService,
        public localeService: LocaleService,
        private dialog: MatDialog,
        public loader: LoaderService,
        private readonly spinner: SpinnerService,
        private readonly messageService: MessageService,
        private location: Location
    ) {
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
        this.maxDate = new Date();
        this.navigationDetails = this.location.getState();
        if (this.navigationDetails && this.navigationDetails?.patient) {
            var patient = this.navigationDetails.patient;
            this.seacrchInput.setValue(patient);
        }
    }
    public form = this.formBuilder.group({
        birthName: this.birthname,
        firstName: this.firstname,
        lastName: this.lastname
    });


    ngOnInit(): void {
        this.loader.patient = {};
        this.loader.patientSearchText = "";
        this.ss_external_id = this.loader.ss_external_id;
        if (this.loader.recRedirection?.patient_qr && this.loader.recRedirection?.action != null) {
            var searchReq = {
                "MixedData": "",
                "QrId": this.loader.recRedirection.patient_qr,
                "Dob": "",
                "org_id": this.loader.ss_selected_org_id,
                "pageSize": 10,
                "pageNumber": 1
            }
            this.patientService.getPatients(searchReq).subscribe(
                (response: any) => {
                    var patData = response.data[0]
                    this.loader.patient = patData;
                    if (this.loader.recRedirection.action == 'patient-info') {
                        this.loader.highlightLeftMenu(['receptionist/patient-info'], {
                            state: { patient: patData, searchInputValue: searchReq }
                        });
                        this.loader.recRedirection.patient_qr = null;
                        this.loader.recRedirection.action = null;
                    }
                })
        }
        this.seacrchInput.setValue(this.loader.patientSearchText);
        if (this.loader.patientSearchText?.length > 2) {
            this.findPatient();
        }
    }
    ngAfterViewChecked(): void {
    }

    ngAfterViewInit

    // filterPatient(event: any) {
    //     console.log(event.key);
    //     if (event.key === 'Enter') {
    //         this.currentPage = 1;
    //         this.totalRecords = 0;
    //         this.findPatient();
    //     }
    // }
    findPatient() {
        this.loader.patientSearchText = this.seacrchInput.value;
        if (this.seacrchInput.value.startsWith('0') ? this.seacrchInput?.value?.length < 4 : this.seacrchInput?.value?.length < 3) {
            this.reset();
        } else {
            const re = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/; // regex to validate DOB format
            if (re.test(this.seacrchInput.value)) {
                this.isDOB = true;
                this.newDOB = this.seacrchInput.value.split('/').reverse().join('-');
            } else {
                this.isDOB = false;
            }
            setTimeout(() => {
                this.findPatientList();
            }, 0);
        }
    }

    onPageChange(evt: any) {
        this.currentPage = evt?.pageIndex + 1;
        this.pageSize = evt?.pageSize;
        this.findPatientList();
    }

    public findPatientList() {
        let dob = "";
        if (this.dateOfBirth.value) {
            const date = new Date(this.dateOfBirth.value);

            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
            const day = String(date.getDate()).padStart(2, '0');
            dob = `${year}-${month}-${day}`;
        }
        let valueExist = [
            this.firstname.value,
            this.lastname.value,
            this.birthname.value,
            dob,
            this.email.value,
            this.ssn.value,
            this.seacrchInput.value,
            this.phoneNumber.value,
        ].some(value => value?.trim() !== '' && value !== null);
        if (this.form.valid && valueExist) {
            const self = this;
            self.refresh = false;
            let searchReq: any;
            self.dataSource = null;

            let phone_number = "";
            if (this.phoneNumber.value && this.phoneNumber.value.startsWith('0') && this.phoneNumber.value.length > 1) {
                phone_number = this.phoneNumber.value.replace(/^0+/, '');
            }
            else {
                phone_number = this.phoneNumber.value;
            }
            searchReq = {
                "is_advanced_search": true,
                "last_name": this.lastname.value ? this.lastname.value : "",
                "first_name": this.firstname.value ? this.firstname.value : "",
                "birth_name": this.birthname.value ? this.birthname.value : "",
                "Dob": this.dateOfBirth.value ? dob : "",
                "QrId": this.seacrchInput.value ? this.seacrchInput.value : "",
                "email": this.email.value ? this.email.value : "",
                "ssn": this.ssn.value ? this.ssn.value : "",
                "phone_number": phone_number ? phone_number : "",
                "MixedData": !this.isDOB ? this.seacrchInput.value : "",
                "pageSize": this.pageSize,
                "org_id": this.loader.ss_selected_org_id,
                "pageNumber": this.currentPage
            }

            this.spinner.show(true);
            this.patientService.getPatients(searchReq).subscribe(
                (response: any) => {
                    // if (searchReq.seacrchInput == this.seacrchInput.value.replace(/^0+/, '')) {
                    if (response['message'] !== 'No Patient Found') {
                        self.refresh = true;
                        this.totalRecords = response.count;
                        self.dataSource = response.data;
                    } else {
                        this.reset();
                    }
                    // }
                    this.spinner.show(false);
                },
                (error) => {
                    this.spinner.show(false);
                    this.reset();
                    console.log(error);
                }
            );
        }
    }

    reset() {
        const self = this;
        if (this.seacrchInput.value) {
            self.refresh = false;
            // this.seacrchInput.reset();
            setTimeout(() => {
                self.refresh = true;
                self.dataSource = [];
                this.totalRecords = 0;
            }, 0);
        } else {
            self.refresh = false;
            setTimeout(() => {
                self.refresh = true;
                self.dataSource = [];
                this.totalRecords = 0;

            }, 0);
        }
    }

    clearSearch() {
        const self = this;
        self.refresh = false;
        this.loader.patientSearchText = '';
        this.loader.patient = {};
        this.seacrchInput.reset();
        this.firstname.reset();
        this.lastname.reset();
        this.birthname.reset();
        this.dateOfBirth.reset();
        this.ssn.reset();
        this.email.reset();
        this.phoneNumber.reset();
        setTimeout(() => {
            self.refresh = true;
            self.dataSource = [];
        }, 0);
    }

    redirectReceiptionistBooking(patient_details: any) {
        this.loader.bookingPatientId = patient_details.id;
        this.loader.user_name = patient_details.first_name + " " + patient_details.birth_name;
        this.loader.dob = patient_details.dob;
        this.loader.bookingPatientName =
            patient_details.id != 0
                ? patient_details.first_name + " " + patient_details.birth_name
                : this.translate.instant('patient-info.guest');
        this.loader.highlightLeftMenu([
            "/receptionist/book-appointment/" + this.ss_external_id,
        ]);
    }

    scanQR() {
        this.dialog
            .open(ScanQrComponent, {
                width: '500px',
                height: '450px',
                autoFocus: false
            })
            .afterClosed()
            .subscribe((res) => {
                setTimeout(() => {
                    if (res == undefined) {
                        return;
                    }
                    const searchReq = { QrId: res, org_id: this.loader.ss_selected_org_id };
                    this.patientService
                        .getPatients(searchReq)
                        .subscribe((response: any) => {
                            if (response['message'] !== 'No Patient Found') {
                                this.loader.patient = response.data[0];
                                this.loader.highlightLeftMenu(['receptionist/patient-info'], {
                                    state: { patient: response.data[0] }
                                });
                            } else {
                                this.messageService.warning(this.translate.instant('receptionist.invalid-QR'));
                            }
                        });
                }, 0);
            });
    }

    rowClicked(data: any) {

        this.loader.patient = data;
        this.loader.patientSearchText = data.first_name + " " + data.last_name;
        this.loader.email = data.email;
        this.loader.highlightLeftMenu(['receptionist/patient-info'], {
            state: { patient: data, searchInputValue: this.seacrchInput.value }
        });
    }

    public openSearch() {
        this.openSearchFilter = !this.openSearchFilter;
    }


    keyPressNumbers(event: any): boolean {
        const inp = String.fromCharCode(event.keyCode);
        if (/[0-9]/.test(inp)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

}
