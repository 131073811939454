import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GetAuthLoginRequestStatus } from '../../eClinical/services/Models/getAuthAccessTokenRequestModel';
import { CommonApiService } from '../../eClinical/services/common-api.service';
import { AuthenticationService } from '../../eClinical/services/authentication.service';


@Injectable({
  providedIn: 'root'
})
export class OtpStatusService {

  constructor(public commonAPi: CommonApiService,
    public authenticationService: AuthenticationService,
  ) { }

  loginStatus(form: FormGroup | null, password: any, fromAppointment: boolean = false, MessageId: string, Mode: string, Id?: string) {
    const loginRequest: GetAuthLoginRequestStatus = new GetAuthLoginRequestStatus();
    loginRequest.username = form && form.controls.loginName ? form.controls.loginName.value.trim() : '';
    loginRequest.device_detail = form && form.controls.deviceDetail ? form.controls.deviceDetail.value : '';
    loginRequest.password = password || '';
    loginRequest.from_appointment = fromAppointment;
    loginRequest.mode = Mode;
    loginRequest.message_id = MessageId;
    if (Id) {
      loginRequest.unique_id = Id;
    }
    return this.authenticationService.getAuthLoginStatus(loginRequest);
  }
}
