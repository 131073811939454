import { HttpClient } from '@angular/common/http';
import { ComponentFactory, Injectable, ViewContainerRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocaleService } from '../../app.locale.service';
import {
    AppSettingsService,
    DrawerComponent,
    GlobalRepositoryService,
    NavNode,
    SliderConfiguration,
    SpinnerService
} from '../../core';
import { AuthenticationService } from '../../eClinical/services/authentication.service';
import { CommonApiService } from '../../eClinical/services/common-api.service';
import { LoginServiceService } from '../../eClinical/services/login-service.service';
import { GetAuthLoginRequest } from '../../eClinical/services/Models/getAuthAccessTokenRequestModel';
import { GetAuthRefresRequest } from '../../eClinical/services/Models/getAuthRefreshRequestModel';
import { Language } from '../models/language-info.model';
import { MessageService } from './message.service';
import { ResetPasswordServiceService } from '../../eClinical/services/reset-password-service.service';
import { constants } from '../../eClinical/shared/constant';
@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    /* #region Fields */
    private loggedInStatus = new BehaviorSubject<boolean>(false);
    public loggedInStatus$ = this.loggedInStatus.asObservable();
    public docReq: boolean = false;

    private componentFactory: any = null;
    public openNowClicked = false;
    public loggedIn = false;
    public userRole = 0;
    public IsAdmin = false;
    public isPreLoginFLow = false;
    public rootViewContainer!: ViewContainerRef;
    private readonly subject = new Subject<any>();
    public readonly subjectClose = new Subject<boolean>();
    public readonly subjectLogin = new Subject<boolean>();
    private langSubject = new Subject<any>();
    public refreshDialog = new BehaviorSubject<boolean>(false);
    public closeReceptionistBookingDialog = new Subject<any>();
    public languages: Language[];
    private sliderConfig: SliderConfiguration = new SliderConfiguration();
    public ss_id: any;
    public ss_selected_org_id: any;
    public samplingStationChangeSubject = new Subject<any>();
    public patientSearchText: any;
    public ss_external_id: any;
    public bookingPatientId: any;
    public bookingPatientName: any;
    public salt: any;
    public password: any;
    public saltkey: any;
    public uiMode: any;
    public user_name: any;
    public dob: any;
    public passwordExpired: boolean;
    public mobileLogin = false;
    public navNodes: NavNode[];
    public isNotificationsLoaded: boolean;
    public selectedMenuId!: number;
    public selectedPMenuId!: number;
    public selectedRoute!: string;
    public lastSelectedRoute!: string;
    public isRecShowWalkinAppointments: boolean;
    public recAppointmentselectedTestsList: any[];
    public recAppointmentFromDate: string;
    public recAppointmentToDate: string;
    public ppaInfo: any;
    user_id: string;
    deviceId: string;
    sampling_stations_list: any[] = [];
    sampling_companies_list: any[] = [];
    public sampling = new FormControl();
    public samplingcompany = new FormControl();
    retryCount: any = 1;
    patient: {};
    public loginRedirect: string;
    public redirectDocuments: string;
    public latitude: any = Number(this.appSettings.mapCenterLat);
    public longitude: any = Number(this.appSettings.mapCenterLang);
    public zoom: any = 6;
    public redirectRelMem: boolean = false;
    public qrCode: boolean = false;
    public result: boolean = false;
    public id: any;
    public forSelf: boolean;
    public rmMode: boolean = false;
    public rm_id: any;
    public rm_data: any;
    public isRMAdded: boolean = false;
    public relativeMember: { user_name: string; dob: string; };
    public recRedirection: { action: string; org_id: string; ss_id: string; patient_qr: string; appointment_id: string, patient_id: string }
    public favLabMode: boolean = false;
    public testType: any[] = [];
    public testArray: any[] = [];
    public current_index: number = 0;
    public flow_detail: number = 0;
    isMassiveTesting: boolean = false;
    public flow_arr: number[] = [];
    public action_required: boolean = false;
    isLoginReceptionist: boolean = false;
    qr_rm_id: string = "";
    public stepconsolidate = {
        check_number: "",
        token_val: "",
        check_file: "",
        check_presc: "",
        selected_lab: "",
        selected_test: "",
        selected_slot: "",
        upload_presc: ""
    }
    public optionsG = {
        radius: 27,
        outerStrokeWidth: 5,
        innerStrokeWidth: 5,
        outerStrokeColor: "#24D156",
        showBackground: false,
        showImage: true,
        imageHeight: 34,
        imageWidth: 34,
        showInnerStroke: true,
    }

    private variableSubject = new BehaviorSubject<number>(0);
    variable$ = this.variableSubject.asObservable();
    public email: string;
    IsEhpAdmin: boolean = false;

    setVariable(value: number) {
        this.variableSubject.next(value);
    }
    // private loadslot = new BehaviorSubject<boolean>(false);
    // loadslot$ = this.variableSubject.asObservable();

    // setLoadSlotVariable(value: boolean) {
    //     this.loadslot.next(value);
    // }
    constructor(
        public readonly messageService: MessageService,
        private router: Router,
        private readonly translateService: TranslateService,
        private readonly globalRepositoryService: GlobalRepositoryService,
        private readonly http: HttpClient,
        private readonly appSettings: AppSettingsService,
        public loginService: LoginServiceService,
        public authenticationService: AuthenticationService,
        public localeService: LocaleService,
        private spinnerService: SpinnerService,
        public commonAPi: CommonApiService,
        private cookieService: CookieService,
        private readonly dialogRef: MatDialog,
        private resetPasswordService: ResetPasswordServiceService
    ) {
        this.saltkey = CryptoJS.lib.WordArray.random(128 / 20).toString();
        this.patientSearchText = '';
        const access_token = sessionStorage.getItem('access_token');
        if (access_token) {
            const { role } = JSON.parse(access_token);
            this.loggedIn = true;
            this.userRole = role;
            this.isLoginReceptionist = role == constants.nurseRole;
            this.handleNavigationNodes();
        } else {
            this.highlightLeftMenu(['']);
        }
    }

    updateLoginStatus(status: boolean) {
        this.loggedInStatus.next(status);
    }
    generateDeviceId(form: FormGroup, cookiekey: string) {
        this.deviceId = CryptoJS.lib.WordArray.random(128 / 20).toString();
        // localStorage.setItem('device-unique-id', this.deviceId);
        this.cookieService.set(cookiekey, this.deviceId, 30);
        form.controls.deviceDetail.setValue(this.deviceId)
    }
    public highlightLeftMenu(route: any, obj: any = null) {
        if (this.navNodes != null && this.navNodes.length > 0) {
            this.navNodes.forEach((x) => {
                x.Expand = false;
                for (const child of x.Children) {
                    if (child.Route === route[0]) {
                        this.selectedPMenuId = x.Id;
                        this.selectedMenuId = child.Id;
                        this.selectedRoute = child.Route;
                        x.Expand = true;
                        this.messageService.setPageTitle('', x.TranslationLabel);
                        break;
                    }
                }
            });
        }
        if (obj != null) {
            this.router.navigate(route, obj);
        } else {
            this.router.navigate(route);
        }
    }
    public TranslateLang(entity: any) {
        return JSON.parse(entity)[this.localeService.localeLang];
    }
    public calculateAge(dob: any): number {
        const age = new Date(dob);
        const date = new Date();
        let act_age = date.getFullYear() - age.getFullYear();
        if (date.getMonth() < age.getMonth()) {
            act_age--;
        } else if (
            date.getMonth() == age.getMonth() &&
            date.getDate() < age.getDate()
        ) {
            act_age--;
        }
        if (isNaN(act_age)) { return 0; } else {
            return act_age;
        }

    }
    public clearLogin() {
        this.dialogRef.closeAll();
        this.patient = {};
        this.navNodes = [];
        this.globalRepositoryService.authorizedNavNodes = [];
        this.loggedIn = false;
        this.isPreLoginFLow = false;
        sessionStorage.clear();
        this.selectedMenuId = 0;
        this.selectedPMenuId = 0;
        this.userRole = 0;
        this.IsAdmin = false;
        this.patientSearchText = '';
        this.isRecShowWalkinAppointments = false;
        this.recAppointmentselectedTestsList = null;
        this.recAppointmentFromDate = "";
        this.recAppointmentToDate = "";
        if (this.componentFactory) {
            this.cancelForm();
        }
        //this.highlightLeftMenu(['/']);
        this.spinnerService.show(false);
        window.location.reload();
    }
    public Logout(): void {
        this.authenticationService.postAuthLogout().subscribe(
            () => {
                this.clearLogin();
            },
            (error) => {
                if (error.status === '404') {
                    this.Logout();
                } else {
                    this.clearLogin();
                }
            }
        );
    }
    public RedirecttoLogin(): void {
        this.uiMode = 1;
        this.highlightLeftMenu(['/auth/login']);
        this.loggedIn = false;
        this.isPreLoginFLow = true;
        setTimeout(() => this.localeService.setCaptchalang(), 200);
    }
    public fetch(mockData: any, file: any): void {
        const req = new XMLHttpRequest();
        const path = `/assets/data/${file}.json`;
        req.open('GET', path);
        req.onload = () => {
            mockData(JSON.parse(req.response));
        };
        req.send();
    }
    public getLanguageInfo(): void {
        this.fetch((data: Language[]) => {
            const langs: any = this.appSettings.locale?.split(';');
            this.languages = data.filter((languageInfo: Language) =>
                langs.includes(languageInfo.Name)
            );
            //  this.setTranlations();
        }, 'language-info');
    }
    public handleNavigationNodes(fromconfirmBooking: boolean = false): void {
        this.selectedMenuId = 0;
        this.selectedPMenuId;
        if (this.userRole == constants.adminRole || this.userRole == constants.supervisorRole) {
            this.IsAdmin = true;
        }
        if (this.userRole == constants.ehpAdminRole || this.userRole == constants.ehpSupervisorRole) {
            this.IsEhpAdmin = true;
        }
        let nav_nodes = '';
        switch (this.userRole) {
            case 10: // Ehp user
                nav_nodes = constants.nav_user_ehp;
                break;
            case 9: // Ehp supervisor
                nav_nodes = constants.nav_supervisor_ehp;;
                break;
            case 8: // Ehp Admin
                nav_nodes = constants.nav_admin_ehp;
                break;
            // eclinc
            case 5: // Admin
                nav_nodes = constants.nav_admin;
                break;
            case 3: // supervisor
                nav_nodes = constants.nav_supervisor;
                break;
            case 4: // patient
                nav_nodes = constants.nav_user;
                break;
            case 1: // reception
                nav_nodes = constants.nav_receptionist;
                break;
            default:
                break;
        }
        this.getJsonFileData(nav_nodes).subscribe((navNodes: NavNode[]) => {
            if (navNodes && navNodes.length > 0) {
                this.navNodes = navNodes;
                this.globalRepositoryService.authorizedNavNodes = navNodes;
                this.handlePageSubTitle();
                this.loggedIn = true;
                if (fromconfirmBooking != true) {
                    if (this.userRole == constants.patientRole) {
                        if (this.loginRedirect) {
                            this.highlightLeftMenu(['patient/my-appointment']);
                        }
                        else if (this.redirectDocuments) {
                            this.highlightLeftMenu(['patient/documents']);
                        }
                        else if (this.redirectRelMem) {
                            this.highlightLeftMenu(['patient/members']);
                        }
                        else if (this.result) {
                            this.highlightLeftMenu(['patient/results']);
                        }
                        else if (this.qrCode) {
                            this.highlightLeftMenu(['patient/qr-code']);
                        }
                        else {
                            this.highlightLeftMenu(['patient/dashboard']);
                        }
                    } else if (this.IsAdmin) {
                        this.highlightLeftMenu(['admin/user']);
                    } else if (this.IsEhpAdmin) {
                        this.highlightLeftMenu(['ehp/establishment']);
                    }
                    else if (this.userRole == constants.ehpUserRole) {
                        this.highlightLeftMenu(['ehp/result']);
                    }
                    else {
                        this.highlightLeftMenu(['receptionist/find-patient']);
                    }
                }
                // else {
                //     this.highlightLeftMenu(['patient/confirm-appointment']);
                // }
            }
        });
    }

    private handlePageSubTitle(): void {
        if (this.router.url !== '/') {
            const title = this.findNavNodeBasedOnRoute(this.router.url.substr(1));
            this.messageService.setPageTitle('', title);
        }
    }

    /** Method to find title from Nav Nodes based on the Route path */
    private findNavNodeBasedOnRoute(routePath: string): any {
        let title = '';
        if (this.navNodes) {
            for (const item of this.navNodes) {
                const nodes = item.Children.filter(
                    (node: any) => node.Route === routePath
                );
                if (nodes != null && nodes.length > 0) {
                    title = item.TranslationLabel;
                    break;
                }
            }
        }
        return title;
    }

    private getJsonFileData(fileName: string): Observable<Object> {
        const filePath = `/assets/data/${fileName}.json`;

        return this.http.get(filePath);
    }
    /* #region Public Methods */
    /** Method to subscrible to loader when component is added in side nav */
    public onComponentAdd(): Observable<SliderConfiguration> {
        return this.subject.asObservable();
    }

    public onReceptionistBookingDialogClose() {
        this.closeReceptionistBookingDialog.next();
    }

    public onReceptionistBookingDialogCloseCallBack(): Observable<any> {
        return this.closeReceptionistBookingDialog.asObservable();
    }

    public onLangChange() {
        this.langSubject.next();
    }

    public onLangChangeCallback(): Observable<any> {
        return this.langSubject.asObservable();
    }

    public onSSChange() {
        this.samplingStationChangeSubject.next();
    }

    public onSSChangeCallback(): Observable<any> {
        return this.samplingStationChangeSubject.asObservable();
    }

    refreshState(isRefresh: boolean): void {
        this.refreshDialog.next(isRefresh);
    }
    /** Method to subscribe to closing of component or form */
    public onFormClose(payload?: any): Observable<boolean> {
        if (payload) {
            this.subjectClose.next(payload);
        }
        return this.subjectClose.asObservable();
    }
    reloadComponent() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.highlightLeftMenu([this.router.url]);
    }

    /** Method to log an entry in console */
    public setRootViewContainerRef(viewContainerRef: ViewContainerRef): void {
        this.rootViewContainer = viewContainerRef;
    }

    /** This method is to check if the slider is already open and if so, shows pop-up */
    public addDynamicComponent(
        factory: ComponentFactory<DrawerComponent>,
        width: any,
        data: any,
        isReadOnly = false
    ): void {
        document.querySelector('body').classList.add('hideScroll');
        if (this.componentFactory === null) {
            this.createSliderComponent(factory, width, data, isReadOnly);
        } else {
            if (this.componentFactory.instance.isSliderDataEdited) {
                this.messageService
                    .confirm(
                        this.translateService.instant(
                            'Common.GlobalMessages.UnSaveDataConfirmText'
                        ),
                        this.translateService.instant(
                            'Common.GlobalMessages.UnSaveDataConfirmTitle'
                        ),
                        this.translateService.instant('Common.Button.Yes'),
                        this.translateService.instant('Common.Button.No')
                    )
                    .subscribe((actionResult: boolean) => {
                        if (!actionResult) {
                            this.cancelForm();
                            this.createSliderComponent(factory, width, data, isReadOnly);
                        } else {
                            this.subject.next(this.sliderConfig);
                        }
                    });
            } else {
                this.createSliderComponent(factory, width, data, isReadOnly);
            }
        }
    }

    /** Method to Save form */
    public closeForm(): void {
        this.subjectClose.next(true);
        this.componentFactory.instance.isSliderDataEdited = false;
    }

    /** Method to cancel the form */
    public cancelForm(): void {
        document.querySelector('body').classList.remove('hideScroll');
        this.highlightLeftMenu([this.router.url]);
        this.subjectClose.next(false);
        this.componentFactory.instance.isSliderDataEdited = false;
    }

    /** This method is to check if the slider data is edited */
    public isSliderEdited(): boolean {
        if (this.componentFactory !== null) {
            return this.componentFactory.instance.isSliderDataEdited;
        }
        return false;
    }

    /** This method is used to open the on hold slider when there is an action on confirmation pop-up */
    public openSlider(): void {
        this.subject.next(this.sliderConfig);
    }
    /* #endregion */

    /* #region Private Methods */
    /** This method is to create new view on slider component */
    private createSliderComponent(
        factory: ComponentFactory<DrawerComponent>,
        width: any,
        data: any,
        isReadOnly: boolean
    ): void {
        this.rootViewContainer.clear();
        this.componentFactory = factory.create(this.rootViewContainer.injector);
        this.rootViewContainer.insert(this.componentFactory.hostView);
        (this.componentFactory.instance as DrawerComponent).sliderFormData = data;
        this.sliderConfig = { Width: width, IsSilderReadOnly: isReadOnly };
        this.componentFactory.instance.isSliderDataEdited = false;
        this.subject.next(this.sliderConfig);
    }
    public sendactivationLink(email: any) {
        const emailobj = {
            Email: email
        };
        this.commonAPi.SendActivationLink(emailobj).subscribe(() => {
            this.messageService.success(
                this.translateService.instant('login.activated-mail-success')
            );
        });
    }
    getLoginCookie(form: any, rememberMe: any) {
        const rememberData = this.cookieService.get('remember');
        if (rememberData) {
            const decryptedData = this.decryptData(rememberData, '');

            if (decryptedData) {
                form.controls.loginName.setValue(decryptedData.username.trim());
                form.controls.password.setValue(decryptedData.password);
                rememberMe.setValue(true);
            }
        }
    }
    setResetLoginCookie(form: any, rememberMe: any, salt: any) {
        salt;
        if (rememberMe.value) {
            const data = {
                username: form.controls.loginName.value.trim(),
                password: form.controls.password.value.trim()
            };
            this.cookieService.set('remember', this.encryptData(data, ''));
        } else {
            this.cookieService.set('remember', '');
        }
    }
    deleteMatomoCookie() {
        var arrSplit = document.cookie.split(";");

        for (var i = 0; i < arrSplit.length; i++) {
            var cookie = arrSplit[i].trim();
            var cookieName = cookie.split("=")[0];
            // If the prefix of the cookie's name matches the one specified, remove it
            if (cookieName.indexOf("_pk_ref") === 0) {
                this.cookieService.delete(cookieName);
                // Remove the cookie
            }
        }
    }
    encryptData(data: any, salt: any) {
        try {
            const key = environment.saltval + salt;
            const keyutf = CryptoJS.enc.Utf8.parse(key);
            const iv = CryptoJS.enc.Base64.parse(key);

            let enc = null;
            if (salt) {
                enc = CryptoJS.AES.encrypt(JSON.stringify(data), keyutf, {
                    iv
                }).toString();
            } else {
                enc = CryptoJS.AES.encrypt(
                    JSON.stringify(data),
                    environment.saltval
                ).toString();
            }
            return enc;
        } catch (e) {
            console.log(e);
            return null;
        }
    }

    decryptData(data: string, salt: any) {
        try {
            if (!salt) {
                const bytes = CryptoJS.AES.decrypt(data, environment.saltval);
                if (bytes.toString()) {
                    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                }
            }

            return null;
        } catch (e) {
            console.log(e);
            return null;
        }
    }
    checkDate(element: any) {
        const st = new Date(element?.SlotTime);
        if (st.getHours() > 0 || st.getMinutes() > 0) {
            return moment(element?.SlotTime)
                .startOf('second')
                .isBefore(moment().startOf('second'));
        } else {
            return moment(element.SlotTime)
                .startOf('date')
                .isBefore(moment().startOf('date'));
        }
    }
    checkExpiryDate(date: any) {
        return moment(date)
            .startOf('date')
            .isBefore(moment().startOf('date'));
    }

    /* #endregion */

    login(form: FormGroup, password: any, fromAppointment: boolean = false, userType: string = 'eclinic',) {
        const loginRequest: GetAuthLoginRequest = new GetAuthLoginRequest();
        loginRequest.username = form.controls.loginName.value.trim();
        loginRequest.password = password;
        loginRequest.device_detail = form.controls.deviceDetail.value;
        loginRequest.from_appointment = fromAppointment;
        loginRequest.mode = "mail";
        loginRequest.user_type = userType;
        this.spinnerService.show(true);
        return this.authenticationService.getAuthLogin(loginRequest);
    }

    startRefreshTokenTimer(user: any) {
        this.retryCount = 1;
        const access_token = jwtDecode(user['access_token']);
        const timeout = (access_token['exp'] - moment().unix() - 180) * 1000;
        const refreshTokenTimeout = setTimeout(() => {
            this.callRefreshTokenAPI();
        }, timeout);
        refreshTokenTimeout;
    }

    callRefreshTokenAPI() {
        const refreshRequest: GetAuthRefresRequest = new GetAuthRefresRequest();
        refreshRequest.access_token = sessionStorage.getItem('header');
        refreshRequest.refresh_token = sessionStorage.getItem('refresh_token');
        this.authenticationService.getAuthRefresh(refreshRequest).subscribe(
            (response: any) => {
                const refreshToken = jwtDecode(sessionStorage.getItem('refresh_token'));
                if (refreshToken['exp'] >= moment().unix()) {
                    const refreshPayload = { access_token: response['access_token'] };
                    this.startRefreshTokenTimer(refreshPayload);
                } else {
                    if (sessionStorage.getItem('header') != '') {
                        this.Logout();
                    }
                }
                sessionStorage.setItem('header', response['access_token']);
                const access_token = jwtDecode(response['access_token']);
                sessionStorage.setItem('access_token', JSON.stringify(access_token));
            },
            (error: any) => {
                console.log(error);
                if (sessionStorage.getItem('header') != '') {
                    this.Logout();
                }
            }
        );
    }

    getGenderKeyName(key: string) {
        if (key.toLowerCase() == 'male') {
            return 'createAccount.male';
        } else if (key.toLowerCase() == 'female') {
            return 'createAccount.female';
        } else {
            return 'createAccount.others';
        }
    }

    disableSpace(event: Event) {
        event.preventDefault();
    }
    public sendPasswordLink(email: string) {
        const inputValue = {
            email: email
        };
        this.resetPasswordService.postAuthforget(inputValue).subscribe(
            (output) => {
                console.log(output);
                this.messageService.success(
                    this.translateService.instant('login.mail-success'),
                    5000
                );
            })
    }

    public keyPressAlphabets(event: any) {
        const inp = String.fromCharCode(event.keyCode);

        if (/[a-zA-ZàâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ -]/.test(inp)) {
            return true;
        } else {
            // event.preventDefault();
            return false;
        }
    }

    public pasteAlphabets(event: any) {
        const clipboardData = event.clipboardData;
        const pastedText = clipboardData.getData("text");

        if (/^[a-zA-ZàâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ -]+$/.test(pastedText)) {
            return true;
        } else {
            event.preventDefault();
            return false;
        }
    }

    validateAllowedCharacters(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
            // Regular expression to match only alphabet, comma, hyphen, and space
            // const regex = /^[a-zA-Z, -]*$/;

            if (value) {
                const regex = /^[A-Za-z\s,-]+$/;
                const isValid = regex.test(value);
                const hasAlphabet = /[A-Za-z]/.test(value);
                if (!isValid || !hasAlphabet) {
                    return { invalidChars: 'Input must contain only alphabets, spaces, commas, or hyphens, and must not be only spaces or commas.' };
                }
            }
            return null;
        };
    }


    public toBoolean(str: string): boolean {
        return str?.toLowerCase() === "true";
    }
}
