import { Component, Input, OnInit } from '@angular/core';
import { DrawerComponent, SpinnerService } from '../../../core';
import { LoaderService } from '../../../main/services/loader.service';
import { PatientAppointmentService } from '../../services/patient-appointment.service';
import { MessageService } from '../../../main/services/message.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-view-prescription',
  templateUrl: './view-prescription.component.html',
  styleUrls: ['./view-prescription.component.scss']
})
export class ViewPrescriptionComponent implements OnInit, DrawerComponent {

  constructor(
    private loaderService: LoaderService,
    private readonly spinner: SpinnerService,
    private patientAppointmentService: PatientAppointmentService,
    private readonly messageService: MessageService,
    private translate: TranslateService,
    public toastr: ToastrService
  ) { }
  @Input() sliderFormData: any;
  isSliderDataEdited?: boolean;
  isPdfViewerOpened: boolean;
  protected prescriptions: any;
  pdfBlob: any;
  pdfUpdate = false;
  documents: any;
  openUrl: any;
  protected updateDocumentUrl: any;
  selectedIndex: number;
  protected roleId = JSON.parse(sessionStorage.getItem('access_token')).role;
  protected item = { Id: 1, FrontSideData: "", BackSideData: "", FrontFileName: "", BackFileName: "", IsIncorrectFrontFileFormat: false, IsInvalidFrontFileSize: false, IsIncorrectBackFileFormat: false, IsInvalidBackFileSize: false, Expanded: true };
  protected isPastAppointment: boolean = false;
  protected uploadStatus: boolean = false;
  patientComments: any;


  ngOnInit(): void {
    console.log(this.sliderFormData);
    this.patientComments = this.sliderFormData.Comments;
    this.prescriptions = this.sliderFormData.PrescriptionUrl.split('|');
    this.isPastAppointment = this.isSlotValid(this.sliderFormData.SlotTime) && this.checkDate(this.sliderFormData);
  }

  dismiss() {
    this.loaderService.cancelForm();
  }

  openPdf(path: any, index: number) {
    this.selectedIndex = index;
    this.openUrl = path;
    this.spinner.show(true);
    const prescriptionName = path.substring(path.lastIndexOf('/') + 1);
    this.patientAppointmentService.getPrescription(prescriptionName).subscribe((res: any) => {
      const byteArray = new Uint8Array(atob(res).split('').map(char => char.charCodeAt(0)));
      const file = new Blob([byteArray], { type: 'application/octet-stream' });
      this.pdfBlob = URL.createObjectURL(file);
      this.spinner.show(false);
      this.isPdfViewerOpened = true;
      document.querySelector('body').classList.add('hideScroll');
    });
  }

  isPdfClosed() {
    this.isPdfViewerOpened = false;
    document.querySelector('body').classList.remove('hideScroll');
  }

  isPdfReplaced() {
    this.isPdfClosed();
    this.updatePrescription(this.openUrl);
  }

  deletePrescription(url: any) {
    this.messageService
      .confirm(
        "",
        this.translate.instant('view-prescription.delete-prescription-title')
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          this.spinner.show(true);
          this.patientAppointmentService.deletePatientPrescription(this.sliderFormData.AppointmentId, url.substring(url.lastIndexOf('/') + 1)).subscribe(() => {
            this.messageService.success(this.translate.instant('view-prescription.prescription-delete-success'), 5000);
            this.spinner.show(false);
            this.prescriptions = this.prescriptions.filter((p: any) => p !== url);
            this.dismiss();
          },
            (error: any) => {
              this.messageService.warning(this.translate.instant(error['message']), 5000);
              this.spinner.show(false);
            });
        }
      });
  }

  getUpdateDocument(event: any) {
    this.documents = event;
    this.uploadStatus = false;
  }

  updatePrescription(url: any) {
    this.updateDocumentUrl = url;
    this.pdfUpdate = true;
    this.documents = this.item;
  }

  goBack() {
    this.pdfUpdate = false;
  }

  fileUploaded() {
    if (this.documents?.PrescriptionParts?.length > 0) {
      this.spinner.show(true);
      this.patientAppointmentService.updateReplacePrecription(this.createReplaceObject()).subscribe(
        () => {
          this.pdfUpdate = false;
          this.documents.PrescriptionParts = [];
          this.messageService.success(this.translate.instant('view-prescription.prescription-update-success'), 5000);
          this.spinner.show(false);
        },
        (eR: HttpErrorResponse) => {
          if (eR.error.internal_code === 4001 && eR.error.code == 400) {
            this.messageService.alert(this.translate.instant('view-prescription.prescription-filesize-update-error'), 5000);
          } else {
            this.messageService.alert(this.translate.instant('view-prescription.prescription-update-error'), 5000);
          }
          this.pdfUpdate = false;
          this.spinner.show(false);
        }
      );
    }
    else {
      this.uploadStatus = true;
      this.toastr.warning(this.translate.instant("upload-pic.no-prescription"));

    }
  }

  createReplaceObject() {
    let pdfDoc = this.documents.PrescriptionParts.filter(i => i.type == "pdf");
    console.log(pdfDoc?.length);
    return {
      appointment_id: this.sliderFormData.AppointmentId,
      azure_id: this.updateDocumentUrl,
      grey_scale: pdfDoc?.length > 0 ? true : false,
      prescriptions_pages: this.documents.PrescriptionParts.map((s: any) => s.prescriptionPartData)
    };
  }

  checkDate(element: any) {
    if (element.SlotTime == null || element.SlotTime == "" || new Date(element.SlotTime).getFullYear() == 1 || element.ArrivedTime != "") {
      return true;
    }
    const st = new Date(element.SlotTime);
    if (st.getHours() > 0 || st.getMinutes() > 0) {
      return moment(element.SlotTime)
        .startOf('second')
        .isBefore(moment().startOf('second'));
    } else {
      return moment(element.SlotTime)
        .startOf('date')
        .isSameOrBefore(moment().startOf('date'));
    }
  }

  isSlotValid(slotTime: any): boolean {
    if (slotTime == null || slotTime == "" || new Date(slotTime).getFullYear() == 1) {
      return false
    }
    return true;
  }

}
