<div class="ecl-table-container" *ngIf="!replacePrescription">
  <header class="pull-left">

    <span *ngIf="isHistoryData">
      <span class="icon-ic-back icon-cls" (click)="isHistoryData=false;"></span>
      <h3 class="pull-left">{{'my-apointment.appointments-history'|translate}}</h3>
    </span>
    <h3 class="pull-left" *ngIf="!isHistoryData">
      {{'my-apointment.my-appointment'|translate}}
    </h3>
    <div class="history">
      <button *ngIf="isHistoryData == false" (click)="isHistoryData = true" class="mat-tbl-icon lab-button"
        mat-icon-button>
        <fa-icon class="icon-ic-history icon-cls"></fa-icon>
      </button>
      <a mat-button color="accent" class="history-text border-right" *ngIf="isHistoryData == false"
        (click)="isHistoryData = true" type="button">{{'my-apointment.appointments-history'|translate}}</a>
      <button mat-button color="accent" class="ghost history-text margintw" *ngIf="isHistoryData == false"
        (click)=" redirectBooking()" type="button">{{'my-apointment.appointments-register'|translate}}</button>
    </div>
  </header>
  <mat-tab-group disableRipple [@.disabled]="true" class="tab-app pull-left" animationDuration="1000ms"
    [selectedIndex]="tabSelection">
    <!-- <mat-tab label="{{'my-apointment.all'|translate}}">
      <app-all-apointment *ngIf="refresh" (editButtonClicked)="editPatientInfo($event)"
        (cancelButtonClicked)="cancelButtonClicked($event)" (viewPdf)="viewPrecription($event)"
        (viewPdfMobile)="openPdf($event)" (deletePdfMobile)="deletePrescription($event)"
        (uploadPresc)="uploadPrecription($event)" [displayedColumns]="displayedColumns" [dataSource]="dataSource">
      </app-all-apointment>
    </mat-tab> -->
    <mat-tab label="{{'my-apointment.my-appointments'|translate}} ({{totalCnt}})">
      <app-upcoming-apointment *ngIf="!isHistoryData" (editButtonClicked)="editPatientInfo($event)"
        (cancelButtonClicked)="cancelButtonClicked($event)" (viewPdfMobile)="openPdf($event)"
        (deletePdfMobile)="deletePrescription($event)" (viewPdf)="viewPrecription($event)"
        (appointmentCnt)="updateTotalCnt($event)" (uploadPresc)="uploadPrecription($event)"
        [displayedColumns]="displayedColumns" [isHistoryData]="isHistoryData">
      </app-upcoming-apointment>
      <app-past-apointment *ngIf="isHistoryData" (editButtonClicked)="editPatientInfo($event)"
        (viewPdfMobile)="openPdf($event)" (appointmentCnt)="updateTotalCnt($event)"
        (deletePdfMobile)="deletePrescription($event)" (viewPdf)="viewPrecription($event)"
        (uploadPresc)="uploadPrecription($event)" [displayedColumns]="displayedColumnsPast"
        [isHistoryData]="isHistoryData">
      </app-past-apointment>
    </mat-tab>
    <mat-tab label="{{'my-apointment.relative-members-appointments'|translate}}   ({{totalRCnt}})">
      <app-relative-member-upcoming-apointment *ngIf="!isHistoryData" (editButtonClicked)="editPatientInfo($event)"
        (cancelButtonClicked)="cancelButtonClicked($event)" (viewPdfMobile)="openPdf($event)"
        (appointmentCnt)="updateRTotalCnt($event)" (deletePdfMobile)="deletePrescription($event)"
        (viewPdf)="viewPrecription($event)" (uploadPresc)="uploadPrecription($event)"
        [displayedColumns]="displayedColumns">
      </app-relative-member-upcoming-apointment>
      <app-relative-member-past-apointment *ngIf="isHistoryData" (appointmentCnt)="updateRTotalCnt($event)"
        (editButtonClicked)="editPatientInfo($event)" (viewPdfMobile)="openPdf($event)"
        (deletePdfMobile)="deletePrescription($event)" (viewPdf)="viewPrecription($event)"
        (uploadPresc)="uploadPrecription($event)" [displayedColumns]="displayedColumnsPast">
      </app-relative-member-past-apointment>
    </mat-tab>
  </mat-tab-group>
  <app-pdf-viewer *ngIf="isPdfViewerOpened" [allPages]="true" pdftitle="{{'stepper-flow.prescriptions'|translate}}"
    [blobSrc]="pdfBlob" (closed)="isPdfClosed()" (replacePrescriptionClicked)="uploadPrecriptionMobile()"
    [replacePrescription]="roleId === patientRole && replaceEnable"
    [patientComments]="patientComments"></app-pdf-viewer>
</div>
<div *ngIf="replacePrescription" class="replace-prescription-container">
  <div class="header" style="display: flex; justify-content: space-between; flex-direction: row; width: 100%;">
    <div class="pull-left back-icon" style="margin: 10px;">
      <app-section-title title="{{'upload-pic.upload'|translate}}"></app-section-title>
    </div>
    <div class="pull-right" style="margin: 10px;">
      <button class="slider_closebtn" mat-button color="accent" (click)="goBack()">
        <span class="icon-ic-close"></span>
      </button>
    </div>
  </div>
  <div class="appointment-container" [class.grey]="!uploadStatus" [class.red]="uploadStatus">
    <app-appointment-prescription [document]="item" (updateDocument)="getUpdateDocument($event)" [prescription]="true"
      (isImageClicked)="getClickImageStatus($event)"></app-appointment-prescription>
  </div>
  <div class="actions-wrapper">
    <button mat-raised-button color="primary" type="submit" class="ghost alcenter" (click)="fileUploaded()"
      style="width: 25%;">
      {{'upload-pic.upload' | translate}}
    </button>
  </div>
</div>