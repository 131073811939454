import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { LocaleService } from '../../../app.locale.service';
import { LoaderService, MessageService } from '../../../main';
import { PatientService } from '../../services/patient.service';
import { GetDirectionsComponent } from './get-directions/get-directions.component';
import { Location } from '@angular/common';
export interface documents {
    sampling_station_name: string;
    address_details: string;
    phone_number: string;
}
// const FAVORAITE_LAB_DATA: documents[] = [
//   {lab : 'Eurofins |IPROMA Valencia', address : 'Avenida de las Cortes Valencianas, 26 Bloque 1', contact: '+34 963 89 12 66'},
//   {lab : 'EuroFins Testing Alpha', address : 'Carrer de l’Emparrat, 3, bajo, 46920 Mislata, Valencia, Spain', contact: '+34 963 13 41 10'},
// ];

@Component({
    selector: 'app-favourite-lab',
    templateUrl: './favourite-lab.component.html',
    styleUrls: ['./favourite-lab.component.scss']
})
export class FavouriteLabComponent implements OnInit {
    displayedColumns: string[] = ['lab', 'address', 'contact', 'actions'];
    // dataSource = FAVORAITE_LAB_DATA;
    directionsSliderContainer: any;
    dataSource: any = [];
    isMobile: boolean = false;

    readonly breakpoint$ = this.breakpointObserver
        .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
        .pipe(
            tap(value => console.log(value)),
            distinctUntilChanged()
        );
    public patientId: '';
    refresh = true;
    FAVORAITE_LAB_DATA: any[] = [];
    lab: any;
    protected navigationDetails: any;

    constructor(public breakpointObserver: BreakpointObserver,
        private componentFactoryResolver: ComponentFactoryResolver,
        public loader: LoaderService, private translate: TranslateService,
        public router: Router,
        private patientService: PatientService,
        private messageService: MessageService,
        private location: Location,
        private localeService: LocaleService) {
        this.breakpoint$.subscribe(() =>
            this.breakpointChanged()
        );
        this.FAVORAITE_LAB_DATA = new Array<documents>();
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
        this.navigationDetails = this.location.getState();
        if (this.navigationDetails) {
            this.lab = this.navigationDetails?.lab;
            console.log(this.lab);
            //this. getDirections(this.lab);
        }

    }
    openDirections(station: any) {
        event.stopPropagation();
        const lat = Number(
            station.coordinates.substring(
                station.coordinates.indexOf(":") + 1,
                station.coordinates.indexOf(",")
            )
        ).toString();
        const lng = Number(
            station.coordinates.substring(
                station.coordinates.lastIndexOf(":") + 1,
                station.coordinates.indexOf("}")
            )
        ).toString();
        window.open(
            "https://www.google.com/maps/dir/?api=1&destination=" +
            lat +
            "," +
            lng +
            "&travelmode=driving",
            "_blank"
        );
    }
    ngOnInit(): void {
        this.patientId = JSON.parse(sessionStorage.getItem('userDetails')).id;
        this.getPatientfavorite();
        if (screen.width < 962) { this.isMobile = true; }

    }
    public getPatientfavorite() {
        const self = this;
        self.refresh = false;
        this.patientService.getsavePatientFavoriteSamplingStations(this.patientId).subscribe((documents: any) => {
            if (documents != null) {
                this.FAVORAITE_LAB_DATA = documents;
            } else {
                this.FAVORAITE_LAB_DATA = [];
            }
            this.dataSource = []; this.dataSource = this.FAVORAITE_LAB_DATA; self.refresh = true;
        });
    }

    redirectFindALab() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(['patient/favourite-lab/add']);
    }
    onSelectedFavoriteLab(data: any) {
        const datasaveFavorite = {
            'sampling_station_id': [data.sampling_station_id],
            'patient_id': this.patientId,
            'is_active': false
        };
        this.patientService.savePatientFavoriteSamplingStations(datasaveFavorite).subscribe(
            response => {
                if (response != null) {
                    this.messageService.success(this.translate.instant('lab-google.unmarked-favorite'));
                    this.getPatientfavorite();
                }
            },
            error => {
                this.messageService.alert(this.translate.instant('lab-google.unmarked-favorite') + error.message, 3000);
            }
        );


    }



    breakpointChanged() {
        if (this.breakpointObserver.isMatched('(max-width: 1050px) and (min-width: 601px)')) {
            this.directionsSliderContainer = 640;
        } else if (this.breakpointObserver.isMatched('(max-width: 600px)')) {
            this.directionsSliderContainer = '100%';
        } else {
            this.directionsSliderContainer = 664;
        }
    }

    getDirections(data?: any) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GetDirectionsComponent);
        this.loader.addDynamicComponent(componentFactory, this.directionsSliderContainer, data);
    }

}
