<!-- <div class="header">
    <div class="header-content">
        <span class="fs20">Résultat</span>
        <div class="header-style"></div>
        <mat-checkbox>
            Remonter les cas urgents
        </mat-checkbox>
    </div>
    <div class="header-content">
        <input matInput placeholder="Search" class="search-input" />
        <div class="header-style"></div>
        <span class="icon-ic_filter"></span>
    </div>
</div> -->

<table mat-table [dataSource]="filteredDataSource" class="mat-elevation-z8" width="100%">

    <!-- Lu / non lu Column -->
    <ng-container matColumnDef="isRead">
        <th mat-header-cell *matHeaderCellDef class="wp-7">
            <div class="display-flex">
                Lu / non lu
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['isRead'], 'icon-ic_filteraaplied': filtersApplied['isRead']}"
                [matMenuTriggerFor]="isReadMenu" ></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="!element.isRead" class="icon-ic-unread color-yellow"  matTooltip="Unread Result" matTooltipPosition="below"></span>

        </td>
    </ng-container>

    <!-- Patient Column -->
    <ng-container matColumnDef="patient">
        <th mat-header-cell *matHeaderCellDef class="wp-9">
            <div class="display-flex">
                Patient
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['patient'], 'icon-ic_filteraaplied': filtersApplied['patient']}"
                [matMenuTriggerFor]="patientMenu"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="name-col">
                <span class="pname color-blue"> {{ element.patient }}</span>
                <span class="gender"> {{ element.gender}}</span>
            </div> 
        </td>
    </ng-container>

    <!-- Date de naissance Column -->
    <ng-container matColumnDef="dob">
        <th mat-header-cell *matHeaderCellDef class="wp-74">
            <div class="display-flex">
                Date de naissance
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['dob'], 'icon-ic_filteraaplied': filtersApplied['dob']}"
                [matMenuTriggerFor]="dobMenu"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.dob }} </td>
    </ng-container>

    <!-- IPP Column -->
    <ng-container matColumnDef="ipp">
        <th mat-header-cell *matHeaderCellDef class="wp-7">
            <div class="display-flex">
                IPP
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['ipp'], 'icon-ic_filteraaplied': filtersApplied['ipp']}"
                [matMenuTriggerFor]="ippMenu"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.ipp }} </td>
    </ng-container>

    <!-- Hospital ID Column -->
    <ng-container matColumnDef="hospitalId">
        <th mat-header-cell *matHeaderCellDef class="wp-94">
            <div class="display-flex">
                No. Hospitalisation
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['hospital'], 'icon-ic_filteraaplied': filtersApplied['hospital']}"
                [matMenuTriggerFor]="hospitalMenu"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.hospitalId }} </td>
    </ng-container>

    <!-- Dossier Column -->
    <ng-container matColumnDef="dossier">
        <th mat-header-cell *matHeaderCellDef class="wp-84">
            <div class="display-flex">
                No. dossier
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['dossier'], 'icon-ic_filteraaplied': filtersApplied['dossier']}"
                [matMenuTriggerFor]="dossierMenu"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> 
            <div class="name-col">
                <span>{{ element.dossier }}</span>
                <span class="emergency" *ngIf="element.is_emergency">Emergency</span>
              </div>   
        </td>
    </ng-container>

    <!-- Prescriber Column -->
    <ng-container matColumnDef="prescriber">
        <th mat-header-cell *matHeaderCellDef class="wp-10">
            <div class="display-flex">
                Prescripteur
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['prescriber'], 'icon-ic_filteraaplied': filtersApplied['prescriber']}"
                [matMenuTriggerFor]="prescriberMenu"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.prescriber }} </td>
    </ng-container>

    <!-- Department Column -->
    <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef class="wp-10">
            <div class="display-flex">
                Département
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['dept'], 'icon-ic_filteraaplied': filtersApplied['dept']}"
                [matMenuTriggerFor]="deptMenu"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.department }} </td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="wp-6">
            <div class="display-flex">
                Status
                <span class=" filter-icon"
                [ngClass]="{'icon-ic_filter': !filtersApplied['status'], 'icon-ic_filteraaplied': filtersApplied['status']}"
                 [matMenuTriggerFor]="statusMenu"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element">
            <span [ngClass]="{
                    'icon-ic-samplerecvd color-darkblue': element.status?.toLowerCase() === 'sample received',
                    'icon-ic-ongoinganaly color-yellow': element.status?.toLowerCase() === 'ongoing analysis',
                    'icon-ic-partialresult color-yellow2': element.status?.toLowerCase() === 'partial results',
                    'icon-ic-resultready color-green': element.status?.toLowerCase() === 'results'
                  }"
                  
                  [matTooltip]="element.status" matTooltipPosition="below">
            </span>
    
        </td>
    </ng-container>

    <!-- Date heure prel Column -->
    <ng-container matColumnDef="slotDate">
        <th mat-header-cell *matHeaderCellDef class="wp-84">
            <div class="display-flex">
                Date heure prel
                <span class="filter-icon" 
                [ngClass]="{'icon-ic_filter': !filtersApplied['slotDate'], 'icon-ic_filteraaplied': filtersApplied['slotDate']}"
                [matMenuTriggerFor]="dateMenu" (click)="setActiveColumn('slotDate')"></span>
            </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.slotDate }} </td>
    </ng-container>

    <!-- Date heure résultat Column -->
    <ng-container matColumnDef="modifyDate">
        <th mat-header-cell *matHeaderCellDef class="wp-86">
            <div class="display-flex">
                Date heure résultat
                <span class="filter-icon"
                [ngClass]="{'icon-ic_filter': !filtersApplied['modifyDate'], 'icon-ic_filteraaplied': filtersApplied['modifyDate']}"
                 [matMenuTriggerFor]="dateMenu" (click)="setActiveColumn('modifyDate')"></span>
            </div>        
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.modifyDate }} </td>
    </ng-container>

    <!-- Action Colums for view result and view pdf -->
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
            <span class="icon-ic_filter-close filter-icon" (click)="ClearAllFilters()"></span> &nbsp;
            <span class="color-blue">Clear All</span>
        </th>
        <td mat-cell *matCellDef="let element">
            <!-- <span class="icon-ic-pdf color-blue"></span>
            <span>PDF</span> -->
        </td>
    </ng-container>

    <!-- No Record State -->
    <ng-container matColumnDef="noRecord">
        <td mat-footer-cell *matFooterCellDef colspan="12">
            <img src="/assets/images/application-logos/svg/il-empty-screen.svg" alt="No Data">
            <div class="desc">
                <p>empty records</p>
                <p>no rec found</p>
            </div>
        </td>
    </ng-container>

    <!-- Table Header and Rows -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Empty State Footer -->
    <ng-template [ngIf]="dataSource.length === 0">
        <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
    </ng-template>

</table>

<!-- Mat Menus Menu -->
<mat-menu #isReadMenu="matMenu">
    <button mat-menu-item (click)="isReadFilter = true; $event.stopPropagation()">
        <mat-checkbox [checked]="isReadFilter === true">Read</mat-checkbox>
    </button>
    <button mat-menu-item (click)="isReadFilter = false; $event.stopPropagation()">
        <mat-checkbox [checked]="isReadFilter === false">Unread</mat-checkbox>
    </button>
    <div class="menu-footer">
        <button mat-button class="clear-button" (click)="clearIsReadFilter()">Clear</button>
        <!-- Apply Filter Button -->
        <button mat-button class="apply-button" (click)="applyFilter()">Apply Filter</button>
    </div>
</mat-menu>


<mat-menu #patientMenu="matMenu">
    <div class="menu1">
        <div class="menu-header">
            <input matInput placeholder="Search Patients" [(ngModel)]="searchText" (input)="filterRecords()"
                (click)="$event.stopPropagation();" class="search-input" />
        </div>
        <div class="menu-content">
            <mat-option *ngFor="let patient of filteredPatients" [value]="patient.id" class="menu-option">
                <mat-checkbox [checked]="selectedPatients.includes(patient.id)" (click)="$event.stopPropagation();"
                    (change)="togglePatientSelection(patient.id, $event.checked)">
                    {{ patient.name }}
                </mat-checkbox>
            </mat-option>
        </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearPatientFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyFilter()">Apply Filter</button>
          </div>
    </div>
</mat-menu>  

<mat-menu #dobMenu="matMenu">
    <div class="menu3">
        <div class="menu-content">
            <mat-form-field appearance="outline">
              <mat-label>Start Date</mat-label>
              <input matInput [matDatepicker]="startDatePicker" [(ngModel)]="filters['dob'].startDate" (click)="$event.stopPropagation();">
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
        
            <mat-form-field>
              <mat-label>End Date</mat-label>
              <input matInput [matDatepicker]="endDatePicker" [(ngModel)]="filters['dob'].endDate">
              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #endDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearDobFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyDobFilter()">Apply Filter</button>
        </div>
    </div>
</mat-menu>

<mat-menu #ippMenu="matMenu">
    <div class="menu1">
        <div class="menu-header">
            <input matInput placeholder="Search Ipp" [(ngModel)]="ippSearchText"
                (input)="filterRecords()" (click)="$event.stopPropagation();" class="search-input" />
        </div>
        <div class="menu-content">
            <mat-option *ngFor="let ipp of filteredIpp" [value]="ipp.id" class="menu-option">
                <mat-checkbox [checked]="selectedIpp.includes(ipp.id)"
                    (click)="$event.stopPropagation();"
                    (change)="toggleIppSelection(ipp.id, $event.checked)">
                    {{ ipp.name }}
                </mat-checkbox>
            </mat-option>
        </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearIppFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyFilter()">Apply Filter</button>
        </div>
    </div>
</mat-menu>

<mat-menu #hospitalMenu="matMenu">
    <div class="menu1">
        <div class="menu-header">
            <input matInput placeholder="Search Hospital Id" [(ngModel)]="hospitalSearchText"
                (input)="filterRecords()" (click)="$event.stopPropagation();" class="search-input" />
        </div>
        <div class="menu-content">
            <mat-option *ngFor="let hospitalId of filteredHospital" [value]="hospitalId.id" class="menu-option">
                <mat-checkbox [checked]="selectedHospital.includes(hospitalId.id)"
                    (click)="$event.stopPropagation();"
                    (change)="toggleHospitalSelection(hospitalId.id, $event.checked)">
                    {{ hospitalId.name }}
                </mat-checkbox>
            </mat-option>
        </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearHospitalFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyFilter()">Apply Filter</button>
        </div>
    </div>
</mat-menu>

<mat-menu #dossierMenu="matMenu">
    <div class="menu1">
        <div class="menu-header">
            <input matInput placeholder="Search Dossier Id" [(ngModel)]="dossierSearchText"
                (input)="filterRecords()" (click)="$event.stopPropagation();" class="search-input" />
        </div>
        <div class="menu-content">
            <mat-option *ngFor="let dossier of filteredDossier" [value]="dossier.id" class="menu-option">
                <mat-checkbox [checked]="selectedDossier.includes(dossier.id)"
                    (click)="$event.stopPropagation();"
                    (change)="toggleDossierSelection(dossier.id, $event.checked)">
                    {{ dossier.name }}
                </mat-checkbox>
            </mat-option>
        </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearDossierFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyFilter()">Apply Filter</button>
        </div>
    </div>
</mat-menu>

<mat-menu #prescriberMenu="matMenu">
    <div class="menu1">
        <div class="menu-header">
            <input matInput placeholder="Search Prescribers" [(ngModel)]="prescriberSearchText"
                (input)="filterRecords()" (click)="$event.stopPropagation();" class="search-input" />
        </div>
        <div class="menu-content">
            <mat-option *ngFor="let prescriber of filteredPrescribers" [value]="prescriber.id" class="menu-option">
                <mat-checkbox [checked]="selectedPrescribers.includes(prescriber.id)"
                    (click)="$event.stopPropagation();"
                    (change)="togglePrescriberSelection(prescriber.id, $event.checked)">
                    {{ prescriber.name }}
                </mat-checkbox>
            </mat-option>
        </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearPrescriberFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyFilter()">Apply Filter</button>
        </div>
    </div>
</mat-menu>

<mat-menu #deptMenu="matMenu">
    <div class="menu1">
        <div class="menu-header">
            <input matInput placeholder="Search Departments" [(ngModel)]="departmentSearchText"
                (input)="filterRecords()" (click)="$event.stopPropagation();" class="search-input" />
        </div>
        <div class="menu-content">
            <mat-option *ngFor="let department of filteredDepartments" [value]="department.id" class="menu-option">
                <mat-checkbox [checked]="selectedDepartments.includes(department.id)"
                    (click)="$event.stopPropagation();"
                    (change)="toggleDepartmentSelection(department.id, $event.checked)">
                    {{ department.name }}
                </mat-checkbox>
            </mat-option>
        </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearDepartmentFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyFilter()">Apply Filter</button>
        </div>
    </div>
</mat-menu>

<mat-menu #statusMenu="matMenu">
    <div class="menu1">
        <div class="menu-content">
            <div *ngFor="let status of statuses" class="menu-option">
                <mat-checkbox 
                    [checked]="selectedStatuses.includes(status.id)"
                    (change)="toggleStatusSelection(status.id, $event.checked)">
                    {{ status.name }}
                </mat-checkbox>
            </div>
        </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearStatusFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyFilter()">Apply Filter</button>
        </div>
    </div>
</mat-menu>

<mat-menu #dateMenu="matMenu" class="slot-date-menu" xPosition="before">
    <div class="menu2">
        <div class="menu-content">
            <div class="date-time-row">
                <!-- Start Date -->
                <mat-form-field appearance="outline">
                    <mat-label>Start Date</mat-label>
                    <input 
                        matInput 
                        [matDatepicker]="startPicker" 
                        [(ngModel)]="filters[activeColumn].startDate" 
                        (click)="$event.stopPropagation();">
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                </mat-form-field>
        
                <!-- Start Time -->
                <mat-form-field>
                    <mat-label>Start Time</mat-label>
                    <input 
                        matInput 
                        type="time" 
                        [(ngModel)]="filters[activeColumn].startTime" 
                        (click)="$event.stopPropagation();">
                </mat-form-field>
            </div>
        
            <div class="date-time-row">
                <!-- End Date -->
                <mat-form-field appearance="outline">
                    <mat-label>End Date</mat-label>
                    <input 
                        matInput 
                        [matDatepicker]="endPicker" 
                        [(ngModel)]="filters[activeColumn].endDate" 
                        (click)="$event.stopPropagation();">
                    <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
                    <mat-datepicker #endPicker></mat-datepicker>
                </mat-form-field>
        
                <!-- End Time -->
                <mat-form-field>
                    <mat-label>End Time</mat-label>
                    <input 
                        matInput 
                        type="time" 
                        [(ngModel)]="filters[activeColumn].endTime" 
                        (click)="$event.stopPropagation();">
                </mat-form-field>
            </div>
        </div>
        <div class="menu-footer">
            <button mat-button class="clear-button" (click)="clearDateFilter()">Clear</button>
            <button mat-button class="apply-button" (click)="applyDateFilter()">Apply Filter</button>
        </div>
    </div>
</mat-menu>
