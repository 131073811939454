<table class="ecl-table reltop" width="100%" *ngIf="refresh" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="patient" class="top0">
        <th mat-header-cell *matHeaderCellDef class="width-17">{{'all-apointment.patient'|translate}}</th>
        <td mat-cell *matCellDef="let element" class="top0"
            [ngClass]="{'cancelled-column':element.IsDeleted, 'modified-column':element.IsModified}">
            <!-- <div class="top-action" *ngIf="!checkDate(element) && !element.IsDeleted">
                <button class="mat-tbl-icon" mat-icon-button (click)="editButtonClicked.emit({'row_data':element})">
                    <span class="icon-ic-edit"></span>
                </button>
            </div> -->
            <span class="title onlyDesktop">{{element.FirstName}} {{element.LastName}}</span>
            <div class="onlyMobile tophead">
                <div class="childcenter">
                    <div class="icon-ic-gender"></div>
                    <div class="namecol">{{element.FirstName}}{{element.LastName}}</div>
                </div>
                <div class="childcenter">
                    <div class="icon-ic-birthday"></div>
                    <div class="bobcol">{{element.DOB}}</div>
                </div>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="requestNo">
        <th mat-header-cell *matHeaderCellDef>{{'all-apointment.requestno'|translate}} </th>
        <td mat-cell *matCellDef="let element"
            [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
            <span class="onlyDesktop title expadding">
                {{element.AppointmentCode}}
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="test">
        <th mat-header-cell *matHeaderCellDef>{{'all-apointment.test'|translate}}</th>
        <td mat-cell *matCellDef="let element" class="flex-td mar-8"
            [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
            <div class="avatar onlyMobile"><span class="titleWrapBox">{{element.TestShortName.charAt(0)}}</span></div>
            <span class="title onlyDesktop" *ngIf="element.TestShortName">{{element.TestShortName}}</span>
            <span class="subText onlyDesktop" *ngIf="element.TestType">{{loader.TranslateLang(element.TestType)}}</span>
            <div class="mobile-td onlyMobile">
                <span class="title titleWrapBoxch" *ngIf="element.TestShortName">{{element.TestShortName}}</span>
                <span class="subText titletype"
                    *ngIf="element.TestType">{{loader.TranslateLang(element.TestType)}}</span>
            </div>
            <!-- <button class="mat-tbl-icon onlyMobile" mat-icon-button
                (click)="editButtonClicked.emit({'row_data':element})">
                <span class="icon-ic-edit"></span>
            </button> -->
        </td>
    </ng-container>
    <ng-container matColumnDef="lab">
        <th mat-header-cell *matHeaderCellDef>{{'all-apointment.lab'|translate}} </th>
        <td mat-cell *matCellDef="let element" class="flex-td gap-25"
            [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
            <div class="onlyDesktop">
                <span class="title"
                    *ngIf="element.SamplingStationName"><span>{{element.SamplingStationName}}</span></span>
                <span class="subText link" *ngIf="element.AddressDetails">{{element.AddressDetails}}</span>
            </div>
            <div class="mobile-td onlyMobile">
                <span class="title" *ngIf="element.SamplingStationName">
                    <span class="icon-ic_navigate onlyMobile link"></span>
                    <span class="link" (click)="openDirections(element)">
                        {{element.SamplingStationName}}hh</span>
                </span>
                <span class="title onlyMobile light" *ngIf="element.AddressDetails">{{element.AddressDetails}}</span>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="labContact">
        <th mat-header-cell *matHeaderCellDef>{{'all-apointment.lab-contact'|translate}} </th>
        <td mat-cell *matCellDef="let element" class="titlemobile"
            [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
            <span class="title" *ngIf="isMobile"><span class="icon-ic-mobile onlyMobile expadding"></span>
                <span class="marginb numcolor">
                    <a href="tel:{{element.PhoneNumber | removeZero}} ">{{element.PhoneNumber |
                        phonenoFormate}}</a>
                </span></span>
            <span class="title" *ngIf="!isMobile"><span class="icon-ic-mobile onlyMobile expadding"></span>
                <span class="marginb expadding">{{element.PhoneNumber}}
                </span>
            </span>
        </td>
    </ng-container>
    <ng-container matColumnDef="dateTime">
        <th mat-header-cell *matHeaderCellDef class="pl-50 width-15">{{'all-apointment.date-time'|translate}} </th>
        <td mat-cell *matCellDef="let element"
            [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
            <div class="appointment-info1">
                <div class="mobile-reqno onlyMobile">{{'all-apointment.requestno'|translate}}
                    {{element.AppointmentCode}} </div>
                <div class="mobile-dateua onlyMobile">{{element.SlotTime | dateFormat}} </div>
            </div>
            <span class="onlyDesktop">
                <div [ngClass]="element.IsSlotAvailable=='true' ? 'slot-display' : 'ml-35'">
                    <button *ngIf=" element.IsSlotAvailable=='true'" class=" mat-tbl-icon"
                        matTooltip="{{'appointments.with-appointment' | translate}}" matTooltipClass="tooltip-style"
                        mat-icon-button>
                        <span class="icon-ic-preappoint"></span>
                    </button>
                    {{element.SlotTime | dateFormat}}
                </div>
            </span>
            <div class="appointment-info padzero"
                *ngIf="element.IsDeleted || element.IsModified || element.isOpen && element.history || element.IsArrived && !element.IsDeleted">
                <div class="isCancelled" *ngIf="element.IsDeleted">{{'appointments.cancelled' | translate}}</div>
                <div class="modicontainer">
                    <div class="isModified" *ngIf="element.IsModified">{{'appointments.modified' | translate}}</div>
                    <div style="position: relative">
                        <div class="history-info" *ngIf="element.IsModified" (click)="getHistory(element)">i</div>
                        <div class="read-container" *ngIf="element.isOpen && element.history"
                            (click)="getHistory(element)">
                            <div class="sb10"
                                [ngClass]="element.IsSlotAvailable=='true' ? 'container-content-slot' : 'container-content' ">
                                <div class="history-label">{{'appointments.app-shifted' | translate}}</div>
                                <div class="slots" *ngFor="let row of element.history">
                                    {{row | dateFormat}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="isArrived arrived" *ngIf="element.IsArrived && !element.IsDeleted">{{'appointments.arrived'
                    |
                    translate}}
                </div>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="force-display"
            [ngClass]="{'cancelled-row':element.IsDeleted, 'modified-row':element.IsModified}">
            <!-- <div class="mobile-action" *ngIf="element.PrescriptionUrl !== '' && !element.IsDeleted"> -->
            <!-- <div *ngFor="let url of element.PrescriptionUrl.split('|'); index as i"
                    style="display: flex; margin-top:15px;"> -->
            <!-- <div style="width:70%; display: flex; height: 45px;"> -->
            <!-- <div style="display: flex; flex-direction: column; line-height: 0px;">
                            <div>{{'view-prescription.prescription'|translate}} - {{i+1}}</div>
                            <hr style="width:100%;">
                            <div style="margin: -15px;"><button mat-button color="accent"
                                    (click)="uploadPresc.emit({'row_data':element, 'url': url})">{{'view-prescription.replace-prescription'|translate}}</button>
                            </div>
                        </div> -->
            <!-- </div> -->
            <!-- <div style="width:30%; display: flex;">
                        <button style="width:50%;" class="mat-tbl-icon"
                            matTooltip="{{'all-apointment.replace_prescription'|translate}}" mat-icon-button
                            (click)="uploadPresc.emit({'row_data':element, 'url': url})">
                            <span class="icon-ic-replace-prescription"></span>
                        </button>
                        <button style="width:50%;" class="mat-tbl-icon"
                            matTooltip="{{'all-apointment.view_prescription'|translate}}" mat-icon-button
                            (click)="viewPdfMobile.emit({'row_data':element, 'url': url})">
                            <span class="icon-ic-view-prescription"></span>
                        </button>

                        <button style="width:50%;" class="mat-tbl-icon"
                            matTooltip="{{'all-apointment.view_prescription'|translate}}" mat-icon-button
                            (click)="deletePdfMobile.emit({'row_data':element, 'url': url})">
                            <span class="icon-ic-delete-rec"></span>
                        </button>
                    </div> -->
            <!-- </div> -->
            <!-- </div> -->
            <!-- <div class="mobile-action" *ngIf="!checkDateForUploadPrescription(element)">
                <span class="uploadBtn text-center" mat-button
                    (click)="loader.redirectRelMem=true; uploadPresc.emit({'row_data':element})">
                    {{'all-apointment.upload-prescription'|translate}}
                </span>
            </div> -->
            <div class="action-btn btndel" *ngIf="!element.IsDeleted">
                <button class="mat-tbl-icon" matTooltip="{{'edit-appointment.edit-appointment'|translate}}"
                    mat-icon-button (click)="editButtonClicked.emit({'row_data':element})"
                    *ngIf="!checkDate(element) && (element.StatusId==0)">
                    <span class="icon-ic-edit"></span>
                </button>
                <!-- <span class="mobile-reqno onlyMobile">{{'all-apointment.requestno'|translate}}
                  {{element.AppointmentCode}} </span> -->
                <button class="mat-tbl-icon" matTooltip="{{'all-apointment.upload-prescription'|translate}}"
                    mat-icon-button *ngIf="!checkDateForUploadPrescription(element)"
                    (click)="uploadPresc.emit({'row_data':element})">
                    <span class="icon-ic-add-prescription"></span>
                </button>
                <button class="mat-tbl-icon only-web" matTooltip="{{'all-apointment.view_prescription'|translate}}"
                    mat-icon-button *ngIf="!(!element.IsPrescriptionRequired || element.PrescriptionUrl=='')"
                    (click)="viewPdf.emit(element)">
                    <span class="icon-ic-view-prescription"></span>
                </button>

                <!-- <button class="mat-tbl-icon only-mobile" matTooltip="{{'all-apointment.view_prescription'|translate}}"
                    mat-icon-button *ngIf="!(!element.IsPrescriptionRequired || element.PrescriptionUrl=='')"
                    (click)="viewPdfMobile.emit({'row_data':element, 'url': element.PrescriptionUrl})">
                    <span class="icon-ic-view-prescription"></span>
                </button> -->

                <button class="mat-tbl-icon" matTooltip="{{'edit-appointment.cancel-appointment'|translate}}"
                    mat-icon-button (click)="cancelButtonClicked.emit({'row_data':element})"
                    *ngIf="!checkDataForDelete(element)">
                    <span class="icon-ic-delete-rec"></span>
                </button>
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="noRecord">
        <td colspan="5" mat-footer-cell *matFooterCellDef>
            <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
            <div class="desc">
                <p> {{'upcoming-apointment.empty'|translate}}</p>
                <p *ngIf="loader.userRole !=4"> {{'upcoming-apointment.search-patient'|translate}}</p>
            </div>
        </td>
    </ng-container>
    <!-- <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element" class="force-display">
        <div class="mobile-action">
          <button mat-button color="accent" class="ghost" (click)="uploadPresc.emit({'row_data':element})"
            type="button">{{'all-apointment.replace_prescription'|translate}}</button>
        </div>
        <div class="action-btn">
          <button class="mat-tbl-icon" matTooltip="{{'edit-appointment.edit-appointment'|translate}}" mat-icon-button (click)="editButtonClicked.emit({'row_data':element})">
            <span class="icon-ic-edit"></span>
          </button>
          <button class="mat-tbl-icon" matTooltip="{{'all-apointment.view_prescription'|translate}}" mat-icon-button (click)="viewPdf.emit(element.PrescriptionUrl)"
            [disabled]="element.PrescriptionUrl == ''">
            <span class="icon-ic-view-prescription"></span>
          </button>
          <button class="mat-tbl-icon" matTooltip="{{'patient-info.upload-prescription'|translate}}" mat-icon-button (click)="uploadPresc.emit({'row_data':element})">
            <span class="icon-ic-replace-prescription"></span>
          </button>
        </div>
      </td>
    </ng-container> -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <ng-template [ngIf]="dataSource && dataSource.length === 0">
        <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
    </ng-template>
</table>
<mat-paginator *ngIf="totalRecords>0" [length]="totalRecords" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 20, 50]"
    showFirstLastButtons="true" (page)="onPageChange($event)">
</mat-paginator>