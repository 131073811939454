<div class="upload-documents-container">
    <div *ngIf="!fromAppointment && isPatient" class="header header-align">
        <app-section-title
            [title]="isPatient ?  ('createAccount.upload-document' | translate) : '' "></app-section-title>
    </div>
    <div class="formContainer" [ngClass]="{'fromApp': fromAppointment}">
        <form [formGroup]="docForm" #formReference>

            <div class="form-layout">
                <div class="upload-header-doc" *ngIf="identityItem.show">
                    {{ 'createAccount.identity-card' | translate}}
                </div>
                <div *ngIf="nationality==1 && minor &&  identityItem.show" class="id-card-selection mb-16">
                    <mat-label class="header only-web">{{isPatient ? ('createAccount.passport-confirm' |
                        translate) : 'createAccount.rm-id-document' |
                        translate}} <span class="text-red">
                            *</span></mat-label>
                    <mat-radio-group [formControl]="minorPassport" required>
                        <mat-radio-button *ngFor="let val of choices" value="{{val.Id}}"
                            (click)="resetBirthField(val.Id)">{{val.KeyName|translate}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>

                <div class="identity-card" *ngIf="minorPassport.value=='1'  &&  identityItem.show">
                    <div class="doc-description">
                        {{'createAccount.identity-comment' | translate}}
                    </div>
                    <app-appointment-prescription [document]="identityItem"
                        (updateDocument)="getUpdateIdentityDocument($event)" [destroyData]=false
                        (deleteDocument)="removeIdentityFileOnly()">
                    </app-appointment-prescription>
                    <div class="next-gap"></div>
                </div>
                <div class="birth-certificate" *ngIf="minorPassport.value=='2' && minor  &&  birthItem.show">
                    <div>
                        <p class="upload-header-doc only-web">
                            {{'createAccount.certificate' | translate}}
                        </p>
                        <p class="upload-header-doc only-mobile">
                            {{'createAccount.certificate' | translate}}
                        </p>
                        <p class="doc-description">{{'createAccount.birth-comment' | translate}}</p>
                    </div>
                    <app-appointment-prescription [document]="birthItem" [destroyData]=false
                        (updateDocument)="getUpdateBirthDocument($event)" (deleteDocument)="removeBirthFile()">
                    </app-appointment-prescription>
                    <div class="next-gap"></div>
                </div>
                <ng-container *ngIf="showInsurance &&  insuranceItem.show">
                    <div class="mb-16">
                        <p class="upload-header-doc only-web">
                            {{'createAccount.insurance' | translate}}
                        </p>
                        <p class="upload-header-doc only-mobile">
                            {{'createAccount.insurance' | translate}}
                        </p>
                        <label
                            style="display: block; margin-bottom: 16px;">{{isPatient?('createAccount.private-insurance'
                            | translate) :
                            ('createAccount.rm-private-insurance' | translate)}}<span class="text-red">
                                *</span></label>
                        <mat-radio-group [formControl]="insurance" required>
                            <mat-radio-button *ngFor="let val of choices" value="{{val.Id}}"
                                (click)="resetField(val.Id)">{{val.KeyName|translate}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="insurance.value=='1'">
                        <div>

                            <p class="doc-description">{{isPatient ? ('createAccount.insurance-comment' | translate) :
                                ('createAccount.rm-insurance-comment' | translate) }}</p>

                        </div>
                        <app-appointment-prescription class="next-gap" [document]="insuranceItem"
                            (updateDocument)="getUpdateInsuranceDocument($event)" [destroyData]=false
                            (deleteDocument)="removeInsuranceFileOnly()"></app-appointment-prescription>
                        <app-datepicker [ngClass]="{'disabled':insuranceItem?.PrescriptionParts?.length==0}"
                            class="browse-input mt-15" id="insuranceExpiry"
                            labelText="{{'createAccount.expiry' | translate}}" [dateInput]="insuranceExpiry"
                            [minDate]="minDate" [isRequiredError]="registerUserClickedinsuranceExpiry"
                            [isRequired]="registerUserClickedinsuranceExpiry" (deleteDocument)="removeInsuranceFile()">
                        </app-datepicker>
                    </div>
                    <div class="next-gap"></div>
                </ng-container>
                <ng-container *ngIf="!showInsurance">
                    <div>
                        <p class="upload-header-doc">
                            {{'createAccount.insurance' | translate}}
                        </p>
                        <p class="doc-description"> {{'relative-members.same-as-mine-part1' | translate}}</p>
                    </div>
                    <div class="next-gap"></div>
                </ng-container>
            </div>
        </form>
        <div class="action-wrapper" [ngClass]="{'mobile-booking':bookAppointment, 'justify-content-end': !showBack}">
            <button mat-button color="accent" class="ghost button_width pull-right " *ngIf="showBack" type="reset"
                (click)="back()" id="clear_btn">
                {{'createAccount.back' | translate}}
            </button>
            <button mat-raised-button color="primary" class="button_width pull-right "
                [ngClass]="{'full-width': !showBack}" type="submit" (click)="SaveDocuments()" id="save_btn">{{
                loader.flow_detail==constants.complete_booking_flow ?
                ('next'|translate): ('createAccount.register' | translate)}} </button>
        </div>
    </div>
</div>