<div class="unavailabilty-container">
    <div>
        <button class="close" mat-dialog-close mat-button id="btn_close_dialog">X</button>
    </div>
    <div class="sub-container">
        <div class="header">
            <app-section-title title="{{title | translate}}"></app-section-title>
        </div>
        <div>
            <ng-container *ngIf="!data?.edit">
                <mat-radio-group [formControl]="FormType" (change)="refreshTest()"
                    [hidden]="FormType.value=='0' && step==2" required>
                    <mat-radio-button value="0">{{'add-unavailability.new-slot'| translate}}
                    </mat-radio-button>
                    <mat-radio-button value="1">{{'add-unavailability.new-unavailability'| translate}}
                    </mat-radio-button>
                </mat-radio-group>

                <ng-container *ngIf="FormType.value=='1'">
                    <app-add-unavailability [tests]="tests" [selectedDateTime]="selectedDateTime"
                        [samplingStation]="samplingStation" [occurences]="occurences"> </app-add-unavailability>
                </ng-container>
            </ng-container>
            <form class="form-container" *ngIf="(FormType.value=='0'  && step==1) " [formGroup]="SlotGroupForm">
                <ng-container *ngIf="step==1">
                    <div class="field-wrapper">
                        <mat-form-field>
                            <mat-label class="label-float">{{'add-slot-group.slot-title'| translate}}</mat-label>
                            <input matInput formControlName="SlotTitle" max="{{titleMaxLength}}"
                                maxlength="{{titleMaxLength}}" name="slot_title" id="slot_title" />
                        </mat-form-field>

                        <div></div>
                    </div>
                    <h4 class="label-float">{{'add-slot-group.slot-duration'| translate}}</h4>
                    <div class="field-wrapper">
                        <div class="date-container">
                            <span class="datepicker-container-stepper w-100">
                                <div class="datepicker-container">
                                    <mat-form-field class="example-full-width mb-0">
                                        <mat-label class="label-float">{{'add-slot-group.date'|
                                            translate}}</mat-label>
                                        <input id="slotTime" matInput [matDatepickerFilter]="myFilter"
                                            (dateInput)="onDateValueChange($event)" [formControl]="StartDate"
                                            [min]="fromMinDate" (dateChange)="onDateValueChange($event)"
                                            [matDatepicker]="picker" [dateInput]="StartDate"
                                            placeholder="{{'add-slot-group.date'| translate}}">
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker [dateAdapter]="adapter"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-error class="color-error" *ngIf="StartDate.value!=undefined">
                                        <span *ngIf="!StartDate.valid && isFuture">{{'stepper-flow.future-date'
                                            | translate}}</span>
                                        <span
                                            *ngIf="!StartDate.valid && isNonWorkingDay">{{'stepper-flow.non-working-day'
                                            | translate}}</span>
                                        <span *ngIf="!StartDate.valid && isPastDate">{{'stepper-flow.past-date'
                                            | translate}}</span>
                                    </mat-error>
                                    <mat-error class="color-error" *ngIf="StartDate.hasError('notExist')">
                                        {{'stepper-flow.select-date' |
                                        translate}}</mat-error>
                                </div>
                            </span>
                            <!-- <app-datepicker id="startDate" labelText="{{'add-slot-group.date'| translate}}"
                                [dateInput]="StartDate" [matDatepickerFilter]="myFilter" isRequired="true"
                                [minDate]="fromMinDate" isRequiredError="true" (selectedDate)="onDateChange()">
                            </app-datepicker> -->
                            <div class="time-field-container">
                                <mat-form-field class="timeInputField" [ngClass]="{'mat-form-field-disabled':AllDay}">
                                    <mat-label>{{'add-slot-group.start-time'| translate}}</mat-label>
                                    <input matInput type="time" [min]="selectedDow?.start_time"
                                        [max]="selectedDow?.end_time" name="start_time" id="start_time"
                                        (blur)="validateStartTimeRange()" formControlName="StartTime"
                                        [readonly]="AllDay" [disabled]="AllDay" required>
                                </mat-form-field>
                                <mat-form-field class="timeInputField" [ngClass]="{'mat-form-field-disabled':AllDay}">
                                    <mat-label>{{'add-slot-group.end-time'| translate}}</mat-label>
                                    <input matInput type="time"
                                        placeholder="{{'add-sampling.time-placeholder' | translate}}" name="end_time"
                                        id="end_time" name="end_time" formControlName="EndTime" [min]="StartTime"
                                        [max]="selectedDow?.end_time" (blur)="validateStartTimeRange()" required
                                        [readonly]="AllDay" [disabled]="AllDay">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <ng-container>
                        <mat-checkbox [checked]="AllDay" (change)="triggerAllDay($event)">{{'add-slot-group.all-day' |
                            translate}}</mat-checkbox>
                    </ng-container>

                    <div class="field-wrapper">
                        <mat-form-field def="occurence"
                            [ngClass]="{'occurence-50':selectedOccurence?.Key=='DoesNotRepeat' , 'occurence':selectedOccurence?.Key!='DoesNotRepeat'}">
                            <mat-label>{{'add-slot-group.occurence-title' | translate}}</mat-label>
                            <mat-select name="occurence" required formControlName="Occurence"
                                [disableOptionCentering]="true" (selectionChange)="onOccurenceChange($event.value)">
                                <mat-option *ngFor="let occurence of occurences"
                                    [value]="occurence.Id">{{occurence.KeyName
                                    | translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field def="Daily" *ngIf="selectedOccurence?.Key=='Daily'">
                            <mat-label>{{'add-slot-group.repeat-every' | translate}}</mat-label>
                            <mat-select name="Daily" required formControlName="RepeatEvery"
                                (selectionChange)="buildDailyMsg();getAvailableTests();"
                                [disableOptionCentering]="true">
                                <mat-option *ngFor="let day of occurenceDays"
                                    [value]="day.id">{{day.value}}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field def="weekly" *ngIf="selectedOccurence?.Key=='Weekly'">
                            <mat-label>{{'add-slot-group.repeat-every' | translate}}</mat-label>
                            <mat-select name="weekly" required formControlName="RepeatEvery"
                                (selectionChange)="getAvailableTests()" [disableOptionCentering]="true">
                                <mat-option *ngFor="let week of occurenceWeeks" [value]="week.value">{{week.name |
                                    translate}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="field-wrapper" *ngIf="selectedOccurence?.Key=='Weekly'">
                        <div class="circular-checkboxes">
                            <div *ngFor="let day of days; let i = index" class="circular-checkbox"
                                [class.selected]="selectedDays[i]" (click)="toggleDay(i)">
                                {{translateService.instant(day).charAt(0) }}
                            </div>
                        </div>
                    </div>

                    <div class="field-wrapper" *ngIf="selectedOccurence?.Key=='Weekly'">
                        <div *ngIf="areAnyDaysSelected()">
                            <p>{{ selectedDayMsg }}</p>
                        </div>
                    </div>
                    <div class="field-wrapper" *ngIf="selectedOccurence?.Key=='Daily'">
                        <p>{{ selectedDayMsg }}</p>
                    </div>

                    <div class="field-wrapper">
                        <mat-form-field class="full-width" def="samplingStationTests">
                            <mat-label class="label-float">{{'add-slot-group.select-tests'| translate}}
                            </mat-label>
                            <mat-select formControlName="TestIds" multiple required>
                                <app-select-check-all [model]="SlotGroupForm.get('TestIds')" [values]="tests || []">
                                </app-select-check-all>
                                <mat-option *ngFor="let test of tests" [value]="test" matTooltip="{{test.short_name}}">
                                    <span>{{test.short_name }} </span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div></div>
                    </div>
                    <div class="actions my-20">
                        <button mat-button color="accent" class="ghost button_width" (click)="clearSlotGroupForm()"
                            type="button" id="btn_clear">
                            {{'admin.clear' | translate}}
                        </button>
                        <button mat-button color="accent" class="ghost button_width" type="submit"
                            (click)="onNextClick()" id="btn_next">
                            {{'admin.next' | translate}}
                        </button>
                        <!-- <button mat-button color="accent" class="ghost button_width" type="submit" (click)="onSaveClick()"
                            id="btn_save">
                            {{'admin.save' | translate}}
                        </button> -->
                    </div>
                </ng-container>

            </form>
            <form #finalForm="ngForm" novalidate>
                <div *ngIf="step==2">
                    <ng-container>
                        <div class="section-info">
                            <div class="sub-section1">
                                <span class="heading">{{'add-slot-group.slot-title'| translate}}</span>
                                <span class="description">{{SlotGroupForm.controls["SlotTitle"].value}}</span>
                            </div>
                            <div class="sub-section2">
                                <span class="heading">{{'add-slot-group.slot-duration'| translate}}</span>
                                <span class="description">
                                    {{ SlotGroupForm.controls["StartDate"].value | dateTimeFormat:
                                    SlotGroupForm.controls["StartTime"].value :SlotGroupForm.controls["EndTime"].value
                                    }}
                                </span>
                            </div>
                            <div class="sub-section3">
                                <span class="heading">{{'add-slot-group.occurence-title'| translate}}</span>
                                <span class="description"
                                    *ngIf="selectedOccurence?.Key!='DoesNotRepeat'">{{selectedDayMsg}}</span>
                                <span class="description"
                                    *ngIf="selectedOccurence?.Key=='DoesNotRepeat'">{{selectedOccurence.KeyName |
                                    translate}}</span>
                            </div>
                        </div>
                        <table class="ecl-table" width="100%" *ngIf="refresh" mat-table [dataSource]="dataSource">
                            <ng-container matColumnDef="name">
                                <th mat-header-cell *matHeaderCellDef style="min-width: 300px;max-width: 300px;">
                                    <!-- {{'documents.document-type' | translate}} -->
                                    {{'add-slot-group.test-title'| translate}}
                                </th>
                                <td mat-cell *matCellDef="let element" class="flex-td"
                                    style="min-width: 300px;max-width: 300px;">

                                    <div class="alert-box">
                                        <span class="title">
                                            {{element.name | translate}}

                                        </span>
                                    </div>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="avg_time">
                                <th mat-header-cell *matHeaderCellDef style="min-width: 120px;max-width:120px">
                                    {{'add-slot-group.avg-time'| translate}}
                                </th>
                                <td mat-cell *matCellDef="let element ;let i=index" class="flex-td gap-25 hide-column"
                                    style="min-width: 120px;max-width:120px">

                                    <mat-form-field def="avg_time">
                                        <mat-select required [(ngModel)]="element.avg_time" name="avg_time{{i}}"
                                            (selectionChange)="updateMaxLimit($event.value,element)">
                                            <div style="height: 150px;max-height: 150px;overflow-y: auto;">
                                                <mat-option value="1">1</mat-option>
                                                <mat-option value="3">3</mat-option>
                                                <mat-option value="5">5</mat-option>
                                                <mat-option value="10">10</mat-option>
                                                <mat-option value="15">15</mat-option>
                                                <mat-option value="20">20</mat-option>
                                                <mat-option value="25">25</mat-option>
                                                <mat-option value="30">30</mat-option>
                                            </div>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="limits" style="min-width: 120px;max-width:120px">
                                <th mat-header-cell *matHeaderCellDef>{{'add-slot-group.limit-bookings-title'|
                                    translate}}
                                </th>
                                <td mat-cell *matCellDef="let element;let i=index" class="flex-td gap-25 hide-column"
                                    style="min-width: 120px;max-width:120px">
                                    <mat-form-field>
                                        <mat-label class="label-float">{{'add-slot-group.max' |
                                            translate}}({{element.max_limits}})</mat-label>
                                        <input matInput id="max_limit{{element.id}}" required #max_limit="ngModel"
                                            [(ngModel)]="element.limits" name="max_limit{{element.id}}"
                                            (blur)="validateInput(element,max_limit)" [max]="3" [maxlength]="3"
                                            (input)="onInput($event)" required />

                                    </mat-form-field>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef=" displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>

                        <div class="actions my-20">
                            <button mat-button color="accent" class="ghost button_width" (click)="goBack()"
                                type="button" id="btn_clear">
                                {{'admin.back' | translate}}
                            </button>

                            <button mat-button color="accent" class="ghost button_width" type="submit"
                                (click)="onSubmit(finalForm)" id="btn_save_slot_group">
                                {{'admin.save' | translate}}
                            </button>
                        </div>

                    </ng-container>
                </div>
            </form>
        </div>