<div class="ecl-table-container">
  <header class="pull-left">
    <h3 class="pull-left">{{'patient-results.results'|translate}}</h3>
  </header>
  <mat-tab-group disableRipple [@.disabled]="true" class="tab-app pull-left" animationDuration="1000ms">
    <mat-tab
      label="{{'patient-results.all'|translate}} ({{allResultsdataSource?.length > 0 ? allResultsdataSource.length : 0}})">
      <app-all-results *ngIf="refresh" [displayedColumns]="displayedColumns" [dataSource]="allResultsdataSource"
        [dispSpan]="false" (showResult)="showResultData($event)">
      </app-all-results>
    </mat-tab>
    <mat-tab
      label="{{'patient-results.waiting-results'|translate}} ({{watitingResultsdataSource?.length > 0 ? watitingResultsdataSource.length : 0}})">
      <app-waiting-results *ngIf="refresh" [displayedColumns]="displayedColumns"
        [dataSource]="watitingResultsdataSource">
      </app-waiting-results>
    </mat-tab>
    <mat-tab
      label="{{'patient-results.results-ready'|translate}} ({{readyResultsdataSource?.length > 0 ? readyResultsdataSource.length : 0}})">
      <app-results-ready *ngIf="refresh" [displayedColumns]="displayedColumns" [dataSource]="readyResultsdataSource"
        (showResult)="showResultData($event)">
      </app-results-ready>
    </mat-tab>
  </mat-tab-group>
  <app-pdf-viewer *ngIf="isPdfViewerOpened" pdftitle="{{'stepper-flow.result'|translate}}" [pdfFileName]="resultId"
    [blobSrc]="pdfBlob" [allPages]="true" (closed)="isPdfViewerOpened = false"></app-pdf-viewer>
</div>