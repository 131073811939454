<div class="create-establishment-container" >
    <div class="header flex-box">
        <div class="pull-left">
            <div class="heading">
                <span class="title">Add Establishment</span>
                <span>|</span>
                <span class="userid">RC001</span>
            </div>
        </div>
        <div class="pull-right">
            <button class="slider_closebtn" mat-button color="accent" (click)="dismiss()">
                <span class="icon-ic-close"></span>
            </button>
        </div>
    </div>

    <form [formGroup]="EstablishForm" #formDirective="ngForm" id="createAccount_form">
        <div class="createAccountwrapper">
            <mat-form-field>
                <mat-label>Establishment name</mat-label>
                <input matInput name="EstablishName" id="EstablishName" [formControl]="establishName" formControlName="establishName"
                    />
                <!-- <mat-error *ngIf="form.get('EstablishName')?.hasError('invalidChars') && establishName.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error> -->
            </mat-form-field>

            <mat-form-field def="Status">
                <mat-label >Status</mat-label>
                <mat-select placeholder="Status" formControlName="status" required="true" name="status">
                    <mat-option [value]="true">Active</mat-option>
                    <mat-option [value]="false">Suspended</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Address</mat-label>
                <input matInput type="text" id="autocompleteinput" name="address"
                    placeholder="Search a locality or a postal code..." autocomplete="off"
                     [formControl]="address" />
                     <!-- (focus)="isAutoCompleteFocused = true" -->
                <!-- <mat-error
                    *ngIf="!form.get('address')?.hasError('invalidChars') && address.invalid">{{'createAccount.valid-address'
                    | translate}}</mat-error> -->
                <mat-error *ngIf="EstablishForm.get('address')?.hasError('invalidChars') && address.invalid">
                    {{'createAccount.invalid-char'| translate}}</mat-error>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Country</mat-label>
                <mat-select name="country" id="country" [formControl]="country">
                    <mat-option *ngFor="let nationality of nationalities" [value]="nationality.id">
                        {{nationality.country_name}}</mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>city</mat-label>
                <input matInput name="City" id="City" [formControl]="city" />
                <!-- <mat-error *ngIf="city.invalid">{{'createAccount.valid-city' | translate}}</mat-error> -->
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>zipcode</mat-label>
                <input matInput name="Zipcode" id="Zipcode" [formControl]="zip" />
                <!-- <mat-error *ngIf="zip.invalid">{{'createAccount.valid-zipcode' | translate}}</mat-error> -->
            </mat-form-field>

            <app-datepicker id="openFrom" labelText="Open From" [dateInput]="openFrom"
                isRequired="true" [minDate]="fromDateMin" (selectedDate)="onSelectedDate($event)">
            </app-datepicker>
            <app-datepicker id="openTo" labelText="Open Until" [dateInput]="openTo" isRequired="true"
                [minDate]="toDateMin">
            </app-datepicker>
        </div>
   
        <div class="actions">
            <div class="inner-btn">
                <button mat-button color="accent" class="ghost button_width mr-20" type="button" (click)="reset()"
                    id="btn_clear">
                    Clear
                </button>
                <button mat-raised-button color="primary" class="button_width" type="submit" id="btn_save" (click)="onSave()"
                    >
                    <!-- (click)="addEstablish()" -->
                    Save
                </button>
            </div>
        </div>
    </form>
</div>