<div class="favourite-container">
  <header>
    <h3>{{'favourite-lab.favourite-laboratories'|translate}}</h3>
    <button mat-button color="accent" class="ghost onlyDesktop" *ngIf="!isMobile"
      (click)="redirectFindALab()">{{'favourite-lab.favourite-lab-des'|translate}}</button>
    <button mat-button color="accent" class="ghost " *ngIf="isMobile"
      (click)="redirectFindALab()">{{'favourite-lab.favourite-lab-mob'|translate}}</button>
  </header>
  <div class="table-container">
    <table *ngIf="refresh" class="ecl-table" width="100%" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="lab">
        <th mat-header-cell *matHeaderCellDef>{{'favourite-lab.lab'|translate}}</th>
        <td mat-cell *matCellDef="let element" class="flex-td lab-td mar-8">
          <div class="avatar"><span class="titleWrapBox">{{element.sampling_station_name.charAt(0)}}</span></div>
          <span class="title onlyDesktop" *ngIf="element.sampling_station_name">{{element.sampling_station_name}}</span>
          <div class="mobile-td onlyMobile">
            <div class="title" *ngIf="element.sampling_station_name">{{element.sampling_station_name}}</div>
            <div class="icon-ic-delete link" (click)="onSelectedFavoriteLab(element)"></div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef>{{'favourite-lab.address'|translate}}</th>
        <td mat-cell *matCellDef="let element" class="address-td">
          <span class="title" *ngIf="element.address_details"><span
              class="icon-ic-location ic-colr onlyMobile"></span><span class="link"
              (click)="openDirections(element)">{{element.address_details}}</span></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="contact">
        <th mat-header-cell *matHeaderCellDef>{{'favourite-lab.contact'|translate}}</th>
        <td mat-cell *matCellDef="let element">
          <span class="title" *ngIf="element.phone_number"><span
              class="icon-ic-mobile ic-colr onlyMobile"></span><span><a
                href="tel:{{element.phone_number | removeZero}}">{{element.phone_number |
                phonenoFormate}}</a></span></span>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="force-display">
          <div class="mobile-action">
            <button mat-button color="accent" class="ghost"
              [routerLink]="['/patient/book-appointment/fav_lab/'+element.external_id]" id="btn_book_appointment"
              type="button">{{'favourite-lab.book'|translate}}</button>
            <!-- <button mat-button color="accent" class="ghost" (click)="onSelectedFavoriteLab(element)" id="btn_remove_fav"
              type="button">{{'dashboard.favourite-remove'|translate}}</button> -->
          </div>
          <div class="action-btn stay">
            <button hidden class="mat-tbl-icon" mat-icon-button id="btn_direction_fav"
              matTooltip="{{'favourite-lab.lab-directions'|translate}}" matTooltipClass="tooltip-style"
              (click)="getDirections(element)">
              <span class="icon-ic-location"></span>
            </button>



            <button class="mat-tbl-icon" mat-icon-button id="btn_fav_book_appointment"
              (click)="loader.favLabMode = true" matTooltip="{{'favourite-lab.book'|translate}}"
              [routerLink]="['/patient/book-appointment/fav_lab/'+element.external_id]">
              <span class="icon-ic-regisgternew newbtn"></span>
            </button>
            <button class="mat-tbl-icon" mat-icon-button
              matTooltip="{{'favourite-lab.remove-from-favourites'|translate}}" matTooltipClass="tooltip-style"
              id="btn_remove1_fav" (click)="onSelectedFavoriteLab(element)">
              <span class="icon-ic-delete delbtn"></span>
            </button>

          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="noRecord">
        <td colspan="5" mat-footer-cell *matFooterCellDef>
          <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
          <div class="desc">
            <p>{{'favourite-lab.empty'|translate}}</p>
          </div>
        </td>
      </ng-container>{{displayedColumns|json}}
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <ng-template [ngIf]="dataSource && dataSource.length === 0">
        <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
      </ng-template>
    </table>
  </div>
</div>