import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ActivationpopupComponent } from '../activation-popup/activation-popup.component';
import { PageTitleService } from './../../core';
import { ConfirmPopupComponent } from './../confirm-popup/confirm-popup.component';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    public displayTime = 2000;

    constructor(
        private readonly dialog: MatDialog,
        private readonly toastr: ToastrService,
        private readonly pageTitle: PageTitleService
    ) { }

    /* #region Public Methods */
    /** This method is being used to show Confirmation Pop up */
    public confirm(
        message: string,
        title: string,
        confirmLable = 'admin.yes',
        cancelLabel = 'admin.no',
        optionalValue?: string,
        optionalValue1?: string,
        email: string="",
    ): Observable<boolean> {
        const dialogRef = this.dialog.open(ConfirmPopupComponent, {
            width: '500px',
            // minHeight: "220px",
            // maxHeight: "320px",
            data: { message, title, confirmLable, cancelLabel, optionalValue, optionalValue1, email },
            autoFocus: false,
            restoreFocus: false,
            disableClose: true
        });
        return dialogRef.afterClosed();
    }
    public activation(
        data: any
    ): Observable<boolean> {
        const dialogRef = this.dialog.open(ActivationpopupComponent, {
            width: '500px',
            // minHeight: "220px",
            // maxHeight: "320px",
            data,
            autoFocus: false,
            disableClose: true
        });
        return dialogRef.afterClosed();
    }
    /** This method is being used to display a Primary toast messsage */
    public primary(message: string, displayTime = this.displayTime): void {
        this.toastr.show(
            message,
            null,
            this.getConfig('ng-toast border-p', displayTime)
        );
    }

    /** This method is being used to display a Warning toast messsage */
    public warning(message: string, displayTime = this.displayTime): void {
        this.toastr.show(
            message,
            null,
            this.getConfig('ng-toast border-w', displayTime)
        );
    }

    /** This method is being used to display a Success toast messsage */
    public success(message: string, displayTime = this.displayTime): void {
        this.toastr.show(
            message,
            null,
            this.getConfig('ng-toast border-s', displayTime)
        );
    }

    /** This method is being used to display a Alert toast messsage */
    public alert(message: string, displayTime = this.displayTime): void {
        this.toastr.show(
            message,
            null,
            this.getConfig('ng-toast border-a', displayTime)
        );
    }

    /** This method is being used to set Page title in Toolbar */
    public setPageTitle(title: string, subTitle = ''): void {
        this.pageTitle.setPageTitle([title, subTitle]);
    }

    /** This method is being used to clear toast messsage */
    public clear(): void {
        this.toastr.clear();
    }
    /* #endregion */

    /* #region Private Methods */
    /** Method to customize / set toast Config */
    private getConfig(className: string, displayTime: number): any {
        return {
            toastClass: className,
            messageClass: 'custom-message',
            timeOut: displayTime
        };
    }
    /* #endregion */
}
