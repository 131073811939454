import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FaqComponent } from '../shared/faq/faq.component';
import { RegisterComponent } from '../shared/register/register.component';
import { ConfirmBookingComponent } from './confirm-booking/confirm-booking.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentsComponent } from './documents/documents.component';
import { FavouriteLabComponent } from './favourite-lab/favourite-lab.component';
import { MyApointmentComponent } from './my-apointment/my-apointment.component';
import { PatientInfoComponent } from './patient-info/patient-info.component';
import { PatientComponent } from './patient.component';
import { QrCodeCompComponent } from './qr-code-comp/qr-code-comp.component';
import { ResultsComponent } from './results/results.component';
import { SetPasswordComponent } from './set-password/set-password.component';
import { DocumentManagementComponent } from './set-password/document-management/document-management.component';
import { RelativeMembersComponent } from './relative-members/relative-members.component';
import { BookingFlowComponent } from '../shared/booking-flow/booking-flow.component';
import { StepperFlowComponent } from '../shared/stepper-flow/stepper-flow.component';
import { CompleteBookingComponent } from '../shared/complete-booking/complete-booking.component';

const routes: Routes = [{
    path: '', component: PatientComponent,
    children: [
        { path: 'dashboard', component: DashboardComponent },
        { path: 'info', component: PatientInfoComponent },
        { path: 'my-appointment', component: MyApointmentComponent },
        { path: 'documents', component: DocumentsComponent },
        { path: 'results', component: ResultsComponent },
        { path: 'qr-code', component: QrCodeCompComponent },

        { path: 'book-appointment/scan/:ss_id', component: BookingFlowComponent },
        { path: 'book-appointment/scan/:ss_id/:te_id', component: BookingFlowComponent },
        { path: 'book-appointment/fav_lab/:fav_lab_id', component: BookingFlowComponent },
        { path: 'book-appointment/:l_id', component: BookingFlowComponent },
        { path: 'book-appointment/massive/:l_id/:t_id', component: BookingFlowComponent },
        { path: 'book-appointment/test/massive/:t_id', component: BookingFlowComponent },
        { path: 'book-appointment/lab/massive/:l_id', component: BookingFlowComponent },
        { path: 'book-appointment/test/:t_id', component: StepperFlowComponent },
        { path: 'book-appointment', component: BookingFlowComponent },
        { path: 'complete-appointment/:u_id/:a_id/:is_active/:doc_id/:is_prescription', component: CompleteBookingComponent },
        { path: 'favourite-lab', component: FavouriteLabComponent },
        { path: 'favourite-lab/add', component: BookingFlowComponent },
        { path: 'confirm-appointment', component: ConfirmBookingComponent },
        { path: 'registration', component: RegisterComponent },
        { path: 'faq', component: FaqComponent },
        { path: 'set_password/:id/:action/:address/:termsflag', component: SetPasswordComponent },
        { path: 'document-management/:id/:action', component: DocumentManagementComponent },
        { path: 'members', component: RelativeMembersComponent },
        { path: 'elevate/:unique_id', component: RegisterComponent },
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PatientRoutingModule { }
