import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettingsService } from '../../core';
import { DocumentInfo } from '../patient/documents/patient-document.model';
import { RMData } from '../patient/relative-members/relative-member-data.model'


@Injectable({
    providedIn: 'root'
})
export class PatientService {
    emailPattern = '^[a-zA-Z0-9àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ]+[a-zA-Z0-9àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ._-]+@(?!wearehackerone\\.com$)([a-zA-Z0-9àâçéèêëîïôûùüÿñæœÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ._-])+\\.+?[a-zA-Z]+$';
    ssnPattern = '[0-9]{6}[0-9AB][0-9]{8}$';
    namePattern = '[a-zàâçéèêëîïôûùüÿñæœA-ZÀÂÇÉÈÊËÎÏÔÛÙÜŸÑÆŒ]{1,50}$'
    fnamePattern = '[a-zA-Z, -]{1,50}$'
    constructor(
        private httpClient: HttpClient,
        private readonly appSettings: AppSettingsService
    ) { }

    getBookedAppointment(c_id: number, s_time: string, e_time: string = null) {
        e_time = !e_time ? s_time : e_time;
        return this.httpClient.get(
            this.appSettings.patientBaseUrl +
            'appointments?calendar_id=' +
            c_id +
            '&start_time=' +
            s_time +
            '&end_time=' +
            e_time
        );
    }
    activatePatient(email: string) {
        return this.httpClient.get(this.appSettings.patientBaseUrl + 'mail/verify?email=' + email);
    }

    getPatients(searchReq: any) {
        return this.httpClient.post(
            this.appSettings.patientBaseUrl + 'search',
            searchReq
        );
    }

    // TODO: Replace any input type with a data model
    putTermsandPolicy(body: any) {
        return this.httpClient.put<any>(
            this.appSettings.patientBaseUrl + 'termsandpolicy',
            body
        );
    }

    updateAddress(body: any) {
        return this.httpClient.patch<any>(
            this.appSettings.patientBaseUrl + 'updatepatient',
            body
        );
    }

    deleteAccount(id: number) {
        return this.httpClient.delete(
            this.appSettings.patientBaseUrl + 'deletepatient?id=' + id
        );
    }

    // TODO: Replace any input type with a data model
    saveAppointment(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(
            this.appSettings.patientBaseUrl + 'appointments',
            body
        );
    }

    // TODO: Replace any input type with a data model
    createPatient(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(
            this.appSettings.patientBaseUrl + 'new',
            body
        );
    }
    validatePaient(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(
            this.appSettings.patientBaseUrl + 'validatePatient',
            body
        );
    }

    // TODO: Replace any input type with a data model
    updateAppointment(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.put<any>(
            this.appSettings.patientBaseUrl + 'appointments',
            body
        );
    }

    // TODO: Replace any input type with a data model
    deleteAppointment(a_id: any, patient_id: any): Observable<any> {
        return this.httpClient.delete<any>(
            this.appSettings.patientBaseUrl +
            'appointments?id=' +
            a_id +
            '&patient_id=' +
            patient_id
        );
    }

    getGender() {
        return this.httpClient.get(
            this.appSettings.adminBaseUrl + 'master?key=gender'
        );
    }

    // TODO: Replace any input type with a data model
    updatePatientDetails(patientDetails: any) {
        return this.httpClient.put(
            this.appSettings.patientBaseUrl + 'updatepatient',
            patientDetails
        );
    }

    getCoutryFlags() {
        return this.httpClient.get(
            this.appSettings.adminBaseUrl + 'common/countries'
        );
    }

    // TODO: Replace any input type with a data model
    updateArrival(data: any) {
        return this.httpClient.put(
            this.appSettings.patientBaseUrl + 'arrival',
            data
        );
    }

    // TODO: Replace any input type with a data model
    findLab(data: any) {
        return this.httpClient.get(
            this.appSettings.adminBaseUrl + 'company/stations?id=' + data
        );
    }

    getPatientDocuments(patientId: string): Observable<any> {
        return this.httpClient.get(this.appSettings.patientBaseUrl + 'documents?patient_id=' + patientId);
    }

    getRelativePatientDocuments(patientId: string): Observable<any> {
        return this.httpClient.get(this.appSettings.patientBaseUrl + 'documents?patient_id=' + patientId + '&relative=relative');
    }

    // TODO: Replace any input type with a data model
    createPatientDocument(postData: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'text/plain; charset=utf-8' });
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.patientBaseUrl + 'document', body, { headers });
    }

    // TODO: Replace any input type with a data model
    postSendMailResetPassword(email: any) {
        return this.httpClient.get(this.appSettings.patientBaseUrl + 'mail/password?email=' + email.email);
    }

    // TODO: Replace any input type with a data model
    updatePatientDocument(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.put<any>(this.appSettings.patientBaseUrl + 'document', body);
    }

    getPatientDocument(documentPath: string) {
        return this.httpClient.get(this.appSettings.patientBaseUrl + 'document?path=' + documentPath);
    }

    deletePatientDocument(documentId: number) {
        return this.httpClient.delete(this.appSettings.patientBaseUrl + 'document?id=' + documentId);
    }

    // TODO: Replace any input type with a data model
    savePatientFavoriteSamplingStations(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.patientBaseUrl + 'createfavorite', body);
    }

    getsavePatientFavoriteSamplingStations(patientId: string) {
        return this.httpClient.get(this.appSettings.patientBaseUrl + 'getFavoriteSamplingStations?patient_id=' + patientId);
    }

    // TODO: Replace any input type with a data model
    getPatientsAntigenPCRAppointment(date: any, p_id: any, s_id: any) {
        return this.httpClient.get(
            this.appSettings.patientBaseUrl +
            'appointment/details?fromAppointment=true&limit=undefined&covid=1&on_date=' + date + '&patient_id=' + p_id + '&ss_id=' + s_id
        );
    }

    getAntigenPatientAppointment(testKitId: string, ssId: string) {
        return this.httpClient.get(this.appSettings.patientBaseUrl + 'kit/appointment?barcode=' + testKitId + '&ss_id=' + ssId);
    }

    // TODO: Replace any input type with a data model
    putPatientAntigenResult(postData: any): Observable<any> {
        const headers = new HttpHeaders({ 'Content-Type': 'text/plain; charset=utf-8' });
        const body = JSON.stringify(postData);
        return this.httpClient.put<any>(this.appSettings.patientBaseUrl + 'kit/result', body, { headers });
    }

    getRequiredDocumentsByPatientId(patientId: string, loginId: number): Observable<DocumentInfo[]> {
        return this.httpClient.get<DocumentInfo[]>(this.appSettings.patientBaseUrl + "document/require?patient_id=" + patientId + "&login_id=" + loginId);
    }

    getRequiredDocumentByPatientId(patientId: string, loginId: number, all: boolean): Observable<DocumentInfo[]> {
        return this.httpClient.get<DocumentInfo[]>(this.appSettings.patientBaseUrl + "document/require?patient_id=" + patientId + "&login_id=" + loginId + "&all=" + all);
    }

    getRequiredDocumentByRelativePatientId(patientId: string, loginId: number, all: boolean): Observable<DocumentInfo[]> {
        return this.httpClient.get<DocumentInfo[]>(this.appSettings.patientBaseUrl + "document/require?patient_id=" + patientId + "&login_id=" + loginId + "&all=" + all + '&relative=relative');
    }

    // TODO: Replace any input type with a data model
    postuploadDocuments(doc: any): Observable<DocumentInfo[]> {
        return this.httpClient.post<DocumentInfo[]>(this.appSettings.patientBaseUrl + "documents", doc)
    }

    getNotification() {
        let patientId = JSON.parse(sessionStorage.getItem('userDetails')).id;
        return this.httpClient.get(
            this.appSettings.patientBaseUrl + "notifications?patient_id=" + patientId
        );
    }

    putNotification(notificationIds: string) {
        let body = {
            ids: notificationIds
        };
        return this.httpClient.put(
            this.appSettings.patientBaseUrl + "notifications", body
        );
    }

    deleteNotification(notificationIds: string) {
        return this.httpClient.delete(
            this.appSettings.patientBaseUrl + "notifications?ids=" + notificationIds
        );
    }


    updateEMail(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(
            this.appSettings.patientBaseUrl + 'updatepatientemail',
            body
        );
    }

    veriryOTPOfNewEmail(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.patch<any>(
            this.appSettings.patientBaseUrl + 'updatepatientemail',
            body
        );
    }

    findAccount(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(
            this.appSettings.patientBaseUrl + 'searchpatient',
            body
        );
    }

    findMember(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(
            this.appSettings.patientBaseUrl + 'rm/search',
            body
        );
    }

    getPatientInfo(id: any) {
        return this.httpClient.get(this.appSettings.patientBaseUrl + 'find?id=' + id);
    }

    getRmData(patientId: string): Observable<RMData[]> {
        return this.httpClient.get<RMData[]>(this.appSettings.patientBaseUrl + "rm/list?patientId=" + patientId);
    }

    deletePatient(id: any): Observable<any> {
        return this.httpClient.delete<any>(
            this.appSettings.patientBaseUrl + "remove?id=" + id
        );
    }
    elevateRM(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.patientBaseUrl + 'satopa', body);
    }

    verifyReqNo(a_code: string, ss_code: string): Observable<any> {
        return this.httpClient.get<any>(this.appSettings.patientBaseUrl + "queueTurn?appointment_code=" + a_code + "&sampling_station_code=" + ss_code);
    }

    validatePrescription(postData: any): Observable<any> {
        const body = JSON.stringify(postData);
        return this.httpClient.post<any>(this.appSettings.patientBaseUrl + 'validateprescription', body);
    }

}
