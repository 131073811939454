import { Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DOC_ORIENTATION, DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import { LocaleService } from '../../../app.locale.service';
import { DrawerComponent } from '../../../core/service-loader/drawer-component';
import { FileData } from '../../../features/styles/models/file-data';
import { LoaderService, MessageService } from '../../../main';
import { PrescriptionDocument, PrescriptionPart } from './appointment-prescription.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { PreviewFileComponent } from '../preview-file/preview-file.component';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { ImageProcessorService } from '../../services/image-processor.service';
import { SpinnerService } from '../../../core';
import { MatDialog } from '@angular/material/dialog';
import { ImageCropperDialogComponent } from '../img-crop/image-cropper-dialog/image-cropper-dialog.component';
import { PatientService } from '../../services/patient.service';


@Component({
    selector: 'app-appointment-prescription',
    templateUrl: './appointment-prescription.component.html',
    styleUrls: ['./appointment-prescription.component.scss']
})
export class AppointmentPrescriptionComponent implements OnInit, OnDestroy, DrawerComponent {
    croppedFile: File | null = null;
    checkSizeValidation: boolean;
    cameraMode: number = 0;
    images: PrescriptionPart[];
    pdfs: PrescriptionPart[];
    public get dialog(): MatDialog {
        return this._dialog;
    }
    public mobileFalse: boolean = true;
    public set dialog(value: MatDialog) {
        this._dialog = value;
    }
    isMobile: boolean = false;
    public maxFile: number = 1;
    @Output() updateDocument = new EventEmitter<any>();
    @Output() deleteDocument = new EventEmitter<boolean>();
    @Output() isImageClicked = new EventEmitter<boolean>();
    @Input() clearFile = false;
    @Input() prescription: boolean = false;
    @Input() document: PrescriptionDocument = new PrescriptionDocument();
    @Input() showSkip = true;
    @Input() sliderFormData: PrescriptionDocument;
    @Input() destroyData: boolean = true;
    isSliderDataEdited?: boolean;
    isValidPrescriptionFile = true;

    public File: FileData = null;
    public isHovering: boolean;
    capturePicDiasabled = true;
    imageChangedEvent: any = '';
    croppedImage: any = '';
    public azureblobid = '';

    public errors: WebcamInitError[] = [];

    public FileName = new FormControl('', []);
    public form = this.fb.group({
        FileName: this.FileName
    });
    protected pdfurl: string;
    protected ctx: CanvasRenderingContext2D;
    prescriptionData: string;
    readonly breakpoint$ = this.breakpointObserver
        .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
        .pipe(
            tap(value => console.log(value)),
            distinctUntilChanged()
        );

    protected previewSlider: any;
    protected previewMode: boolean;
    protected currentImage: WebcamImage;
    constructor(private breakpointObserver: BreakpointObserver,
        private readonly componentFactoryResolver: ComponentFactoryResolver,
        public loader: LoaderService,
        private fb: FormBuilder,
        private translate: TranslateService,
        private localeService: LocaleService,
        private imageCompress: NgxImageCompressService,
        private messageService: MessageService,
        private ips: ImageProcessorService,
        private spinner: SpinnerService,
        private ps: PatientService,
        private _dialog: MatDialog
    ) {
        GlobalWorkerOptions.workerSrc = 'assets/data/pdfworker.js';
        this.breakpoint$.subscribe(() =>
            this.breakpointChanged()
        );
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }
    ngOnDestroy(): void {
        if (this.destroyData) {
            this.document.PrescriptionParts = [];
            this.document.OrgFiles = [];
        }
    }

    ngOnInit(): void {
        if (this.sliderFormData) {
            this.document = this.sliderFormData;
        }

        if (this.document.PrescriptionParts == undefined) {
            this.document.PrescriptionParts = [];
        }

        this.images = this.document.PrescriptionParts.filter(part => part.type !== 'pdf');
        this.pdfs = this.document.PrescriptionParts.filter(part => part.type === 'pdf');
        if (this.images.length > 0) {
            this.maxFile = 2;
        }
        else {
            this.maxFile = 1;
        }
        if (screen.width < 962) { this.isMobile = true; }
    }

    public removeUploadedFile(index: any): void {
        this.document.PrescriptionParts?.slice(index, 1);
        this.document.OrgFiles?.slice(index, 1);
        this.updateDocument.emit(this.document);
        this.deleteDocument.emit(true);
    }

    public removeUploadedFileLocal(i: number) {
        this.document.PrescriptionParts?.splice(i, 1);
        this.document.OrgFiles?.splice(i, 1);
        if (this.document.PrescriptionParts.length == 0) {
            this.cameraMode = 0;
        }
        this.updateDocument.emit(this.document);
        this.deleteDocument.emit(true);
    }

    private breakpointChanged() {
        if (this.breakpointObserver.isMatched('(max-width: 1050px) and (min-width: 601px)')) {
            this.previewSlider = 750;
        } else if (this.breakpointObserver.isMatched('(max-width: 600px)')) {
            this.previewSlider = '100vw';
        } else {
            this.previewSlider = 664;
        }
    }

    public toDataURL(url: any, callback: any) {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            }
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    public isValidPrescription(file: File, checkSizeValidation: boolean = true): Boolean {
        this.ips;
        let isFileValid = true;
        this.isValidPrescriptionFile = true;

        this.document.IsIncorrectFileFormat = false;
        this.document.IsInvalidFileSize = false;

        const prescriptionType = file.type;
        if (checkSizeValidation) {
            if (file.size <= 0 || file.size > 3 * 1024 * 1024) {
                this.form.controls.FileName.setValue('');
                this.document.IsInvalidFileSize = true;
                isFileValid = false;
                this.isValidPrescriptionFile = false;
            }
        }

        let kbInput = 600;
        if (prescriptionType === 'application/pdf' && file.size > kbInput * 1024) {
            this.form.controls.FileName.setValue('');
            this.document.IsInvalidFileSize = true;
            isFileValid = false;
            this.isValidPrescriptionFile = false;
        }
        if (prescriptionType !== 'application/pdf' && prescriptionType !== 'image/png' && prescriptionType !== 'image/jpeg') {
            this.document.IsIncorrectFileFormat = true;
            isFileValid = false;
            this.isValidPrescriptionFile = false;
        }
        if (!file.name.toLowerCase().includes('.png') && !file.name.toLowerCase().includes('.jpg') && !file.name.toLowerCase().includes('.jpeg')
            && !file.name.toLowerCase().includes('.pdf')) {
            this.document.IsIncorrectFileFormat = true;
            isFileValid = false;
            this.isValidPrescriptionFile = false;

        }

        if (isFileValid) { this.updateDocument.emit(this.document); }

        return isFileValid;
    }

    public addOrgData(orgData: string, file: File) {
        if (!this.prescription) {
            if (this.document.OrgFiles == undefined) {
                this.document.OrgFiles = [];
            }
            if (file.type === 'application/pdf') {
                this.document.OrgFiles.push(orgData);
            } else {
                this.document.OrgFiles.push("");
            }
        }
    }

    displayCroppedImage(file: File): void {
        const reader = new FileReader();
        reader.onload = (e: any) => {
            this.croppedImage = e.target.result;
        };
        reader.readAsDataURL(file);
    }

    public handleFilteredFileInput(filelist: FileList, checkSizeValidation: boolean = true, cameraMode: number = 0) {

        const timestamp = filelist[0].lastModified;
        const date = new Date(timestamp);
        this.cameraMode = cameraMode;
        this.checkSizeValidation = checkSizeValidation;
        this.mobileFalse = checkSizeValidation;
        const currentTime = new Date();
        // Calculate the difference in milliseconds
        const timeDifference = Math.abs(currentTime.getTime() - date.getTime());
        // Convert the difference from milliseconds to seconds
        const differenceInSeconds = timeDifference / 1000;
        // Check if the difference is less than 60 seconds (1 minute)
        if (differenceInSeconds < 60 && this.isMobile) {
            this.mobileFalse = false;
        }
        if (date)
            if (this.document.PrescriptionParts?.length >= 2) {
                this.messageService.alert(this.translate.instant('upload-prescription.max-file-count-reached'));
                return;
            }
        if (this.document.PrescriptionParts == undefined) {
            this.document.PrescriptionParts = [];
        }

        this.images = this.document.PrescriptionParts.filter(part => part.type !== 'pdf');
        let ext = filelist[0].name.split('.').pop()?.toLowerCase();

        this.maxFile = (this.document.PrescriptionParts.length === 0 && ext === 'pdf') ? 1 : 2;

        if (this.document.PrescriptionParts.length === 0) {
            this.fileChangeEvent(filelist[0]);
        }
        else if (this.images.length > 0 && ext != "pdf") {
            this.fileChangeEvent(filelist[0]);
        }
        else {
            this.messageService.alert(this.translate.instant('upload-prescription.combination-err'));
        }
    }


    // public inputDataConversion(file: File) {
    //     let base64Filedata;
    //     const reader = new FileReader();
    //     this.spinner.show(true);
    //     reader.onload = () => {
    //         base64Filedata = reader.result.toString();
    //         this.addOrgData(base64Filedata, file);
    //         if (file.type === 'application/pdf') {
    //             if (!this.isValidPrescription(file, this.checkSizeValidation)) {
    //                 this.form.controls.FileName.setValue('');
    //                 this.spinner.show(false);
    //                 if (this.document.PrescriptionParts.length == 0) {
    //                     this.cameraMode = 0;
    //                 }

    //             } else {
    //                 if (this.document.PrescriptionParts == undefined) {
    //                     this.document.PrescriptionParts = [];
    //                 }
    //                 if (this.prescription) {
    //                     var payload = { "prescription": base64Filedata };
    //                     this.ps.validatePrescription(payload).subscribe(
    //                         (response: any) => {
    //                             console.log(response);
    //                             if (response.message == true) {
    //                                 const match = file.name.match(/\.([0-9a-z]+)$/i);
    //                                 let ext = match ? match[1].toLowerCase() : '';
    //                                 this.document.PrescriptionParts.push(
    //                                     {
    //                                         prescriptionPartData: base64Filedata,
    //                                         PrescriptionPartName: file.name,
    //                                         prescriptionPartUrl: base64Filedata,
    //                                         SizeInBytes: file.size,
    //                                         type: ext
    //                                     }
    //                                 );
    //                                 this.spinner.show(false);
    //                                 this.updateDocument.emit(this.document);
    //                             }
    //                             else {
    //                                 this.messageService.alert(this.translate.instant('view-prescription.prescription-filesize-update-error'), 5000);
    //                             }
    //                             this.spinner.show(false);
    //                         },
    //                         (error) => {
    //                             if (error.error.internal_code === 4001 && error.error.code == 400) {
    //                                 this.messageService.alert(this.translate.instant('view-prescription.prescription-filesize-update-error'), 5000);
    //                             } else {
    //                                 this.messageService.alert(this.translate.instant('view-prescription.prescription-update-error'), 5000);
    //                             }
    //                             this.spinner.show(false);
    //                         }
    //                     );
    //                 }
    //                 else {
    //                     const match = file.name.match(/\.([0-9a-z]+)$/i);
    //                     let ext = match ? match[1].toLowerCase() : '';
    //                     this.document.PrescriptionParts.push(
    //                         {
    //                             prescriptionPartData: base64Filedata,
    //                             PrescriptionPartName: file.name,
    //                             prescriptionPartUrl: base64Filedata,
    //                             SizeInBytes: file.size,
    //                             type: ext
    //                         }
    //                     );
    //                     this.spinner.show(false);
    //                     this.updateDocument.emit(this.document);
    //                 }
    //             }
    //         }
    //         else {

    //             if (this.prescription) {
    //                 if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
    //                     this.ips.convertToGrayScale(base64Filedata).then((aa: any) => {
    //                         // const file = fil;
    //                         this.form.controls.FileName.setValue('');
    //                         this.form.controls.FileName.markAllAsTouched();

    //                         if (file) {
    //                             if (!this.isValidPrescription(file, this.checkSizeValidation)) {
    //                                 this.form.controls.FileName.setValue('');
    //                                 this.spinner.show(false);
    //                                 if (this.document.PrescriptionParts.length == 0 && this.mobileFalse == true) {
    //                                     this.cameraMode = 0;
    //                                 }
    //                             } else {
    //                                 setTimeout(() => {
    //                                     reader.readAsDataURL(file);
    //                                     reader.onload = () => {
    //                                         if (this.document.PrescriptionParts == undefined) {
    //                                             this.document.PrescriptionParts = [];
    //                                         }
    //                                         if (this.document.PrescriptionParts.length < 2) {
    //                                             this.compress(aa, file);
    //                                         }
    //                                     };
    //                                     this.form.controls.FileName.markAsDirty();
    //                                 }, 100);
    //                             }
    //                         }
    //                     });
    //                 }
    //                 else {
    //                     this.form.controls.FileName.setValue('');
    //                     this.form.controls.FileName.markAllAsTouched();

    //                     this.spinner.show(false);
    //                     this.isValidPrescriptionFile = false;
    //                     this.document.IsIncorrectFileFormat = true;
    //                     this.document.IsInvalidFileSize = true;
    //                     if (this.document.PrescriptionParts.length == 0 && this.mobileFalse == true) {
    //                         this.cameraMode = 0;
    //                     }
    //                 }
    //             }
    //             else {
    //                 this.form.controls.FileName.setValue('');
    //                 this.form.controls.FileName.markAllAsTouched();
    //                 const reader = new FileReader();
    //                 if (file) {
    //                     if (!this.isValidPrescription(file, this.checkSizeValidation)) {
    //                         this.form.controls.FileName.setValue('');
    //                         this.spinner.show(false);
    //                         if (this.document.PrescriptionParts.length == 0 && this.mobileFalse == true) {
    //                             this.cameraMode = 0;
    //                         }
    //                     } else {
    //                         setTimeout(() => {
    //                             reader.readAsDataURL(file);
    //                             reader.onload = () => {
    //                                 if (this.document.PrescriptionParts == undefined) {
    //                                     this.document.PrescriptionParts = [];
    //                                 }
    //                                 if (this.document.PrescriptionParts.length < 2) {
    //                                     this.compress(reader.result.toString(), file);
    //                                 }
    //                             };
    //                             this.form.controls.FileName.markAsDirty();
    //                         }, 100);
    //                     }
    //                 }
    //             }
    //         }
    //     };
    //     reader.readAsDataURL(file);
    // }

    public inputDataConversion(file: File) {
        const reader = new FileReader();
        this.spinner.show(true);

        reader.onload = () => {
            const base64FileData = reader.result.toString();
            this.addOrgData(base64FileData, file);

            if (file.type === 'application/pdf') {
                this.handlePdfFile(file, base64FileData);
            } else {
                this.handleImageOrOtherFiles(file, base64FileData);
            }
        };

        reader.readAsDataURL(file);
    }

    private handlePdfFile(file: File, base64FileData: string) {
        if (!this.isValidPrescription(file, this.checkSizeValidation)) {
            this.clearFileNameAndStopSpinner();
            this.resetCameraMode();
            return;
        }

        if (this.prescription) {
            this.validatePrescriptionAndUpdateDocument(file, base64FileData);
        } else {
            this.addPrescriptionPart(file, base64FileData);
            this.updateDocument.emit(this.document);
            this.spinner.show(false);
        }
    }

    private handleImageOrOtherFiles(file: File, base64FileData: string) {
        if (this.prescription) {
            if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
                this.convertImageToGrayScale(base64FileData, file);
            } else {
                this.handleIncorrectFileFormat();
            }
        } else {
            this.handleFileWithoutPrescription(file, base64FileData);
        }
    }

    private convertImageToGrayScale(base64FileData: string, file: File) {
        this.ips.convertToGrayScale(base64FileData).then((grayScaledFileData: any) => {
            this.form.controls.FileName.setValue('');
            this.form.controls.FileName.markAllAsTouched();

            if (!this.isValidPrescription(file, this.checkSizeValidation)) {
                this.clearFileNameAndStopSpinner();
                this.resetCameraMode();
            } else {
                setTimeout(() => {
                    this.handleFileAfterGrayScale(grayScaledFileData, file);
                }, 100);
            }
        });
    }

    private handleFileAfterGrayScale(grayScaledFileData: any, file: File) {
        const reader = new FileReader();
        reader.onload = () => {
            if (!this.document.PrescriptionParts) {
                this.document.PrescriptionParts = [];
            }
            if (this.document.PrescriptionParts.length < 2) {
                this.compress(grayScaledFileData, file);
            }
        };
        reader.readAsDataURL(file);
    }

    private handleFileWithoutPrescription(file: File, base64FileData: string) {
        this.form.controls.FileName.setValue('');
        this.form.controls.FileName.markAllAsTouched();

        if (!this.isValidPrescription(file, this.checkSizeValidation)) {
            this.clearFileNameAndStopSpinner();
            this.resetCameraMode();
        } else {
            setTimeout(() => {
                this.handleFileAfterGrayScale(base64FileData, file);
            }, 100);
        }
    }

    private validatePrescriptionAndUpdateDocument(file: File, base64FileData: string) {
        const payload = { "prescription": base64FileData };
        this.ps.validatePrescription(payload).subscribe(
            (response: any) => {
                this.spinner.show(false);
                if (response.message) {
                    this.addPrescriptionPart(file, base64FileData);
                    this.updateDocument.emit(this.document);
                } else {
                    this.resetCameraMode();

                    this.messageService.alert(this.translate.instant('view-prescription.prescription-filesize-update-error'), 5000);
                }
            },
            (error) => {
                this.spinner.show(false);
                this.resetCameraMode();

                const errorMessage = error.error.internal_code === 4001 && error.error.code == 400
                    ? 'view-prescription.prescription-filesize-update-error'
                    : 'view-prescription.prescription-update-error';
                this.messageService.alert(this.translate.instant(errorMessage), 5000);
            }
        );
    }

    private addPrescriptionPart(file: File, base64FileData: string) {
        const ext = this.getFileExtension(file);
        this.document.PrescriptionParts.push({
            prescriptionPartData: base64FileData,
            PrescriptionPartName: file.name,
            prescriptionPartUrl: base64FileData,
            SizeInBytes: file.size,
            type: ext
        });
    }

    private handleIncorrectFileFormat() {
        this.form.controls.FileName.setValue('');
        this.form.controls.FileName.markAllAsTouched();
        this.spinner.show(false);
        this.isValidPrescriptionFile = false;
        this.document.IsIncorrectFileFormat = true;
        this.document.IsInvalidFileSize = true;
        this.resetCameraMode();
    }

    private resetCameraMode() {
        if (this.document.PrescriptionParts.length === 0 && this.mobileFalse) {
            this.cameraMode = 0;
        }
    }

    private clearFileNameAndStopSpinner() {
        this.form.controls.FileName.setValue('');
        this.spinner.show(false);
    }

    private getFileExtension(file: File): string {
        const match = file.name.match(/\.([0-9a-z]+)$/i);
        return match ? match[1].toLowerCase() : '';
    }

    public compress(data: string, file: File, quality: number = 0, imageCompressUptoKb: number = 150) {
        if (quality == 0) {
            quality = 75;
        }
        if (this.document.PrescriptionParts == null) {
            this.document.PrescriptionParts = [];
        }
        let kbInput = this.prescription ? 250 : 300;
        let availableLimit = this.getAvailableLimit(kbInput);
        if (this.document.PrescriptionParts.length == 0) {
            availableLimit = availableLimit * .50;
        }
        else {
            availableLimit = availableLimit * .75;
        }

        imageCompressUptoKb = Math.min(imageCompressUptoKb, availableLimit);
        if (this.document.PrescriptionParts.length < 2) {

            if (!data.includes('application/pdf')) {
                this.imageCompress.compressFile(data, DOC_ORIENTATION.Default, quality, quality).then(
                    (result: DataUrl) => {
                        fetch(result).then(s => s.blob()).then(b => {
                            let sizeValid = this.isSizeValid(b, kbInput);
                            if ((b.size / 1024) > imageCompressUptoKb) {
                                if (this.document.PrescriptionParts.length < 2) {
                                    this.compress(data, file, quality - 10, imageCompressUptoKb);
                                }
                            } else {
                                if (sizeValid) {
                                    const match = file.name.match(/\.([0-9a-z]+)$/i);
                                    let ext = match ? match[1].toLowerCase() : '';
                                    this.document.PrescriptionParts.push(
                                        {
                                            prescriptionPartData: result,
                                            PrescriptionPartName: file.name,
                                            prescriptionPartUrl: result,
                                            SizeInBytes: b.size,
                                            type: ext
                                        }
                                    );
                                    this.spinner.show(false);

                                    this.updateDocument.emit(this.document);
                                } else {
                                    this.spinner.show(false);
                                    this.messageService.alert(this.translate.instant('upload-prescription.max-limit-reached'));
                                }
                            }
                        });
                    },
                    () => {
                        console.error('Compression failed, Retry and check later.');
                    });
            }
        }
    }

    public isSizeValid(file: any, kbInput: number): boolean {
        let returnVal = true;
        let sum = 0;
        if (this.document.PrescriptionParts?.length > 0) {
            sum = this.document.PrescriptionParts.map(a => a.SizeInBytes).reduce((a, b) => a + b);
        }
        if (sum + file.size > kbInput * 1024) {
            returnVal = false;
        }
        return returnVal;
    }

    getAvailableLimit(kbInput: number) {
        let sum = 0;
        if (this.document.PrescriptionParts?.length > 0) {
            sum = this.document.PrescriptionParts.map(a => a.SizeInBytes).reduce((a, b) => a + b);
        }
        if (sum > kbInput * 1024) {
            return (sum) / 1024 - kbInput;
        }
        return kbInput - (sum) / 1024;;
    }

    public dropzoneState(event: any) {
        this.isHovering = event;
    }

    captureImage() {
        if (this.document.PrescriptionParts?.length >= 2) {
            this.messageService.alert(this.translate.instant('upload-prescription.max-file-count-reached'));
            return;
        }
        this.spinner.show(true);
        setTimeout(() => {
            this.spinner.show(false);
        }, 2000);
    }

    preview(url: string) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(PreviewFileComponent);
        this.loader.addDynamicComponent(componentFactory, screen.width > 962 ? "60%" : "100%", { imageData: url });
    }

    fileChangeEvent(event: any): void {
        const prescriptionType = event.type;
        this.imageChangedEvent = event;
        let that = this;
        var reader = new FileReader();
        if (this.mobileFalse == false) {
            reader.readAsDataURL(event);
            reader.onloadend = function () {
                var base64data = reader.result;
                if (prescriptionType != 'application/pdf') {
                    const img = new Image();
                    img.src = base64data as string;

                    img.onload = function () {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
                        canvas.width = img.width;
                        canvas.height = img.height;
                        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                        const compressedBase64 = canvas.toDataURL('image/jpeg', 0.6);
                        that.openCropper(compressedBase64, event.name);
                    };
                }
                if (prescriptionType === 'application/pdf') {
                    that.inputDataConversion(event);
                }
            }
        } else {
            reader.readAsDataURL(event);
            reader.onloadend = function () {
                that.inputDataConversion(event);
            }
        }
    }

    openCropper(base64: any, file_name: string): void {

        this.spinner.show(true);
        const dialogRef = this.dialog.open(ImageCropperDialogComponent, {
            width: '95vw',
            maxWidth: '100vw',
            height: '75vh',
            disableClose: true,
            data: { imageChangedEvent: base64, name: file_name }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result instanceof File) {
                this.croppedFile = result;
                this.inputDataConversion(result)
            }
            else {
                if (this.document.PrescriptionParts.length == 0) {
                    this.cameraMode = 0;
                }
            }
        });
    }
}
