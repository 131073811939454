<mat-sidenav-container id='mat-sidenav-container-root' class="app-sidenav-container"
  (backdropClick)="onBackdropClick();hideScroll(false)" hasBackdrop="true">
  <mat-sidenav #sidena1 position="start" #mobileDrawer class="mat-elevation-z10 side-nav-bg">
    <app-mobile-drawer (close)="sidena1.close(); hideScroll(false)" (ssChanged)="ssChanged()"
      *ngIf="loader.navNodes?.length" [navNodes]="loader.navNodes"></app-mobile-drawer>
  </mat-sidenav>

  <mat-sidenav #sidenav2 (keydown.escape)="onEscape()" [style.width]="formWidth" class="mat-elevation-z10"
    [position]="'end'" [disableClose]="false" [autoFocus]="false" [mode]="formMode">
    <div class="app-sidenav-content">
      <ng-template #dynamic></ng-template>
    </div>
  </mat-sidenav>

  <div class="side-nav-content" [class.addGreybg]="!loader.loggedIn">
    <app-tool-bar (openDrawer)="mobileDrawer.toggle(); hideScroll(true)" [heroBanner]="heroBannerWrapper"
      (ssChanged)="ssChanged()" (toggleGlobalError)="onToggleGlobalErrorPage($event)"
      (onLogout)="getLandingPageConfiguration()">
    </app-tool-bar>
    <app-landing-page [heroBanner]="heroBannerWrapper" [videoUrl]="videoUrl" [keyFeatures]="keyFeaturesWrapper"
      [ourPresence]="ourPresenceWrapper" [companyInfo]="companyInfo" [fbtw]="fbtws" [seoInfo]="seoWrapper"
      [patientJourney]="patientJourneyWrapper" *ngIf="!loader.loggedIn && !loader.isPreLoginFLow"></app-landing-page>

    <div class="ecl-content" *ngIf="loader.loggedIn || loader.isPreLoginFLow"
      [class.isAdmin]="loader.userRole == 5 || loader.userRole == 3 || loader.userRole == 8 ||loader.userRole == 9">
      <ng-container *ngIf="loader?.navNodes?.length > 0">
        <div class="desktop-nav-cover"
          *ngIf="loader.userRole == 5 || loader.userRole == 3  || loader.userRole == 8 ||loader.userRole == 9">
          <div class="desktop-nav">
            <mat-accordion class="example-headers-align">
              <mat-expansion-panel *ngFor="let menu of loader.navNodes;index as index" [expanded]="index===0">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h3 class="color-stepper-blue">{{ menu.TranslationLabel | translate }}</h3>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ul>
                  <ng-container *ngFor="let submenu of menu.Children">
                    <li [class.selected-submenu]="submenu.Id == loader.selectedMenuId" class="color-stepper-blue"
                      [ngClass]="{'onlyMobile': submenu?.forMobile === true}"
                      *ngIf="submenu.isHide != true && submenu?.isLink !=true && ignoreMenuList.indexOf(submenu?.Id.toString())==-1"
                      (click)="onSubmenuClick(submenu, menu, $event)"><span class="{{submenu.Icon}}"></span>{{
                      submenu.TranslationLabel | translate }}</li>
                  </ng-container>
                </ul>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>

        <div class="desktop-nav-cover"
          *ngIf="loader.userRole != 5 && loader.userRole != 3 && loader.userRole != 8 &&loader.userRole != 9">
          <div class="desktop-nav" *ngFor="let menu of loader.navNodes">
            <h3>{{ menu.TranslationLabel | translate }}</h3>
            <ul>
              <ng-container *ngFor="let submenu of menu.Children">
                <li [class.selected-submenu]="submenu.Id == loader.selectedMenuId"
                  [ngClass]="{'onlyMobile': submenu?.forMobile === true}"
                  *ngIf="submenu.isHide != true && submenu?.isLink !=true && ignoreMenuList.indexOf(submenu?.Id.toString())==-1"
                  (click)="onSubmenuClick(submenu, menu, $event)"><span class="{{submenu.Icon}}"></span>{{
                  submenu.TranslationLabel | translate }} </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </ng-container>
      <div class="router-container w-100" cdkScrollable>
        <router-outlet></router-outlet>
      </div>
    </div>
    <footer>
      <div class="support-bg" *ngIf="!loader.loggedIn && !loader.isPreLoginFLow">
        <div class="footer-fullwidth" *ngIf="!loader.loggedIn && !loader.isPreLoginFLow">
          <div class="web-help-support" *ngIf="!loader.loggedIn && !loader.isPreLoginFLow">
            <span>
              {{'footerText.need-help-1' | translate}}
              <a class="cp" (click)="redirectFAQ()" [routerLink]="">{{'footerText.online-help' | translate}}</a>
              {{'footerText.need-help-2' | translate}}
              <a href="mailto:support.eclinical@biologie.eurofinseu.com" target="_blank" class="cp">
                {{'login.support-phone' | translate}}</a>
            </span>
          </div>
        </div>
      </div>
      <div class="footer-fullwidth" *ngIf="!loader.loggedIn && !loader.isPreLoginFLow">
        <div class="footer-container webview" *ngIf="!loader.loggedIn && !loader.isPreLoginFLow">
          <div class="footer-section">
            <a class="brand-logo cp" href="{{'footerText.about-us-link' | translate}}">
              <img src="./../../../../assets/images/Eurofins biologie.png" />
            </a>
            <!-- <a href="{{'footerText.about-us-link' | translate}}">{{'footerText.about-us' | translate}}</a> -->
          </div>
          <div class="footer-section">
            <h3>{{'footerText.quick-links' | translate}}</h3>
            <div class="link-column">
              <div class="link-row">
                <a class="cp" (click)="redirectBooking()" [routerLink]="">{{'footerText.checkin' | translate}}</a>
                <a class="cp" (click)="redirectGuestResults()" [routerLink]="">{{'footerText.download-results' |
                  translate}}</a>
              </div>
              <div class="link-row">
                <a class="cp" (click)="redirectGuestRelative()" [routerLink]="">{{'footerText.family-management' |
                  translate}}</a>
                <a class="cp" (click)="redirectQR()" [routerLink]="">{{'footerText.qr-code' | translate}}</a>
              </div>
            </div>
          </div>
          <div class="footer-section protected-data">
            <h3>{{'footerText.protected-data' | translate}}</h3>
            <p>{{'footerText.data-safety' | translate}}</p>
            <a class="cp" (click)="openFaq()">{{'footerText.read-more' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="cookie-pref webview cp" *ngIf="!loader.loggedIn && !loader.isPreLoginFLow">
        <button id="ot-sdk-btn" class="ot-sdk-show-settings">{{'login.cookie' | translate}}</button>
      </div>
      <!-- <hr class="webview sep"> -->
      <div class="mobile-footer" *ngIf="!loader.loggedIn && !loader.isPreLoginFLow">
        <div class="help-support">
          <span>
            {{'footerText.need-help-1' | translate}}
            <a href="#">{{'footerText.online-help' | translate}}</a>
            {{'footerText.need-help-2' | translate}}
            <a href="mailto:support.eclinical@biologie.eurofinseu.com" target="_blank">{{'login.support-phone' |
              translate}}</a>
          </span>
        </div>
        <div class="policy-section">
          <div class="links">
            <a class="privacypolicy" href="{{'login.privacy-policy-link' | translate}}" target="_blank">
              {{'login.privacy-policy-footer' | translate}}
            </a> <br class="webview">
            <a class="privacypolicy" href="{{'login.terms-of-use-link' | translate}}" target="_blank">
              {{'login.terms-of-use-footer' | translate}}
            </a>
          </div>
          <div class="button-and-copyright">
            <button id="ot-sdk-btn" class="ot-sdk-show-settings">{{'login.cookie' | translate}}</button>
            <span class="copyright">{{'login.copyright' | translate}} {{currentYear}}</span>
          </div>
        </div>
      </div>
    </footer>
    <!-- <div class="hrdiv"></div> -->
    <div class="fbackground webview">
      <div class="footer-fullwidth webview">
        <div class="web-footer">
          <div class="web-footer-content">
            <a class="privacypolicy" href="{{'login.privacy-policy-link'|translate}}" target="_blank">
              {{'login.privacy-policy-footer' | translate}} &nbsp;|
            </a>
            <a class="privacypolicy" href="{{'login.terms-of-use-link' | translate}}" target="_blank">
              &nbsp;{{'login.terms-of-use-footer' | translate}}
            </a>
            <span class="copyright">{{'login.copyright' | translate}} {{currentYear}}</span>
          </div>
          <div class="footer-logo">
            <img src="./../../../../assets/Eurofins logo1.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <button *ngIf="isFormOnHold" (click)="onHoldForm()" color="warn" class="form-on-hold-icon" mat-mini-fab>
    <mat-icon>fast_rewind</mat-icon>
  </button>
  <!-- <footer>
    <a class="privacypolicy" href="{{'login.privacy-policy-link'|translate}}"
      target="_blank">{{'login.privacy-policy-footer' | translate}}
    </a>
    <a class="privacypolicy" href="{{'login.terms-of-use-link' | translate}}" target="_blank">
      &nbsp;{{'login.terms-of-use-footer' |
      translate}}</a>
    <span class="privacypolicy"> &nbsp;{{'login.support' | translate}} <a
        href="mailto:support.eclinical@biologie.eurofinseu.com" target="_blank">{{'login.support-phone' |
        translate}}</a> </span>
    <span class="copyright">{{'login.copyright' | translate}} {{currentYear}}</span>
    <button id="ot-sdk-btn" class="ot-sdk-show-settings">{{'login.cookie' | translate}}</button>
  </footer> -->
</mat-sidenav-container>