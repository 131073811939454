<div id="createAccount_Slider" class="slider-data-container">
    <div class="header">
        <div style="text-align: center;">
            <app-section-title title="{{title | translate}}"></app-section-title>
        </div>
    </div>

    <form class="pt-30" [formGroup]="UserForm" [appAutoScroll]="UserForm">
        <div id="createAccount_form" class="form-layout">
            <mat-form-field *ngIf="data.edit">
                <mat-label class="label-float">{{'add-user.id-user' | translate}}</mat-label>
                <input matInput name="Id" id="Id" [value]="data.user.id" disabled />
            </mat-form-field>
            <!-- <mat-form-field class="invisible">
        
                <input  matInput formControlName="Id"  autocomplete="off" required>
             </mat-form-field> -->
            <div *ngIf="data.edit">
                <button mat-button color="accent" class="ghost button_width" (click)="confirmSendPasswordWithYesNo()"
                    type="submit" id="remove_btn" [disabled]="data.user.is_active == 'false'" required
                    tabindex="{{enableFocus ? -1 : 0}}">
                    {{'add-user.renew-password' | translate}}
                </button>
                <!-- <button mat-button color="accent"  (click)="showQrImage()" class="ghost button_width" type="submit" id="save_btn" required>
                    QR
                </button> -->
            </div>

            <mat-form-field>
                <mat-label class="label-float">{{'add-user.first-name' | translate}}</mat-label>
                <!-- <input *ngIf="data.edit" matInput formControlName="FirstName" id="first_name" [value]="data.user.first_name" required />
                <input *ngIf="!data.edit" matInput formControlName="FirstName" id="first_name" required /> -->
                <input matInput formControlName="FirstName" maxlength="50" autocomplete="off" required>
                <mat-error
                    *ngIf="!UserForm.get('FirstName')?.hasError('invalidChars') && UserForm.get('FirstName')?.invalid">{{'createAccount.valid-first-name'
                    | translate}}</mat-error>
                <mat-error
                    *ngIf="UserForm.get('FirstName')?.hasError('invalidChars') && UserForm.get('FirstName')?.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>

            <div></div>

            <mat-form-field>
                <mat-label class=" label-float">{{'add-user.family-name' | translate}}</mat-label>
                <!-- <input *ngIf="data.edit" matInput formControlName="FamilyName" id="FamilyName" [value]="data.user.last_name" required />
                <input *ngIf="!data.edit" matInput formControlName="FamilyName" id="FamilyName" required /> -->
                <input matInput formControlName="LastName" maxlength="50" autocomplete="off" required>
                <mat-error
                    *ngIf="!UserForm.get('LastName')?.hasError('invalidChars') && UserForm.get('LastName')?.invalid">{{'createAccount.valid-first-name'
                    | translate}}</mat-error>
                <mat-error
                    *ngIf="UserForm.get('LastName')?.hasError('invalidChars') && UserForm.get('LastName')?.invalid">
                    {{'createAccount.invalid-char'| translate}}
                </mat-error>
            </mat-form-field>

            <div></div>

            <mat-form-field>
                <mat-label class="label-float">{{'add-user.email' | translate}}</mat-label>
                <!-- <input *ngIf="data.edit" matInput formControlName="Email" id="email" [value]="data.edit && data.user.email" required />
                <input *ngIf="!data.edit" matInput formControlName="Email" id="email" required /> -->
                <input matInput type="email" maxlength="60" formControlName="Email" autocomplete="off" required>
            </mat-form-field>

            <div></div>

            <mat-form-field def="Role">
                <mat-label class="label-float">{{'add-user.role' | translate}}</mat-label>
                <mat-select formControlName="Role" (selectionChange)="roleChange($event)" required
                    [disableOptionCentering]="true">

                    <mat-option *ngFor="let role of roles" [value]="role.id">{{role.role_name}}</mat-option>

                </mat-select>
            </mat-form-field>

            <div></div>
            <mat-form-field def="Company">
                <mat-label class="label-float">{{'add-user.company' | translate}}</mat-label>
                <mat-select formControlName="Company" required [disableOptionCentering]="true"
                    [disabled]="disableDropDowns" multiple (selectionChange)="ssFilterOnCompany($event.value)">
                    <app-select-check-all [model]="UserForm.get('Company')" [values]="companies"
                        (allChecked)="allChecked($event)">
                    </app-select-check-all>
                    <mat-option *ngFor="let company of companies" [value]="company" matTooltip="{{company.org_name}}">
                        {{company.org_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <div></div>
            <mat-form-field floatLabel="always" class="phone-ctrl">
                <mat-label>{{'createAccount.phone' | translate}}</mat-label>
                <ngx-mat-intl-tel-input [preferredCountries]="['fr']" [enablePlaceholder]="false" name="phone" #phone
                    [formControl]="phoneNumber">
                </ngx-mat-intl-tel-input>
                <mat-error *ngIf="phone?.errors?.required">{{'createAccount.valid-phone' | translate}}</mat-error>
                <mat-error *ngIf="phone?.errors?.validatePhoneNumber">{{'createAccount.valid-phone' | translate}}
                </mat-error>

            </mat-form-field>
            <div></div>

            <!-- <mat-form-field def="samplingStations" *ngIf="!disableDropDowns">
                <mat-label class="label-float">{{'add-user.sampling-station' | translate}}</mat-label>
                <mat-select formControlName="SamplingStationsIds" multiple required [disableOptionCentering]="true"
                    (selectionChange)="onSelectChange($event)" [disabled]="disableDropDowns">
                    <mat-option *ngFor="let samplingStation of samplingStations" [value]="samplingStation.id">
                        {{samplingStation.sampling_station_name}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field def="samplingStations" *ngIf="disableDropDowns">
                <mat-label class="label-float">{{'add-user.sampling-station' | translate}}</mat-label>
                <mat-select formControlName="SamplingStationsIds" multiple [disableOptionCentering]="true"
                    [disabled]="disableDropDowns">
                    <mat-option *ngFor="let samplingStation of samplingStations" [value]="samplingStation.id"
                        (onSelectionChange)="sampChange($event)">{{samplingStation.sampling_station_name}}</mat-option>
                </mat-select>
            </mat-form-field> -->
            <mat-form-field class="full-width" def="samplingStations">
                <mat-label class="label-float">{{'add-user.sampling-station' | translate}}</mat-label>
                <mat-select formControlName="SamplingStationsIds" [disabled]="disableDropDowns" multiple required>
                    <app-select-check-all [model]="UserForm.get('SamplingStationsIds')"
                        [values]="samplingStations || []">
                    </app-select-check-all>
                    <mat-option *ngFor="let samplingStation of samplingStations" [value]="samplingStation"
                        matTooltip="{{samplingStation.sampling_station_name}}  ({{samplingStation.org_name}})">
                        <span
                            [style.font-weight]="samplingStation.is_active ? 'bold' : 'normal'">{{samplingStation.sampling_station_name
                            }} ({{samplingStation.org_name}})</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <app-datepicker id="dateFrom" labelText="{{'add-user.validated-from' | translate}}" [dateInput]="dateFrom"
                isRequired="true" [minDate]="fromDateMin" (selectedDate)="onSelectedDate($event)"></app-datepicker>

            <mat-form-field def="Status">
                <mat-label class="label-float">{{'admin.status' | translate}}</mat-label>
                <mat-select placeholder="{{'admin.status' | translate}}" formControlName="Status" required="true"
                    [disableOptionCentering]="true">
                    <mat-option value="true">{{'admin.active' | translate}}</mat-option>
                    <mat-option value="false">{{'admin.suspended' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>

            <app-datepicker id="dateTo" labelText="{{'add-user.validated-to' | translate}}" [dateInput]="dateTo"
                isRequired="true" [minDate]="toDateMin"></app-datepicker>

        </div>

        <div class="actions">
            <button mat-button color="accent" mat-dialog-close="true" class="ghost button_width" type="submit"
                id="btn_close">
                {{'admin.close' | translate}}
            </button>
            <button mat-button color="accent" (click)="clear()" class="ghost button_width" type="button" id="btn_clear">
                {{'admin.clear' | translate}}
            </button>
            <button mat-button color="accent" class="ghost button_width" type="submit" (click)="onSave()" id="btn_save">
                {{'admin.save' | translate}}
            </button>

            <button mat-button color="accent" (click)="deleteUser()" class="ghost button_width" type="button"
                id="btn_clear" *ngIf="data.edit">
                {{'admin.delete' | translate}}
            </button>
        </div>
    </form>
</div>