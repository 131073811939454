import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GoogleMapService {

    constructor() { }

    public api = this.load();

    private load(): Promise<any> {
        console.log('WoosMapService: load() called');
        return Promise.all([
            this.loadScript('mapSdk', this.getMapScriptSrc()),
            this.loadScript('localitiesWidgetSdk', this.getLocalitiesWidgetScriptSrc())
        ]).then(() => {
            console.log('Both Woosmap SDKs loaded');
            return (window as any).woosmap;
        }).catch((err) => {
            console.error('Error loading Woosmap SDKs:', err);
            throw err;
        });
    }

    private loadScript(id: string, src: string): Promise<void> {
        const head = document.getElementsByTagName('head')[0];

        // Remove existing script if already loaded
        const existingScript = document.getElementById(id);
        if (existingScript) head.removeChild(existingScript);

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = id;
        script.async = true;
        script.defer = true;
        script.src = src;

        console.log(`Appending script with ID: ${id}, Source: ${src}`);

        return new Promise((resolve, reject) => {
            script.onload = () => {
                console.log(`Script loaded: ${id}`);
                resolve();
            };
            script.onerror = (err) => {
                console.error(`Failed to load script: ${id}`, err);
                reject(err);
            };
            head.appendChild(script);
        });
    }

    private getMapScriptSrc(): string {
        const query = {
            key: environment.woosmap_api_val,
        };
        const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
        return `https://sdk.woosmap.com/map/map.js?${params}`;
    }

    private getLocalitiesWidgetScriptSrc(): string {
        const query = {
            key: environment.woosmap_api_val, // Assuming a different key for the localities widget
        };
        const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
        return `https://sdk.woosmap.com/localities/localitieswidget.2.0.js?${params}`;
    }

    /**
     * Get timezone information based on latitude and longitude.
     * @param lat Latitude of the location
     * @param lng Longitude of the location
     * @returns Promise with timezone data
     */
    public getTimezone(lat: number, lng: number): Promise<any> {
        const query = {
            location: `${lat},${lng}`,
            key: environment.woosmap_api_val,
        };
        const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
        const url = `https://api.woosmap.com/geolocation/timezone/?${params}`;
        //https://api.woosmap.com/geolocation/timezone/?key={PUBLIC_API_KEY}&location=43.6114130,3.8735291&timestamp=1708440298
        return fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Failed to fetch timezone data: ${response.statusText}`);
                }
                return response.json();
            })
            .then(data => {
                console.log('Timezone data:', data);
                return data;
            })
            .catch(error => {
                console.error('Error fetching timezone data:', error);
                throw error;
            });
    }
}
