import { Component, ComponentFactoryResolver, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LocaleService } from "../../../app.locale.service";
import { LoaderService, MessageService } from "../../../main";
import { LoginServiceService } from "../../services/login-service.service";
import { PatientService } from "../../services/patient.service";
import { RegisterComponent } from "../../shared/register/register.component";
import { ResetPasswordComponent } from "../reset-password/reset-password.component";
import { PatientDocumentsServiceService } from "../../services/patient-documents-service.service";
import { SpinnerService } from "../../../core";
import { CommonApiService } from "../../services/common-api.service";
import { HttpClient } from "@angular/common/http";
import { UploadDocumentComponent } from "../documents/upload-document/upload-document.component";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { distinctUntilChanged, tap } from "rxjs/operators";
import * as moment from "moment";
import { constants } from "../../shared/constant";
import { EditEmailComponent } from "../../shared/edit-email/edit-email.component";

@Component({
  selector: "app-patient-info",
  templateUrl: "./patient-info.component.html",
  styleUrls: ["./patient-info.component.scss"],
})
export class PatientInfoComponent implements OnInit {
  protected documentTypes: any;
  private uploadedDocuments: any;
  refresh = false;
  reqDoc: any;
  uploadedDoc: any;
  identityUploaded: boolean;
  isDocLoaded: boolean = false;
  birthUploaded: boolean;
  insuranceuploaded: boolean;
  resetPwdSliderWidth: 40;
  name: string;
  first_name: string;
  last_name: string;
  birth_name: string;
  ssn: string;
  dob: string;
  age: string;
  mail: string;
  address: string;
  phone: string;
  avatar: string;
  gender: any;
  act_age: any;
  Gender: any;
  details: any;
  editPatientDetail: any;
  protected privateInsurance: any;
  pdfBlob: string;
  isPdfViewerOpened: boolean;
  uploadSliderContainer: any;
  optionalMutualCertificateIdentifier = "Mutual certificate(";
  protected date = new Date();
  protected expiry: any = null;
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(
      tap((value) => console.log(value)),
      distinctUntilChanged()
    );
  characters = 30;
  constructor(
    private breakpointObserver: BreakpointObserver,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    public loader: LoaderService,
    private httpClient: HttpClient,
    private readonly messageService: MessageService,
    private readonly patientService: PatientService,
    private translate: TranslateService,
    private localeService: LocaleService,
    public loginService: LoginServiceService,
    protected patientDocumentsService: PatientDocumentsServiceService,
    private commonApiService: CommonApiService,
    private spinner: SpinnerService
  ) {
    this.breakpoint$.subscribe(() => this.breakpointChanged());
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);
  }
  ngOnInit(): void {
    this.spinner.show(true);
    this.commonApiService.getDocumentTypeList().subscribe((res: any) => {
      this.documentTypes = res;
      this.getPatientDocuments();

    });
    // this.patientDocumentsService.loadDocumentTypes();

    this.details = JSON.parse(sessionStorage.getItem("userDetails"));
    this.loader.onFormClose().subscribe((data: any) => {
      if (data) {
        this.commonApiService.getDocumentTypeList().subscribe((res: any) => {
          this.documentTypes = res;
          this.getPatientDetails();
        });
      }
    });
    if (this.details.gender == constants.male) {
      this.gender = "Male";
    } else if (this.details.gender == constants.female) {
      this.gender = "Female";
    } else if (this.details.gender == constants.other) {
      this.gender = "Other";
    }
    this.editPatientDetail = {
      FirstName: this.details.first_name,
      LastName: this.details.last_name,
      BirthName: this.details.birth_name,
      Email: this.details.email,
      gender_id: this.details.gender,
      Gender: this.details.gender,
      CountryId: this.details.country_id,
      country: this.details.country_id,
      Ssn: this.details.ssn,
      PhoneNumber: this.details.phone_number,
      Address: this.details.address,
      City: this.details.city,
      Zip: this.details.zip,
      Dob: this.details.dob,
      Nationality: this.details.nationality,
      qr_id: this.details.qr_id,
      state: this.details.state,
      Id: this.details.id,
      private_insurance: this.details.private_insurance,
    };
    this.fillValues();
  }

  private getPatientDocuments() {
    this.patientService
      .getPatientDocuments(JSON.parse(sessionStorage.getItem("userDetails")).id)
      .subscribe((res) => {
        this.uploadedDocuments = res;
        this.uploadedDocuments?.forEach((doc: any) => {
          if (
            doc.expiry_date != "" &&
            (this.expiry == null || this.expiry > doc.expiry_date)
          ) {
            this.expiry = doc.expiry_date;
          }
        });
        this.getPatientDetails();
        this.expiry =
          moment(this.date).format("YYYY-MM-DD") >=
          moment(this.expiry).format("YYYY-MM-DD");
        this.spinner.show(false);
      });
  }

  getPatientDetails() {
    this.loginService.getPatientDetails().subscribe((resp) => {
      this.details = resp;
      sessionStorage.setItem("userDetails", JSON.stringify(resp));
      this.filterDocuments();
      this.fillValues();
    });
  }
  protected checkUploadedDocuments(): boolean {
    let check;
    let keepGoing = true;
    if (!this.uploadedDocuments) {
      check = false;
    } else {
      this.documentTypes?.forEach((doc: any) => {
        if (keepGoing) {
          check = this.isDocumentUploaded(doc) && !this.checkExpiry(doc);
          keepGoing = check;
        }
      });
    }
    return check;
  }
  protected checkExpiry(input: any) {
    let currTime = moment(this.date);
    let expTime = moment(this.getDocumentValidity(input));
    let val =
      moment(currTime).format("YYYY-MM-DD") >=
      moment(expTime).format("YYYY-MM-DD");
    return val;
  }
  protected getDocumentValidity(document: any): string {
    return this.uploadedDocuments?.filter(
      (doc: any) => doc.document_type_id === document.Id
    )[0]?.expiry_date;
  }
  protected isDocumentUploaded(document: any): boolean {
    if (!this.uploadedDocuments) {
      return false;
    } else {
      return (
        this.uploadedDocuments?.filter(
          (doc: any) => doc.document_type_id === document.Id
        ).length !== 0
      );
    }
  }
  private breakpointChanged() {
    if (
      this.breakpointObserver.isMatched(
        "(max-width: 1050px) and (min-width: 601px)"
      )
    ) {
      this.uploadSliderContainer = 640;
    } else if (this.breakpointObserver.isMatched("(max-width: 600px)")) {
      this.uploadSliderContainer = "100%";
    } else {
      this.uploadSliderContainer = 664;
    }
  }

  documentViewAll(data: any) {
    this.loader.highlightLeftMenu(["patient/documents"]);
    data.Action = 1;
    data.patient_id = this.details.id;
    for (let key of this.documentTypes) {
      key["id"] = key["Id"];
      key["document_type"] = key["KeyName"];
      key["expiry"] = key["Expiry"];
    }
    data.avilableDocumentTypes = this.documentTypes;
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        UploadDocumentComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      this.uploadSliderContainer,
      data
    );
  }

  private filterDocuments() {
    this.documentTypes = this.documentTypes.filter(
      (d: any) => d.Key !== "CovidPassport" && d.Key !== "Prescription"
    );
    if (
      this.details.private_insurance === "No" ||
      !this.details.private_insurance
    ) {
      this.documentTypes = this.documentTypes.filter(
        (d: any) => d.Key !== "Insurance"
      );
    } else {
      if (!this.uploadedDocuments?.some((ud: any) => ud.document_type_en.indexOf(this.optionalMutualCertificateIdentifier) >= 0)) {
        this.documentTypes = this.documentTypes.filter
          ((d: any) => d.EngKeyName.indexOf(this.optionalMutualCertificateIdentifier) < 0)
      }
    }
    if (
      this.loader.calculateAge(this.details.dob) > 18 ||
      this.details.minor_passport ||
      this.details.nationality !== constants.nationality
    ) {
      this.documentTypes = this.documentTypes.filter(
        (d: any) => d.Key !== "BirthCertificate"
      );
    }
    this.documentTypes.sort((a: any, b: any) => a.KeyOrder - b.KeyOrder);
    this.isDocLoaded = true;
  }

  public fillValues() {
    this.name = this.details.first_name + " " + this.details.last_name + " " + this.details.birth_name;
    //  this.Fname=this.details.first_name;
    this.first_name = this.details.first_name;
    this.last_name = this.details.last_name;
    this.birth_name = this.details.birth_name;
    this.avatar = this.name.slice(0, 1);
    this.ssn = this.details.ssn;
    this.privateInsurance = this.details.private_insurance ? "Yes" : "No";
    this.dob = this.details.dob;
    const age = new Date(this.details.dob);
    const date = new Date();
    this.act_age = date.getFullYear() - age.getFullYear();
    if (date.getMonth() < age.getMonth()) {
      this.act_age--;
    } else if (
      date.getMonth() == age.getMonth() &&
      date.getDate() < age.getDate()
    ) {
      this.act_age--;
    }
    this.dob = this.dob.slice(0, 10);
    if (this.details.gender == constants.male) {
      this.Gender = "Male";
    } else if (this.details.gender == constants.female) {
      this.Gender = "Female";
    } else if (this.details.gender == constants.other) {
      this.Gender = "Other";
    }
    this.mail = this.details.email;
    this.phone = this.details.phone_number;
    this.address = this.details.address;
    this.editPatientDetail = {
      FirstName: this.details.first_name,
      LastName: this.details.last_name,
      BirthName: this.details.birth_name,
      Email: this.details.email,
      gender_id: this.details.gender,
      Gender: this.details.gender,
      CountryId: this.details.country_id,
      country: this.details.country_id,
      Ssn: this.details.ssn,
      PhoneNumber: this.details.phone_number,
      Address: this.details.address,
      City: this.details.city,
      Zip: this.details.zip,
      Dob: this.details.dob,
      Nationality: this.details.nationality,
      qr_id: this.details.qr_id,
      state: this.details.state,
      Id: this.details.id,
      BirthCountry: this.details.birth_country,
      BirthCity: this.details.birth_city,
      private_insurance: this.details.private_insurance,
      MinorPassport: this.details.minor_passport,
      IsActive: this.details.is_active
    };
  }

  public deleteAccount(): void {
    this.messageService
      .confirm(
        this.translate.instant("patient-information.delete-confirmation"),
        this.translate.instant("patient-information.confirmation")
      )
      .subscribe((actionResult: boolean) => {
        if (actionResult) {
          const id = JSON.parse(sessionStorage.getItem("userDetails"))["id"];
          this.patientService.deletePatient(id).subscribe(
            (deletedAccount) => {
              if (deletedAccount != null) {
                this.loader.Logout();
              }
            },
            (error) => {
              console.log(error);
              if (error.error.internal_code === 3003) {
                this.messageService.alert(
                  this.translate.instant("patient-information.error.3003")
                );
              }
              else {
                this.messageService.alert(
                  this.translate.instant("patient-information.error.422")
                );
              }
            }
          );
        }
      });
  }
  resetPasswordSlider() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        ResetPasswordComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "30%" : "100%",
      []
    );
  }
  editPatientIfo() {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(RegisterComponent);
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "60%" : "100%",
      { patientInfo: this.editPatientDetail, isEdit: true }
    );
  }
  protected downloadPres(path: any) {
    const doc = this.uploadedDocuments?.filter(
      (doc: any) => doc.document_type_id === path.Id
    )[0];
    path = doc.document_path;
    const docName = path.substring(path.lastIndexOf("/") + 1);
    this.spinner.show(true);
    this.patientService.getPatientDocument(docName).subscribe((res: any) => {
      const byteArray = new Uint8Array(
        atob(res)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/octet-stream" });
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = URL.createObjectURL(file);
      if (doc["document_name"].lastIndexOf(".") > 0) {
        a.download =
          doc["document_name"].substring(
            0,
            doc["document_name"].lastIndexOf(".")
          ) + ".pdf";
      } else if (doc["document_name"] != "") {
        a.download = doc["document_name"] + ".pdf";

      } else {
        a.download = "Document.pdf";
      }
      a.click();
      window.URL.revokeObjectURL(URL.createObjectURL(file));
      a.remove();
      this.spinner.show(false);
    });
  }

  openPdf() {
    this.isPdfViewerOpened = true;
    const url = "../assets/data/pdfdummy.pdf";
    this.httpClient
      .get(url, { responseType: "blob" as "json" })
      .subscribe((res: any) => {
        const file = new Blob([res], { type: "application/octet-stream" });
        this.pdfBlob = URL.createObjectURL(file);
      });
  }

  protected openImage(path: any) {
    path = this.uploadedDocuments?.filter(
      (doc: any) => doc.document_type_id === path.Id
    )[0].document_path;
    const docName = path.substring(path.lastIndexOf("/") + 1);
    this.spinner.show(true);
    this.patientService.getPatientDocument(docName).subscribe((res: any) => {
      const byteArray = new Uint8Array(
        atob(res)
          .split("")
          .map((char) => char.charCodeAt(0))
      );
      const file = new Blob([byteArray], { type: "application/octet-stream" });
      this.pdfBlob = URL.createObjectURL(file);
      this.spinner.show(false);
      this.isPdfViewerOpened = true;
    });
  }

  editEmail() {
    console.log("Edit email")

    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        EditEmailComponent
      );
    this.loader.addDynamicComponent(
      componentFactory,
      screen.width > 962 ? "30%" : "100%",
      []
    );
  }
}
