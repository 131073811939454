import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
    Component,
    Inject,
    Input,
    OnInit, AfterViewInit,
    Output,
    EventEmitter,
    ViewChild
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    ValidationErrors,
    Validators
} from '@angular/forms';
import {
    MAT_DIALOG_DATA,
    MatDialogRef,
    MatDialog
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { LocaleService } from '../../../app.locale.service';
import { AppSettingsService, DrawerComponent } from '../../../core';
import { SpinnerService } from '../../../core/spinner/spinner.service';
import { LoaderService, MessageService } from '../../../main';
import { CommonApiService } from '../../services/common-api.service';
import { LoginServiceService } from '../../services/login-service.service';
import { PatientService } from '../../services/patient.service';
import { ResetPasswordServiceService } from '../../services/reset-password-service.service';
import { GoogleMapService } from '../../shared/lab-google/google-map.service';
import { QrCodeDialogComponent } from './qr-code-dialog/qr-code-dialog.component';
import { constants } from '../../shared/constant';
declare var woosmap: any;
@Component({
    selector: 'app-add-patient',
    templateUrl: './add-patient.component.html',
    styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit, DrawerComponent, AfterViewInit {
    @Input() sliderFormData: any;
    @Input() public isSliderDataEdited = false;
    @Output() patientList = new EventEmitter<any>();
    dateFrom = new FormControl();
    dateTo = new FormControl();
    title: string;
    samplingNames: any[] = [];

    saveData: {};
    loginId: any;
    dataFieldsFilled: boolean;
    nationalities: any;
    selectedFlag = 'fr';
    sendPasswordconfirmtext = 'add-user.generate-password';
    saveAlert = {
        edit: 'admin.save-alert.edit',
        add: 'admin.save-alert.add'
    };
    errorMessage = 'admin.error-message';
    states: any;
    place: any;
    fromDateMin: Date;
    toDateMin: Date;
    selectedDate: Date;
    disableDropDowns: boolean;
    enableFocus = true;
    genders: any[];
    selectedPhoneCountryCode: string;
    maxDate: Date;
    isSSNRequired = true;
    showHaveSSN = false;
    protected haveSSN = new FormControl('0');
    public birthName = new FormControl('', [
        Validators.required,
        Validators.pattern('.{1,50}$'), this.loader.validateAllowedCharacters(),
    ]);
    public firstName = new FormControl('', [
        Validators.required,
        Validators.pattern('.{1,50}$'), this.loader.validateAllowedCharacters(),
    ]);
    public lastName = new FormControl('', [
        Validators.required,
        Validators.pattern('.{1,50}$'), this.loader.validateAllowedCharacters()
    ]);
    public phoneNumber = new FormControl('', [Validators.required]);
    public email = new FormControl('', [
        Validators.required,
        Validators.pattern('.{1,60}$'),
        Validators.pattern(this.patientService.emailPattern)
    ]);
    public dateOfBirth = new FormControl('', [Validators.required]);
    public address = new FormControl('');
    public city = new FormControl('', [Validators.pattern('^.{1,50}$')]);
    public zip = new FormControl('', [Validators.pattern('^[0-9 ]{4,10}$')]);
    public password = new FormControl('', [
        Validators.pattern(constants.passwordValidation)
    ]);
    public repeatPassword = new FormControl('');
    public ssn = new FormControl('', [
        Validators.pattern('^.{1,15}$'),
        Validators.pattern(this.patientService.ssnPattern)
    ]);
    public gender = new FormControl('', [Validators.required]);
    public nationality = new FormControl(1, [Validators.required]);
    public country = new FormControl('');
    public status = new FormControl('');
    public Id = new FormControl('');
    public sameLastName = new FormControl("0");
    isBirthCityRequired = true;
    selectedBirthCountryName = '';
    public filteredBirthCities: Observable<any[]>;
    birthCities: any[];
    selectedBirthCountryFlag = 'fr';
    public birthCity = new FormControl({ value: '', disabled: true });
    public birthCountry = new FormControl(1, [Validators.required]);

    public PatientForm = this.formBuilder.group({
        birthName: this.birthName,
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        dateOfBirth: this.dateOfBirth,
        address: this.address,
        city: this.city,
        zip: this.zip,
        ssn: this.ssn,
        gender: this.gender,
        nationality: this.nationality,
        country: this.country,
        status: this.status,
        Id: this.Id,
        birthCountry: this.birthCountry,
        birthCity: this.birthCity
    });
    countryList: any;
    editMode: boolean;

    constructor(
        public readonly dialogRef: MatDialogRef<AddPatientComponent>,
        public patientService: PatientService,
        private googleMapService: GoogleMapService,
        private readonly messageService: MessageService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder,
        private readonly spinner: SpinnerService,
        private translate: TranslateService,
        private localeService: LocaleService,
        public loader: LoaderService,
        public loginServiceService: LoginServiceService,
        private resetPasswordService: ResetPasswordServiceService,
        private readonly dialog: MatDialog,
        private adminService: CommonApiService,
        private readonly appSettings: AppSettingsService
    ) {
        this.maxDate = new Date();

        this.title = this.data.edit
            ? 'add-user.edit-patient'
            : 'add-user.add-patient';
        if (this.data.edit) {
            this.loginId = this.data.patient.login_id;
        }
        this.translate.setDefaultLang(this.localeService.localeLang);
        this.translate.use(this.localeService.localeLang);
    }

    @ViewChild('phone')
    phone: NgxMatIntlTelInputComponent;

    ngOnInit(): void {
        this.getBirthCities();
        if (this.data.edit) {
            this.editMode = true;
            this.email.disable();
            if (this.data.patient.email == '') {
                this.phoneNumber.disable();
            }
            const editPatientDetail = this.data.patient;
            this.PatientForm.controls['address'].setValidators([Validators.required]);
            this.PatientForm.controls['city'].setValidators([Validators.required]);
            this.PatientForm.controls['zip'].setValidators([Validators.required]);
            this.PatientForm.controls['country'].setValidators([Validators.required]);
            this.PatientForm.controls.nationality.setValue(
                editPatientDetail.nationality
            );
            this.dateOfBirth.setValue(editPatientDetail.dob.substring(0, 10));
            this.patientService.getCoutryFlags().subscribe((data: any) => {
                this.countryList = data;
            });
            this.loginServiceService.getAdminCommonCountries().subscribe((data) => {
                this.nationalities = data;
                const frNationIndex = this.nationalities.findIndex((i: any) => i.id == 1);
                const frNation = this.nationalities[frNationIndex];
                this.nationalities.splice(frNationIndex, 1);
                this.nationalities.sort((i: any) => i.country_name);
                this.nationalities.unshift(frNation);

                this.onSelectNationality(editPatientDetail.nationality);
                this.onSelectBirthCountry(editPatientDetail.birth_country);
            });
            if (editPatientDetail.ssn) {
                this.showHaveSSN = false;
                this.haveSSN.setValue('0');
                this.isSSNRequired = true;
            } else {
                this.showHaveSSN = true;
                this.haveSSN.setValue('1');
                this.isSSNRequired = false;
            }
            this.onHaveSSNChange();
            this.PatientForm.controls.status.setValue(editPatientDetail.is_active);
            editPatientDetail.last_name == editPatientDetail.birth_name ? this.sameLastName.setValue("0") : this.sameLastName.setValue("1");
            this.PatientForm.patchValue({
                firstName: editPatientDetail.first_name,
                lastName: editPatientDetail.last_name,
                birthName: editPatientDetail.birth_name,
                email: editPatientDetail.email,
                gender: editPatientDetail.gender_id,
                nationality: editPatientDetail.nationality,
                ssn: editPatientDetail.ssn,
                phoneNumber: '+' + editPatientDetail.phone_number,
                address: editPatientDetail.address,
                country: editPatientDetail.country_id,
                city: editPatientDetail.city,
                zip: editPatientDetail.zip,
                status: editPatientDetail.is_active,
                Id: editPatientDetail.id,
                birthCountry: editPatientDetail.birth_country
            });

            this.getGenders();
            this.getCountries();
        } else {
            this.getGenders();
            this.getCountries();
            this.haveSSN.setValue('0');
            this.onHaveSSNChange();
        }
        this.addressAutocomplete();
    }

    ngAfterViewInit(): void {
        if (typeof woosmap !== 'undefined') {
            //this.initializeMap();
            this.addressAutocomplete();
        } else {
            console.error('Woosmap SDK is not loaded!');
        }
    }

    public addressAutocomplete() {
        this.googleMapService.api.then(() => {
            const options = {
                "components": {
                    country: this.appSettings?.country?.split(";")
                },
                "types": ['locality', 'postal_code', 'address', 'admin_level', 'country', 'train_station', 'metro_station', 'shopping', 'airport', 'museum', 'zoo', 'amusement_park', 'art_gallery', 'tourist_attraction']
            };
            //@ts-ignore
            const localitiesWidget = new window.woosmap.localities.Autocomplete("autocomplete-input", options);
            localitiesWidget.addListener("selected_suggestion", () => {
                this.spinner.show(true);
                const selectedLocality = localitiesWidget.getSelectedSuggestionDetails();
                if (selectedLocality.address_components) {
                    this.PatientForm.controls.address.setValue(selectedLocality.formatted_address);
                    selectedLocality.address_components.filter((item: any) => {
                        if (item.types[0] == "postal_code") {
                            this.PatientForm.controls.zip.setValue(item.long_name[0]);
                        }
                        if (item.types[0] == "country") {
                            this.nationalities.forEach((n: any, index: number) => {
                                if (n.country_name == item.long_name) {
                                    this.spinner.show(false);
                                    this.PatientForm.controls.country.setValue(n.id);
                                    document.getElementById("country").focus();
                                } else {
                                    this.spinner.show(false);
                                }
                                if (index == this.nationalities.length - 1) {
                                    this.spinner.show(false);
                                }
                            });
                        }
                        if (item.types[0] == "locality") {
                            this.PatientForm.controls.city.setValue(item.long_name);
                        }
                        if (item.types[0] == "administrative_area_level_2") {
                            this.PatientForm.controls.city.setValue(item.long_name);
                        }
                        if (item.types[0] == "county") {
                            this.PatientForm.controls.city.setValue(item.long_name);
                        }
                        if (item.types[0] == "administrative_area_level_2") {
                            this.PatientForm.controls.city.setValue(item.long_name);
                        }
                        document.getElementById("Zipcode").focus();
                        document.getElementById("autocomplete-input").focus();
                    });
                } else {
                    this.spinner.show(false);
                }
            });
        });
    }

    public showQRCode(): void {
        this.dialog.open(QrCodeDialogComponent, {
            width: '400px',
            disableClose: true,
            data: { action: 'add', patient: this.data?.patient }
        });
    }
    public copyLastName() {
        if (this.sameLastName.value == "0") {
            this.PatientForm.controls.birthName.setValue(this.PatientForm.controls.lastName.value);
            this.PatientForm.controls.birthName.disable();
        }
    }
    public onSameLastNameChange() {
        if (this.sameLastName.value == "1") {
            this.birthName.setValue("");
            this.PatientForm.controls.birthName.enable();
        }
        else {
            this.copyLastName();

        }
    }
    public getGenders() {
        this.loginServiceService
            .getGenderMasters()
            .subscribe((data: Array<any>) => {
                this.genders = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
                if (this.data.edit) {
                    this.PatientForm.controls.gender.setValue(
                        this.data.patient.gender_id.toString()
                    );
                } else {
                    this.PatientForm.controls.gender.setValue(
                        this.genders[0].Id.toString()
                    );
                }
            });
    }
    public getCountries() {
        this.loginServiceService
            .getAdminCommonCountries()
            .subscribe((data: Array<any>) => {
                this.nationalities = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
                const frNationIndex = this.nationalities.findIndex((i: any) => i.id == 1);
                const frNation = this.nationalities[frNationIndex];
                this.nationalities.splice(frNationIndex, 1);
                this.nationalities.sort((i: any) => i.country_name);
                this.nationalities.unshift(frNation);
                if (this.data.edit) {
                    this.nationality.setValue(this.data.patient.nationality);
                    this.country.setValue(this.data.patient.country_id);
                }
            });
    }

    public onSelectNationality(id: any, isReset = false) {
        const selectedValue = this.nationalities.find((i: any) => i.id == id);
        this.selectedFlag = selectedValue.country_code.toLowerCase();
        if (this.data.edit) {
            if (this.PatientForm.controls['ssn'].value != '') {
                this.haveSSN.setValue('0');
            } else {
                this.haveSSN.setValue('1');
            }
        }
        if (isReset) {
            this.birthCountry.setValue(id);
            this.onSelectBirthCountry(id);
        }
    }

    public onSSNChange() {
        let birth_month = '';
        this.ssn.setValue(this.ssn?.value?.replace(/\s/g, ''));
        if (this.dateOfBirth.value && this.ssn.value) {
            switch (new Date(this.dateOfBirth.value).getMonth()) {
                case 0: {
                    birth_month = '01';
                    break;
                }
                case 1: {
                    birth_month = '02';
                    break;
                }
                case 2: {
                    birth_month = '03';
                    break;
                }
                case 3: {
                    birth_month = '04';
                    break;
                }
                case 4: {
                    birth_month = '05';
                    break;
                }
                case 5: {
                    birth_month = '06';
                    break;
                }
                case 6: {
                    birth_month = '07';
                    break;
                }
                case 7: {
                    birth_month = '08';
                    break;
                }
                case 8: {
                    birth_month = '09';
                    break;
                }
                case 9: {
                    birth_month = '10';
                    break;
                }
                case 10: {
                    birth_month = '11';
                    break;
                }
                case 11: {
                    birth_month = '12';
                    break;
                }
            }

            this.PatientForm.controls['ssn'].setErrors({ incorrect: null });
            this.PatientForm.controls['ssn'].updateValueAndValidity();

            const selectedGender = this.genders
                .find((i) => i.Id == this.PatientForm.controls['gender'].value)
                .KeyName.toLowerCase();
            const dobYear = new Date(this.dateOfBirth.value).getFullYear();
            const age = new Date().getFullYear() - dobYear;
            if (age >= 18) {
                // check ssn value string
                if (
                    this.ssn.value.substr(1, 4) !==
                    dobYear.toString().slice(-2) + birth_month
                ) {
                    this.ssn.setErrors({ incorrect: true });
                } else {
                    // check for male
                    if (selectedGender === 'male' && this.ssn.value[0] !== '1') {
                        // valid
                        this.ssn.setErrors({ incorrect: true });
                    }
                    // check for female
                    else if (selectedGender === 'female' && this.ssn.value[0] !== '2') {
                        this.ssn.setErrors({ incorrect: true });
                    } else if (
                        selectedGender === 'other' &&
                        (parseInt(this.ssn.value[0], 10) >= 3 ||
                            parseInt(this.ssn.value[0], 10) <= 0)
                    ) {
                        this.ssn.setErrors({ incorrect: true });
                    }
                }
                this.birthCity.disable();
                if (this.ssn.valid) {
                    this.bindBirthCity(dobYear);
                }
            } else {
                this.birthCity.enable();
                if (
                    parseInt(this.ssn.value[0], 10) >= 3 ||
                    parseInt(this.ssn.value[0], 10) <= 0
                ) {
                    this.ssn.setErrors({ incorrect: true });
                }
            }
        }
    }

    private bindBirthCity(dobYear: number) {
        let inseeValue = this.ssn.value.substr(5, 5);
        let bcs = this.birthCities.filter(i => i.insee_code == inseeValue);
        bcs.sort((a, b) => {
            const dateA = a.validity_enddate ? new Date(a.validity_enddate) : new Date(9999, 11, 31); // Far future date
            const dateB = b.validity_enddate ? new Date(b.validity_enddate) : new Date(9999, 11, 31); // Far future date

            return dateA.getTime() - dateB.getTime();
        });
        if (bcs.length > 0) {
            const result = bcs.filter(item => {
                if (item.validity_enddate) {
                    const endYear = new Date(item.validity_enddate).getFullYear();
                    return dobYear < endYear;
                }
                return false;
            });

            if (result.length === 0) {
                let validBCCount = bcs.filter(item => item?.validity_enddate === "").length;
                validBCCount > 0 ? this.birthCity.setValue(bcs.find(item => item.validity_enddate === "")) : this.PatientForm.controls["ssn"].setErrors({ incorrect: true });
            }

            else
                this.birthCity.setValue(result[0]);
        }
        else {
            this.PatientForm.controls["ssn"].setErrors({ incorrect: true });
        }
    }

    public dismiss() {
        this.dialogRef.close(null);
    }

    clear() {
        this.PatientForm.reset();
        this.phone.reset();
        this.gender.setValue(this.genders[0].Id.toString());
        const toSelect = this.nationalities.find((c) => c.id == 1);
        this.selectedFlag = toSelect.country_code.toLowerCase();
        this.nationality.setValue(toSelect.id);
        if (this.editMode) {
            this.email.setValue(this.data.patient.email?.toLowerCase());
            this.Id.setValue(this.data.patient.id);
        }
    }

    onSave() {
        if (this.PatientForm.valid && this.dateOfBirth.valid) {
            const datePipe = new DatePipe('en-US');
            this.displaySpinner(true);
            this.email.setValue(this.email.value?.toLowerCase());
            let patientStatus: any;
            if (this.data.edit) {
                patientStatus = this.status.value;
            } else {
                patientStatus = true;
            }

            const payload = {
                Id: this.data.patient?.id,
                FirstName: this.firstName.value,
                BirthName: this.birthName.value,
                LastName: this.lastName.value,
                Dob: datePipe.transform(this.dateOfBirth.value, 'yyyy-MM-dd'),
                Gender: parseInt(this.PatientForm.controls['gender'].value, 10),
                Nationality: this.nationality.value,
                PhoneNumber: this.phoneNumber.value.replace('+', ''),
                Email: this.email.value,
                is_active: patientStatus,
                birth_country: this.birthCountry.value,
                birth_city: this.birthCity.value ? JSON.parse(JSON.stringify(this.birthCity.value))?.id : 0,
                parent_id: this.data.patient?.parent_id
            };
            payload['Ssn'] = this.ssn.value;
            if (this.data.edit) {
                payload['Address'] = this.address.value;
                payload['City'] = this.city.value;
                payload['CountryId'] = this.country.value;
                payload['Zip'] = this.zip.value;
                payload['is_privacy_accepted'] = this.data.patient.is_privacy_accepted;
                payload['is_terms_accepted'] = this.data.patient.is_terms_accepted;
                payload['private_insurance'] = this.data.patient.pvt_insurance;
                this.patientService.updatePatientDetails(payload).subscribe(
                    () => {
                        this.displaySpinner(false);
                        this.messageService.success(
                            this.translate.instant(this.data.savealert[this.data.action])
                        );
                        this.dialogRef.close(null);
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.displaySpinner(false);
                        if (errorResponse.error.internal_code === 423) {
                            this.email.setErrors({ emailExist: true } as ValidationErrors);
                        }
                        if (errorResponse.error.internal_code === 427) {
                            this.ssn.setErrors({ ssnExist: true } as ValidationErrors);
                        } else if (errorResponse.error.internal_code === 428) {
                            this.messageService.alert(
                                this.translate.instant('createAccount.duplicate-account'),
                                5000
                            );
                        }
                    }
                );
            } else {
                const salt = this.loader.saltkey;
                payload['salt'] = salt;
                this.patientService.createPatient(payload).subscribe(
                    () => {
                        this.displaySpinner(false);
                        this.messageService.success(
                            this.translate.instant(this.data.savealert[this.data.action])
                        );
                        this.dialogRef.close(null);
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.displaySpinner(false);
                        if (errorResponse.error.internal_code === 423) {
                            this.email.setErrors({ emailExist: true } as ValidationErrors);
                        } else if (errorResponse.error.internal_code === 427) {
                            this.ssn.setErrors({ ssnExist: true } as ValidationErrors);
                        } else if (errorResponse.error.internal_code === 428) {
                            this.messageService.alert(
                                this.translate.instant('createAccount.duplicate-account'),
                                5000
                            );
                        } else {
                            this.messageService.alert(
                                this.translate.instant(errorResponse.error.message)
                            );
                        }
                    }
                );
            }
        }
    }

    public displaySpinner(flag: boolean): void {
        this.spinner.show(flag);
    }
    selectedCountry(event: any) {
        this.selectedPhoneCountryCode = '+' + event.dialCode;
    }

    /**
   * Setting the initial Min Date to date pickers.
   */
    setInitialMinDate() {
        this.fromDateMin = new Date();
        this.toDateMin = new Date();
        this.toDateMin.setDate(this.fromDateMin.getDate() + 1);
    }

    /**
   * This method will envoke when patient will change the date by datepicker or manually.
   * @param e ChangedDate Value
   */
    // onSelectedDate(e: any) {
    //   this.changeMinDateInDatePicker(e);
    // }

    /**
   * This Method will set the min date on To Date Picker field according to selected value in From Date Picker
   * @param inputData Date Value
   * @returns returns the method without setting any min date if the date value is invalid.
   */

    confirmSendPasswordWithYesNo() {
        this.messageService
            .confirm(this.translate.instant(this.sendPasswordconfirmtext), '')
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.sendPasswordLink();
                }
            });
    }

    sendPasswordLink() {
        const inputValue = {
            email: this.email.value
        };
        this.resetPasswordService.postAuthforget(inputValue).subscribe(
            (output) => {
                console.log(output);
                this.messageService.success(
                    this.translate.instant('login.mail-success'),
                    5000
                );
            },
            (errorResponse: HttpErrorResponse) => {
                console.log(errorResponse);
                this.dialogRef.close(null);
            }
        );
    }

    /**
   * Change the fcus of Reset button
   */
    changeFocusOfRenewButton() {
        setTimeout(() => {
            this.enableFocus = false;
        }, 2000);
    }


    public onSelectBirthCountry(id: any) {
        const selectedValue = this.nationalities.find((i: any) => i.id == id);
        this.selectedBirthCountryFlag = selectedValue.country_code.toLowerCase();
        this.selectedBirthCountryName = selectedValue.country_name.toLowerCase();
        if (this.selectedBirthCountryName == 'france') {
            this.isBirthCityRequired = true;
            this.onSSNChange();
        } else {
            this.isBirthCityRequired = false;
            this.birthCity.setValue('');
        }
    }

    getBirthCities() {
        this.adminService.getBirthCities().subscribe(
            (data: any) => {
                this.birthCities = data;
                if (this.adminService.birthCitiesList.value == null) {
                    this.adminService.birthCitiesList.next(data)
                }
                this.filteredBirthCities = this.birthCity.valueChanges.pipe(
                    startWith(''),
                    map((value) => this._filter(value))
                );
                if (this.data.edit) {
                    const bc = this.birthCities.find(i => i.id == this.data.patient.birth_city);
                    if (bc) {
                        this.PatientForm.controls.birthCity.setValue(
                            bc
                        );
                    } else {
                        this.PatientForm.controls.birthCity.setValue('');
                    }

                }
            },
            (error: any) => {
                console.log(error);
            }
        );
    }

    displayFn(option?: any): string {
        return option?.city_name;
    }

    private _filter(value?: any): any[] {
        if (this.birthCities != null) {
            if (typeof value == 'string') {
                const filterval = value?.toLowerCase();
                return this.birthCities.filter((option) =>
                    option.city_name.toLowerCase().startsWith(filterval)
                ).slice(0, 500);
            } else {
                const filterval = value?.city_name?.toLowerCase();
                return this.birthCities.filter((option) =>
                    option.city_name.toLowerCase().startsWith(filterval)
                ).slice(0, 500);
            }
        }
        return null;
    }

    validateSelection() {
        if (this.birthCity.value && typeof (this.birthCity.value) == 'string') {
            this.birthCity.setErrors({ incorrect: true });
        }
    }

    protected onHaveSSNChange() {
        if (this.haveSSN.value == '0') {
            this.isSSNRequired = true;
            this.birthCity.disable();
        } else {
            this.isSSNRequired = false;
            this.birthCity.enable();
            this.ssn.setValue('');
            this.PatientForm.controls['ssn'].setErrors({ incorrect: null });
            this.PatientForm.controls['ssn'].updateValueAndValidity();
        }
    }


    restrictSpace(event: any) {
        const testVal = event.target.value.replace(/\s/g, '');
        event.target.value = testVal
    }

    public deleteUser(): void {
        this.messageService
            .confirm(
                this.translate.instant("user.delete-sure"),
                this.translate.instant("user.delete-confirm")
            )
            .subscribe((actionResult: boolean) => {
                if (actionResult) {
                    this.patientService.deletePatient(this.data?.patient?.id).subscribe(
                        () => {
                            this.messageService.success(
                                this.translate.instant('admin.save-alert.delete')
                            );
                            this.dismiss();
                        },
                        (errorResponse: any) => {
                            if (errorResponse.error.internal_code === 3003) {
                                this.messageService.alert(
                                    this.translate.instant("patient.error.3003")
                                );
                            }
                            else {
                                this.messageService.alert(
                                    this.translate.instant("patient.error.422")
                                );
                            }
                        }
                    );
                }
            });
    }
}
