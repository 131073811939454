import { Component, ElementRef, EventEmitter, Input, OnInit, AfterViewInit, Output } from '@angular/core';
import { LoginServiceService } from '../../../services/login-service.service';
import { LoaderService, MessageService } from '../../../../main';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FindAccountResponse } from '../../../shared/find-my-account/find-my-account.model';
import { constants } from '../../../shared/constant';
import { Observable } from 'rxjs';
import { CommonApiService } from '../../../services/common-api.service';
import { map, startWith } from 'rxjs/operators';
import { AppSettingsService, SpinnerService } from '../../../../core';
import { PatientService } from '../../../services/patient.service';
import { GoogleMapService } from '../../../shared/lab-google/google-map.service';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
declare var woosmap: any;
@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit, AfterViewInit {
  public step: number = 1;
  @Input() sliderFormData: any;
  @Input() firstNameInput: any;
  @Input() birthNameInput: any;
  @Input() genderInput: any;
  @Input() dobInput: any;
  @Input() rmInfo: any;
  @Input() updateList: boolean;
  @Output() submitRelativeMember = new EventEmitter<any>();
  @Output() goToBack = new EventEmitter<any>();
  @Input() isEdit: boolean;

  public firstName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters(),
  ]);
  public birthName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters(),
  ]);
  public lastName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters(),
  ]);
  public phoneNumber = new FormControl("");
  public otherPhoneNumber = new FormControl("");

  public sameLastName = new FormControl("0");
  public nationality = new FormControl("", [Validators.required]);
  public dateOfBirth = new FormControl("", [Validators.required]);
  public gender = new FormControl("", [Validators.required]);
  public birthCity = new FormControl({ value: '', disabled: true });
  public birthCountry = new FormControl("", [Validators.required]);
  public parentSsn = new FormControl({ value: "", disabled: true });
  public ssn = new FormControl("", [Validators.pattern("^.{1,15}$"),
  Validators.pattern(this.patientService.ssnPattern)]);
  public address = new FormControl("", [Validators.required,]);
  public city = new FormControl("", [
    Validators.pattern("^.{1,50}$"), Validators.required
  ]);
  public zip = new FormControl("", [
    Validators.pattern("^[0-9 ]{4,10}$"), Validators.required
  ]);

  public privacy = new FormControl(false, [Validators.requiredTrue]);

  public country = new FormControl("", [Validators.required]);
  public email = new FormControl({ value: "", disabled: true });
  public genders: any[];
  public maxDate: Date;
  public accounts: FindAccountResponse[];
  public selectedBirthCountryFlag: any;
  public selectedFlag: any;
  public searchMemberForm = this.formBuilder.group({
    firstName: this.firstName,
    birthName: this.birthName,
    dateOfBirth: this.dateOfBirth,
    gender: this.gender,
    birthCountry: this.birthCountry,
    birthCity: this.birthCity,
    lastName: this.lastName,
    nationality: this.nationality,
    ssn: this.ssn,
    phoneNumber: this.phoneNumber,
    country: this.country,
    email: this.email,
    address: this.address,
    city: this.city,
    zip: this.zip,
    privacy: this.privacy
  });
  public nationalities: any[];
  public showHaveSSN = false;
  public filteredBirthCities: Observable<any[]>;
  public birthCities: any[];
  public selectedBirthCountryName: any = "";
  public isBirthCityRequired = true;
  public useMyContact = new FormControl('0');
  public haveSSN = new FormControl("1");
  public useMySSN = new FormControl("0");
  public isAutoCompleteFocused: boolean;

  public isSSNRequired = true;
  public autocomplete: any;
  public primaryAccountHolder: any;
  public age: number;
  public bookAppointment: boolean = false;
  constructor(public loader: LoaderService, private formBuilder: FormBuilder,
    // constructor(private el: ElementRef) { }
    private el: ElementRef,
    public loginService: LoginServiceService, public adminService: CommonApiService,
    public spinnerService: SpinnerService, public patientService: PatientService,
    private googleMapService: GoogleMapService, public toaster: ToastrService,
    private readonly appSettings: AppSettingsService, public translate: TranslateService,
    public messageService: MessageService) {
    this.genders = new Array<any>();
    if (sessionStorage.getItem("userDetails")) {
      this.primaryAccountHolder = JSON.parse(sessionStorage.getItem("userDetails"));
    }
    else {
      let p_data = JSON.parse(sessionStorage.getItem("p_data"));

      this.primaryAccountHolder = { ssn: p_data.Ssn, phone_number: p_data.PhoneNumber, email: p_data.Email, address: p_data.Address, country_id: p_data.CountryId, city: p_data.City, zip: p_data.Zip }
    }

  }

  ngOnInit(): void {
    const datePipe = new DatePipe("en-US");
    const dobYear = new Date(this.dobInput).getFullYear();
    this.age = new Date().getFullYear() - dobYear;
    this.maxDate = new Date();
    if (this.isEdit) {
      this.firstName.setValue(this.rmInfo?.first_name);
      this.lastName.setValue(this.rmInfo?.last_name);
      this.birthName.setValue(this.rmInfo?.birth_name);
      if (this.lastName.value == this.birthName.value) {
        this.searchMemberForm.controls['lastName'].disable();
        this.sameLastName.setValue("0");
      }
      else {
        this.sameLastName.setValue("1");
      }

      this.useMySSN.setValue(this.rmInfo?.is_rm_ssn_same == true ? "0" : "1");
      this.dateOfBirth.setValue(this.rmInfo?.dob.substring(0, 10));
      const dobYear = new Date(this.dateOfBirth.value).getFullYear();
      this.age = new Date().getFullYear() - dobYear;
      this.ssn.setValue(this.rmInfo?.ssn);
      if (this.rmInfo?.is_rm_ssn_same == true && this.rmInfo?.ssn) {
        this.haveSSN.setValue("0");
        this.ssn.setValue(this.rmInfo?.ssn);
      }
      else if (this.rmInfo?.is_rm_ssn_same == false && this.rmInfo?.ssn) {
        this.haveSSN.setValue("0");
        this.ssn.setValue(this.rmInfo?.ssn);
        this.isSSNRequired = true;

      }
      else {
        this.haveSSN.setValue("1");
        this.ssn.setValue("");
        this.isSSNRequired = false;
      }
      this.useMyContact.setValue(this.rmInfo?.is_rm_address_same == true ? "0" : "1");
      this.onUseMyContactChange(true);
      this.privacy.setValue(this.rmInfo?.is_rm_privacy_accepted);
      if (this.primaryAccountHolder?.ssn) {
        this.parentSsn.setValue(this.primaryAccountHolder?.ssn);
      }
    }
    else {
      this.firstName.setValue(this.firstNameInput);
      this.lastName.setValue(this.birthNameInput);
      this.birthName.setValue(this.birthNameInput);
      this.searchMemberForm.controls['lastName'].disable();
      this.dateOfBirth.setValue(datePipe.transform(this.dobInput, "yyyy-MM-dd"));
      if (this.primaryAccountHolder?.ssn) {
        this.ssn.setValue(this.primaryAccountHolder?.ssn);
        this.parentSsn.setValue(this.primaryAccountHolder?.ssn);
        if (this.age >= 18) {
          this.useMySSN.setValue("1");
          this.haveSSN.setValue("0");
          this.ssn.setValue("");
        }
        else {
          this.useMySSN.setValue("0");
          this.birthCity.enable();
        }
      }
      else {
        this.useMySSN.setValue("1");
        this.haveSSN.setValue("0");
        this.ssn.setValue("");
      }
    }

    this.getGenders();
    this.birthCities = this.adminService.birthCitiesList.value;
    this.filteredBirthCities = this.birthCity.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    if (this.isEdit) {
      const bc = this.birthCities.find(
        (i) => i.id == this.rmInfo?.birth_city
      );
      if (bc) {
        this.searchMemberForm.controls.birthCity.setValue(bc);
      } else {
        this.searchMemberForm.controls.birthCity.setValue("");
      }
    }
    const self = this;
    document.getElementsByClassName("app-sidenav-content")[0]?.addEventListener(
      "scroll",
      () => {
        if (self.isAutoCompleteFocused) {
          const elements: any =
            document.getElementsByClassName("pac-container");
          for (let i = 0; i < elements.length; ++i) {
            elements[i].style.display = "none";
          }
        }
      },
      false
    );
    this.loginService.getAdminCommonCountries().subscribe((data: any[]) => {
      this.nationalities = data;
      const frNationIndex = this.nationalities.findIndex((i: any) => i.id == constants.nationality);
      const frNation = this.nationalities[frNationIndex];
      this.nationalities.splice(frNationIndex, 1);
      this.nationalities.sort((i) => i.country_name);
      this.nationalities.unshift(frNation);
      if (this.isEdit) {
        this.nationality.setValue(this.rmInfo?.nationality);
        this.birthCountry.setValue(this.rmInfo?.birth_country);
        const toSelect = this.nationalities.find(
          (c) => c.id == this.rmInfo?.nationality
        );
        const toBCSelect = this.nationalities.find(
          (c) => c.id == this.rmInfo?.birth_country
        );
        this.selectedFlag = toSelect?.country_code.toLowerCase();
        this.selectedBirthCountryFlag = toBCSelect?.country_code.toLowerCase();
        if (this.rmInfo?.birth_country != "")
          this.onSelectBirthCountry(this.rmInfo?.birth_country);
      } else {
        const toSelect = this.nationalities.find((c) => c.id == constants.nationality);
        this.selectedFlag = toSelect?.country_code.toLowerCase();
        this.selectedBirthCountryFlag = toSelect?.country_code.toLowerCase();

        this.nationality.setValue(toSelect?.id);
        this.showHaveSSN = true;

        this.birthCountry.setValue(toSelect?.id);

        this.onUseMyContactChange();

      }
      this.addressAutocomplete();
    });

    if (window.location.href.includes("patient/book-appointment") && screen.width < 962) {
      this.bookAppointment = true;
    }
  }

  ngAfterViewInit(): void {
    if (typeof woosmap !== 'undefined') {
      //this.initializeMap();
      this.addressAutocomplete();
    } else {
      console.error('Woosmap SDK is not loaded!');
    }
  }

  // getBirthCities() {
  //   this.spinnerService.show(true);
  //   this.adminService.getBirthCities().subscribe(
  //     (data: any) => {
  //       this.birthCities = data;
  //       this.filteredBirthCities = this.birthCity.valueChanges.pipe(
  //         startWith(""),
  //         map((value) => this._filter(value))
  //       );
  //       if (this.isEdit) {
  //         const bc = this.birthCities.find(
  //           (i) => i.id == this.rmInfo?.birth_city
  //         );
  //         if (bc) {
  //           this.searchMemberForm.controls.birthCity.setValue(bc);
  //         } else {
  //           this.searchMemberForm.controls.birthCity.setValue("");
  //         }
  //       }

  //       this.spinnerService.show(false);
  //     },
  //     (error: any) => {
  //       console.log(error);
  //       this.spinnerService.show(false);
  //     }
  //   );
  // }

  public _filter(value?: any): any[] {
    if (this.adminService.birthCitiesList.value != null) {
      if (typeof value == "string") {
        const filterval = value?.toLowerCase();
        return this.birthCities
          .filter((option) =>
            option.city_name.toLowerCase().startsWith(filterval)
          )
          .slice(0, 500);
      } else {
        const filterval = value?.city_name?.toLowerCase();
        return this.birthCities
          .filter((option) =>
            option.city_name.toLowerCase().startsWith(filterval)
          )
          .slice(0, 500);
      }
    }
    return null;
  }

  displayFn(option?: any): string {
    return option?.city_name;
  }

  public onSameLastNameChange() {
    if (this.sameLastName.value == "1") {
      this.lastName.setValue("");
      this.searchMemberForm.controls.lastName.enable();
    }
    else {
      this.copyBirthName();

    }
  }

  public copyBirthName() {
    if (this.sameLastName.value == "0") {
      this.searchMemberForm.controls.lastName.setValue(this.searchMemberForm.controls.birthName.value);
      this.searchMemberForm.controls.lastName.disable();
    }
  }


  public onSelectNationality(id: any) {
    const selectedValue = this.nationalities.find((i: any) => i.id == id);
    this.selectedFlag = selectedValue.country_code.toLowerCase();
    this.birthCountry.setValue(id);
    this.onSelectBirthCountry(id);
  }


  validateSelection() {
    if (this.birthCity.value && typeof this.birthCity.value == "string") {
      this.birthCity.setErrors({ incorrect: true });
    }
  }

  public onSelectBirthCountry(id: any) {
    const selectedValue = this.nationalities.find((i: any) => i.id == id);
    this.selectedBirthCountryFlag = selectedValue.country_code.toLowerCase();
    this.selectedBirthCountryName = selectedValue.country_name.toLowerCase();
    if (this.selectedBirthCountryName == "france") {
      this.isBirthCityRequired = true;
      this.onSSNChange();
    } else {
      this.isBirthCityRequired = false;
      this.birthCity.setValue("");
    }
  }

  public getGenders() {
    this.loginService.getGenderMasters().subscribe((data: Array<any>) => {
      this.genders = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      if (this.isEdit) {
        this.gender.setValue(this.rmInfo?.gender.toString());
      }
      else {
        if (this.genderInput) {
          this.gender.setValue(this.genderInput);
        }
        else {
          this.gender.setValue(this.genders[0].Id.toString());
        }
      }
    });
  }

  dismiss() {
    this.loader.cancelForm();
  }

  setBirthCity(event: any) {
    this.birthCity.setValue(event.option.value);
  }

  public onHaveSSNChange() {
    this.ssn.setValue(this.ssn?.value?.replace(/\s/g, ''));
    if (this.haveSSN.value == "0") {
      this.isSSNRequired = true;
      this.birthCity.disable();
    } else {
      this.isSSNRequired = false;
      this.birthCity.enable();
      this.ssn.setValue("");
      this.searchMemberForm.controls["ssn"].setErrors({ incorrect: null });
      this.searchMemberForm.controls["ssn"].updateValueAndValidity();
    }
  }


  public onSSNChange(update: boolean = true) {
    const dobYear = new Date(this.dateOfBirth.value).getFullYear();
    this.age = new Date().getFullYear() - dobYear;
    if (update) {
      if (this.primaryAccountHolder?.ssn && this.age < 18) {
        this.birthCity.enable();
        if (this.useMySSN.value != "1") {
          this.useMySSN.setValue("0");
          this.ssn.setValue(this.primaryAccountHolder?.ssn?.replace(/\s/g, ''));
        }
      }
      else {

        this.useMySSN.setValue("1");
        this.haveSSN.setValue("0");
        this.onHaveSSNChange();
        if (!this.isEdit) {
          this.ssn.setValue("");
        }
      }
    }
    let birth_month = "";
    this.ssn.setValue(this.ssn?.value?.replace(/\s/g, ''));
    if (this.dateOfBirth.value && this.ssn.value) {
      switch (new Date(this.dateOfBirth.value).getMonth()) {
        case 0: {
          birth_month = "01";
          break;
        }
        case 1: {
          birth_month = "02";
          break;
        }
        case 2: {
          birth_month = "03";
          break;
        }
        case 3: {
          birth_month = "04";
          break;
        }
        case 4: {
          birth_month = "05";
          break;
        }
        case 5: {
          birth_month = "06";
          break;
        }
        case 6: {
          birth_month = "07";
          break;
        }
        case 7: {
          birth_month = "08";
          break;
        }
        case 8: {
          birth_month = "09";
          break;
        }
        case 9: {
          birth_month = "10";
          break;
        }
        case 10: {
          birth_month = "11";
          break;
        }
        case 11: {
          birth_month = "12";
          break;
        }
      }

      this.searchMemberForm.controls["ssn"].setErrors({ incorrect: null });
      this.searchMemberForm.controls["ssn"].updateValueAndValidity();

      const selectedGender = this.genders
        .find((i) => i.Id == this.gender.value)
        .KeyName.toLowerCase();

      if (this.age >= 18) {
        // check ssn value string
        if (
          this.ssn.value.substr(1, 4) !==
          dobYear.toString().slice(-2) + birth_month
        ) {
          this.searchMemberForm.controls["ssn"].setErrors({ incorrect: true });
        } else {
          // check for male
          if (selectedGender === "male" && this.ssn.value[0] !== "1") {
            // valid
            this.searchMemberForm.controls["ssn"].setErrors({ incorrect: true });
          }
          // check for female
          else if (selectedGender === "female" && this.ssn.value[0] !== "2") {
            this.searchMemberForm.controls["ssn"].setErrors({ incorrect: true });
          } else if (
            selectedGender === "other" &&
            (parseInt(this.ssn.value[0], 10) >= 3 ||
              parseInt(this.ssn.value[0], 10) <= 0)
          ) {
            this.searchMemberForm.controls["ssn"].setErrors({ incorrect: true });
          }
        }
        this.birthCity.disable();
        if (this.ssn.valid) {
          this.bindBirthCity(dobYear);
        }
      } else {
        this.birthCity.enable();
        if (
          parseInt(this.ssn.value[0], 10) >= 3 ||
          parseInt(this.ssn.value[0], 10) <= 0
        ) {
          this.searchMemberForm.controls["ssn"].setErrors({ incorrect: true });
        }
      }
    }
  }

  private bindBirthCity(dobYear: number) {
    let inseeValue = this.ssn.value.substr(5, 5);
    let bcs = this.birthCities.filter(i => i.insee_code == inseeValue);
    bcs.sort((a, b) => {
      const dateA = a.validity_enddate ? new Date(a.validity_enddate) : new Date(9999, 11, 31); // Far future date
      const dateB = b.validity_enddate ? new Date(b.validity_enddate) : new Date(9999, 11, 31); // Far future date

      return dateA.getTime() - dateB.getTime();
    });
    if (bcs.length > 0) {
      const result = bcs.filter(item => {
        if (item.validity_enddate) {
          const endYear = new Date(item.validity_enddate).getFullYear();
          return dobYear < endYear;
        }
        return false;
      });

      if (result.length === 0) {
        let validBCCount = bcs.filter(item => item?.validity_enddate === "").length;
        validBCCount > 0 ? this.birthCity.setValue(bcs.find(item => item.validity_enddate === "")) : this.searchMemberForm.controls["ssn"].setErrors({ incorrect: true });
      }

      else
        this.birthCity.setValue(result[0]);
    }
    else {
      this.searchMemberForm.controls["ssn"].setErrors({ incorrect: true });
    }
  }

  restrictSpace(event: any) {
    const testVal = event.target.value.replace(/\s/g, '');
    event.target.value = testVal
  }

  public onUseMySSNChange() {
    if (this.useMySSN.value == '0' && this.primaryAccountHolder?.ssn) {
      this.ssn.setValue(this.primaryAccountHolder?.ssn);
      this.showHaveSSN = false;
      this.haveSSN.setValue("1");
      this.isSSNRequired = true;

    }
    else if (this.useMySSN.value == '1') {
      this.ssn.setValue("");
      this.showHaveSSN = true;
      this.haveSSN.setValue("0");
      this.isSSNRequired = true;
    }
    if (this.age < 18) {
      this.birthCity.enable();
    }
  }

  public onUseMyContactChange(prefill: boolean = false) {

    if (this.useMyContact.value == '0') {
      this.phoneNumber.setValue("+" + this.primaryAccountHolder.phone_number);
      this.email.setValue(this.primaryAccountHolder.email);
      this.address.setValue(this.primaryAccountHolder.address);
      this.country.setValue(this.primaryAccountHolder.country_id == 0 ? "" : this.primaryAccountHolder.country_id);
      this.city.setValue(this.primaryAccountHolder.city);
      this.zip.setValue(this.primaryAccountHolder.zip);
      this.searchMemberForm.controls["address"].disable();
      this.searchMemberForm.controls["city"].disable();
      this.searchMemberForm.controls["zip"].disable();
      this.searchMemberForm.controls["country"].disable();
      this.searchMemberForm.controls["phoneNumber"].disable();
    }
    else {
      this.searchMemberForm.controls["address"].enable();
      this.searchMemberForm.controls["city"].enable();
      this.searchMemberForm.controls["zip"].enable();
      this.searchMemberForm.controls["country"].enable();
      this.searchMemberForm.controls["phoneNumber"].enable();
      if (prefill) {
        this.phoneNumber.setValue("+" + this.rmInfo?.phone_number);
        this.email.setValue(this.rmInfo.email);
        this.address.setValue(this.rmInfo.address);
        this.country.setValue(this.rmInfo.country_id == 0 ? "" : this.rmInfo.country_id);
        this.city.setValue(this.rmInfo.city);
        this.zip.setValue(this.rmInfo.zip);
      } else {
        this.address.setValue(null);
        this.country.setValue(null);
        this.city.setValue(null);
        this.zip.setValue(null);
      }
    }
  }

  public addressAutocomplete() {
    this.googleMapService.api.then(() => {
      const options = {
        "components": {
          country: this.appSettings?.country?.split(";")
        },
        "types": ['locality', 'postal_code', 'address', 'admin_level', 'country', 'train_station', 'metro_station', 'shopping', 'airport', 'museum', 'zoo', 'amusement_park', 'art_gallery', 'tourist_attraction']
      };
      //@ts-ignore
      const localitiesWidget = new window.woosmap.localities.Autocomplete("autocomplete-input", options);
      localitiesWidget.addListener("selected_suggestion", () => {
        this.spinnerService.show(true);
        const selectedLocality = localitiesWidget.getSelectedSuggestionDetails();
        if (selectedLocality.address_components) {
          this.searchMemberForm.controls.address.setValue(selectedLocality.formatted_address);
          selectedLocality.address_components.filter((item: any) => {
            if (item.types[0] == "postal_code") {

              this.searchMemberForm.controls.zip.setValue(item.long_name[0]);
            }
            if (item.types[0] == "country") {
              this.nationalities.forEach((n: any, index: number) => {
                if (n.country_name == item.long_name) {
                  this.spinnerService.show(false);
                  this.searchMemberForm.controls.country.setValue(n.id);
                  document.getElementById("country").focus();
                } else {
                  this.spinnerService.show(false);
                }
                if (index == this.nationalities.length - 1) {
                  this.spinnerService.show(false);
                }
              });
            }
            if (item.types[0] == "locality") {
              this.searchMemberForm.controls.city.setValue(item.long_name);
            }
            if (item.types[0] == "administrative_area_level_1") {
              this.searchMemberForm.controls.city.setValue(item.long_name);
            }
            if (item.types[0] == "county") {
              this.searchMemberForm.controls.city.setValue(item.long_name);
            }
            if (item.types[0] == "administrative_area_level_2") {
              this.searchMemberForm.controls.city.setValue(item.long_name);
            }
            document.getElementById("Zipcode").focus();
            document.getElementById("autocomplete-input").focus();
          });
        } else {
          this.spinnerService.show(false);
        }
      });
    });
  }

  back() {
    this.step = 1;
  }

  submitRM() {
    if (this.searchMemberForm.invalid) {
      const invalidControls: NodeListOf<HTMLElement> = this.el.nativeElement.querySelectorAll('.ng-invalid');

      if (invalidControls.length > 0) {
        const firstInvalidControl = invalidControls[1];
        firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
        firstInvalidControl.focus();
      }
    }
    this.searchMemberForm.markAllAsTouched();
    if (this.searchMemberForm.valid) {
      if (this.primaryAccountHolder?.ssn && this.useMySSN.value == '1' && this.primaryAccountHolder?.ssn == this.ssn.value) {
        this.ssn.markAsTouched();
        this.searchMemberForm.controls["ssn"].setErrors({ parentSsn: true });
        return
      }
      if (this.isEdit) {
        this.addMember(null);
      }
      else {
        const datePipe = new DatePipe("en-US");
        let payload = {
          FirstName: this.firstName.value.trim(),
          BirthName: this.birthName.value.trim(),
          LastName: this.lastName.value.trim(),
          Dob: datePipe.transform(this.dateOfBirth.value, "yyyy-MM-dd"),
          Gender: parseInt(this.gender.value, 10),
          Nationality: this.nationality.value,
          PhoneNumber: this.phoneNumber.value.replace("+", ""),
          Email: this.email.value,
          Password: "",
          salt: "",
          birth_country: this.birthCountry.value,
          birth_city: this.birthCity.value
            ? JSON.parse(JSON.stringify(this.birthCity.value))?.id
            : 0,
          Ssn: this.ssn.value,
          Address: this.address.value,
          City: this.city.value,
          CountryId: this.country.value,
          Zip: this.zip.value,
          parent_id: sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails"))?.id : "",
        }
        this.patientService.validatePaient(payload).subscribe(
          (response: any) => {
            this.spinnerService.show(false);
            if (response?.message == "successful") {
              this.step = 2;
            }
          },
          (error) => {
            this.spinnerService.show(false);
            if (error.error.internal_code === 427) {
              this.ssn.markAsTouched();
              this.messageService.alert(
                this.translate.instant("createAccount.invalid-ssn"),
                5000
              );
              this.searchMemberForm.controls["ssn"].setErrors({ ssnExist: true });
            } else if (error.error.internal_code === 428) {
              this.messageService.alert(
                this.translate.instant("createAccount.duplicate-account"),
                5000
              );
            } else {
              this.messageService.alert(
                this.translate.instant('add-member.unsuccessful-req'),
                5000
              );
            }
          }
        );
      }
    }
  }

  public showError(error: any) {
    if (error.error.internal_code === 427) {
      this.ssn.markAsTouched();
      this.messageService.alert(
        this.translate.instant("createAccount.invalid-ssn"),
        5000
      );
    } else if (error.error.internal_code === 428) {
      this.messageService.alert(
        this.translate.instant("createAccount.duplicate-account"),
        5000
      );
    } else {
      this.messageService.alert(
        "Your request can not be processed at this moment",
        5000
      );
    }
  }

  public addMember(evt: any) {
    this.spinnerService.show(true);
    if (evt) {
      evt.pvt_insurance = true;
      console.log(evt);
    }
    const datePipe = new DatePipe("en-US");

    this.searchMemberForm.markAllAsTouched();
    if (this.searchMemberForm.valid) {
      let payload = {
        "FirstName": this.firstName.value.trim(),
        "BirthName": this.birthName.value.trim(),
        "LastName": this.lastName.value.trim(),
        "Dob": datePipe.transform(this.dateOfBirth.value, "yyyy-MM-dd"),
        "Gender": parseInt(this.gender.value, 10),
        "Nationality": this.nationality.value,
        "PhoneNumber": this.phoneNumber.value.replace("+", ""),
        "Email": this.email.value,
        "Password": "",
        "salt": "",
        "birth_country": this.birthCountry.value,
        "birth_city": this.birthCity.value
          ? JSON.parse(JSON.stringify(this.birthCity.value))?.id
          : 0,
        "Ssn": this.ssn.value,
        "is_privacy_accepted": true,
        "is_terms_accepted": true,
        "Address": this.address.value,
        "City": this.city.value,
        "CountryId": this.country.value,
        "Zip": this.zip.value,
        "private_insurance": false,
        "parent_id": sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails"))?.id : "",
        "is_rm_privacy_accepted": this.privacy.value,
        "is_rm_ssn_same": this.useMySSN.value == '0',
        "is_rm_address_same": this.useMyContact.value == '0'
      }
      let response: any;
      if (sessionStorage.getItem("userDetails")) {
        if (this.isEdit) {
          payload["id"] = this.rmInfo?.id;
          payload["is_active"] = this.rmInfo?.is_active;
          response = this.patientService.updatePatientDetails(payload);
        } else {
          payload["private_insurance"] = evt?.pvt_insurance;
          payload["documents"] = evt?.documents;
          payload["minor_passport"] = evt?.minor_passport;
          response = this.patientService.createPatient(payload);
        }

        response.subscribe(
          (data: any) => {
            this.loader.rm_id = data?.id;
            this.spinnerService.show(false);
            if (this.updateList) {
              this.submitRelativeMember.emit(payload);
            } else {
              this.loader.cancelForm();
              this.toaster.success(this.translate.instant('add-member.success'));
            }

          }, (error: any) => {
            this.spinnerService.show(false);
            this.showError(error);
          });
      }
      else {
        payload["private_insurance"] = evt?.pvt_insurance;
        payload["documents"] = evt?.documents;
        payload["minor_passport"] = evt?.minor_passport;
        this.spinnerService.show(false);
        this.submitRelativeMember.emit(payload);
      }
    }
  }
}
