import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { GoogleMapService } from "../../shared/lab-google/google-map.service";
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { AppSettingsService } from "../../../core";
import { SpinnerService } from "../../../core/spinner/spinner.service";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "../../../main/services/loader.service";
import { PatientService } from "../../services/patient.service";
import { constants } from "../../shared/constant";
//import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../../../main/services/message.service';
import { DatePipe } from '@angular/common';
import { EstablishmentsService } from './establishments.service';
declare var woosmap: any;

@Component({
  selector: 'app-add-establishment',
  templateUrl: './add-establishment.component.html',
  styleUrl: './add-establishment.component.scss'
})
export class AddEstablishmentComponent implements OnInit, AfterViewInit {
  @Input() sliderFormData: any;
  @Input() public isSliderDataEdited = false;
  @Input() data: any;

  //EstablishForm: FormGroup;

  public establishName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters()
  ]);
  public address = new FormControl("");
  public city = new FormControl("", Validators.pattern("^.{1,50}$"));
  public country = new FormControl("");
  public zip = new FormControl("", Validators.pattern("^[0-9 ]{4,10}$"));
  public openFrom = new FormControl("", [Validators.required]);
  public openTo = new FormControl("", [Validators.required]);
  public status = new FormControl("", [Validators.required]);

  public EstablishForm = this.formBuilder.group({
    establishName: this.establishName,
    status: this.status,
    address: this.address,
    city: this.city,
    zip: this.zip,
    country: this.country,
    openTo: this.openTo,
    openFrom: this.openFrom
  });

  nationalities: any[];
  fromDateMin: Date;
  toDateMin: Date;
  selectedDate: Date;
  countryList: any;
  title: string;

  saveAlert = {
    edit: 'admin.save-alert.edit',
    add: 'admin.save-alert.add'
  };
  errorMessage = 'admin.error-message';

  constructor(
    public loader: LoaderService,
    private googleMapService: GoogleMapService,
    private spinnerService: SpinnerService,
    private readonly appSettings: AppSettingsService,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    private patientService: PatientService,
    public messageService: MessageService,
    private EstablishmentsService: EstablishmentsService,
  ) {
    this.fromDateMin = new Date();
    this.toDateMin = new Date();
  }

  ngOnInit(): void {
    this.patientService.getCoutryFlags().subscribe(
      (data: any) => {
        this.nationalities = data;
        const frNationIndex = this.nationalities.findIndex((i: any) => i.id == constants.nationality);
        const frNation = this.nationalities[frNationIndex];
        this.nationalities.splice(frNationIndex, 1);
        this.nationalities.sort((i) => i.country_name);
        this.nationalities.unshift(frNation);
        this.countryList = data;
      },
      (error: any) => {
        console.log(error);
      }
    );
    if (this.sliderFormData[0].edit) {
      this.bindUserObject(this.sliderFormData[0].EstablishData);
    }


  }

  ngAfterViewInit(): void {
    if (typeof woosmap !== 'undefined') {
      //this.initializeMap();
      this.addressAutocomplete();
    } else {
      console.error('Woosmap SDK is not loaded!');
    }
  }
  public addressAutocomplete() {
    this.googleMapService.api.then(() => {
      const options = {
        "components": {
          country: this.appSettings?.country?.split(";")
        },
        "types": ['locality', 'postal_code', 'address', 'admin_level', 'country', 'train_station', 'metro_station', 'shopping', 'airport', 'museum', 'zoo', 'amusement_park', 'art_gallery', 'tourist_attraction']
      };
      //@ts-ignore
      const localitiesWidget = new window.woosmap.localities.Autocomplete("autocompleteinput", options);
      localitiesWidget.addListener("selected_suggestion", () => {
        this.spinnerService.show(true);
        const selectedLocality = localitiesWidget.getSelectedSuggestionDetails();
        console.log(selectedLocality);
        if (selectedLocality.address_components) {
          console.log("address:", selectedLocality.formatted_address);
          this.EstablishForm.controls.address.setValue(selectedLocality.formatted_address);
          selectedLocality.address_components.filter((item: any) => {
            if (item.types[0] == "postal_code") {
              console.log("Postal Code", item.long_name)
              if (Array.isArray(item.long_name)) { this.EstablishForm.controls.zip.setValue(item.long_name[0]); }
              else { this.EstablishForm.controls.zip.setValue(item.long_name); }
            }
            if (item.types[0] == "country") {
              this.nationalities.forEach((n: any, index: number) => {
                if (n.country_name == item.long_name) {
                  this.spinnerService.show(false);
                  this.EstablishForm.controls.country.setValue(n.id);
                  document.getElementById("country").focus();
                } else {
                  this.spinnerService.show(false);
                }
                if (index == this.nationalities.length - 1) {
                  this.spinnerService.show(false);
                }
              });
            }
            if (item.types[0] == "locality") {
              console.log("locality:", item.long_name)
              this.EstablishForm.controls.city.setValue(item.long_name);
            }
            // else{
            //   this.EstablishForm.controls.city.setValue("");
            // }
            if (item.types[0] == "administrative_area_level_2") {
              console.log("ad2:", item.long_name)
              this.EstablishForm.controls.city.setValue(item.long_name);
            }
            if (item.types[0] == "administrative_area_level_1") {
              console.log("ad2:", item.long_name)
              this.EstablishForm.controls.city.setValue(item.long_name);
            }
            if (item.types[0] == "county") {
              console.log("ad2:", item.long_name)
              this.EstablishForm.controls.city.setValue(item.long_name);
            }
            // else{
            //   this.EstablishForm.controls.city.setValue("");
            // }
            document.getElementById("Zipcode").focus();
            document.getElementById("autocompleteinput").focus();
          });
        } else {
          this.spinnerService.show(false);
        }
      });
    });
  }


  public dismiss() { this.loader.cancelForm(); }

  reset() {
    this.EstablishForm.reset();
    this.EstablishForm.markAsUntouched();
  }

  public success(message: any): void {
    this.messageService.success(this.translate.instant(message));
  }
  public alert(error: any): void {
    this.messageService.alert(this.translate.instant(error), 10000);
  }

  /**
  * This method will envoke when user will change the date by datepicker or manually.
  * @param e ChangedDate Value
  */
  onSelectedDate(e: any) {
    this.changeMinDateInDatePicker(e);
  }

  /**
  * This Method will set the min date on To Date Picker field according to selected value in From Date Picker
  * @param inputData Date Value
  * @returns returns the method without setting any min date if the date value is invalid.
  */
  changeMinDateInDatePicker(inputData: any) {
    this.selectedDate = inputData;
    const datePipe = new DatePipe('en-US');

    const formattedDate = datePipe.transform(this.selectedDate, 'dd-MM-yyyy');
    if (formattedDate == null) {
      return;
    }

    const splittedDateArray = formattedDate.split('-');

    const year = Number(splittedDateArray[2]);
    const month = Number(splittedDateArray[1]) - 1;
    const date = Number(splittedDateArray[0]) + 1;

    this.toDateMin = new Date(year, month, date);
  }

  getSelectedData(selectedData: any) {
    if (selectedData === undefined) {
      return '';
    } else if (selectedData === null) {
      return '';
    } else {
      return selectedData;
    }
  }

  protected checkIfDateInputIsValid() {
    return this.openFrom.valid && this.openTo.valid;
  }

  /**
  * This method will convert the date string into a formatted date.
  * @param dateValue
  * @returns formatted date.
  */
  getFormattedDate(dateValue: any) {
    const datePipe = new DatePipe('en-US');

    if (dateValue === undefined) {
      return '';
    }

    const formattedDate =
      dateValue !== null ? datePipe.transform(dateValue, 'yyyy-MM-dd') : '';

    return formattedDate;
  }

  onSave() {
    if (this.EstablishForm.valid && this.checkIfDateInputIsValid()) {
      const fromDate = this.getFormattedDate(this.openFrom.value);
      const toDate = this.getFormattedDate(this.openTo.value);
      debugger;
      if (this.sliderFormData[0].edit) {
        const obj = {
          //id: this.data.EstablishData.id,
          hospital_name: this.EstablishForm.controls.establishName.value,
          is_active: this.EstablishForm.controls.status.value,
          //sampling_station_external_id:                    this.EstablishForm.controls.SamplingStationID.value,
          address: this.EstablishForm.controls.address.value,
          open_from: fromDate,
          open_until: toDate
        };
        this.EstablishmentsService.editEstablishment(obj).subscribe(
          () => {
            console.log("est editb saved");
            this.messageService.success(
              this.translate.instant(this.saveAlert[this.data.action])
            );
          },
          () => {
            this.messageService.warning(
              this.translate.instant('add-sampling.invalid-input')
            );
          }
        );
      } else {
        const obj = {
          hospital_name: this.EstablishForm.controls.establishName.value,
          is_active: this.EstablishForm.controls.status.value,
          address: this.EstablishForm.controls.address.value,
          open_from: fromDate,
          open_until: toDate,
        };

        this.EstablishmentsService.addEstablishment(obj).subscribe(
          () => {
            console.log("est saved");
            this.messageService.success(
              this.translate.instant(this.saveAlert[this.data.action])
            );
          },
          () => {
            this.messageService.warning(
              this.translate.instant('add-sampling.invalid-input')
            );

          }
        );
      }
    }
  }

  bindUserObject(EstablishData: any) {

    const address =
      EstablishData.address === 'n/a' ? '' : EstablishData.address;

    this.EstablishForm.patchValue({
      establishName: EstablishData.hospital_name,
      status: EstablishData.is_active,
      address: address
      //sampling_station_external_id:this.EstablishForm.controls.SamplingStationID.value,
    });
  }
}
