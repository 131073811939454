<div class="d-flex flex-column">
    <div>
        <button class="close" mat-dialog-close mat-button id="btn_close_dialog">X</button>
    </div>
    <div class="search-container" *ngIf="editSlotGroupMode">
        <header class="header">
            <mat-label class="label-float title"><span class="slot-name">{{previewData?.slot_name}}</span>
                ({{previewDate | date :'dd/MM/YYYY'}} |
                {{previewData?.start_time| date:'HH:mm'}}-{{previewData?.end_time| date:'HH:mm'}})</mat-label>

            <div class="pr-15" *ngIf="data.selectedEndDateTime.getTime() >=  currTime.getTime()">
                <button rippleOnHover class="mat-tbl-icon deleteEntity_btn" mat-icon-button
                    (click)="editConfiguration()" *ngIf="data?.inRange">
                    <span class="icon-ic-edit" matTooltip="{{'user.edit' | translate}}"
                        matTooltipClass="tooltip-style"></span>
                </button>
                <button rippleOnHover class="mat-tbl-icon deleteEntity_btn" mat-icon-button
                    (click)="confirmDeleteConfiguration()">
                    <span class="icon-ic-delete" matTooltip="{{'user.delete' | translate}}"
                        matTooltipClass="tooltip-style"></span>
                </button>

            </div>
        </header>
        <div class="test-wrapper">
            <ng-container *ngFor="let test of previewData?.tests;let i=index">
                <mat-label class="label-float">{{test.test_name}}</mat-label>
            </ng-container>
        </div>
    </div>
    <div class="search-container my-20" *ngIf="!editSlotGroupMode">
        <header class="header">
            <mat-label class="label-float title"><span class="slot-name">{{previewData?.slot_name}}</span>
                ({{previewData?.start_time | date :'dd/MM/YYYY'}} |
                {{previewData?.start_time| date:'HH:mm'}}-{{previewData?.end_time| date:'HH:mm'}})</mat-label>
        </header>
        <div class="my-20">
            <mat-radio-group [formControl]="is_sequence" (change)="changeSequence()" required>
                <mat-radio-button value="0">{{'add-slot-group.only-one'| translate}}
                </mat-radio-button>
                <mat-radio-button value="1">{{'add-slot-group.series'| translate}}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <div class="text-center mt-15">
            <button mat-button color="accent" class="ghost button_width" (click)="deleteSlotGroup()" type="button"
                id="btn_clear">
                {{'user.delete' | translate}}
            </button>
        </div>
    </div>
</div>