import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EhpRoutingModule } from './ehp-routing.module';
import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { ResultGridComponent } from './result-grid/result-grid.component';
import { PartialResultComponent } from './partial-result/partial-result.component';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CalendarModule } from 'angular-calendar';
import { SharedModule } from "../../shared/shared.module";
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { EstablishementComponent } from './establishement/establishement.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AddEstablishmentComponent } from './add-establishment/add-establishment.component';
import { MatSelectModule } from '@angular/material/select';


@NgModule({
  declarations: [PartialResultComponent, ResultGridComponent, EstablishementComponent, AddEstablishmentComponent],
  imports: [
    CommonModule,
    EhpRoutingModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule, FormsModule, MatMenuModule, MatCheckboxModule,
    MatFormFieldModule, MatDatepickerModule, CalendarModule, MatSelectModule,
    SharedModule, ReactiveFormsModule, MatInputModule, MatTooltipModule, MatPaginatorModule
]
})
export class EhpModule { }
