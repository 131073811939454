import {
    Component,
    ComponentFactoryResolver,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { int } from '@zxing/library/esm/customTypings';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { LocaleService } from '../../../app.locale.service';
import { CommonApiService } from '../../../eClinical/services/common-api.service';
import { AboutUsComponent } from '../../about-us/about-us.component';
import { Language } from '../../models/language-info.model';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { LoaderService } from '../../services/loader.service';
import { MessageService } from '../../services/message.service';
import { PatientService } from '../../../eClinical/services/patient.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NotificationsComponent } from '../../../eClinical/shared/notifications/notifications.component';
import { HeroBannerWrapper } from '../../../eClinical/admin/landing-page-config/landing-page-config.model';
import { constants } from '../../../eClinical/shared/constant';

const DARK_THEME = 'dark-theme';
@Component({
    selector: 'app-tool-bar',
    templateUrl: './tool-bar.component.html',
    styleUrls: ['./tool-bar.component.scss']
})
export class ToolBarComponent implements OnInit {
    /* #region Fields */
    @Input() heroBanner: HeroBannerWrapper;
    @Output() public toggleGlobalError = new EventEmitter<string>();
    @Output() public openDrawer = new EventEmitter();
    @Output() public ssChanged = new EventEmitter();
    @Output() public onLogout = new EventEmitter();
    @ViewChild('toolbarMenuLanguage', { read: MatMenuTrigger })
    toolbarMenuLanguage: MatMenuTrigger;
    @ViewChild('toolbarMenuMore', { read: MatMenuTrigger })
    toolbarMenuMore: MatMenuTrigger;
    public selectedLanguage = { Name: 'en' };
    public languages: Language[];
    isNotFAQ = true;
    public puid: string;
    public showLogo = true;
    public title = '';
    public subTitle = '';
    public labSite: string;
    public environmentName: string;
    public isDarkTheme = false;
    public preferredSize = 'default';
    private readonly elem: any = document.documentElement;
    public breadCrumbObs$: Observable<any>;
    public param = '';
    public subTitleRoute: string;
    protected notifications: any;
    mobileDrawerWidth = 250;
    ss_external_id: string;
    userDetails: any;
    userInitial: string;
    userName: string;
    sampling_stations: any[] = [];
    sampling_station_companies: any[] = [];
    sampling_station_List: [];
    userLoggedIn: boolean;
    private notificationsSliderContainer: any;
    readonly breakpoint$ = this.breakpointObserver
        .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
        .pipe(
            tap(value => console.log(value)),
            distinctUntilChanged()
        );
    protected loggedIn: boolean = false;
    private subscription: Subscription;
    public adminRole = constants.adminRole;
    public supervisorRole = constants.supervisorRole;
    public patientRole = constants.patientRole;
    public nurseRole = constants.nurseRole;
    showDiv = true;
    currentUrl: any;
    ehpPage: boolean = false;
    routerSubscription: Subscription;

    constructor(
        private commonAPi: CommonApiService,
        private readonly router: Router,
        private readonly dialog: MatDialog,
        private readonly messageService: MessageService,
        protected readonly translateService: TranslateService,
        private readonly breadCrumbSvc: BreadcrumbService,
        public loader: LoaderService,
        public localeService: LocaleService,
        private patientService: PatientService,
        private componentFactoryResolver: ComponentFactoryResolver,
        private breakpointObserver: BreakpointObserver,
    ) {
        this.breakpoint$.subscribe(() =>
            this.breakpointChanged()
        );
    }

    /* #region Public Methods */
    public ngOnInit(): void {

        if (this.router.url == '/patient/faq') {
            this.isNotFAQ = false;
        }
        // Subscribe to router events to listen for URL changes
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Update the currentUrl with the new route URL
                this.currentUrl = event.urlAfterRedirects;
                this.currentUrl.split('/')[1] == constants.ehp_page_type ? this.ehpPage = true : this.ehpPage = false;

            }
        });
        this.setTheme();
        this.loader.getLanguageInfo();
        this.loader.subjectClose.subscribe(() => {
            this.patientService.getNotification().subscribe(res => {
                this.notifications = res;
            });
        });
        this.loader.subjectLogin.subscribe(() => {
            this.patientService.getNotification().subscribe(res => {
                this.notifications = res;
                for (let i = 0; i < this.notifications.length; i++) {
                    if (this.notifications[i].NotificationType.split('|')[0] == 'MISSING_DOCUMENT_NOTIFICATION' || this.notifications[i].NotificationType.split('|')[0] == 'EXPIRED_DOCUMENT_NOTIFICATION') {
                        this.loader.docReq = true;
                        return;
                    }
                }
            });
        });
        const manulaLang = localStorage.getItem('locale');
        if (this.loader.userRole === constants.nurseRole) {
            if (document.getElementById('manual')) {
                manulaLang == 'fr'
                    ? document
                        .getElementById('manual')
                        .setAttribute(
                            'href',
                            './assets/user-manual/e-Clinical_Help_Manual_French.pdf'
                        )
                    : document
                        .getElementById('manual')
                        .setAttribute(
                            'href',
                            './assets/user-manual/e-Clinical_Help_manual_English.pdf'
                        );
            }
        } else if (this.loader.userRole === constants.patientRole) {
            this.patientService.getNotification().subscribe(res => {
                this.notifications = res;
            });
            if (document.getElementById('manual')) {
                document
                    .getElementById('manual')
                    .setAttribute('href', './assets/user-manual/PatientManualEnglish.pptx');
            }
        }
        this.breadCrumbObs$ = this.breadCrumbSvc.breadCrumbObs$;
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => {
                if (this.router.url === '/') {
                    this.subTitle = '';
                }
                this.disposeOverlayOnNavigation();
            });
        const access_token: any = JSON.parse(
            sessionStorage.getItem('access_token')
        );

        this.userDetails =
            this.loader.userRole === constants.patientRole
                ? JSON.parse(sessionStorage.getItem('userDetails'))
                : access_token;
        this.userInitial = this.userDetails?.first_name
            ? this.userDetails?.first_name?.charAt(0).toUpperCase()
            : this.userDetails?.user_name?.charAt(0).toUpperCase() || 'G';
        this.userName = this.userDetails?.first_name
            ? this.userDetails?.first_name
            : this.userDetails?.user_name || 'Guest';

        this.subscription = this.loader.loggedInStatus$.subscribe(
            (status: boolean) => {
                this.loggedIn = status;
                if (this.loader.userRole === constants.patientRole) {
                    this.patientService.getNotification().subscribe(res => {
                        this.notifications = res;
                        for (let i = 0; i < this.notifications.length; i++) {
                            if (this.notifications[i].NotificationType.split('|')[0] == 'MISSING_DOCUMENT_NOTIFICATION' || this.notifications[i].NotificationType.split('|')[0] == 'EXPIRED_DOCUMENT_NOTIFICATION') {
                                this.loader.docReq = true;
                                return;
                            }
                        }
                    });
                } else {
                    this.getSSList();
                }
                // Update controls visibility based on the login status
            }
        );

    }

    protected hideFindaLab() {
        return this.router.url == '/patient/book-appointment/0'
    }

    protected hideBookAppointment() {
        return this.router.url == '/patient/book-appointment'
    }

    protected hideToolbar() {
        return this.router.url.includes('/book-appointment') || this.router.url.includes('/complete-appointment') || this.router.url.includes('/favourite-lab/add');
    }

    protected hidehamburger() {
        return this.router.url.includes('/patient/book-appointment');
    }

    private getSSList() {
        if (this.loader.userRole > 0) {
            this.commonAPi.GetSamplingStation(0).subscribe(
                (data: any) => {
                    const sampling_station = JSON.stringify(data);
                    sessionStorage.setItem('sampling_stations', sampling_station);
                    this.sampling_station_companies = JSON.parse(sampling_station).sampling_stations.filter(
                        (thing: any, i: int, arr: any[]) => arr.findIndex(t => t.org_id === thing.org_id) === i
                    );
                    this.sampling_stations = JSON.parse(sampling_station).sampling_stations.sort();
                    this.sampling_stations = JSON.parse(sampling_station).sampling_stations.filter((s: any) => s.org_id == this.sampling_station_companies[0].org_id);
                    this.loader.sampling_companies_list = this.sampling_station_companies;
                    this.loader.sampling_stations_list = this.sampling_stations;
                    this.loader.sampling.setValue(this.sampling_stations[0].id);
                    this.loader.samplingcompany.setValue(this.sampling_stations[0].org_id);
                    this.loader.ss_id = this.sampling_stations[0].id;
                    this.loader.ss_selected_org_id = this.sampling_stations[0].org_id;
                    this.loader.ss_external_id = this.sampling_stations[0].external_id;
                    this.ss_external_id = this.sampling_stations[0].external_id;
                    this.loader.sampling_stations_list = this.sampling_stations;
                    if (this.loader.recRedirection?.org_id && this.loader.recRedirection?.ss_id) {
                        this.getSSandComp(this.loader.recRedirection.ss_id, this.loader.recRedirection.org_id)
                    }
                },
                (error: any) => error
            );
        }
    }

    private getSSandComp(ss_id: any, org_id: any) {
        var sampling_station = JSON.parse(sessionStorage.getItem('sampling_stations'));
        const ss_id_index = sampling_station.sampling_stations.findIndex((item: any) => item.id == ss_id);
        const org_id_index = sampling_station.sampling_stations.findIndex((item: any) => item.org_id == org_id);
        if (ss_id_index > -1 && org_id_index > -1) {
            this.sampling_stations = sampling_station.sampling_stations.sort();
            this.sampling_stations = sampling_station.sampling_stations.filter((s: any) => s.org_id == org_id);
            const id_index = this.sampling_stations.findIndex((item: any) => item.id == ss_id);
            this.loader.sampling_companies_list = this.sampling_station_companies;
            this.loader.sampling_stations_list = this.sampling_stations;
            this.loader.sampling.setValue(this.sampling_stations[id_index].id);
            this.loader.samplingcompany.setValue(this.sampling_stations[id_index].org_id);
            this.loader.ss_id = this.sampling_stations[id_index].id;
            this.loader.ss_selected_org_id = this.sampling_stations[id_index].org_id;
            this.loader.ss_external_id = this.sampling_stations[id_index].external_id;
            this.ss_external_id = this.sampling_stations[id_index].external_id;
            this.loader.sampling_stations_list = this.sampling_stations;
            if (this.loader.recRedirection.action == 'appointments'
                || this.loader.recRedirection.action == 'appointment-edit'
                || this.loader.recRedirection.action == 'prescription') {
                this.loader.highlightLeftMenu(['receptionist/appointments']);
            } else if (this.loader.recRedirection.action == 'patient-info') {
                this.loader.highlightLeftMenu(['receptionist/find-patient']);
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private breakpointChanged() {
        if (this.breakpointObserver.isMatched('(max-width: 1050px) and (min-width: 601px)')) {
            this.notificationsSliderContainer = 500;
        } else if (this.breakpointObserver.isMatched('(max-width: 600px)')) {
            this.notificationsSliderContainer = '100%';
        } else {
            this.notificationsSliderContainer = 500;
        }
    }

    getLoggedInUserData() {
        const access_token: any = JSON.parse(
            sessionStorage.getItem('access_token')
        );
        this.userDetails =
            this.loader.userRole === constants.patientRole
                ? JSON.parse(sessionStorage.getItem('userDetails'))
                : access_token;
        this.userInitial = this.userDetails?.first_name
            ? this.userDetails?.first_name?.charAt(0).toUpperCase()
            : this.userDetails?.user_name?.charAt(0).toUpperCase() || 'G';
        this.userName = this.userDetails?.first_name
            ? this.userDetails?.first_name
            : this.userDetails?.user_name || 'Guest';
        if (this.userDetails?.email || this.userDetails?.user_name) {
            this.userLoggedIn = true;
        }
        return this.userDetails?.email;
    }

    /** This method is being used to redirect the application to specific language  */
    public onLanguageMenuClick(language: Language): void {
        const path = window.location.pathname.slice(
            0,
            window.location.pathname.lastIndexOf('/')
        );
        const pathWithoutLocale = path.slice(0, path.lastIndexOf('/'));
        window.location.href = `${window.location.origin + pathWithoutLocale}/${language.Locale
            }/`;
    }

    /** This method is being used to toggle display of Logo */
    public hideLogo(show: boolean): void {
        this.showLogo = show;
    }
    redirectBooking() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(['patient/book-appointment']);
    }

    redirectGuestBooking() {
        if (this.loader.userRole == constants.nurseRole) {
            this.redirectGuestBookingFlow();
        } else {
            this.loader.isPreLoginFLow = true;
            this.showDiv = false;
            this.loader.highlightLeftMenu(['patient/book-appointment']);
        }
    }

    redirectGuestResults() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(['/auth/result']);
    }

    redirectResults() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(['patient/results']);
    }

    redirectFindALab() {
        this.loader.isPreLoginFLow = true;
        this.loader.highlightLeftMenu(['patient/book-appointment/0']);
    }

    redirectGuestBookingFlow() {
        this.loader.bookingPatientId = '';
        this.loader.bookingPatientName = this.translateService.instant('patient-info.guest');
        this.loader.user_name = '';
        this.loader.dob = '';
        this.loader.highlightLeftMenu([
            '/receptionist/book-appointment/' + this.loader.ss_external_id
        ]);

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    }

    /** This method is being used to open About Us pop up  */
    public onAboutUsMenuOpen(): void {
        setTimeout(() => {
            this.dialog.open(AboutUsComponent, {
                width: '650px',
                height: '300px',
                autoFocus: false,
                disableClose: true,
                panelClass: 'about-us-menu'
            });
        }, 525);
    }

    /** Event for eurofins logo click */
    public onLogoClick(): void {
        this.showDiv = true;
        if (this.loader.isSliderEdited()) {
            this.messageService
                .confirm(
                    this.translateService.instant(
                        'Common.GlobalMessages.UnSaveDataConfirmText'
                    ),
                    this.translateService.instant(
                        'Common.GlobalMessages.UnSaveDataConfirmTitle'
                    ),
                    this.translateService.instant('Common.Button.Yes'),
                    this.translateService.instant('Common.Button.No')
                )
                .subscribe((actionResult) => {
                    if (actionResult) {
                        this.loader.openSlider();
                    } else {
                        this.loader.cancelForm();
                        this.loader.highlightLeftMenu(['/']);
                    }
                });
        } else {
            if (this.loader.loggedIn) {
                switch (this.loader.userRole) {
                    case 1:
                        this.loader.highlightLeftMenu(['receptionist/find-patient']);
                        break;
                    case 3:
                        this.loader.highlightLeftMenu(['admin/user']);
                        break;
                    case 4:
                        this.loader.highlightLeftMenu(['patient/dashboard']);
                        break;
                    case 5:
                        this.loader.highlightLeftMenu(['admin/user']);
                        break;
                }
            } else {
                this.loader.highlightLeftMenu(['/']);
            }
        }
        setTimeout(() => this.localeService.setCaptchalang(), 200);
    }

    private setTheme(): void {
        if (
            localStorage.getItem('theme') &&
            localStorage.getItem('theme') === 'dark'
        ) {
            document.body.classList.add(DARK_THEME);
            this.isDarkTheme = true;
        }
        const sizing = localStorage.getItem('sizing');
        if (sizing) {
            document.body.classList.add(sizing);
            this.preferredSize = sizing;
        }
    }

    /* This method is being used to emit the toggle value */
    public onToggleThemeChange($event: MatSlideToggleChange): void {
        if ($event.checked) {
            document.body.classList.add(DARK_THEME);
            localStorage.setItem('theme', 'dark');
        } else {
            document.body.classList.remove(DARK_THEME);
            localStorage.removeItem('theme');
        }
    }

    public onChangeSize(size: string): void {
        this.preferredSize = size;
        document.body.classList.remove('comfortable', 'compact');
        localStorage.removeItem('sizing');
        switch (size) {
            case 'comfortable':
                document.body.classList.add('comfortable');
                localStorage.setItem('sizing', 'comfortable');
                break;
            case 'compact':
                document.body.classList.add('compact');
                localStorage.setItem('sizing', 'compact');
                break;
            default:
                break;
        }
    }

    /* Method to open separate browser window to show help / user guide */
    public openHelp(): void {
        window.open(
            './assets/user-manual/e-Clinical_Help_manual_English.docx',
            '_blank',
            'location=yes,scrollbars=yes,status=yes,width=800'
        );
    }

    /* Method to display web page in Full screen mode */
    public displayFullScreen(): void {
        this.elem.requestFullscreen();
    }
    /* #endregion */

    /** This method is used to close mat-menu when navigation happens throught browser back button */
    private disposeOverlayOnNavigation(): void {
        if (
            this.toolbarMenuLanguage !== undefined &&
            this.toolbarMenuMore !== undefined
        ) {
            this.toolbarMenuLanguage.closeMenu();
            this.toolbarMenuMore.closeMenu();
        }
    }

    changeLocale(locale: string) {
        this.translateService.use(locale);
        this.localeService.setLocale(locale);
        this.loader.onLangChange();
        if (this.loader.userRole === constants.nurseRole) {
            if (document.getElementById('manual')) {
                locale == 'fr'
                    ? document
                        .getElementById('manual')
                        .setAttribute(
                            'href',
                            './assets/user-manual/e-Clinical_Help_Manual_French.pdf'
                        )
                    : document
                        .getElementById('manual')
                        .setAttribute(
                            'href',
                            './assets/user-manual/e-Clinical_Help_manual_English.pdf'
                        );
            }

        } else if (this.loader.userRole === constants.patientRole) {
            document
                .getElementById('manual')
                .setAttribute('href', './assets/user-manual/PatientManualEnglish.pptx');
        } else {
            document
                .getElementById('manual')
                .setAttribute('href', './assets/user-manual/index.html');
        }
    }

    loadSamplingStations(data: any) {
        this.sampling_stations = JSON.parse(sessionStorage.getItem('sampling_stations')).sampling_stations.filter((s: any) => s.org_id == data);
        this.loader.sampling_stations_list = this.sampling_stations;
        this.loader.sampling.setValue(this.sampling_stations[0].id);
        this.loader.ss_id = this.sampling_stations[0].id;
        this.loader.ss_selected_org_id = this.sampling_stations[0].org_id;
        this.loader.ss_external_id = this.sampling_stations[0].external_id;
        this.ss_external_id = this.sampling_stations[0].external_id;
        if (this.router.url.includes('book-appointment')) {
            this.redirectGuestBookingFlow()
        }

        if (this.sampling_stations.length > 0) {
            this.getSampling(this.loader.ss_id);
        }
    }

    getSampling(data: any) {
        this.loader.ss_id = data;
        this.loader.onSSChange();
        const selectedindex = this.sampling_stations.findIndex((s) => s.id == data);
        this.loader.ss_external_id =
            this.sampling_stations[selectedindex].external_id;
        this.ss_external_id = this.sampling_stations[selectedindex].external_id;
        if (this.router.url.includes('book-appointment')) {
            this.redirectGuestBookingFlow()
        } else {
            this.ssChanged.emit(true);
        }
    }

    onLogOut() {
        this.messageService
            .confirm(
                this.translateService.instant('toolbar.title-logout'),
                this.translateService.instant('toolbar.sign-out'),
                this.translateService.instant('Common.Button.Yes'),
                this.translateService.instant('Common.Button.No')
            )
            .subscribe((actionResult) => {
                if (actionResult) {
                    this.loader.Logout();
                    this.onLogout.emit();
                }
            });
    }

    public openFaq(): void {
        this.loader.isPreLoginFLow = true;
        if (this.loader.userRole == constants.adminRole) {
            this.router.navigate(['admin/faq']);
        } else if (this.loader.userRole == constants.patientRole) {
            this.router.navigate(['patient/faq']);
        } else if (this.loader.userRole == constants.nurseRole) {
            this.router.navigate(['receptionist/faq']);
        } else if (this.loader.userRole == constants.supervisorRole) {
            this.router.navigate(['admin/faq']);
        } else {
            this.router.navigate(['patient/faq']);
        }
    }

    protected openNotificatios() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationsComponent);
        this.loader.addDynamicComponent(componentFactory, this.notificationsSliderContainer, this.notifications);
    }

    protected markNotificationRead(notification: any) {
        this.patientService.putNotification(notification.Id.toString()).subscribe(
            () => {
                this.patientService.getNotification().subscribe(res => {
                    this.notifications = res;
                });
            }
        );
    }

    protected routeLink(notification: any) {
        switch (notification.NotificationType.split('|')[0]) {
            case 'PASSWORD_EXPIRE_NOTIFICATION': {
                this.loader.highlightLeftMenu(["patient/info"]);
                break;
            }

            case 'EXPIRED_DOCUMENT_NOTIFICATION': {
                this.loader.highlightLeftMenu(["patient/documents"]);
                break;
            }

            case 'MISSING_DOCUMENT_NOTIFICATION': {
                this.loader.highlightLeftMenu(["patient/documents"]);
                break;
            }
        }
    }

    protected getUnreadNotification(): number {
        return this.notifications ? (this.notifications.filter((n: any) => !n.IsRead)).length : 0;
    }
}
