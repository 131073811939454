<div class="container">
    <div class="d-flex justify-content-between heading-box">
        <div class="d-flex align-items-center">
            <span class="heading">Establishment</span>
        </div>
    
        <div class="d-flex right-box">
            <div id="statusfilter" class="status-filter">
                <mat-form-field floatLabel="never">
                    <mat-select placeholder="Status" [(ngModel)]="status">
                        <mat-option *ngFor="let stepOption of sampleData | keyvalue: unsorted" (click)="statusFilter()"
                            [value]="stepOption.key">
                            {{stepOption.value}}
                        </mat-option>
    
                    </mat-select>
                </mat-form-field>
            </div>

            <div id="inputsearch">
                <mat-form-field>
                    <mat-label>Search</mat-label>
                    <span class="icon-ic-Search mat-icon" matSuffix></span>
                    <input [(ngModel)]="searchinput" matInput (keydown.enter)="onSearch()" (keydown.space)="keyDownHandler($event)" />
                </mat-form-field>
            </div>

            <div class="divider"></div>
            
            <div class="mx-4">
                <button mat-raised-button color="primary" id="btn_add" (click)="addEstablishment()" class="add-hospital">
                    + Add Establishment
                </button>
            </div>
        </div>
    
    </div>
    <div class="main-container main-container-res">
        <table mat-table [dataSource]="dataSource" class="table-height input-min-spacing table-flex table-with-rounded-corners">
      
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> ID </th>
            <td mat-cell *matCellDef="let element"> {{ element.external_code }} </td>
          </ng-container>
      
          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{ element.name }} </td>
          </ng-container>
      
          <!-- Departments Column -->
          <ng-container matColumnDef="departments">
            <th mat-header-cell *matHeaderCellDef> Departments </th>
            <td mat-cell *matCellDef="let element">
                <div class="d-flex flex-column">
                    <span>{{ getDepartmentNames(element.departments).firstDepartment }}</span>
                    <ng-container *ngIf="getDepartmentNames(element.departments).additionalCount > 0">
                        <span class="view-more" [matMenuTriggerFor]="menu">
                            +{{ getDepartmentNames(element.departments).additionalCount }} more
                        </span>
                    </ng-container>
                </div>
            
            
                <!-- Mat Menu for the additional departments -->
                <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngFor="let dept of element.departments.slice(1)">
                        {{ dept.name }}
                    </button>
                </mat-menu>
            </td>
            </ng-container>
          
      
          <!-- Address Column -->
          <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let element"> {{ element.address || 'N/A' }} </td>
          </ng-container>
      
          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
                <span [ngClass]="{
                        'active': element.is_active,
                        'inactive': !element.is_active
                      }">
                  {{ element.is_active ? 'Active' : 'Suspended' }}
                </span>
              </td>              
          </ng-container>
      
          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element">
                <button class="mat-tbl-icon"  (click)="editEstablishment(element)" mat-icon-button>
                    <span class="icon-ic-edit" matTooltip="edit"></span>
                </button>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="columnList"></tr>
        <tr mat-row *matRowDef="let row; columns: columnList;"></tr>
      </table>
      </div>
      
    

  </div>