export class GetAuthLoginRequest {
    username: string;
    password: string;
    device_detail: string;
    from_appointment: boolean;
    mode: string;
    user_type?: string;
}
export class GetAuthLoginRequestStatus {
    username: string;
    password: string;
    device_detail: string;
    from_appointment: boolean;
    mode: string;
    message_id: string;
    unique_id: string;
}

export class GetAuthOtpRequest {
    username: string;
    password: string;
    otp: string;
    _flow_id: string;
    device_detail: string;
    mode?:string;
    user_type: string;
}
export class SaltRequest {
    user_name: string;
    id: string;
}
