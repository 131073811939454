import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardService } from './main/services/guard/guard.service';

const routes: Routes = [
    { path: 'seed', loadChildren: () => import('./seed/seed.module').then(m => m.SeedModule) },
    { path: 'auth', canActivateChild: [GuardService], loadChildren: () => import('./eClinical/auth/auth.module').then(m => m.AuthModule) },
    { path: 'ehp', canActivateChild: [GuardService], loadChildren: () => import('./eClinical/ehp/ehp.module').then(m => m.EhpModule) },
    { path: 'admin', canActivateChild: [GuardService], loadChildren: () => import('./eClinical/admin/admin.module').then(m => m.AdminModule) },
    { path: 'patient', canActivateChild: [GuardService], loadChildren: () => import('./eClinical/patient/patient.module').then(m => m.PatientModule) },
    { path: 'receptionist', canActivateChild: [GuardService], loadChildren: () => import('./eClinical/receptionist/receptionist.module').then(m => m.ReceptionistModule) },
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingRoutingModule { }
