<div>
    <div class="pull-right">
        <button class="close" mat-dialog-close mat-button id="btn_close_dialog"><span
                class="icon-ic-close"></span></button>
    </div>
    <div class="unavailabilty-container" [ngClass]="{'unavailabilty-container-flex': uiModeforSentOtp ==1}">


        <div class="lock-svg" *ngIf="step==1"> <img src="assets/images/application-logos/svg/ic-otp-user.svg"
                alt="pres-no">
        </div>
        <div class="lock-svg" *ngIf="step !=1"> <img src="assets/images/application-logos/svg/ic-otp-lock.svg"
                alt="pres-no">
        </div>

        <div class="sub-container" *ngIf="uiModeforSentOtp !=1">
            <div class="popup-heading">
                {{step===1?('verify-my-account.title'| translate):('verify-my-account.title-otp'| translate)}}
                <!-- <app-section-title title="{{title | translate}}"></app-section-title> -->
            </div>
            <div style="text-align: center;">
                <form #intitalForm="ngForm" novalidate *ngIf="step==1" >
                    <ng-container>
                        <div class="emailonly" *ngIf="!onlyPhoneNo">
                            <ng-container>
                                <div>
                                    <mat-hint class="subtext-hint">{{ selectedAccount.email ?
                                        ('verify-my-account.description_mail-top' |
                                        translate):('verify-my-account.description_ph-top' |
                                        translate)
                                        }}</mat-hint>
                                </div>
    
                                <mat-hint class="subtext-hint">
                                    {{ selectedAccount.email ?
                                    ('verify-my-account.description_mail-mid' |
                                    translate):('verify-my-account.otp-hint-initial-phone' |
                                    translate)
                                    }}
                                </mat-hint>
                            </ng-container>
                            <!-- //verify email address suspended account -->
                            <mat-radio-group [formControl]="selectedAccountId" name="dsd"
                                (change)="accountSelectionChange()" required>
                                <div *ngIf="lengthOfData">
                                    <span class="email-phone" *ngFor="let da of data?.dynamicData">{{ da.email ? da.email :
                                        da.phone_number
                                        }}</span>
    
    
                                </div>
                                <div *ngIf="!lengthOfData">
                                    <mat-radio-button *ngFor="let da of data?.dynamicData" name="{{da.id}}" id="{{da.id}}"
                                        value="{{da.id}}"> {{ da.email ? da.email : da.phone_number }}
                                    </mat-radio-button>
                                </div>
                            </mat-radio-group>
    
                            <div class="actions" >
                                <button mat-raised-button color="primary" class="button_width" type="submit" id="save_btn"
                                    (click)="sendOTP()">
                                    {{'verify-my-account.btn-submit'|translate}}
                                </button>
                            </div>
                        </div>
                        <!--  -->
                        <div class="onlyPhoneNo form-alignment" *ngIf="onlyPhoneNo">
                            <ng-container>
                                <div>
                                    <mat-hint class="subtext-hint">
                                        {{ 'verify-my-account.text-for-account-with-phone-only' |translate }}
                                    </mat-hint>
                                    <span class="email-phone" *ngFor="let da of data?.dynamicData">{{da.phone_number}}</span>
                                    <mat-hint class="subtext-hint">
                                        {{ 'verify-my-account.text-for-account-after-phone-no' |translate }}
                                    </mat-hint>
                                </div>
    
                            </ng-container>
                            <mat-radio-group [formControl]="selectedAccountId" name="dsd"
                                (change)="accountSelectionChange()" required>                                
                            </mat-radio-group>
                            <mat-hint class="subtext-hint imp-msg"  >
                                {{'verify-my-account.account-with-phone-only' |  translate}}
                            </mat-hint>
                            <div class="w-app text-for-whatsapp" >
                                <button mat-button type="submit" class="border-whatsapp" *ngIf="!disableWhatsapp"
                                (click)="sendOTP('whatsapp')">
                                  <span class="icon-ic-whatsapp whatsapp-btn btn-alignment"></span> {{'OTP-msg.Whatsapp-btn'|
                                  translate}}
                                </button>
                                <button mat-button type="submit" class="border-msg " *ngIf="!disableSMS" (click)="sendOTP('sms')">
                                  <span class="icon-ic-sms sms-btn btn-alignment"></span>{{'OTP-msg.sms-btn'| translate}}</button>
                              </div>
                        </div>
                        
                        
                    </ng-container>
                </form>
                <form #finalForm="ngForm" novalidate *ngIf="step==2">
                    <div id="otpinner_container">
                        <!-- add phone to get otp -->
                        <mat-hint class="subtext-hint" *ngIf="!onlyPhoneNo">
                            {{ 'verify-my-account.otp-hint-initial-email' | translate }}
                         
                         
                            <!-- <span class="email-phone" *ngIf="phoneSelectedMessage">{{selectedAccount.phone_number
                                || PhoneNumber}}</span> -->
                            <!-- {{'verify-my-account.otp-hint-initial_end' | translate}} -->
                        </mat-hint>
                        <mat-hint class="subtext-hint" *ngIf="onlyPhoneNo">
                            
                            {{whatsappmessage ?('OTP-msg.otp-whatsapp' | translate):('OTP-msg.otp-sms' | translate)}}
                         
                            <span class="email-phone" *ngIf="phoneSelectedMessage">{{selectedAccount.phone_number
                                || PhoneNumber}}</span>
                            <!-- {{'verify-my-account.otp-hint-initial_end' | translate}} -->
                        </mat-hint>
                       
                        <mat-hint *ngIf="!phoneSelectedMessage" class="email-phone">
                            {{
                            selectedAccount.email
                            }}
                            <!-- {{'verify-my-account.otp-hint-initial_end' | translate}} -->
                        </mat-hint>
                        <!-- <br> -->
                        <div>
                            <ng-otp-input class="otp" #ngOtpInput (onInputChange)="onOtpChange($event)"
                                [config]="config"></ng-otp-input>
                            <div class="otp-counter">
                                <span class="stopwatch-timer">{{'verify-my-account.otp-time-remaining' | translate}} :
                                    {{display}}</span>
                                <!-- <button [disabled]="btndisabled" color="primary" type="submit" class="btn-login"
                                (click)="sendOTP('sms')">
                                {{'verify-my-account.otp-resend' | translate}} </button> -->

                            </div>
                            <div class="otp-buttons">
                                <button mat-raised-button color="primary" type="submit" class="btn-login"
                                    (click)="sendOTP()" *ngIf="!btndisabled">
                                    {{'verify-my-account.otp-resend' | translate}}</button>
                                <button mat-raised-button color="primary" type="submit" class="btn-login"
                                    (click)="verifyOTP()" *ngIf="btndisabled">
                                    {{'verify-my-account.otp-verify'|translate}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </div>
        <div id="otp_container" *ngIf="uiModeforSentOtp ==1">
            
        <div id="otpinner_container">
            <h3 class="otp-header headinginCenter">{{'OTP-msg.heading-option-top'| translate}}</h3>
            <h2 class="mesg">{{'OTP-msg.heading-alloption'| translate}}</h2>
            <br>
            <div class="option-btn ">
                <button mat-button type="submit" class="border-mail" (click)="sendOTP('mail')"
                    *ngIf="!phoneSelected && (otpClickCount<1)|| !disableEmail">
                    <span class="icon-ic-mailcircle mail-btn btn-alignment"></span>{{'OTP-msg.mail-btn'| translate}}
                </button>
                <button mat-button type="submit" class="border-whatsapp" (click)="sendOTP('whatsapp')"
                    *ngIf="!disableWhatsapp">
                    <span class="icon-ic-whatsapp whatsapp-btn btn-alignment"></span> {{'OTP-msg.Whatsapp-btn'|
                    translate}}
                </button>
                <button mat-button type="submit" class="border-msg " (click)="sendOTP('sms')" *ngIf="!disableSms">
                    <span class="icon-ic-sms sms-btn btn-alignment"></span> {{'OTP-msg.sms-btn'| translate}}</button>
            </div>
        </div>
    </div>

        <mat-hint class="important-msg" *ngIf="step==1">{{ selectedAccount.email ?
            ('verify-my-account.description_mail-bottom' |
            translate):('verify-my-account.description_ph-bottom' |
            translate)
            }}</mat-hint>
    </div>
</div>