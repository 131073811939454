<div class="upload-pic-container" [ngClass]="previewMode==true ? 'preview-pic':''">
    <div class="formCointainer" *ngIf="!previewMode">
        <form [formGroup]="form" #formReference>
            <div class="form-layout-container">
                <div *ngIf="(cameraMode ==0 ||  cameraMode ==1) && document?.PrescriptionParts?.length<maxFile">
                    <p class="upload-header only-mobile">
                        {{'upload-pic.click-picture' | translate}}
                    </p>
                </div>
                <div class="drag-drop-container">
                    <div class="pointCursor dropzone only-web" appFileDrop
                        (filesDropped)="handleFilteredFileInput($event)" (filesHovered)="dropzoneState($event)"
                        [class.hovering]="isHovering" #dropZone>
                        <div class="dropzone-text">
                            <span class="dropzone-main-text">{{'upload-pic.drop-file' | translate}}</span>
                            <span *ngIf="isValidPrescriptionFile && prescription" class="dropzone-sub-text ">
                                <div class="tophglt">{{'upload-pic.upload-file-size-top' | translate}}</div>
                                <div>{{'upload-pic.upload-file-size-btm' | translate}}</div>
                            </span>
                            <span *ngIf="isValidPrescriptionFile && !prescription"
                                class="dropzone-sub-text">{{'upload-pic.upload-file-size-600' | translate}}</span>
                            <mat-hint *ngIf="!isValidPrescriptionFile && prescription"
                                class="dropzone-sub-text-error mar-r-l"><span
                                    class="tophglt">{{'upload-pic.upload-file-size-top' | translate}}</span>
                                <div class="mar-r-l">{{'upload-pic.upload-file-size-btm' | translate}}</div>
                            </mat-hint>
                            <mat-hint *ngIf="!isValidPrescriptionFile && !prescription"
                                class="dropzone-sub-text-error">{{'upload-pic.upload-file-size-600' |
                                translate}}</mat-hint>
                        </div>

                        <div class="imagePreviewContainer d-flex">
                            <div class="imageCards  mr-15" [ngClass]="{'mt-15':presPart?.type=='pdf'}"
                                *ngFor="let presPart of document.PrescriptionParts; let indexOfelement=index;">
                                <b *ngIf="presPart?.type!='pdf'" style="margin-top: 5px;"
                                    class="d-flex">{{indexOfelement == 0 ?
                                    ('upload-pic.front-text' | translate) :
                                    ('upload-pic.back-text' | translate)}}</b>
                                <div class="imagePreviewCard">
                                    <ng-container>
                                        <img class="previewImg" *ngIf="presPart?.type!='pdf'"
                                            (click)="preview(presPart?.prescriptionPartData)"
                                            src="{{presPart.prescriptionPartData}}"
                                            alt="{{presPart.PrescriptionPartName}}" />

                                        <span class="icon-ic-pdf capture-image-icon no-cursor"
                                            *ngIf="presPart?.type=='pdf'"></span>

                                        <span (click)="removeUploadedFileLocal(indexOfelement)"
                                            class="icon-ic-delete color-error deleteIcon transcol"></span>
                                    </ng-container>
                                    <p class="imageFileName" matTooltip="{{presPart.PrescriptionPartName}}">
                                        {{presPart.PrescriptionPartName}}</p>
                                </div>

                            </div>
                            <div class="imageCards mt-20" (click)="fileInput1.click()"
                                *ngIf="document?.PrescriptionParts?.length<maxFile">
                                <div class="imagePreviewCard">
                                    <span _ngcontent-wru-c409=""
                                        class="icon-ic_upload-document capture-image-icon"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Camera click picture  -->
                    <div [disabled]="document?.PrescriptionParts?.length==2" id="openCameraBtn"
                        *ngIf="(cameraMode ==1 || cameraMode==0) && document?.PrescriptionParts?.length<maxFile"
                        (click)="fileInput2.click()" class="pointCursor dropzone only-mobile" #dropZone>
                        <div style="display: flex; flex-direction: column; align-items: center;">
                            <span class="icon-ic-click-picture capture-image-icon"></span>
                        </div>
                    </div>
                </div>

                <div *ngIf="dropZone.touched && File == null">
                    {{'upload-pic.attach' | translate}}
                </div>
                <div class="d-flex justify-content-center"
                    *ngIf="cameraMode ==0  && document?.PrescriptionParts?.length<maxFile">
                    <div class="separator">
                        <span class="separator-text"> {{'upload-pic.or' | translate}} </span>
                    </div>
                </div>
                <div class="browse-control-wrapper only-mobile">
                    <div class="browse-control-container">
                        <ng-conatiner
                            *ngIf="(cameraMode==0 || cameraMode==2)  && document?.PrescriptionParts?.length<maxFile">
                            <div>
                                <p class="upload-header only-mobile" i18n="@@Common.Component.BrowseFile">
                                    {{'upload-pic.browse-file' | translate}}</p>
                            </div>
                            <div class="pointCursor browse-input w-100">
                                <div (click)="fileInput1.click()" id="uploadFile"
                                    *ngIf="File == null && (cameraMode ==2 || cameraMode==0)" class="
                                    pointCursor dropzone only-mobile">
                                    <div style="display: flex; flex-direction: column; align-items: center;">
                                        <span class="icon-ic_upload-document capture-image-icon"></span>
                                    </div>
                                </div>
                                <button class="mat-tbl-icon pull-right remove" id="uploadRemoveFile"
                                    *ngIf="File != null" (click)="removeUploadedFile($event)" mat-icon-button>
                                    <span class="icon-ic-delete transcol"></span>
                                </button>

                            </div>
                            <mat-label
                                *ngIf="!document.IsIncorrectFileFormat && !document.IsInvalidFileSize && prescription"
                                class="note-descritpion-mob">
                                <div class="tophglt mar-r-l">{{'upload-pic.upload-file-size-top' |
                                    translate}}</div>
                                <div class="mar-r-l">{{'upload-pic.upload-file-size-btm' | translate}}</div>
                            </mat-label>
                            <mat-label
                                *ngIf="!document.IsIncorrectFileFormat && !document.IsInvalidFileSize && !prescription"
                                class="note-descritpion">{{'upload-pic.upload-file-size-600' |
                                translate}}</mat-label>
                            <mat-error
                                *ngIf="(document.IsIncorrectFileFormat || document.IsInvalidFileSize)&& prescription">{{'upload-pic.upload-file-size-btm'
                                | translate}}</mat-error>
                            <mat-error
                                *ngIf="(document.IsIncorrectFileFormat || document.IsInvalidFileSize)&& !prescription">{{'upload-pic.upload-file-size-600'
                                | translate}}</mat-error>
                        </ng-conatiner>
                        <input hidden type="file" accept="image/png, image/jpeg, image/jpg, application/pdf"
                            (click)="$event.target.value = null"
                            (change)="handleFilteredFileInput($event.target.files,true,2)" #fileInput1 />
                        <input hidden type="file" accept="image/*" id="camera" capture="enviroment"
                            (click)="$event.target.value = null"
                            (change)="handleFilteredFileInput($event.target.files,false, 1)" #fileInput2 />
                    </div>
                </div>
                <div class="imagePreviewContainer d-flex only-mobile">
                    <div class="upload-header only-mobile w-100" *ngIf="document?.PrescriptionParts?.length>0">
                        {{maxFile==2?('upload-pic.preview-image' | translate) :('upload-pic.preview-pdf' |
                        translate)}}
                    </div>

                    <div class="imageCards"
                        *ngFor="let presPart of document.PrescriptionParts; let indexOfelement=index;">

                        <div *ngIf="presPart?.type!='pdf'" class="front-back-text">{{indexOfelement == 0 ?
                            ('upload-pic.front-text' | translate) :
                            ('upload-pic.back-text' |
                            translate)}}</div>

                        <div class="imagePreviewCard">
                            <ng-container>
                                <img class="previewImg" *ngIf="presPart?.type!='pdf'"
                                    (click)="preview(presPart?.prescriptionPartData)"
                                    src="{{presPart.prescriptionPartData}}" alt="{{presPart.PrescriptionPartName}}" />
                                <span class="icon-ic-pdf capture-image-icon no-cursor"
                                    *ngIf="presPart?.type=='pdf'"></span>
                                <span (click)="removeUploadedFileLocal(indexOfelement)"
                                    class="icon-ic-delete color-error deleteIcon transcol"></span>
                            </ng-container>

                        </div>
                        <p class="imageFileName" matTooltip="{{presPart.PrescriptionPartName}}">
                            {{presPart.PrescriptionPartName}}</p>

                    </div>
                </div>
            </div>
        </form>
    </div>
</div>