<div class="dashboard-container">
  <header>
    <h3>{{'dashboard.dashboard' | translate}}</h3>
  </header>
  <div class="container">
    <div class="row top-cover">
      <div class="results-cover card11">
        <div class="header">
          <div class="left">
            <h2><span class="icon-icon-results"></span>{{'dashboard.results' | translate}}</h2>
          </div>
          <div class="right">
            <button mat-button color="accent" class="right-pad col" (click)="resultsViewAll()">{{'dashboard.viewall' |
              translate}}</button>
          </div>
        </div>
        <div class="table-container" *ngIf="allResultsdataSource.length>0">
          <app-all-results *ngIf="resultDataLoaded" [displayedColumns]="displayedColumns" [onlydashboard]="true"
            [dispSpan]="true" [dataSource]="allResultsdataSource" (showResult)="showResultData($event)">
          </app-all-results>
          <app-pdf-viewer *ngIf="isPdfViewerOpened" [pdfFileName]="resultId"
            pdftitle="{{'stepper-flow.result'|translate}}" [blobSrc]="pdfBlob" [allPages]="true"
            (closed)="isPdfViewerOpened = false"></app-pdf-viewer>
        </div>
        <div class="table-container disp-no-data" *ngIf="allResultsdataSource.length==0">
          <div class="no-data-found">
            <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
          </div>
          <div class="desc">
            <p style="padding: 8px 0px 0px;;" class="clear">{{'all-results.no-new-result'|translate}}</p>
          </div>
        </div>
      </div>

      <div class="qr-code-cover card22">
        <app-qr-code width="181" [data]=qr_id></app-qr-code>
        <footer>
          <p class="col-grey">{{f_name}} {{l_name}} <strong>{{'patient-information.born'|translate}}</strong>{{b_name}}
          </p>
          <p class="col-grey"><span>{{'dashboard.qr-id' | translate}}</span><span>{{":"+qr_id}}</span></p>
        </footer>
      </div>
      <!-- </div>
    <div class="row bottom-cover"> -->
      <div class="table-card card33">
        <div class="header">
          <div class="left">
            <h2><span class="icon-ic_upload-document"></span>{{'dashboard.upload-document-heading' | translate}}</h2>
          </div>
          <div class="right">
            <button mat-button color="accent" class="right-pad" (click)="documentViewAll()">{{'dashboard.viewall' |
              translate}}</button>
          </div>
        </div>
        <div class="table-container">
          <table class="ecl-table no-responsive scrolll" width="100%" mat-table [dataSource]="dataSourceDocument">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>{{'dashboard.document' | translate}}</th>
              <td mat-cell *matCellDef="let element">
                <span class="title" *ngIf="element?.document_type">{{element?.document_type | translate}}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">

                <th mat-header-cell *matHeaderCellDef>{{'dashboard.status' | translate}}</th>
                <td mat-cell *matCellDef="let element">
                  <span class="title" *ngIf="element?.status">
                    <span (click)="documentViewAll(element,typeOfDoc)" class="upload-status" [ngClass]="{'completed': element?.status === 'true',
                  'inprogress' : element?.status === 'false' }">
                      {{(element.status === 'true') ?( 'dashboard.uploaded' | translate) : (
                      'dashboard.upload-document' | translate)}}
                    </span>
                  </span>
                </td>
              </cdk-virtual-scroll-viewport>
            </ng-container>
            <ng-container matColumnDef="noRecord">
              <td colspan="5" mat-footer-cell *matFooterCellDef>
                <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
                <div class="desc">
                  <p>{{'dashboard.empty' | translate}}</p>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div class="action-btn" [class.stay]="more.menuOpen" *ngIf="element.status === 'true'">
                  <button class="mat-tbl-icon" mat-icon-button [matMenuTriggerFor]="docsMenu" #more="matMenuTrigger"
                    (click)="viewDocument(element)">
                    <span class="icon-ic-view-prescription"></span>
                  </button>
                </div>
                <mat-menu #docsMenu="matMenu" class="table-popover" xPosition="before">
                  <div class="arrow-top"></div>
                  <div class="main">
                    <button mat-menu-item (click)="viewDocument(element)">{{'dashboard.view-document' |
                      translate}}</button>
                    <button mat-menu-item (click)="downloadDocument(element.path)">{{'dashboard.download-document' |
                      translate}}</button>
                  </div>
                </mat-menu>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumnsUpload; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsUpload;"></tr>
            <ng-template [ngIf]="dataSource && dataSource.length === 0">
              <tr mat-footer-row *matFooterRowDef="['noRecord']"></tr>
            </ng-template>
          </table>
        </div>
      </div>
      <div class="table-card card44">
        <div class="header">
          <div class="left">
            <h2><span class="icon-icon-my-appointment"></span>{{'dashboard.upcoming-appointments' | translate}}</h2>
          </div>
          <div class="right">
            <button mat-button color="accent" class="right-pad" (click)="appointmentViewAll()">{{'dashboard.viewall' |
              translate}}</button>
          </div>
        </div>
        <div>
          <div class="scrolll2" *ngIf="availableAppointment">
            <div class="card-content card-content1 appointments ">
              <h3><span class="icon-ic-test"></span><span>{{futureAppointment?.TestShortName}}</span></h3>
              <p><span class="icon-icon-booking-calendar"></span>
                <span>{{futureAppointment?.SlotTime | dateFormat}}</span>
              </p>
              <div class="dispflex">
                <span class="icon-ic-location ic-colr" (click)="openDirections(futureAppointment)"></span>
                <div class="margen-ten">
                  <div class="colr">{{futureAppointment.SamplingStationName }}</div>
                  <div (click)="openDirections(futureAppointment)" class="link cursorpoint cursorcolor">
                    {{futureAppointment.AddressDetails}}</div>
                </div>
              </div>
              <h3 *ngIf="futureAppointment.PhoneNumber !=''">
                <a class=" icon-ic-mobile ic-colr" href="tel:{{futureAppointment.PhoneNumber | removeZero}}"></a>
                <!-- <span class="icon-ic-mobile ic-colr" *ngIf="futureAppointment.PhoneNumber !=''"></span> -->
                <a href="tel:{{futureAppointment.PhoneNumber | removeZero}}"
                  class="cursorcolor margen-ten">{{futureAppointment.PhoneNumber |
                  phonenoFormate}}</a>
              </h3>
              <ng-container
                *ngIf="futureAppointment?.IsPrescriptionRequired && futureAppointment?.PrescriptionUrl == ''">
                <p class="upload-pres"><span class="icon-ic_prescription"></span>
                  <a href="javascript:void(0)"
                    (click)="uploadPrecription(futureAppointment)">{{'dashboard.ic-prescription'
                    |
                    translate}}</a>
                </p>
              </ng-container>
            </div>

          </div>
          <div *ngIf="!availableAppointment">
            <div class="no-data-found img-height">
              <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
            </div>
            <div class="desc disp-no-data">
              <p class="clear">{{'all-results.no-visits'|translate}}</p>
              <!-- <a class="cursorcolor cursorpoint" (click)=" redirectBooking()">{{'dashboard.book-appointment'|
            translate}}</a> -->
            </div>
            <div class="mar-top cursorpoint" (click)=" redirectBooking()">
              <footer class="btncenter" [class.NotAvailableAppointment]="!availableAppointment">
                <button mat-button color="accent" class="bookappointments"
                  (click)=" redirectBooking()">{{'dashboard.book-appointment' |
                  translate}}</button>
              </footer>
            </div>
          </div>
          <div *ngIf="availableAppointment" class="mar-top cursorpoint" (click)=" redirectBooking()">
            <footer class="btncenter" [class.NotAvailableAppointment]="!availableAppointment">
              <button mat-button color="accent" class="bookappointments"
                (click)=" redirectBooking()">{{'dashboard.book-appointment' |
                translate}}</button>
            </footer>
          </div>
        </div>
      </div>
      <div class="table-card card55">
        <div class="header">
          <div class="left">
            <h2><span class="icon-icon-fav-labs"></span>{{'dashboard.favourite-laboratories' | translate}}</h2>
          </div>
          <div class="right">
            <button mat-button color="accent" class="right-pad" (click)="labViewAll()">{{'dashboard.viewall' |
              translate}}</button>
          </div>
        </div>
        <div>
          <div class=" scrolll">
            <div class="card-content" *ngFor="let lab of FAVORAITE_LAB_DATA ">
              <div class="dispflex">
                <span class="icon-ic-location ic-colr" (click)="openDirectionss(lab)"></span>
                <div class="margen-ten">
                  <div class="colr">{{lab.sampling_station_name }}</div>
                  <div (click)="openDirectionss(lab)" class="cursorcolor cursorpoint">
                    {{lab.address_details}}
                  </div>
                </div>
              </div>
              <div>
                <h3 *ngIf="lab.phone_number !=''">
                  <a class=" icon-ic-mobile ic-colr" href="tel:{{lab.phone_number | removeZero}}"></a>
                  <a href="tel:{{lab.phone_number | removeZero}}" class="cursorcolor margen-ten">{{lab.phone_number |
                    phonenoFormate}}</a>
                </h3>
              </div>
            </div>
            <div *ngIf="FAVORAITE_LAB_DATA.length==0">
              <div class="no-data-found">
                <img src="/assets/images/application-logos/svg/il-empty-screen.svg">
              </div>
              <div class="desc disp-no-data">
                <p class="clear">{{'all-results.no-fav'|translate}}</p>
                <!-- <a class="cursorcolor cursorpoint" (click)="redirectFindALab()">{{'all-results.fav-lab'|translate}}</a> -->
              </div>
            </div>
          </div>
          <div class="mar-top cursorpoint" (click)="redirectFindALab()">
            <footer class="btncenter" [class.NotAvailableAppointment]="!availableAppointment">
              <button mat-button color="accent" class="bookappointments"
                (click)="redirectFindALab()">{{'favourite-lab.fav-lab' | translate}}</button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>