import { constants, constantsarr } from '../constant';

import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnInit,
  OnDestroy,
} from "@angular/core";
import {
  FormControl,
  ValidationErrors,
  Validators,
} from "@angular/forms";

import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CalendarView } from "angular-calendar";

import { LocaleService } from "../../../app.locale.service";
import { LoaderService, MessageService } from "../../../main";
import { BookingAppointmentGuestService } from "../../services/booking-appointment-guest.service";
import { CommonApiService } from "../../services/common-api.service";
import { PatientService } from "../../services/patient.service";
import { PrescriptionDocument } from "../appointment-prescription/appointment-prescription.model";
import { DateSlot } from "../date-slot/date-slot.model";

import * as moment from "moment";
import jwtDecode from "jwt-decode";
import { LoginServiceService } from "../../services/login-service.service";
import { ToastrService } from "ngx-toastr";
import { OffsetMaster } from "../../models/common.model";
import { SpinnerService } from '../../../core';
import { environment } from '../../../../environments/environment';
declare var _paq: Array<any>;

@Component({
  selector: 'app-booking-flow',
  templateUrl: './booking-flow.component.html',
  styleUrl: './booking-flow.component.scss',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: "DD-MM-YYYY",
        },
        display: {
          dateInput: "DD-MM-YYYY",
          monthYearLabel: "MMM YYYY",
          dateA11yLabel: "LL",
          monthYearA11yLabel: "MMMM YYYY",
        },
      },
    },
  ],
})
export class BookingFlowComponent implements OnInit, OnDestroy {
  step: number = 0
  readonly constants = constants;
  readonly rconstantsarr = constantsarr;
  isConfirmed = false;
  isLoaded = false;
  clearFile = false;
  currentindex: number = 0;
  @Input() isBackEnabled: boolean;
  @Output() backClicked = new EventEmitter();
  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
  isFileUploadedOrSkip = false;
  stepconsolidate: any;
  protected showPrescription: boolean;
  protected showLoginorRegister = "";
  protected showLoginorRegisterStep: boolean;
  maxDate: any;
  minDate: any;
  public slotTime = new FormControl("");
  bookingComment: string;
  protected slotData: DateSlot;
  public isWithAppointment: boolean = true;
  public adminRole = constants.adminRole;
  public supervisorRole = constants.supervisorRole;
  public patientRole = constants.patientRole;
  public nurseRole = constants.nurseRole;
  stepperTitle = "Testing";
  public havePres: boolean = false;
  public allSamplingStation: any;
  public overallSamplingStation: any;
  public registeredEmail = new FormControl("", [
    Validators.required,
    Validators.pattern(this.patientService.emailPattern),
  ]);
  login_id: any;

  // public sendEmailForm = new FormGroup({
  //   RegisteredEmail: this.registeredEmail,
  // });

  showBooking: boolean;
  u_id = 0;
  calendar_id = 56;
  patient_id = "";
  slot_time: any = "2023-09-04T09:30:00Z";
  tests: any[] = [];
  capacity: any = 5;
  prescription_url = "";
  saveAppointmentObj = {
    calendar_id: this.calendar_id,
    patient_id: this.patient_id,
    slot_time: this.slot_time,
    tests: this.tests,
    capacity: this.capacity,
    prescription_url: this.prescription_url,
  };
  hasCalendar = false;
  noCalendar = false;
  labSelected = false;
  selectedtest: any;
  selectedLab: any;
  selectedAppointment: any;
  slotGroupId: number;
  selectedprescription: any;
  selectedWeek = "";
  favoroteSamplingStationids = new Array();
  s_id: any = 0;
  userDetails: any;
  showLogin = false;
  public showLoginPrebook = true;
  showConfirmBooking = false;
  finalStep: any = "stepper-flow.confirm-booking";
  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Week;
  locale: string = this.localeService.localeLang;
  username: any;
  password: any;
  @Output() handleNavigationNodes = new EventEmitter();
  showConfirmationLink = false;
  showCreateAccount: boolean;
  showRegister: boolean = false;
  showActivateMessage: boolean = false;
  forgetPasswordMailSent: boolean;
  showRadioButton: boolean = true;
  loginSuccessful: boolean;
  registerSuccessful: boolean;
  public authType = new FormControl("", [Validators.required]);
  forgotPassword: boolean;
  isConfirmLinkClicked: boolean;
  userExistError: any;
  ss_ext_id = "";
  ss_id = "";
  standy_ss_ext_id = "";
  check_number_val: any;
  protected test_ext_id: string = "";
  protected standy_test_id: string = "";

  protected test_ext_id_actual: string = "";
  protected preSelectedTestType: number = 0;
  protected mapeditable: boolean = true;
  protected testeditable: boolean = true;
  protected isClicked: boolean = false;
  dateValue: FormControl;
  disabledDates: any[] = [];
  protected documents: PrescriptionDocument[] = [
    {
      Id: 1,
      PrescriptionParts: [],
      IsIncorrectFileFormat: false,
      IsInvalidFileSize: false,
      Expanded: true,
    },
  ];
  protected activeDates: any[] = [];
  protected noDocuments: boolean = true;
  myFilter = (d: Date): boolean => {
    let dpd = new Date(d);
    const year = dpd.getFullYear();
    const month = String(dpd.getMonth() + 1).padStart(2, "0");
    const day = String(dpd.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    return this.activeDates.some((x) => x === dateString);
  };
  protected isFuture: boolean = false;
  protected isPastDate: boolean = false;
  protected isNonWorkingDay: boolean = false;
  protected fav_lab_id: any = 0;
  protected uploadStatus: boolean = false;
  offsets: OffsetMaster[] = [];
  isLoginReceptionist: boolean = false;
  isKioskAvailable: boolean;
  tokenNumber: string;
  appointmentTolerance: boolean;
  generictest: any;
  samplingStaions: any;
  public progressPercent: any;
  calendarPercent: number;
  slotLoaded: boolean;
  constructor(
    public patientService: PatientService,
    private messageService: MessageService,
    private commonAPi: CommonApiService,
    private translate: TranslateService,
    private localeService: LocaleService,
    private route: ActivatedRoute,
    public bookingAppointmentService: BookingAppointmentGuestService,
    public loaderService: LoaderService,
    public spinner: SpinnerService,
    private dateAdapter: DateAdapter<Date>,
    private loginService: LoginServiceService,
    public toastr: ToastrService,
    public router: Router
  ) {
    this.slotData = new DateSlot();
    const today = new Date();
    this.translate.setDefaultLang(this.localeService.localeLang);
    this.translate.use(this.localeService.localeLang);

    this.maxDate = new Date(new Date().setDate(today.getDate() + 30));
    this.minDate = today;
    this.dateAdapter.setLocale(this.localeService.localeLang);


    this.standy_ss_ext_id = this.route.snapshot.params["ss_id"];
    this.ss_ext_id = this.route.snapshot.params["l_id"];
    this.fav_lab_id = this.route.snapshot.params["fav_lab_id"];
    this.test_ext_id = this.route.snapshot.params["t_id"];
    this.standy_test_id = this.route.snapshot.params["te_id"] != undefined ? this.route.snapshot.params["te_id"] : "";
    this.test_ext_id_actual = this.test_ext_id;
    this.stepconsolidate = {
      laboratory: "",
      test: "",
      appointment: "",
      prescription: "",
      loggedIn: "",
    };

  }
  ngOnInit(): void {
    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));

    this.isLoginReceptionist = JSON.parse(sessionStorage.getItem('access_token'))?.role == constants.nurseRole;
    this.getUserOffSet();
    this.getBirthCities();
    this.loaderService.isLoginReceptionist = this.isLoginReceptionist;

  }

  updateServiceVariable() {
    // Update the variable in the service
    this.loaderService.setVariable(0); // Example: setting a random number
  }

  deleteItem(item: number) {
    const index: number = this.loaderService.flow_arr.indexOf(item);
    if (index !== -1) {
      this.loaderService.flow_arr.splice(index, 1);
    }
  }

  HideStep(stepNo: number) {
    return stepNo !== this.step ?? 'hide'
  }
  public getUserOffSet() {
    let urlparam = window.location.href.split("#/")[1]
    if (urlparam.includes('favourite-lab/add')) {
      this.loaderService.flow_detail = constants.add_fav_lab;
      this.loaderService.flow_arr = [...this.rconstantsarr.add_lab];
      this.step = constants.select_lab;

    }
    else if (this.isLoginReceptionist) {
      this.loaderService.flow_detail = constants.recep_flow;
      this.loaderService.flow_arr = [...this.rconstantsarr.recep_flow_withpresc];
      this.ss_id = this.loaderService.ss_external_id;
      this.step = constants.check_presc;
      this.isWithAppointment = true;

    } else if (urlparam.includes('test/massive')) {
      this.loaderService.flow_detail = constants.massiveflowwithtest;
      this.loaderService.flow_arr = [...this.rconstantsarr.massive_flow_withtest]
      this.isWithAppointment = true;
      this.loaderService.isMassiveTesting = true;
      this.step = constants.doc_info

    }
    else if (urlparam.includes('lab/massive')) {
      this.loaderService.flow_detail = constants.massiveflowwithlab;
      this.ss_id = this.ss_ext_id;
      this.loaderService.flow_arr = [...this.rconstantsarr.massive_flow_withlab]
      this.isWithAppointment = true;
      this.step = constants.doc_info
      this.loaderService.isMassiveTesting = true;
    }
    else if (urlparam.includes('/massive')) {
      this.loaderService.flow_detail = constants.massiveflowwithtestlab;
      this.ss_id = this.ss_ext_id;
      this.loaderService.flow_arr = [...this.rconstantsarr.massive_flow_withtestlab]
      this.isWithAppointment = true;
      this.step = constants.doc_info
      this.loaderService.isMassiveTesting = true;
    }
    else if (this.standy_ss_ext_id && this.standy_test_id == "") {
      this.loaderService.flow_detail = constants.inside_lab_flow;
      this.loaderService.flow_arr = [...this.rconstantsarr.inside_lab_withappointment];
      this.step = constants.have_request_number;
      this.ss_id = this.standy_ss_ext_id;
      this.isWithAppointment = false;
    }
    else if (this.fav_lab_id && this.standy_test_id == "") {
      this.loaderService.flow_detail = constants.favlab_flow;
      this.loaderService.flow_arr = [...this.rconstantsarr.favlab_withpresc];
      this.step = constants.doc_info;
      this.ss_id = this.fav_lab_id;
      this.isWithAppointment = true;
    }
    else if (this.standy_ss_ext_id && this.standy_test_id != "") {
      this.loaderService.flow_detail = constants.inside_lab_flow;
      this.loaderService.flow_arr = [...this.rconstantsarr.inside_lab_withoutprescwithtest];
      //this.step = constants.login;
      this.ss_id = this.standy_ss_ext_id;
      this.isWithAppointment = false;

    } else if (this.ss_ext_id && this.ss_ext_id != "0") {
      this.loaderService.flow_detail = constants.website_flow;
      this.loaderService.flow_arr = [... this.rconstantsarr.website_withoutpresc];
      this.step = constants.doc_info;
      this.isWithAppointment = true;
      this.ss_id = this.ss_ext_id;
    } else if (this.ss_ext_id == "0") {
      this.loaderService.flow_detail = constants.findlab_flow;
      this.loaderService.flow_arr = [...this.rconstantsarr.selectlab_withoutpresc]
      this.step = constants.select_lab

    }

    else {
      this.loaderService.flow_detail = constants.book_app_flow;
      this.loaderService.flow_arr = [...this.rconstantsarr.book_withoutpresc]
      this.step = constants.doc_info
    }
    this.getTestTypes();
    this.getSSDetail();
    this.localeService.locale.subscribe(() =>
      this.dateAdapter.setLocale(this.localeService.localeLang)
    );
    if (
      (this.loaderService.bookingPatientId != "" &&
        this.loaderService.bookingPatientId != null) ||
      this.loaderService.userRole == constants.patientRole
    ) {
      this.showLoginorRegisterStep = false;
    } else {
      this.showLoginorRegisterStep = true;
      if (this.loaderService.userRole == constants.nurseRole) {
        this.showRegister = true;
      }
    }
    if (!this.showLoginorRegisterStep) {
      this.deleteItem(constants.login);
    }
    this.loginService.getOffSets().subscribe((data: Array<any>) => {
      this.offsets = data;
      let currentOffsets = new Date().getTimezoneOffset()
      if (this.offsets.length > 0) {
        let index = this.offsets.findIndex(i => i.KeyName.toString() == currentOffsets.toString());
        if (index > -1) {
          this.loaderService.latitude = Number(JSON.parse(this.offsets[index].Key).lat);
          this.loaderService.longitude = Number(JSON.parse(this.offsets[index].Key).long);
          this.loaderService.zoom = Number(JSON.parse(this.offsets[index].Key).zoom);
        }
      }
    });
  }


  private getTestTypes() {
    this.spinner.show(true);
    this.bookingAppointmentService.getAdminTestTypeByExtId(this.ss_id).subscribe(
      (getResponse) => {
        const vals = [];
        for (const i in getResponse) {
          vals.push(getResponse[i]);
        }
        this.loaderService.testType = vals;
        this.spinner.show(false);
      },
      (error) => {
        console.log(error);
      }
    );
    this.getTestBySamplingStationId();

  }

  getTestBySamplingStationId() {
    this.isLoaded = false;
    this.spinner.show(true);
    this.bookingAppointmentService.getAdminTestSortedByExtId(this.ss_id).subscribe(
      (getResponse: any) => {
        this.spinner.show(false);
        this.loaderService.testArray = getResponse.filter(item => item.prescription !== true);

        this.isLoaded = true;
        if (this.standy_test_id) {
          let testInfo = getResponse.filter(item => item.external_code == this.standy_test_id);
          if (testInfo.length > 0) {
            this.selectedtest = testInfo[0];
            if (testInfo[0].prescription == true) {
              this.loaderService.flow_detail = constants.inside_lab_flow;
              this.loaderService.flow_arr = [...this.rconstantsarr.inside_lab_withprescwithtest];
              this.step = constants.upload_presc;
              this.ss_id = this.standy_ss_ext_id;
              this.isWithAppointment = false;
            }
            else {
              this.loaderService.flow_detail = constants.inside_lab_flow;
              this.loaderService.flow_arr = [...this.rconstantsarr.inside_lab_withoutprescwithtest];
              this.step = constants.login;
              this.ss_id = this.standy_ss_ext_id;
              this.isWithAppointment = false;
            }
            //this.selectedtest = testInfo;
          }
          else {
            this.messageService.alert(
              this.translate.instant(
                "backend-errors.500"),
              5000
            );
            this.router.navigate(["patient/book-appointment"]);

          }
        }
        else if (this.test_ext_id) {
          this.generictest = getResponse.filter(item => item.external_code == this.test_ext_id)[0]
          this.selectedtest = this.generictest
          if (this.s_id > 0 && this.loaderService.flow_detail == constants.massiveflowwithtestlab) {
            this.getSlots()
          }
          if (this.generictest.id > 0) {
            if (this.generictest.prescription == true && (this.loaderService.flow_detail == constants.massiveflowwithtest || this.loaderService.flow_detail == constants.massiveflowwithtestlab)) {
              let index = this.loaderService.flow_arr.findIndex(i => i == constants.select_slot);
              if (index > -1) {
                this.loaderService.flow_arr.splice(index + 1, 0, constants.upload_presc)
              }
            }
            //this.selectedtest = testInfo;
          }
          else {
            this.messageService.alert(
              this.translate.instant(
                "backend-errors.500"),
              5000
            );
            this.router.navigate(["patient/book-appointment"]);
          }
        }
        else {
          this.generictest = getResponse.filter(item => item.id == environment.genericTestID)[0]
          this.selectedtest = this.generictest;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  onSelectTest(event: any) {
    this.selectedtest = event;
    this.showPrescription = this.selectedtest.prescription ? true : false;
    const eventText = !(this.loaderService.userRole == 1 || !this.showPrescription) ? 'Test Selected with Prescription' : 'Test Selected';
    if (this.route) {
      this.stepconsolidate.test = event.short_name;
      _paq.push(['trackEvent', eventText, 'Step1 Completed', this.stepconsolidate.test]);
    }
    if (this.selectedLab?.display_map == false) {
      if (
        this.test_ext_id_actual &&
        event.external_code == this.test_ext_id_actual
      )
        this.testeditable = false;
      else this.testeditable = true;
    } else {
      if (window.location.href.split("#/")[1].includes('test/massive')) {
        this.testeditable = false;
        this.loaderService.isMassiveTesting = true;
      } else {
        this.testeditable = true;
      }
    }
    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.loaderService.action_required = false;
    if (!this.showPrescription || this.loaderService.userRole == constants.nurseRole) {
      this.deleteItem(constants.upload_presc)
    }
    if (this.userDetails) {
      this.loginSuccessful = true;
      this.showConfirmBooking = true;
    }
    if (this.loaderService.flow_detail == constants.book_app_flow) {
      this.getAllSSByTestId(event.id)
    } else if (this.isLoginReceptionist || this.loaderService.flow_detail == this.constants.massiveflowwithlab || (!this.havePres && this.isWithAppointment
      && (this.loaderService.flow_detail == constants.findlab_flow || this.loaderService.flow_detail == constants.website_flow))) {
      this.slotData = new DateSlot();
      this.getSlots()
    }
    //this.goNext();

  }

  onSelectLocation(event: any) {
    this.selectedLab = event;
    this.mapeditable = this.selectedLab?.display_map;
    this.s_id = event.id;

    this.hasCalendar = false;
    this.labSelected = false;
    this.noCalendar = false;
    this.ss_id = event.external_id;
    if (this.loaderService.flow_detail == constants.findlab_flow) {
      this.loaderService.testArray = [];
      this.loaderService.testType = [];
      this.getTestTypes();
    }
    if (this.ss_ext_id != event.external_id) {
      this.test_ext_id_actual = "";
    }

    if ((this.havePres || this.loaderService.flow_detail == constants.book_app_flow || this.loaderService.flow_detail == constants.massiveflowwithtest) && this.isWithAppointment) {
      if (this.loaderService.testArray?.length == 0) {
        this.selectedtest.id = environment.genericTestID
      }
      this.getSlots();
    }
    this.stepconsolidate.laboratory = event.sampling_station_name;
    _paq.push(['trackEvent', 'Lab Selected', 'Step1 Completed', this.stepconsolidate.laboratory]);

    //document.body.scrollTop = 0; // For Safari
    //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    //load slots in prev next step
    this.loaderService.action_required = false;
    //this.goNext();
  }
  private getSlots() {
    if (this.s_id > 0) {

      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");

      const formattedDate = `${year}-${month}-${day}`;
      this.spinner.show(true);
      this.commonAPi
        .GetSlots(this.s_id, this.selectedtest?.id, 4, formattedDate)
        .subscribe((x) => {
          this.slotData = x;
          this.spinner.show(false);
          if (this.slotData.calendar_id == 1) {
            this.slotData.slots.forEach((element: any) => {
              element.filterSlots = new Array<string>();
            });
            this.hasCalendar = true;
            this.noCalendar = false;

          } else {
            this.noCalendar = true;
            this.hasCalendar = false;
            this.calendarPercent = ((this.currentindex + 1) / this.loaderService.flow_arr.length) * 100;
            if (this.slotData?.slots?.length > 0) {
              this.slotData.slots.forEach((element: any) => {
                this.activeDates.push(
                  moment.utc(new Date(element.date)).local().format('YYYY-MM-DDTHH:mm:SS').split("T")[0]
                );
              });
              this.spinner.show(false);
            }
            else {
              this.messageService.alert(this.translate.instant("stepper-flow.internal-server-error"));
              this.spinner.show(false);
              return;
            }
          }
          this.labSelected = true;
          //document.body.scrollTop = 0; // For Safari
          //document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
          this.step = this.loaderService.flow_arr[this.currentindex]
        });
    }
  }

  onselectedFavroitestation(event: any) {
    this.favoroteSamplingStationids = event;
  }
  onSelectAppointmnt(event: any) {
    this.selectedAppointment = event.split('|')[0];
    this.slotGroupId = parseInt(event.split('|')[1]);
    this.stepconsolidate.appointment = event.split('|')[0];;
    _paq.push(['trackEvent', 'Slot Selected', 'Step3 Completed', this.stepconsolidate.appointment]);
    // document.body.scrollTop = 0; // For Safari
    // document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (userDetails) {
      this.loginSuccessful = true;
    }
    if (this.loaderService.userRole == constants.nurseRole) {
      this.fileUploaded(new Array<PrescriptionDocument>(), event);
    } else if (!this.showPrescription) {
      this.fileUploaded(new Array<PrescriptionDocument>(), event);
    }
    //this.goNext();
    this.loaderService.action_required = false;
  }

  onSelectAppointmentDate(event: any) {
    if (this.slotTime.valid && !this.isFuture && !this.isPastDate && !this.isNonWorkingDay) {
      if (this.slotTime.value === "" || this.slotTime.value === null) {
        this.slotTime.setErrors({ notExist: true } as ValidationErrors);
        return;
      }
      this.selectedAppointment = this.slotTime.value;
      this.stepconsolidate.appointment = this.slotTime.value;
      _paq.push(['trackEvent', 'Slot Selected', 'Step3 Completed', this.stepconsolidate.appointment]);
      // document.body.scrollTop = 0; // For Safari
      // document.documentElement.scrollTop = 0;
      let userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
      if (userDetails) {
        this.loginSuccessful = true;
      }
      // For Chrome, Firefox, IE and Opera
      if (this.loaderService.userRole == constants.nurseRole) {
        this.fileUploaded(new Array<PrescriptionDocument>(), event);
      } else if (!this.showPrescription) {
        this.fileUploaded(new Array<PrescriptionDocument>(), event);
      }
      this.goNext();
    }
  }
  protected onDateValueChange(e: any) {
    let dpd = new Date(e.value);
    const year = dpd.getFullYear();
    const month = String(dpd.getMonth() + 1).padStart(2, "0");
    const day = String(dpd.getDate()).padStart(2, "0");
    const dateString = `${year}-${month}-${day}`;
    if (this.isDateLessThanToday(dateString)) {
      this.isPastDate = true;
      this.isFuture = false;
      this.isNonWorkingDay = false;
    } else if (this.activeDates.includes(dateString)) {
      this.isPastDate = false;
      this.isFuture = false;
      this.isNonWorkingDay = false;
    } else {
      let isExist = this.isWithin90Days(dateString);
      if (!isExist) {
        this.isPastDate = false;
        this.isFuture = true;
        this.isNonWorkingDay = false;
      } else {
        this.isPastDate = false;
        this.isFuture = false;
        this.isNonWorkingDay = true;
      }
    }
  }

  private isDateLessThanToday(dateString: string): boolean {
    const today = new Date();
    const inputDate = new Date(dateString);
    return inputDate < today;
  }
  private isWithin90Days(dateString: string): boolean {
    const today = new Date();
    const inputDate = new Date(dateString);
    const thirtyDaysFromNow = new Date(today.setDate(today.getDate() + 90));
    return inputDate <= thirtyDaysFromNow;
  }



  fileUploaded(event: Array<PrescriptionDocument>, clickevent?: any) {

    if (this.noDocuments && !(this.loaderService.userRole == this.nurseRole || !this.showPrescription)) {
      this.uploadStatus = true;
      this.toastr.warning(this.translate.instant("upload-pic.no-prescription"));
      return
    }
    else {
      this.uploadStatus = false;
    }
    this.selectedprescription = event;

    console.log(clickevent);
    let start_time = new Date();
    if (this.slotGroupId > 0) {
      start_time = new Date(this.stepconsolidate.appointment);
    } else {
      start_time = new Date(this.stepconsolidate.appointment);
      start_time.setMinutes(start_time.getMinutes());
    }

    this.isFileUploadedOrSkip = true;
    this.showConfirmBooking = true;

    this.isConfirmed = false;
    this.showLogin = false;

    this.showConfirmBooking = true;
    this.isConfirmed = false;
    if (
      (this.loaderService.bookingPatientId &&
        this.loaderService.bookingPatientId) ||
      this.loaderService.userRole == constants.patientRole
    ) {
      this.showLoginorRegisterStep = false;
    } else {
      this.showLoginorRegisterStep = true;

      if (this.loaderService.userRole == constants.nurseRole) {
        this.showRegister = true;
      }
    }
    if (this.showPrescription && this.loaderService.userRole != constants.nurseRole) {
      _paq.push(['trackEvent', 'Prescription Uploaded', 'Step4 Completed', 'prescription']);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

      this.loaderService.action_required = false;
    }

  }


  public autoLogin(response: any) {
    if (response["access_token"]) {
      this.loaderService.startRefreshTokenTimer(response);
      const access_token = jwtDecode(response["access_token"]);
      sessionStorage.setItem("access_token", JSON.stringify(access_token));
      sessionStorage.setItem("header", response["access_token"]);
      sessionStorage.setItem(
        "refresh_token",
        response["refresh_token"]
      );
      this.loaderService.userRole = access_token["role"];

      if (access_token["role"] == constants.patientRole) {
        this.loginService.getPatientDetails().subscribe((resp: any) => {
          sessionStorage.setItem("userDetails", JSON.stringify(resp));
          this.loaderService.user_name = resp.first_name + " " + resp.birth_name;
          this.loaderService.dob = resp.dob;
          this.spinner.show(false);
          this.loaderService.handleNavigationNodes(true);
          this.loaderService.updateLoginStatus(true);
        });
      }
    }
  }



  ShowConfirmationTab(event: any) {
    if ((this.authType.value == "1" || this.authType.value == "2") && this.loaderService.userRole !== constants.nurseRole) {
      this.bookingComment = event.bookingComment;
      this.saveAppoinmentGuestFlowafterLogin(event);
    } else {
      this.saveAppointmentNormalFlow(event);
    }
  }
  saveAppointmentNormalFlow(event: any) {
    const access_token = JSON.parse(sessionStorage.getItem("access_token"));
    let a_data;
    let payload;
    const isoDate = new Date(this.stepconsolidate.appointment);
    payload = {
      slot_group_id: this.slotGroupId,
      slot_time: this.stepconsolidate.appointment ? moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z" : null,
      capacity: 0,
      tests: this.selectedtest ? [this.selectedtest?.id] : [],
      test_comments: this.selectedtest ? {
        [(this.selectedtest?.id).toString()]: event.bookingComment,
      } : { [""]: event.bookingComment },
    };
    let docs: any[] = [];
    this.documents.forEach((element: any) => {
      if (element.PrescriptionParts.length > 0) {
        let pdfDoc = element.PrescriptionParts.filter(i => i.type == "pdf");
        let doc = {
          prescriptions_pages: element.PrescriptionParts.map(
            (s: any) => s.prescriptionPartData
          ),
          grey_scale: pdfDoc?.length > 0 ? true : false,
        };
        docs.push(doc);
      }
    });
    a_data = {
      lab: this.selectedLab,
      test: this.selectedtest,
      s_time: this.stepconsolidate.appointment,
      slot_group_id: this.slotGroupId,
      slot_time: isoDate ? moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z" : null,
      tests: this.selectedtest ? [this.selectedtest?.id] : [],
      favourite_lab: this.favoroteSamplingStationids,
      prescriptions: docs,
      sampling_station_id: this.s_id,
      test_comments: event.bookingComment,
      user_name: this.loaderService.user_name,
      dob: this.loaderService.dob,
      fromAppointmentLogin: false,
      calendar_id: this.slotData.calendar_id
    };
    payload["prescriptions"] = docs;
    payload["sampling_station_id"] = this.s_id;

    sessionStorage.setItem("a_data", JSON.stringify(a_data));
    if (event.isConfirmed) {

      if (access_token && access_token?.role == 1) {
        // receptionist is booking appointment for customer
        payload["patient_id"] = this.loaderService.bookingPatientId;
      } else {
        payload["patient_id"] = this.loaderService.id;
      }
      this.spinner.show(true);
      if (this.loaderService.flow_detail == constants.inside_lab_flow) {
        payload["is_arrived"] = true;
      }
      payload["from_admin"] = this.isLoginReceptionist == true;
      this.patientService.saveAppointment(payload).subscribe(
        (response: any) => {
          this.authType.setValue("");
          response;
          this.spinner.show(false);
          a_data["turn"] = response.turn ? response.turn : response?.appointment_code;
          a_data["requestNumber"] = response.appointment_code;
          a_data["isKioskAvailable"] = response.turn ? true : false;
          a_data["appointmentTolerance"] = response.appointment_tolerance;
          a_data["inside_lab_flow"] = this.loaderService.flow_detail == constants.inside_lab_flow;
          sessionStorage.setItem("a_data", JSON.stringify(a_data));
          sessionStorage.setItem("a_code", JSON.stringify(response.appointment_code));

          this.isConfirmed = event.isConfirmed;
          this.showLogin = event.showLogin;
          this.showConfirmBooking = event.showConfirmBooking;
          this.bookingComment = event.bookingComment;
          this.loaderService.rmMode = false;
          _paq.push(['trackEvent', 'Appointment Booked', 'Success', 'Success']);

        },
        (errorResponse: HttpErrorResponse) => {
          if (errorResponse.error.internal_code === 4001 && errorResponse.error.code == 400) {
            this.spinner.show(false);
            this.messageService.alert(
              this.translate.instant("view-prescription.prescription-filesize-update-error"),
              5000
            );
          }
          else if (errorResponse.error.internal_code === 425) {
            this.spinner.show(false);
            this.messageService.alert(
              this.translate.instant("view-booking.already-booked-error"),
              5000
            );
          } else if (errorResponse.error.internal_code === 424) {
            this.spinner.show(false);
            this.messageService.alert(
              this.translate.instant("view-booking.booking-full-error"),
              5000
            );
          } else {
            this.spinner.show(false);
            this.messageService.alert(
              this.translate.instant(
                "Common.GlobalMessages.InternalServerErrorMessage"
              ),
              5000
            );
          }
        }
      );
    } else {
      this.finalStep = "stepper-flow.confirm-booking";
      this.showLogin = event.showLogin;
      this.showRegister = event.showRegister;
      this.showConfirmBooking = event.showConfirmBooking;
      this.isConfirmed = event.isConfirmed;
      this.bookingComment = event.bookingComment;
    }
  }

  loginSuccessfull() {
    this.loginSuccessful = true;

    this.authType.setValue("1");
    this.stepconsolidate.loggedIn = "Loggedin";
    _paq.push(['trackEvent', 'Login', 'Login Step Completed', 'Loggedin']);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    this.userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
    if (
      (this.loaderService.bookingPatientId) ||
      this.loaderService.userRole == constants.patientRole
    ) {
      this.showLoginorRegisterStep = false;

    } else {
      this.showLoginorRegisterStep = true;

      if (this.loaderService.userRole == constants.nurseRole) {
        this.showRegister = true;
      }
    }

    if (!this.showLoginorRegisterStep) {
      this.deleteItem(constants.login)
    }

    if (this.userDetails) {
      this.deleteItem(constants.login)
    }
    this.showConfirmBooking = true;
    this.step = this.loaderService.flow_arr[this.currentindex];
    this.loaderService.handleNavigationNodes(true);
  }
  saveAppoinmentGuestFlowafterLogin(event: any) {

    const that = this;
    this.favoroteSamplingStationids.forEach((value) => {
      const datasaveFavorite = {
        sampling_station_id: [value],
        patient_id: this.loaderService.id,
        is_active: true,
      };
      that.patientService
        .savePatientFavoriteSamplingStations(datasaveFavorite)
        .subscribe();
    });

    let payload;
    let docs: any[] = [];
    this.documents.forEach((element: any) => {
      if (element.PrescriptionParts.length > 0) {
        let pdfDoc = element.PrescriptionParts.filter(i => i.type == "pdf");
        let doc = {
          prescriptions_pages: element.PrescriptionParts.map(
            (s: any) => s.prescriptionPartData
          ),
          grey_scale: pdfDoc?.length > 0 ? true : false,

        };
        docs.push(doc);
      }
    });
    if (this.slotGroupId > 0) {
      payload = {
        slot_group_id: this.slotGroupId,
        slot_time: moment.utc(new Date(this.selectedAppointment)).local().format('YYYY-MM-DDTHH:mm:SS') + "Z",
        tests: this.selectedtest ? [this.selectedtest?.id] : [],
        patient_id: this.loaderService.id,
        prescriptions: docs,
        sampling_station_id: this.s_id,
        test_comments: this.selectedtest ? { [this.selectedtest?.id.toString()]: event.bookingComment } : { [""]: this.bookingComment },



      };
    } else {
      payload = {
        tests: this.selectedtest ? [this.selectedtest?.id] : [],
        patient_id: this.loaderService.id,
        prescriptions: docs,
        sampling_station_id: this.s_id,
        slot_time: this.slotTime.value ? moment.utc(new Date(this.slotTime.value)).local().format('YYYY-MM-DDTHH:mm:SS') + "Z" : null,
        test_comments: this.selectedtest ? { [this.selectedtest?.id.toString()]: event.bookingComment } : { [""]: this.bookingComment },
      };
    }
    payload["from_admin"] = this.isLoginReceptionist == true;
    payload["user_name"] = this.loaderService.user_name;
    payload["dob"] = this.loaderService.dob;
    payload["test"] = this.selectedtest;
    payload["fromRegister"] = false;
    const isoDate = new Date(this.stepconsolidate.appointment);
    let a_data = {
      lab: this.selectedLab,
      test: this.selectedtest ? this.selectedtest : "",
      s_time: this.stepconsolidate.appointment ? this.stepconsolidate.appointment : "",
      slot_group_id: this.slotGroupId,
      slot_time: isoDate ? moment.utc(isoDate).local().format('YYYY-MM-DDTHH:mm:SS') + "Z" : null,
      tests: this.selectedtest ? [this.selectedtest?.id] : "",
      user_name: this.loaderService.user_name,
      dob: this.loaderService.dob,
      test_comments: event.bookingComment,
      fromAppointmentLogin: true,
      calendar_id: this.slotData.calendar_id
    };
    sessionStorage.setItem("a_data", JSON.stringify(a_data));
    this.spinner.show(true);
    if (this.loaderService.flow_detail == constants.inside_lab_flow) {
      payload["is_arrived"] = true;
    }
    this.patientService.saveAppointment(payload).subscribe(
      (response: any) => {
        this.authType.setValue("");
        this.spinner.show(false);
        this.loaderService.rmMode = false;
        a_data["turn"] = response.turn ? response.turn : response?.appointment_code;
        a_data["requestNumber"] = response.appointment_code;
        a_data["isKioskAvailable"] = response.turn ? true : false;
        a_data["appointmentTolerance"] = response.appointment_tolerance;
        a_data["inside_lab_flow"] = this.loaderService.flow_detail == constants.inside_lab_flow;
        sessionStorage.setItem("a_data", JSON.stringify(a_data));
        sessionStorage.setItem("a_code", JSON.stringify(response.appointment_code));
        _paq.push(['trackEvent', 'Appointment Booked', 'Success', 'Success']);
        this.loaderService.highlightLeftMenu(['patient/confirm-appointment']);
      },
      (errorResponse: HttpErrorResponse) => {
        if (errorResponse.error.internal_code === 4001 && errorResponse.error.code == 400) {
          this.spinner.show(false);
          this.messageService.alert(
            this.translate.instant("view-prescription.prescription-filesize-update-error"),
            5000
          );
        }
        else if (errorResponse.error.internal_code === 425) {
          this.spinner.show(false);
          this.messageService.alert(
            this.translate.instant("view-booking.already-booked-error"),
            5000
          );
        } else if (errorResponse.error.internal_code === 424) {
          this.spinner.show(false);
          this.messageService.alert(
            this.translate.instant("view-booking.booking-full-error"),
            5000
          );
        } else {
          this.spinner.show(false);
          this.messageService.alert(
            this.translate.instant(
              "Common.GlobalMessages.InternalServerErrorMessage"
            ),
            5000
          );
        }
      }
    );
  }

  RegisterSuccessful(data: any) {
    let payload = data;
    this.loaderService.user_name =
      payload.first_name + " " + payload.birth_name;
    this.loaderService.dob = payload.dob;
    setTimeout(() => {
      this.stepconsolidate.loggedIn = "";
      this.authType.setValue("2");

      this.showConfirmBooking = true;
      this.showActivateMessage = true;

      _paq.push(['trackEvent', 'Register', 'Register Step Completed', 'register']);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      this.registerSuccessful = true;// For Chrome, Firefox, IE and Opera
    }, 500);
    this.showConfirmBooking = true;

    this.deleteItem(constants.login)
    this.step = this.loaderService.flow_arr[this.currentindex];
  }

  protected getUpdateDocument(event: any) {
    this.uploadStatus = false;
    let currDoc = this.documents[0];
    if (currDoc) {
      currDoc = event;
    }
    const found = this.documents[0].PrescriptionParts.length !== 0
    if (this.userDetails) {
      this.loginSuccessful = true;
      this.showConfirmBooking = true;
      this.deleteItem(constants.login);
    }
    if (!found) {
      this.noDocuments = true;
    } else {
      this.noDocuments = false;
    }
  }

  protected getClickImageStatus(event: any) {
    this.isClicked = event;
    this.uploadStatus = false;
  }

  ngOnDestroy() {
    // Reset the service variable when the component is destroyed
    this.currentindex = 0;
    this.loaderService.setVariable(this.currentindex);
    this.loaderService.rmMode = false;
    this.loaderService.rm_id = "";
  }

  haveAppointment(evt: any) {
    console.log(evt);
    if (evt) {
      this.loaderService.flow_arr = this.rconstantsarr.inside_lab_withappointment;
    }
    else {
      this.loaderService.flow_arr = this.rconstantsarr.inside_lab_withpresc;
    }
  }

  haveFile(evt: any) {
    this.isWithAppointment = false;
    if (evt) {
      this.isWithAppointment = true;
    }
    if (!this.isWithAppointment) {
      this.stepconsolidate.appointment = ""
      this.deleteItem(constants.select_slot);
    }
    this.flowArrChange();
  }

  havePrescription(evt: any) {
    this.havePres = false;
    if (evt) {
      this.havePres = true;
    }
    this.flowArrChange();
  }
  flowArrChange() {
    if (this.havePres) {
      this.selectedtest = this.generictest
      if (this.loaderService.flow_detail == constants.book_app_flow) {
        this.allSamplingStation = [...this.overallSamplingStation];
      }
      switch (this.loaderService.flow_detail) {
        case constants.inside_lab_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.inside_lab_withpresc];
          break;
        case constants.book_app_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.book_withpresc];
          break;
        case constants.findlab_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.selectlab_withpresc];
          break;
        case constants.website_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.website_withpresc];
          break;
        case constants.recep_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.recep_flow_withpresc];
          break;
        case constants.favlab_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.favlab_withpresc];
          break;
      }
    }
    else {
      switch (this.loaderService.flow_detail) {
        case constants.inside_lab_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.inside_lab_withoutpresc];
          break;
        case constants.book_app_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.book_withoutpresc];
          break;
        case constants.findlab_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.selectlab_withoutpresc];
          break;
        case constants.website_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.website_withoutpresc];
          break;
        case constants.recep_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.recep_flow_withoutpresc];
          break;
        case constants.favlab_flow:
          this.loaderService.flow_arr = [...this.rconstantsarr.favlab_withoutpresc];
          break;
      }
    }
    if (this.isWithAppointment && this.s_id > 0 && this.selectedtest?.id > 0
      && ((this.loaderService.flow_detail == this.constants.book_app_flow && this.slotData?.slots.length == 0)
        || (this.loaderService.flow_detail == this.constants.findlab_flow || this.loaderService.flow_detail == this.constants.favlab_flow || this.loaderService.flow_detail == this.constants.recep_flow || this.loaderService.flow_detail == this.constants.website_flow))) {
      this.slotData = new DateSlot();
      this.getSlots()
    }
    if (this.userDetails || this.loaderService.bookingPatientId) {
      this.deleteItem(constants.login);
    }
    if (!this.isWithAppointment) {
      this.deleteItem(constants.select_slot);
    }
  }
  showTokenNumber(evt: any) {
    this.isKioskAvailable = evt?.isKioskAvailable;
    this.tokenNumber = evt?.tokenNumber;
    this.appointmentTolerance = evt?.appointmentTolerance
    this.goNext();
  }

  goNext() {
    this.currentindex++;
    this.step = this.loaderService.flow_arr[this.currentindex];
    this.loaderService.setVariable(this.currentindex);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  goBack() {
    this.currentindex--;
    this.step = this.loaderService.flow_arr[this.currentindex];
    this.loaderService.setVariable(this.currentindex);
    window.scrollTo(0, 0);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  getAllSSByTestId(testId: number) {
    this.spinner.show(true);
    this.allSamplingStation = [];
    this.bookingAppointmentService.getAdminSamplingStaions(testId).subscribe(
      (response) => {
        if (response["sampling_stations"]) {

          this.allSamplingStation = response["sampling_stations"];
          this.spinner.show(false);
        }
      });
  }
  getAllSSByTestExtId(testExtId: number) {
    this.spinner.show(true);
    this.allSamplingStation = [];
    this.bookingAppointmentService.getAdminTestSortedByExtId(testExtId).subscribe(
      (response) => {
        if (response["sampling_stations"]) {

          this.allSamplingStation = response["sampling_stations"];
          this.spinner.show(false);
        }
      });
  }

  getSSDetail() {
    this.spinner.show(true);
    if (this.test_ext_id) {
      this.bookingAppointmentService.getAdminSamplingStaionsByTestExtId(this.test_ext_id).subscribe(
        (response) => {
          if (response["sampling_stations"]) {
            this.spinner.show(false);
            this.allSamplingStation = response["sampling_stations"];
            this.overallSamplingStation = response["sampling_stations"];
            this.selectedLab = this.allSamplingStation.find(i => i.external_id == this.ss_id);
            this.s_id = this.selectedLab?.id
            if (this.selectedtest?.id > 0 && this.loaderService.flow_detail == constants.massiveflowwithtestlab) {
              this.getSlots()
            } else if (this.ss_id && this.s_id == 0) {

              this.messageService.alert(
                this.translate.instant(
                  "backend-errors.500"),
                5000
              );
              this.router.navigate(["patient/book-appointment"]);

            }
          } else {
            this.messageService.alert(
              this.translate.instant(
                "backend-errors.500"),
              5000
            );
            this.router.navigate(["patient/book-appointment"]);
          }
        }, () => {
          this.messageService.alert(
            this.translate.instant(
              "backend-errors.500"),
            5000
          );
          this.router.navigate(["patient/book-appointment"]);

        });

    } else {
      this.bookingAppointmentService.getAdminSamplingStaionByExtId(this.ss_id).subscribe(
        (response) => {
          if (response["sampling_stations"]) {
            this.spinner.show(false);
            this.allSamplingStation = response["sampling_stations"];
            this.overallSamplingStation = response["sampling_stations"];
            this.selectedLab = this.allSamplingStation.find(i => i.external_id == this.ss_id);
            this.s_id = this.selectedLab?.id
          } else {
            this.messageService.alert(
              this.translate.instant(
                "backend-errors.500"),
              5000
            );
            this.router.navigate(["patient/book-appointment"]);
          }
        }, () => {
          this.messageService.alert(
            this.translate.instant(
              "backend-errors.500"),
            5000
          );
          this.router.navigate(["patient/book-appointment"]);


        });
    }
  }

  getBirthCities() {
    this.commonAPi.getBirthCities().subscribe(
      (data: any) => {
        if (this.commonAPi.birthCitiesList.value == null) {
          this.commonAPi.birthCitiesList.next(data)
        }
      })
  }

  checkDocument() {
    if (this.documents[0].PrescriptionParts.length !== 0) {
      this.goNext();
    }
    else {
      this.noDocuments = true;
      if (this.noDocuments && !(this.loaderService.userRole == this.nurseRole)) {
        this.toastr.warning(this.translate.instant("upload-pic.no-prescription"));
      }
    }
  }

  openForgotPassword(evt: any) {
    this.progressPercent = evt;
    this.showLoginPrebook = false; this.forgotPassword = true;
  }
  redirectBooking() {
    this.loaderService.reloadComponent();
  }
}
