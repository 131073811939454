import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgOtpInputConfig } from 'ng-otp-input';
import { AuthenticationService } from '../../services/authentication.service';
import { LoaderService, MessageService } from '../../../main';
import { TranslateService } from '@ngx-translate/core';
import { FindAccountResponse } from '../find-my-account/find-my-account.model';
import { PatientService } from '../../services/patient.service';
import { int } from '@zxing/library/esm/customTypings';
import { OtpStatusService } from '../../../main/services/otpstatus.service';
import { MessageStatus } from '../../../shared/enums/message-status.enum';

@Component({
  selector: 'app-verify-my-account',
  templateUrl: './verify-my-account.component.html',
  styleUrls: ['./verify-my-account.component.scss']
})
export class VerifyMyAccountComponent implements OnInit {
  public display: any;
  public btndisabled = true;
  public title: string = 'verify-my-account.title';
  public step: number = 1;
  public selectedAccountId = new FormControl('');
  public selectedAccount: FindAccountResponse;
  modecheck: string;
  uiModeforSentOtp: int;
  PhoneNumber: any;
  messageId: string;
  userEmail: any;
  idForststus: string;
  timerInterval = null;
  phoneSelected: boolean;
  phoneSelectedMessage: boolean;
  otpClickCount = 0;
  disableEmail = false;
  displayMessage: any = 0;


  @Output() closeEvent: EventEmitter<any> = new EventEmitter<any>();
  config: NgOtpInputConfig = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
  };
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  public verifyID: any;
  public otp: string;
  public patientData: any;
  disableSms = false;
  disableWhatsapp = false;
  lengthOfData: boolean;
  onlyPhoneNo:boolean;
  whatsappmessage:boolean;
  constructor(
    private readonly dialogRef: MatDialogRef<VerifyMyAccountComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private messageService: MessageService,
    public loader: LoaderService,
    private translate: TranslateService,
    public otpStatus: OtpStatusService,
    public patientService: PatientService) { }

  ngOnInit(): void {
    if (this.data?.dynamicData.length === 1) {
      this.lengthOfData = true;
    } else {
      this.lengthOfData = false;
    }
    this.selectedAccount = this.data?.dynamicData[0];
    this.userEmail = this.selectedAccount.email;
    this.PhoneNumber = this.selectedAccount.phone_number;
    if (!this.selectedAccount?.email) {
      this.title = "verify-my-account.phone-title";
      this.phoneSelected = true;
      this.phoneSelectedMessage = true;
      this.modecheck = "sms";
      this.onlyPhoneNo=true;
    } else {
      this.phoneSelected = false;
      this.phoneSelectedMessage = false;
      this.modecheck = "mail";
      this.onlyPhoneNo=false;
    }

    if (this.data?.dynamicData?.length == 1) {
      this.selectedAccount = this.data?.dynamicData[0];
      this.selectedAccountId.setValue(this.selectedAccount.id);

    }
  }



  // verifyOTP() {
  //   this.dialogRef.close(null);
  // }

  public sendOTP(incomingmode?: string) {
    this.btndisabled = true
    if (incomingmode == "sms" || incomingmode == "whatsapp") {
      this.modecheck = incomingmode;
      if(incomingmode == "whatsapp"){
        this.whatsappmessage=true;
      }else{
        this.whatsappmessage=false;
      }
     
      this.phoneSelectedMessage = true;
      if (this.otpClickCount == 1) {
        this.displayMessage = 0;
      }
    } else if (incomingmode == 'mail') {
      this.phoneSelectedMessage = false;
      this.modecheck = incomingmode;
      // this.otpClickCount++;
    }
    if (this.otpClickCount < 1) {
      this.disableEmail = true;
      this.displayMessage = 1;
    }

    this.uiModeforSentOtp = 0;
    this.idForststus = this.selectedAccount.unique_id;
  

    const req = {
      id: this.selectedAccount.unique_id,
      email: "",
      ipp_id: this.selectedAccount.id,
      // 
      mode: this.modecheck,
      messageid: this.messageId,
      phone_number: ""
    };

    this.authService.sendOTP(req).subscribe(
      (response) => {
        this.verifyID = response.verify_id;
        this.messageId = response.message_id;
        this.timer();
        this.step = 2;
        this.title = 'verify-my-account.otp-entermessage';
        // this.loginStatus();
      },
      (err: { error: { code: any; message: string }; message: any; code: any }) => {
        if (err.error.code == 422) {
        } else if (err.error.code == 400) {
          this.timer();
          this.loginStatus();
          this.otpClickCount++;
        } else {
          this.messageService.alert(err.error.message);
        }
      }
    );
  }

  public onOtpChange(otp: string) {
    this.otp = otp;
  }

  public verifyOTP() {
    const updateEmailData = {
      id: this.selectedAccount.unique_id,
      email: "",
      ipp_id: this.selectedAccount.id,
      otp: this.otp,
      verify_id: this.verifyID,
      phone_number: "",
      mode: this.modecheck,
    };

    this.authService.verifyOTP(updateEmailData).subscribe(
      (response: FindAccountResponse) => {
        this.selectedAccount.id = response.id;
        this.getPatientInfo(response.id);
      },
      (err: {
        error: { code: any; message: string };
        message: any;
        code: any;
      }) => {
        if (err.error.code == 422) {
          this.messageService.alert(
            this.translate.instant("login.invalid-otp")
          );
        } else {
          this.messageService.alert(err.error.message);
        }

      });
  }

  accountSelectionChange() {
    if (this.selectedAccountId.value) {
      this.selectedAccount = this.data.dynamicData.find((i: any) => i.id == this.selectedAccountId.value);
    }
  }

  timer() {
    const minute = 5;
    let seconds = minute * 60;
    let statSec = 60;
    const prefix = minute < 10 ? "0" : "";
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    const startTime = Date.now();
    const endTime = startTime + seconds * 1000;

    this.timerInterval = setInterval(() => {
      const now = Date.now();
      const remainingTime = Math.max(0, endTime - now);
      seconds = Math.floor(remainingTime / 1000);

      if (remainingTime === 0) {
        clearInterval(this.timerInterval);
        this.btndisabled = false;
        // this.uiModeforSentOtp = 1;

      }

      const minutes = Math.floor(seconds / 60);
      statSec = seconds % 60;

      this.display = `${prefix}${minutes}:${statSec.toString().padStart(2, "0")}`;
    }, 1000);
  }

  getPatientInfo(id: string) {
    this.patientService.getPatientInfo(id).subscribe((response: any) => {
      this.patientData = response;
      this.dialogRef.close({ verified: true, otp: this.otp, data: response, unique_id: id, verify_id: this.verifyID });
    });
  }
  loginStatus() {
    let intervalId: any;
    let timeoutId: any;


    setTimeout(() => {
      intervalId = setInterval(() => {
        let attemptCount = 0;

        this.otpStatus.loginStatus(null, '', true, this.messageId, this.modecheck, this.idForststus).subscribe(
          (response: any) => {
            if (response.phone != "") {
              this.PhoneNumber = response.phone;
            }
            // Dropped Deferred Bounced then go to sms whatsapp message page
            // Delivered  then wait for user to enter otp

            if ((response.message_status === MessageStatus.Deferred ||
              response.message_status === MessageStatus.Dropped ||
              response.message_status === MessageStatus.Bounced ||
              response.message_status === MessageStatus.Failed)) {
              this.uiModeforSentOtp = 1;
              this.showFailureMessage();
              this.btndisabled = false;
              clearInterval(intervalId);
              clearTimeout(timeoutId);
              return;
            }else if (response.message_status === "Unknown" || response.message_status === "unknown") {
              attemptCount++;
              if (attemptCount >= 28) {
                this.btndisabled = false;
                return;
              }
            } else {
              clearInterval(intervalId);
              clearTimeout(timeoutId);
            }
          },
          (error: any) => {
            error.error.code == 400; console.log("Message NOT Delivered found");
            this.uiModeforSentOtp = 1;
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            this.showFailureMessage()
            this.btndisabled = false;
            return;
          }
        );
      }, 10000);

      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
      }, 300000);
    });
  }
  showFailureMessage() {
    if (this.modecheck == "mail") {
      this.disableEmail = true;
      this.messageService.alert(this.translate.instant('OTP-msg.failed-mail'), 5000);
    } else if (this.modecheck == "sms") {
      this.disableSms = true;
      this.messageService.alert(this.translate.instant('OTP-msg.failed-sms'), 5000);
    } else {
      this.disableWhatsapp = true;
      this.messageService.alert(this.translate.instant('OTP-msg.failed-whatsapp'), 5000);
    }
  }

}



