import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService, MessageService } from '../../../../main';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginServiceService } from '../../../services/login-service.service';
import { FindAccountRequest, FindAccountResponse } from '../../../shared/find-my-account/find-my-account.model';
import { DatePipe } from '@angular/common';
import { SpinnerService } from '../../../../core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { RelativeMemberService } from '../../../services/relative-member.service';
import { CommonApiService } from '../../../services/common-api.service';
@Component({
  selector: 'app-find-member',
  templateUrl: './find-member.component.html',
  styleUrls: ['./find-member.component.scss']
})
export class FindMemberComponent implements OnInit {
  public activeUser: boolean = false;
  public noRecord: boolean = false;
  public isEdit: boolean = false;
  public rmInfo: any = {};
  @Input() updateList: boolean = false;
  @Output() submitRelativeMember = new EventEmitter<any>();

  @Input() sliderFormData: any;
  public firstName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters(),
  ]);
  public birthName = new FormControl("", [
    Validators.required,
    Validators.pattern(".{1,50}$"), this.loader.validateAllowedCharacters(),
  ]);
  public dateOfBirth = new FormControl("", [Validators.required]);
  public gender = new FormControl("", [Validators.required]);
  public genders: any[];
  public maxDate: Date;
  public accounts: FindAccountResponse[];

  public searchMemberForm = this.formBuilder.group({
    firstName: this.firstName,
    birthName: this.birthName,
    dateOfBirth: this.dateOfBirth,
    gender: this.gender,
  });

  constructor(public loader: LoaderService, private formBuilder: FormBuilder, public messageService: MessageService,
    public loginService: LoginServiceService, public rmService: RelativeMemberService, public spinnerService: SpinnerService,
    public translate: TranslateService, public adminService: CommonApiService,
    public toaster: ToastrService) {
    this.genders = new Array<any>();
  }

  ngOnInit(): void {
    this.maxDate = new Date();
    if (this.sliderFormData?.isEdit) {
      this.noRecord = true;
      this.isEdit = true;
      this.rmInfo = this.sliderFormData.rmInfo;
    }
    this.getGenders();
  }


  public getGenders() {
    this.loginService.getGenderMasters().subscribe((data: Array<any>) => {
      this.genders = data.sort((a, b) => a.KeyOrder - b.KeyOrder);
      this.gender.setValue(this.genders[0].Id.toString());
    });
  }

  dismiss() {
    this.loader.cancelForm();
  }

  findMember() {
    if (this.searchMemberForm.valid && this.dateOfBirth.valid) {
      this.spinnerService.show(true);
      const datePipe = new DatePipe('en-US');
      const payload: FindAccountRequest =
      {
        first_name: this.firstName.value,
        birth_name: this.birthName.value,
        gender: parseInt(this.gender.value, 10),
        dob: datePipe.transform(this.dateOfBirth.value, 'yyyy-MM-dd')
      };
      this.rmService.findMember(payload).subscribe(
        (response: FindAccountResponse[]) => {
          this.accounts = response;
          if (response?.length == 0 || response == null) {
            this.activeUser = false;
            this.noRecord = true;
          }
          else if (response?.length == 1 && response[0]?.is_active == true && response[0]?.is_self_account == true) {
            this.toaster.warning(this.translate.instant('add-member.can-not-be-added'));
          }
          else if (response?.length == 1 && response[0]?.is_already_linked == true) {
            this.toaster.warning(this.translate.instant('add-member.duplicate-success'));
          }
          else if (response?.length == 1 && response[0]?.is_self_account == true) {
            this.toaster.warning(this.translate.instant('add-member.can-not-be-added'));
          }
          else if (response?.length == 1 && response[0]?.is_already_linked_to_other_patient == true) {
            this.toaster.warning(this.translate.instant('add-member.duplicate-to-another-patient', { data: payload.first_name + " " + payload.birth_name }));
          }
          else if (response?.length == 1 && response[0]?.is_active == true) {
            this.noRecord = false;
            if (this.updateList) {
              let rmPayload = { FirstName: payload.first_name, BirthName: payload?.birth_name, LastName: "", Dob: payload.dob, id: response[0].id };
              this.loader.rmMode = true;
              this.loader.rm_id = response[0].id;
              this.loader.user_name = payload.first_name + " " + payload.birth_name;
              //this.loader.relativeMember = { user_name: payload.first_name + " " + payload.birth_name, dob: payload.dob };
              this.loader.dob = payload.dob;
              this.submitRelativeMember.emit(rmPayload);
            }
            else {
              this.loader.onFormClose(true);
              if (response[0]?.is_already_linked == true) {
                this.toaster.warning(this.translate.instant('add-member.duplicate-success'));
              } else if (response[0]?.is_self_account == true) {
                this.toaster.warning(this.translate.instant('add-member.can-not-be-added'));
              } else if (response[0]?.is_already_linked_to_other_patient == true) {
                this.toaster.warning(this.translate.instant('add-member.duplicate-to-another-patient', { data: payload.first_name + " " + payload.birth_name }));
              } else {
                this.toaster.success(this.translate.instant('add-member.success'));
              }
            }
          }
          else {
            this.activeUser = false;
            this.noRecord = false;
            this.toaster.warning(this.translate.instant('add-member.inactive-record-desc'));
          }
          this.spinnerService.show(false);
        },
        () => {
          this.spinnerService.show(false);
        }
      );
    }
  }


  public showModal() {
    if (this.updateList) {
      this.messageService
        .alert(this.translate.instant("add-member.inactive-record-desc")
        )
    }
    else {
      this.messageService
        .confirm(this.translate.instant("add-member.inactive-record-desc"), this.translate.instant("add-member.inactive-record-title"),
          this.translate.instant("add-member.resend-activation"), this.translate.instant("add-member.cancel"),
        ).subscribe((actionResult: boolean) => {
          if (actionResult) {
            //this.sendPasswordLink();
          }
        });
    }
  }

  goBack() {
    this.noRecord = false;
  }
}
